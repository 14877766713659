import React from 'react';

const SvgInfo = props => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    {...props}
    stroke={props.color}
  >
    <g fill="none" fillRule="evenodd" stroke={props.color}>
      <circle strokeWidth={1.6} cx={16} cy={16} r={14} />
      <g transform="rotate(180 8.5 11.5)">
        <path d="M1 1.416v5.056" strokeWidth={1.8} strokeLinecap="round" />
        <circle fill={props.color} cx={1} cy={11.3} r={1} />
      </g>
    </g>
  </svg>
);

SvgInfo.defaultProps = {
  color: '#1b1b1b',
};

export default SvgInfo;
