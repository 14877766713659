export const SET_CLIENT_INFORMATION = 'GENERAL_STATE/SET_CLIENT_INFORMATION';
export const SET_USER = 'GENERAL_STATE/SET_USER';
export const SET_DOCUMENT_TYPES = 'GENERAL_STATE/SET_DOCUMENT_TYPES';
export const SET_DEV_MODE = 'GENERAL_STATE/SET_DEV_MODE';

/**
 * @function setClientInformation
 * @description Set information of the logged in client
 */
export const setClientInformation = clientInfo => ({
  type: SET_CLIENT_INFORMATION,
  payload: clientInfo,
});

/**
 * @function setUser
 * @description Set state of logged in user
 */
export const setUser = user => ({
  type: SET_USER,
  payload: user,
});

/**
 * @function setUser
 * @description Set state of logged in user
 */
export const setDevMode = isDevMode => ({
  type: SET_DEV_MODE,
  payload: isDevMode,
});

export const setDocumentTypes = documentTypes => ({
  type: SET_DOCUMENT_TYPES,
  payload: documentTypes,
});
