import { SET_CLIENTS } from './clientManagement.actions';

const initialState = {
  clients: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CLIENTS:
      return { ...state, clients: action.payload };

    default:
      return state;
  }
};
