import { apolloClient } from '../apollo/apollo';
import {
  getCurrentClient,
  getClientIssuedCerts,
  getAllClients,
  getClientByID,
  getClientIssuerNames,
  getConnectedUsers,
  sendEmail,
  getSmsCount,
  getVIPClients,
} from '../graphql/queries/clients';
import {
  inviteUserToClient,
  updateClientByID,
  removeConnectedUsers,
  deleteClient,
  updateConnectionRequest,
  addConnections,
  bulkSetClients,
  deployClients,
  pushDeployClientCommandToQueue,
} from '../graphql/mutations/clients';

export default {
  async getClientTheme() {
    return apolloClient.query({
      query: getCurrentClient,
    });
  },

  async getSmsCount(clientId, startDate, endDate) {
    return apolloClient.query({
      query: getSmsCount,
      fetchPolicy: 'no-cache',
      variables: {
        clientId,
        startDate,
        endDate,
      },
    });
  },

  async getClientIssuedCerts(clientId) {
    return apolloClient.query({
      query: getClientIssuedCerts,
      fetchPolicy: 'no-cache',
      variables: {
        clientId,
      },
    });
  },

  async getAllClients() {
    return apolloClient.query({
      query: getAllClients,
      fetchPolicy: 'no-cache',
    });
  },

  async getVIPClients() {
    return apolloClient.query({
      query: getVIPClients,
      fetchPolicy: 'no-cache',
    });
  },

  async getClientByID(id) {
    return apolloClient.query({
      query: getClientByID,
      fetchPolicy: 'no-cache',
      variables: {
        clientId: id,
      },
    });
  },

  async updateClientByID(client) {
    return apolloClient.mutate({
      mutation: updateClientByID,
      variables: {
        ...client,
        customText: {
          additionalText: client?.customText?.additionalText,
          labResultText: client?.customText?.labResultText,
        },
      },
    });
  },

  async bulkSetClients(clients) {
    return apolloClient.mutate({
      mutation: bulkSetClients,
      variables: { clients },
    });
  },

  async inviteUserToClient(user) {
    return apolloClient.mutate({
      mutation: inviteUserToClient,
      variables: {
        clientId: user.clientId,
        email: user.email,
        name: user.name,
      },
    });
  },

  async deleteClient(clientId) {
    return apolloClient.mutate({
      mutation: deleteClient,
      variables: {
        id: clientId,
      },
    });
  },

  async clientIssuerNames() {
    return apolloClient.query({
      query: getClientIssuerNames,
    });
  },

  async sendEmail({ clientId, subject, text, emails }) {
    return apolloClient.query({
      query: sendEmail,
      variables: {
        clientId,
        subject,
        text,
        emails,
      },
    });
  },

  async getConnectedUsers(clientId, status, isActive) {
    return apolloClient.query({
      query: getConnectedUsers,
      fetchPolicy: 'no-cache',
      variables: {
        clientId,
        status,
        isActive,
      },
    });
  },

  async updateConnectionRequest(userId, status, clientId, connectionId) {
    return apolloClient.mutate({
      mutation: updateConnectionRequest,
      variables: {
        userId,
        status,
        clientId,
        connectionId,
      },
    });
  },

  async removeConnectedUsers(users, clientId) {
    return apolloClient.query({
      query: removeConnectedUsers,
      variables: {
        users,
        clientId,
      },
    });
  },

  async addConnections(connections, clientId) {
    return apolloClient.mutate({
      mutation: addConnections,
      variables: {
        connections,
        clientId,
      },
    });
  },

  async pushDeployClientCommandToQueue(client) {
    return apolloClient.mutate({
      mutation: pushDeployClientCommandToQueue,
      variables: {
        client,
      },
    });
  },

  async deployClients(clients) {
    return apolloClient.mutate({
      mutation: deployClients,
      variables: {
        clients,
      },
    });
  },
};
