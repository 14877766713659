import React, { useEffect, useState } from 'react';

import CustomLabel from '../CustomLabel';
import chartsColors from '../../../../../helpers/chartsColors';

import { PieChart, Pie, Cell, Label } from 'recharts';

const PieGraph = ({
  hidden,
  data,
  width,
  setHoveredSubType,
  hoveredSubType,
  keys,
}) => {
  const [graphData, setGraphData] = useState([]);
  useEffect(() => {
    const renderMonthlyPieGraph = () => {
      const results = [];

      const lastData = data[data.length - 1];
      const keys = Object.keys(lastData);
      keys.forEach(key => {
        if (key === 'name' || key === 'undefined') return;
        const keyValue = lastData[key];
        results.push({ pv: keyValue, name: key });
      });

      setGraphData(results);
    };

    renderMonthlyPieGraph();
  }, [data]);

  return (
    <PieChart
      width={width}
      height={300}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      {graphData && (
        // check if all non-hidden subtypes value are zero, if yes show a no value graph
        <Pie
          data={
            graphData.filter(x => hidden.indexOf(x.name) === -1 && x.pv !== 0)
              .length === 0
              ? // make sure still display graph with value = 1 when all subtypes chosen has zero issued
                [{ name: 'No Value', pv: 1 }]
              : graphData.filter(x => hidden.indexOf(x.name) === -1)
          }
          dataKey="pv"
          outerRadius={120}
          innerRadius={90}
          cx="50%"
          cy="50%"
          nameKey="name"
          minAngle={5}
        >
          {graphData.filter(x => hidden.indexOf(x.name) === -1 && x.pv !== 0)
            .length === 0 ? (
            <Cell fill={'rgb(209,209,209)'} />
          ) : (
            graphData
              .filter(x => hidden.indexOf(x.name) === -1)
              .map(val => (
                <Cell
                  key={`${val.name}}`}
                  onMouseEnter={() => setHoveredSubType(val.name)}
                  fill={
                    // map the colours
                    chartsColors[
                      keys.indexOf(val.name) !== -1
                        ? keys.indexOf(val.name)
                        : 20
                    ]
                  }
                  fillOpacity={val.name === hoveredSubType ? 1 : 0.7}
                />
              ))
          )}
          <Label
            content={
              <CustomLabel
                data={graphData}
                includes={!hidden.includes(hoveredSubType)}
                hoveredSubType={hoveredSubType}
                color={
                  chartsColors[
                    hoveredSubType != null ? keys.indexOf(hoveredSubType) : 0
                  ]
                }
              />
            }
            position="center"
          />
        </Pie>
      )}
    </PieChart>
  );
};
export default PieGraph;
