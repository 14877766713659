import { apolloClient } from '../apollo/apollo';
import { issueLogByClient } from '../graphql/queries/certificateLog';

export default {
  async issueLogByClient(clientId, startDate, endDate) {
    return apolloClient.query({
      query: issueLogByClient,
      fetchPolicy: 'no-cache',
      variables: {
        clientId,
        startDate,
        endDate,
      },
    });
  },
};
