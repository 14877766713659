import gql from 'graphql-tag';

export const getCertificatesByClientId = gql`
  query certificatesByClientId(
    $clientId: ID!
    $centerId: ID
    $filter: String
    $limit: Int
    $offset: Int
    $fromDate: String
    $toDate: String
    $subType: String
  ) {
    certificatesByClientId(
      clientId: $clientId
      centerId: $centerId
      filter: $filter
      limit: $limit
      offset: $offset
      fromDate: $fromDate
      toDate: $toDate
      subType: $subType
    ) {
      id
      userId
      passNo
      ssn
      holderName
      name
      result
      dateOfBirth
      isVerified
      issuedAt
      isClaimed
      isRevoked
      validUntil
      courseName
      createdAt
      pdfPath
      exportPdfJobId
      exportPdfStatus
      courseId
      issuingUser {
        name
      }
      user {
        email
      }
    }
  }
`;

export const getCertificatesCountInMonth = gql`
  query certificatesCountInMonth(
    $clientId: ID!
    $startMonth: Int
    $endMonth: Int
    $subType: [String]
    $isClientGroup: Boolean
    $groupByLab: Boolean
  ) {
    certificatesCountInMonth(
      clientId: $clientId
      startMonth: $startMonth
      endMonth: $endMonth
      subType: $subType
      isClientGroup: $isClientGroup
      groupByLab: $groupByLab
    ) {
      _id {
        month
        subType
        date
        labName
      }
      count
    }
  }
`;

export const getCertificatesCountInRange = gql`
  query certificatesCountInRange(
    $clientId: ID!
    $startDate: String
    $endDate: String
  ) {
    certificatesCountInRange(
      clientId: $clientId
      startDate: $startDate
      endDate: $endDate
    )
  }
`;

export const getIssuedDocsRange = gql`
  query getIssuedDocsRange(
    $clientId: ID!
    $startDate: String!
    $endDate: String!
    $subType: [String]
  ) {
    getIssuedDocsRange(
      clientId: $clientId
      startDate: $startDate
      endDate: $endDate
      subType: $subType
    ) {
      _id {
        clientId
        issuerName
      }
      count
    }
  }
`;

export const getCertificatesByCourseId = gql`
  query certificatesByCourseId($courseId: ID!) {
    certificatesByCourseId(courseId: $courseId) {
      id
      userId
      holderName
      name
      isVerified
      isRevoked
      validUntil
      issuedAt
      dateOfBirth
      ssn
      courseId
      issuedBy
      isClaimed
      logoImage
      certificateNumber
      certificateInformation
      courseStart
      courseEnd
      trainingProviderAddress
      trainingProviderCity
      trainingProviderZip
      trainingProviderCountry
      location2
      isRevoked
      qrCodeImage
      qrCodeImagePath
      locationImage
      watermarkImage
      imageId
      additionalImage
      type
      certType
      passNo
      attachment
      attachment2
      signers {
        name
        role
        image
      }
    }
  }
`;

export const certificateTemplate = gql`
  query certificateTemplate($certificateTemplateId: ID!) {
    certificateTemplate(certificateTemplateId: $certificateTemplateId) {
      id
      name
      clientId
    }
  }
`;

export const getMIDImage = gql`
  query getMIDImage($imageKey: String!) {
    getMIDImage(imageKey: $imageKey) {
      image
    }
  }
`;

export const getSignedFile = gql`
  query getSignedFile($filePath: String!) {
    getSignedFile(filePath: $filePath) {
      file
    }
  }
`;

export const publicDocAuthentication = gql`
  query publicDocAuthentication(
    $certId: String!
    $password: String
    $login: Boolean
  ) {
    publicDocAuthentication(certId: $certId, password: $password, login: $login)
  }
`;

export const getBulkSignedFile = gql`
  query getBulkSignedFile($filePath: String!) {
    getBulkSignedFile(filePath: $filePath)
  }
`;

export const getCertificatesByUser = gql`
  query certificatesByUserId($userId: ID!) {
    certificatesByUserId(userId: $userId) {
      id
      userId
      holderName
      name
      isVerified
      issuedAt
      isClaimed
      validUntil
      isRevoked
      user {
        email
      }
    }
  }
`;

export const getUserDocuments = gql`
  query getUserDocuments($userId: ID!) {
    getUserDocuments(userId: $userId) {
      id
      name
      type
      subType
      imageId
      images
    }
  }
`;

export const sendDocumentEmail = gql`
  query sendDocumentEmail(
    $clientId: ID!
    $certId: ID!
    $email: String!
    $ccEmails: [String]
  ) {
    sendDocumentEmail(
      clientId: $clientId
      certId: $certId
      email: $email
      ccEmails: $ccEmails
    )
  }
`;

export const getFailedReq = gql`
  query getFailedReq($id: ID!) {
    getFailedReq(id: $id)
  }
`;

export const getCertificate = gql`
  query certificate($id: ID!) {
    certificate(id: $id) {
      id
      userId
      holderName
      additionalData
      firstName
      middleName
      lastName
      clientId
      name
      isVerified
      isRevoked
      email
      validUntil
      issuedAt
      reportedAt
      dateOfBirth
      ssn
      courseId
      issuedBy
      isClaimed
      logoImage
      certificateNumber
      certificateInformation
      courseStart
      collectionDate
      courseEnd
      labAddress
      labName
      trainingProviderAddress
      trainingProviderCity
      trainingProviderZip
      trainingProviderCountry
      location2
      isRevoked
      qrCodeImage
      qrCodeImagePath
      locationImage
      watermarkImage
      imageId
      additionalImage
      version
      type
      certType
      passNo
      attachment
      nationality
      countryCode
      gender
      birthDate
      religion
      seabookNo
      position
      company
      civilStatus
      declarationOfPhysician
      medicalDirector
      medicalDirectorSignature
      lastColorTest
      issuingAuthority
      checkedDocs
      hearingStandards
      visionMeetsStandards
      visualMeetsStandards
      visualAids
      fitForLookout
      transportName
      fitnessLimitation
      hearingSatisfactory
      isFit
      issuedByDLM
      issuerAddress
      examinationDate
      issuerName
      result
      acsn
      secondAcsn
      secondLab
      secondResult
      aggravatedHealth
      limitation
      physicianName
      physicianLicense
      physicianSignature
      patientCity
      patientCountry
      patientAddress
      patientZip
      paperSize
      birthPlace
      authority
      city
      country
      subType
      stampImage
      address
      zip
      template
      certEmail
      telefax
      officialNo
      telephone
      drugFilePath
      drugScreeningFilePath
      roentgenologicalFilePath
      hivScreeningPath
      labResultsPath
      auditoryResultsPath
      storedInBlock
      transactionHash
      createdAt
      signers {
        name
        role
        image
      }
      pdfPath
      exportPdfJobId
      exportPdfStatus
      status
      pinNo
      extraData
      isExternal
      qrCodes
    }
  }
`;

export const getOpenCert = gql`
  query openCert($id: ID!) {
    openCert(id: $id) {
      id
      certId
      cert
    }
  }
`;

export const getAntigenTypes = gql`
  query getAntigenTypes {
    getAntigenTypes {
      id
      name
      accuracy
      code
    }
  }
`;

export const getDocumentTypes = gql`
  query getDocumentTypes($type: String) {
    getDocumentTypes(type: $type) {
      name
      type
      category
      subType
      subCategory
      location {
        country
        region
        continent
      }
    }
  }
`;

export const getCertLog = gql`
  query getCertLog($certId: ID!) {
    getCertLog(certId: $certId) {
      certId
      log {
        timestamp
        action
        result
        message
      }
    }
  }
`;

export const getVaccineCerts = gql`
  query getVaccineCerts($clientId: ID!, $filter: String) {
    getVaccineCerts(clientId: $clientId, filter: $filter) {
      id
      clientId
      type
      countryCode
      dateOfBirth
      email
      gender
      holderName
      idType
      nationality
      passNo
      ssn
      subType
      extraData
    }
  }
`;
