import React from 'react';

const SvgClose = props => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      stroke="black"
      stroke-width="2"
      class="react-date-picker__clear-button__icon react-date-picker__button__icon "
    >
      <line x1="4" x2="15" y1="4" y2="15"></line>
      <line x1="15" x2="4" y1="4" y2="15"></line>
    </svg>
  );
};
export default SvgClose;
