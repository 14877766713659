import { useState, useEffect, useCallback } from 'react';
import moment from 'moment-timezone';
import countries from 'helpers/countries';

export const useTimezone = nationality => {
  const [zonesByCountry, setZonesByCountry] = useState([]);

  const getZoneOption = useCallback(zone => {
    return { key: zone, value: zone, text: zone };
  }, []);

  useEffect(() => {
    if (!nationality && !countries.length) setZonesByCountry([]);
    const country = countries.find(country => country.text === nationality);

    if (country && country.text) {
      const zones = moment.tz.zonesForCountry(country.key);
      const zoneOptions = zones && zones.map(zone => getZoneOption(zone));
      return setZonesByCountry(zoneOptions);
    }

    return setZonesByCountry([]);
  }, [nationality, setZonesByCountry, getZoneOption]);

  return { zonesByCountry };
};
