import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';

const TabContext = createContext({
  currentTab: '',
  handleChangeTab: () => {},
});

export const TabProvider = props => {
  const currentTabRef = useRef(props.tab);
  const [currentTab, setTab] = useState(props.tab);

  useEffect(() => {
    setTab(props.tab);
  }, [props.tab]);

  const handleChangeTab = tab => {
    setTab(tab);
    currentTabRef.current = tab;
  };

  return (
    <TabContext.Provider
      value={{
        currentTab,
        handleChangeTab,
        currentTabRef,
      }}
    >
      {props.children}
    </TabContext.Provider>
  );
};

export const useTab = () => useContext(TabContext);
export const withTab = Comp => props => (
  <TabProvider tab={0}>
    <Comp {...props} />
  </TabProvider>
);
