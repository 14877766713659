import React from 'react';

import { Button } from '../../components';

import LeftArrow from '../../assets/icons/ArrowLeft';
import Cross from '../../assets/icons/Cross';

import './courseCreationHeaderButtons.scss';

const courseCreationHeaderButtons = props => {
  return (
    <div className="course-creation-buttons">
      <Button
        floated="left"
        circular
        icon
        size="medium"
        onClick={props.onBack}
        className="course-creation-buttons__back"
        color="blue"
      >
        <LeftArrow color="white" fontSize="1.3em" />
      </Button>
      <Button
        floated="right"
        circular
        icon
        size="medium"
        onClick={props.onExit}
        className="course-creation-buttons__exit"
        color="blue"
      >
        <Cross color="white" fontSize="1.3em" />
      </Button>
    </div>
  );
};

export default courseCreationHeaderButtons;
