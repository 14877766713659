export const SET_TEST_TYPE = 'WEB_APPOINTMENT/SET_TEST_TYPE';
export const SET_PATIENT_DETAILS = 'WEB_APPOINTMENT/SET_PATIENT_DETAILS';
export const UPDATE_PATIENT_DETAILS = 'WEB_APPOINTMENT/UPDATE_PATIENT_DETAILS';
export const UPDATE_CART = 'WEB_APPOINTMENT/UPDATE_CART';
export const UPDATE_ADULT_COUNT = 'WEB_APPOINTMENT/UPDATE_ADULT_COUNT';
export const UPDATE_CHILD_COUNT = 'WEB_APPOINTMENT/UPDATE_CHILD_COUNT';
export const SET_DETAILS = 'WEB_APPOITNMENT/SET_DETAILS';
export const SET_SELECTED_DATE = 'WEB_APPOINTMENT/SET_SELECTED_DATE';
export const SET_SELECTED_TIME = 'WEB_APPOINTMENT/SET_SELECTED_TIME';
export const SET_TIME_SLOT_ID = 'WEB_APPOINTMENT/SET_TIME_SLOT_ID';
export const UPDATE_ADDRESS_DETAILS = 'WEB_APPOINTMENT/UPDATE_ADDRESS_DETAILS';
export const UPDATE_TOTAL_PAYMENT = 'UPDATE_TOTAL_PAYMENT';

export const setTestTypes = testTypes => ({
  type: SET_TEST_TYPE,
  payload: testTypes,
});

export const updateCart = cart => ({
  type: UPDATE_CART,
  payload: cart,
});

export const updateAdultCount = value => ({
  type: UPDATE_ADULT_COUNT,
  payload: value,
});

export const updateChildCount = value => ({
  type: UPDATE_CHILD_COUNT,
  payload: value,
});

export const setDetails = payload => ({
  type: SET_DETAILS,
  payload: payload,
});

export const setSelectedDate = date => ({
  type: SET_SELECTED_DATE,
  payload: date,
});

export const setSelectedTime = time => ({
  type: SET_SELECTED_TIME,
  payload: time,
});

export const setTimeSlotId = id => ({
  type: SET_TIME_SLOT_ID,
  payload: id,
});

export const setPatientDetails = details => ({
  type: SET_PATIENT_DETAILS,
  payload: details,
});

export const updatePatientDetails = details => ({
  type: UPDATE_PATIENT_DETAILS,
  payload: details,
});

export const updateAddressDetails = address => ({
  type: UPDATE_ADDRESS_DETAILS,
  payload: address,
});

export const updateTotalPayment = data => ({
  type: UPDATE_TOTAL_PAYMENT,
  payload: data,
});
