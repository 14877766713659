import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setClientInformation } from 'redux/generalState.actions';
import ClientService from 'api/clients';
import { setClientStorage } from 'auth/authentication';
import { MARITIME } from 'constant';

// we're enable cms feature for only the following clients
const CMS_WHITE_LIST_CLIENT_IDS = new Set(['zHAzw9fw2H4cGE8udj50']);
const ClientThemeContext = createContext({
  clientInfo: {},
  clientType: '',
  isMaritime: false,
  hasCms: false,
  hasCreateAccount: false,
});

export const ClientThemeProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [clientState, setClientState] = useState();

  useEffect(() => {
    const fetchClientDetails = async () => {
      const {
        data: { client },
      } = await ClientService.getClientTheme();
      dispatch(setClientInformation(client));
      setClientStorage(client);
      setClientState(client);
    };

    fetchClientDetails();
  }, [dispatch]);

  return (
    <ClientThemeContext.Provider
      value={{
        clientInfo: clientState,
        clientType: clientState?.type,
        isMaritime: clientState?.type === MARITIME,
        hasCms: CMS_WHITE_LIST_CLIENT_IDS.has(clientState?.id),
        hasCreateAccount: CMS_WHITE_LIST_CLIENT_IDS.has(clientState?.id),
      }}
    >
      {children}
    </ClientThemeContext.Provider>
  );
};

export const useClient = () => useContext(ClientThemeContext);
