import React from 'react';

import chartsColors from '../../../../../helpers/chartsColors';

import { LineChart, Line } from 'recharts';

const LineGraph = ({
  height,
  hidden,
  data,
  width,
  children,
  hoveredSubType,
  keys,
}) => {
  return (
    <LineChart
      width={width}
      height={height}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      {children}
      {// make lines for each subtype category
      keys.map((val, index) => (
        <Line
          type="monotone"
          dataKey={val}
          key={index}
          stroke={
            chartsColors[keys.indexOf(val) !== -1 ? keys.indexOf(val) : 20]
          }
          // change styles when currently hovered subtype is this
          strokeWidth={
            hoveredSubType === val || hoveredSubType === null ? 1.75 : 1
          }
          strokeOpacity={
            hoveredSubType === val || hoveredSubType === null ? 1 : 0.7
          }
          activeDot={{ r: 8 }}
          hide={hidden.indexOf(val) !== -1}
        />
      ))}
    </LineChart>
  );
};
export default LineGraph;
