import { apolloClient } from '../apollo/apollo';
import {
  getEmailsByClientId,
  getEmailActivityByCertId,
} from '../graphql/queries/emails';

export default {
  async getEmailsByClientId({ clientId, status, limit, dayPeriod, search }) {
    return apolloClient.query({
      query: getEmailsByClientId,
      fetchPolicy: 'no-cache',
      variables: {
        clientId,
        status,
        limit,
        dayPeriod,
        search,
      },
    });
  },

  async getEmailActivityByCertId({ certId }) {
    return apolloClient.query({
      query: getEmailActivityByCertId,
      fetchPolicy: 'no-cache',
      variables: { certId },
    });
  },
};
