// import constants from '../../constants';
// import fetchWrapper from '../../services/fetchWrapper';

export const SET_COURSE_TEMPLATES = 'COURSE_MANAGEMENT/SET_COURSE_TEMPLATES';
export const SET_PLANNED_COURSES = 'COURSE_MANAGEMENT/SET_PLANNED_COURSES';
export const SET_ARCHIVED_COURSES = 'COURSE_MANAGEMENT/SET_ARCHIVED_COURSES';
export const SET_TODAYS_COURSES = 'COURSE_MANAGEMENT/SET_TODAYS_COURSES';
export const SET_WAITING_COURSES = 'COURSE_MANAGEMENT/SET_WAITING_COURSES';
export const SET_CATEGORIES = 'COURSE_MANAGEMENT/SET_CATEGORIES';
export const REMOVE_COURSE_TEMPLATE =
  'COURSE_MANAGEMENT/REMOVE_COURSE_TEMPLATE';

// ****************
// STATE SETTERS
// ****************

/**
 * @function setCourseTemplates
 * @description Set selected header section for certificate management
 */
export const setCourseTemplates = templates => ({
  type: SET_COURSE_TEMPLATES,
  payload: templates,
});

/**
 * @function setCategories
 * @description Set selected header section for certificate management
 */
export const setCategories = payload => ({
  type: SET_CATEGORIES,
  payload,
});

/**
 * @function removeCourseTemplate
 * @description Set selected header section for certificate management
 */
export const removeCourseTemplate = templateId => ({
  type: REMOVE_COURSE_TEMPLATE,
  payload: templateId,
});

/**
 * @function setPlannedCourses
 * @description Set planned courses to Redux state
 */
export const setPlannedCourses = courses => ({
  type: SET_PLANNED_COURSES,
  payload: courses,
});

/**
 * @function setTodaysCourses
 * @description Set planned courses to Redux state
 */
export const setTodaysCourses = courses => ({
  type: SET_TODAYS_COURSES,
  payload: courses,
});

/**
 * @function setWaitingCourses
 * @description Set planned courses to Redux state
 */
export const setWaitingCourses = courses => ({
  type: SET_WAITING_COURSES,
  payload: courses,
});

/**
 * @function setArchivedCourses
 * @description Set planned courses to Redux state
 */
export const setArchivedCourses = courses => ({
  type: SET_ARCHIVED_COURSES,
  payload: courses,
});
