import { apolloClient } from '../apollo/apollo';
import {
  getCourseByClientId,
  getCourseByCenterId,
  getCourse,
  getCourseParticipants,
  getClientParticipants,
  getCourseTitles,
  getCoursesByInstructorId,
  getCoursesByAssistantId,
  getClientCourseLength,
  searchCourses,
  getCourseImages,
  courseParticipantsCount,
  getCompletedCourseCount,
  getCourseByDate,
  getCoursesWithWaitingList,
  coursesByUser,
} from '../graphql/queries/courses';
import {
  createCourse,
  deleteCourse,
  addCourseParticipant,
  moveParticipants,
  deleteParticipant,
  setCourseTitle,
  deleteCourseTitle,
  completeCourse,
  participantsUpdate,
  addCourseImage,
  updateCourse,
  deleteCourseImage,
} from '../graphql/mutations/courses';

export default {
  async getCourseByClientId(
    clientId,
    isCompleted,
    startAfter = '0',
    indexBefore,
    filter,
    filterValue,
    noLimit
  ) {
    return apolloClient.query({
      query: getCourseByClientId,
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables: {
        clientId,
        isCompleted,
        startAfter,
        indexBefore,
        filter,
        filterValue,
        noLimit,
      },
    });
  },

  async getCourseByCenterId(
    centerId,
    instructorId,
    isCompleted,
    startAfter = '0',
    indexBefore,
    filter,
    filterValue,
    noLimit
  ) {
    return apolloClient.query({
      query: getCourseByCenterId,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables: {
        centerId,
        instructorId,
        isCompleted,
        startAfter,
        indexBefore,
        filter,
        filterValue,
        noLimit,
      },
    });
  },

  searchCourses({
    query,
    clientId,
    centerId,
    instructorId,
    isCompleted,
    filter,
    filterValue,
    startDate,
    pageSize,
  }) {
    return apolloClient.watchQuery({
      query: searchCourses,
      errorPolicy: 'all',
      variables: {
        query,
        clientId,
        centerId,
        instructorId,
        isCompleted,
        filter,
        filterValue,
        startDate,
        pageSize,
      },
    });
  },

  async getClientCourseLength({
    clientId,
    centerId,
    instructorId,
    isCompleted,
    filter,
    filterValue,
  }) {
    return apolloClient.query({
      query: getClientCourseLength,
      fetchPolicy: 'no-cache',
      variables: {
        clientId,
        centerId,
        instructorId,
        isCompleted,
        filter,
        filterValue,
      },
    });
  },

  async getCoursesByInstructorId(instructorId) {
    return apolloClient.query({
      query: getCoursesByInstructorId,
      fetchPolicy: 'no-cache',
      variables: {
        instructorId,
      },
    });
  },

  async getCourseImages(clientId) {
    return apolloClient.query({
      query: getCourseImages,
      fetchPolicy: 'no-cache',
      variables: {
        clientId,
      },
    });
  },

  async completeCourse(courseId, passedParticipants) {
    return apolloClient.query({
      query: completeCourse,
      fetchPolicy: 'no-cache',
      variables: {
        courseId,
        passedParticipants,
      },
    });
  },

  async participantsUpdate(courseId, passedParticipants) {
    return apolloClient.query({
      query: participantsUpdate,
      fetchPolicy: 'no-cache',
      variables: {
        courseId,
        passedParticipants,
      },
    });
  },

  async getCoursesByAssistantId(assistantId) {
    return apolloClient.query({
      query: getCoursesByAssistantId,
      fetchPolicy: 'no-cache',
      variables: {
        assistantId,
      },
    });
  },

  async getCourse(id) {
    return apolloClient.query({
      query: getCourse,
      fetchPolicy: 'no-cache',
      variables: {
        id,
      },
    });
  },

  async getCourseTitles(clientId) {
    return apolloClient.query({
      query: getCourseTitles,
      fetchPolicy: 'no-cache',
      variables: {
        clientId,
      },
    });
  },

  async courseParticipantsCount(clientId, centerId) {
    return apolloClient.query({
      query: courseParticipantsCount,
      fetchPolicy: 'no-cache',
      variables: {
        clientId,
        centerId,
      },
    });
  },

  async createCourse(course) {
    return apolloClient.mutate({
      mutation: createCourse,
      variables: {
        ...course,
        sName: course.name.toLowerCase(),
      },
    });
  },

  async createCourseTitle(course) {
    return apolloClient.mutate({
      mutation: setCourseTitle,
      variables: {
        ...course,
      },
    });
  },

  async addCourseParticipant(course) {
    return apolloClient.mutate({
      mutation: addCourseParticipant,
      variables: {
        ...course,
        emailTemplate: course.emailTemplate || null,
        phone: null,
        bookFirstName: null,
        bookLastName: null,
        bookEmail: null,
        bookPhone: null,
      },
    });
  },

  async moveParticipants(payload) {
    return apolloClient.mutate({
      mutation: moveParticipants,
      variables: {
        ...payload,
      },
    });
  },

  async getCourseParticipants(courseId, status) {
    return apolloClient.query({
      query: getCourseParticipants,
      fetchPolicy: 'no-cache',
      variables: {
        courseId,
        status,
      },
    });
  },

  async getClientParticipants(clientId, centerId) {
    return apolloClient.query({
      query: getClientParticipants,
      fetchPolicy: 'no-cache',
      variables: {
        clientId,
        centerId,
      },
    });
  },

  async addCourseImage(clientId, imageId) {
    return apolloClient.query({
      query: addCourseImage,
      fetchPolicy: 'no-cache',
      variables: {
        clientId,
        imageId,
      },
    });
  },

  async updateCourse(course) {
    return apolloClient.mutate({
      mutation: updateCourse,
      variables: {
        ...course,
      },
    });
  },

  async deleteCourse(courseId) {
    return apolloClient.mutate({
      mutation: deleteCourse,
      variables: {
        id: courseId,
      },
    });
  },

  async deleteCourseImage(imageId) {
    return apolloClient.mutate({
      mutation: deleteCourseImage,
      variables: {
        imageId,
      },
    });
  },

  async deleteParticipant(id) {
    return apolloClient.mutate({
      mutation: deleteParticipant,
      variables: {
        id,
      },
    });
  },

  async deleteTitle(id) {
    return apolloClient.mutate({
      mutation: deleteCourseTitle,
      variables: {
        id,
      },
    });
  },

  async getCompletedCourseCount(clientId, centerId) {
    return apolloClient.query({
      query: getCompletedCourseCount,
      variables: {
        clientId,
        centerId,
      },
    });
  },

  async getCourseByDate(clientId, startDate, endDate) {
    return apolloClient.query({
      query: getCourseByDate,
      variables: {
        clientId,
        startDate,
        endDate,
      },
    });
  },

  async getCoursesWithWaitingList(clientId) {
    return apolloClient.query({
      query: getCoursesWithWaitingList,
      fetchPolicy: 'no-cache',
      variables: {
        clientId,
      },
    });
  },

  async coursesByUser(userId) {
    return apolloClient.query({
      query: coursesByUser,
      variables: {
        userId,
      },
    });
  },
};
