import React from 'react';
import PropTypes from 'prop-types';

import { Text, Icon } from '../index';
// TODO: Move function to utils/helpers
const ErrorToast = props => {
  const filteredErrorMessage = () => {
    const { error } = props;

    if (!error || !error.message) return 'Something went wrong';
    if (error.message.indexOf(':'))
      return error.message
        .slice(error.message.indexOf(':') + 1, error.message.length)
        .replace('Error: ', '');
    return error.message.replace('Error: ', '');
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Icon
        name="info"
        color="white"
        style={{
          marginLeft: '15px',
          marginRight: '15px',
          minWidth: '20px',
          minHeight: '20px',
          color: 'white',
        }}
        fontSize="1.5em"
      />
      <Text color="white" size="small">
        {props.message || filteredErrorMessage()}
      </Text>
    </div>
  );
};

ErrorToast.propTypes = {
  error: PropTypes.object,
  message: PropTypes.string,
};

ErrorToast.defaultProps = {
  error: { message: '' },
};

export default ErrorToast;
