import gql from 'graphql-tag';

export const getCurrentClient = gql`
  query client {
    client {
      id
      name
      subdomain
      color
      logo
      isRoot
      issuerName
      address
      city
      searchAgg
      zip
      country
      certificatesIssued
      certificatesRevoked
      centerId
      emailNotif
      notCcAll
      notAttachLabRes
      role
      isWebShopClient
      type
      cost
      planType
      integrations
      documents
      accountHolderEmail
      distributionId
      devDistributionId
      notCcAll
      customText {
        additionalText
        labResultText
      }
    }
  }
`;

export const getClientIssuedCerts = gql`
  query client($clientId: ID!) {
    client(clientId: $clientId) {
      certificatesIssued
    }
  }
`;

export const getClientIssuerNames = gql`
  query clientIssuerNames {
    clientIssuerNames {
      name
    }
  }
`;

export const getSmsCount = gql`
  query getSmsCount($clientId: ID!, $startDate: String!, $endDate: String!) {
    getSmsCount(clientId: $clientId, startDate: $startDate, endDate: $endDate)
  }
`;

export const getAllClients = gql`
  query clients {
    clients {
      id
      name
      subdomain
      color
      logo
      adminCount
      accountHolderName
      accountHolderEmail
      distributionId
      devDistributionId
      hciCode
    }
  }
`;

export const getVIPClients = gql`
  query getVIPClients {
    getVIPClients {
      id
      name
      subdomain
      color
      logo
      adminCount
      accountHolderName
      accountHolderEmail
      distributionId
      devDistributionId
      hciCode
    }
  }
`;

export const sendEmail = gql`
  query sendEmail(
    $clientId: ID!
    $subject: String!
    $text: String!
    $emails: String!
  ) {
    sendEmail(
      clientId: $clientId
      subject: $subject
      text: $text
      emails: $emails
    )
  }
`;

export const getConnectedUsers = gql`
  query getConnectedUsers($clientId: ID!, $status: String, $isActive: Boolean) {
    getConnectedUsers(
      clientId: $clientId
      status: $status
      isActive: $isActive
    ) {
      id
      firstName
      middleName
      lastName
      email
      fromCountry
      birthday
      positionOfInterest
      isAccepted
      isActive
      profileImage
      phoneNumber
      isVerified
      rank
      connectionId
      status
      verificationStatus {
        status
      }
    }
  }
`;

export const getClientByID = gql`
  query client($clientId: ID!) {
    client(clientId: $clientId) {
      id
      name
      subdomain
      color
      logo
      accountHolderEmail
      integrations
      accountHolderName
      accountHolderPhone
      emailNotif
      address
      city
      zip
      country
      createdAt
      adminCount
      issuerName
      centerId
      role
      isWebShopClient
      type
      emailTemplate
      notCcAll
      notAttachLabRes
      hciCode
      documents
      searchAgg
      platoDatabase
      pdfCallback
      dnsDomain
      customText {
        additionalText
        labResultText
      }
      admins {
        id
        email
        name
        isActivated
        centerId
        role
      }
    }
  }
`;
