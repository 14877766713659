import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { Dropdown, Icon } from 'semantic-ui-react';
import { useMediaQuery } from 'react-responsive';

import { HeaderDropdown, IconDropdownItem, Text } from '../../components';
import { signOutUser } from '../../auth/authentication';
import history from '../../history';
import LabResultsService from '../../api/labResults';
import emailService from '../../api/email';
import CertService from '../../api/certificates';
import { setDocumentTypes } from '../../redux/generalState.actions';
import { changeBarState } from '../../redux/sideBarState.reducer';

import './headerBar.scss';

const HeaderBar = props => {
  const dispatch = useDispatch();
  const dropdownToggleRef = useRef();

  const userInfo = useSelector(state => state.general.user);
  const isDevMode = useSelector(state => state.general.isDevMode);
  const waitingCourses = useSelector(
    state => state.courseManagement.waitingCourses
  );
  const clientInfo = useSelector(state => state.general.clientInformation);

  // --- Local state ---
  const [isSettingsToggled, setIsSettingsToggled] = useState(false);
  const [labResultsCount, setLabResultsCount] = useState(0);
  const [notifs, setNotifs] = useState([]);
  const [bouncedEmails, setBouncedEmails] = useState([]);
  // const [isNotificationsToggled, setIsNotificationsToggled] = useState(false);

  const [isSideBarVisible, setIsSideBarVisible] = useState(true);
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });

  useEffect(() => {
    fetchDocumentTypes();
    // getBouncedEmails();
    if (clientInfo.type === 'medical') getLabResultsCount();
    if (!isPortrait) {
      dispatch(changeBarState('', { type: 'set', sidebarShow: true }));
    }
    if (isPortrait) {
      dispatch(changeBarState('', { type: 'set', sidebarShow: false }));
    }
  }, []); // eslint-disable-line

  const getBouncedEmails = async () => {
    try {
      const {
        data: { getEmailsByClientId },
      } = await emailService.getEmailsByClientId({
        clientId: clientInfo.id,
        status: 'notdelivered',
        limit: 20,
        dayPeriod: true,
      });

      setBouncedEmails(getEmailsByClientId);
    } catch (error) {
      console.log('Sendgrid - error');
    }
  };

  const fetchDocumentTypes = async () => {
    try {
      const {
        data: { getDocumentTypes },
      } = await CertService.getDocumentTypes();
      dispatch(setDocumentTypes(getDocumentTypes));
    } catch (error) {
      console.log('router on start error', error);
    }
  };

  useEffect(() => {
    const getNotifs = () => {
      const nots = [];

      if (waitingCourses.length > 0) {
        nots.push(
          <Dropdown.Item
            key={1}
            className="header-bar__notif__item"
            onClick={() => history.push('/course/planned')}
          >
            <Text size="tiny">{`${waitingCourses.length} course(s) with a waiting list and available seats!`}</Text>
          </Dropdown.Item>
        );
      }
      if (labResultsCount > 0) {
        nots.push(
          <Dropdown.Item
            key={2}
            className="header-bar__notif__item"
            onClick={() => history.push('/documents/lab')}
          >
            <Text size="tiny">{`${labResultsCount} unhandled Lab Results`}</Text>
          </Dropdown.Item>
        );
      }

      if (bouncedEmails.length > 0) {
        nots.push(
          <Dropdown.Item
            key={3}
            className="header-bar__notif__item"
            onClick={() =>
              history.push({
                pathname: '/email/activity',
                state: { status: 'not_delivered' },
              })
            }
          >
            <Text size="tiny">{`${bouncedEmails.length} email(s) failed sending`}</Text>
          </Dropdown.Item>
        );
      }

      setNotifs(nots);
    };
    getNotifs();
  }, [labResultsCount, waitingCourses.length, bouncedEmails]);

  const hide = () => {
    setIsSettingsToggled(!isSettingsToggled);
  };

  const getLabResultsCount = async () => {
    try {
      const id = localStorage.getItem('ROOT_CLIENT_ID');
      const requestParams = {
        clinicId: clientInfo.role !== 'lab' ? id : null,
        clientId: clientInfo.role === 'lab' ? id : null,
        filter: 'incomplete',
      };

      const {
        data: { getLabResultsCount },
      } = await LabResultsService.getLabResultsCount(requestParams);
      setLabResultsCount(parseInt(getLabResultsCount));
    } catch (error) {
      console.log('router on start error', error);
    }
  };

  const hideShowSideBar = () => {
    setIsSideBarVisible(!isSideBarVisible);

    dispatch(
      changeBarState('', { type: 'set', sidebarShow: isSideBarVisible })
    );
  };

  return (
    <>
      <HeaderBarContainer className="header-bar">
        <LeftHeaderBar>
          <LogoContainer>
            <div className="header-bar__icon" onClick={() => hideShowSideBar()}>
              <Icon name="bars" size="large" />
            </div>
            <div className="header-bar__logo">
              <LogoImage
                alt="logo"
                src={localStorage.getItem('CLIENT_LOGO') || ''}
              />
            </div>
          </LogoContainer>
        </LeftHeaderBar>
        {isDevMode && (
          <div className="header-bar__dev-wrapper">
            <Text bold color="green">
              Sandbox mode
            </Text>
          </div>
        )}
        <RightHeaderBar>
          {userInfo.role !== 'appointmentHandler' && (
            <NotificationsContainer className="header-bar__notifications">
              <Dropdown direction="left" icon="announcement">
                <Dropdown.Menu className="header-bar__notif">
                  {notifs.length > 0 ? (
                    <>{notifs}</>
                  ) : (
                    <Dropdown.Item className="header-bar__notif">
                      <Text size="tiny">No new notifications</Text>
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
              {notifs.length > 0 && (
                <div className="header-bar__notif-circle">
                  <Text
                    className="header-bar__notif-circle__text"
                    size="tiny"
                    color="white"
                    bold
                  >
                    {notifs.length}
                  </Text>
                </div>
              )}
            </NotificationsContainer>
          )}

          <div
            className={`profile-dropdown${
              isSettingsToggled ? ' profile-dropdown--toggled' : ''
            }`}
          >
            <div className=" profile-dropdown__name-avatar">
              <IconContainer ref={dropdownToggleRef} onClick={() => hide()}>
                <Letter>
                  <Text>{userInfo.name ? userInfo.name.charAt(0) : ''}</Text>
                </Letter>
                <div className="profile-dropdown--username">
                  <Text>{userInfo.name}</Text>
                </div>
              </IconContainer>
            </div>
            {isSettingsToggled && (
              <HeaderDropdown
                closeModal={() => setIsSettingsToggled(false)}
                toggleButtonRef={dropdownToggleRef}
              >
                <IconDropdownItem
                  icon="edit"
                  text="Reset password"
                  onClick={() => props.setIsModalToggled(true)}
                />
                <IconDropdownItem
                  icon="power off"
                  text="Sign out"
                  onClick={() => signOutUser(userInfo.id)}
                />
              </HeaderDropdown>
            )}
          </div>
        </RightHeaderBar>
      </HeaderBarContainer>
    </>
  );
};

const HeaderBarContainer = styled.div`
  width: 100vw;
  height: 80px;
  border: none;
  border-radius: 0;
  background: #f8f9ff;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LeftHeaderBar = styled.div``;

const RightHeaderBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoContainer = styled.div`
  min-width: 80px;
  height: 67px;
  display: flex;
  align-items: center;
  padding: 0 10px;
`;

const LogoImage = styled.img`
  width: auto;
  height: 67px;
  margin: auto;
  max-height: 67px;
  max-width: 300px;
  object-fit: contain;
  border-radius: 5px;
`;

const NotificationsContainer = styled.div`
  z-index: 2;
`;

const IconContainer = styled.div`
  width: 100%;
  bottom: 0;
  border-radius: 8px 8px 0 0;
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 2;
  position: relative;
`;

const Letter = styled.div`
  height: 40px;
  width: 40px;
  background: #efefef;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  margin-right: 10px;
  padding-top: 2px;
`;

export default withRouter(HeaderBar);
