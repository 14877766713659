export const SET_CLIENTS = 'CLIENTS_MANAGEMENT/SET_CLIENTS';

export const fetchClients = () => [];
// ****************
// STATE SETTERS
// ****************

/**
 * @function setClients
 * @description Set selected header section for certificate management
 */
export const setClients = clients => ({
  type: SET_CLIENTS,
  payload: clients,
});
