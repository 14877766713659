import moment from 'moment-timezone';

const ISO_WEEK_DAYS = [1, 2, 3, 4, 5];

//date.string
export const toLocalTime = (date, format = 'DD-MM-YYYY', timezone = '') => {
  return toTimezone(date, timezone).format(format);
};

export const toTimezone = (date, timezone = '') => {
  if (!timezone) return moment(date);

  return moment(date).tz(timezone);
};

export const toUTCTime = (date = '', time = '') => {
  const startOfDate = moment(date).format('L');
  return moment(`${startOfDate} ${time}`).utc();
};

export const toUTCFormat = (date, time, format = 'DD-MM-YYYY') => {
  return toUTCTime(date, time).format(format);
};

//date: Moment
const isBusinessDay = date => {
  return ISO_WEEK_DAYS.indexOf(date.isoWeekday()) >= 0;
};

//date: Moment
export const addDate = (date, number, unit, includeWeekend = false) => {
  if (includeWeekend === true) {
    return date.clone().add(number, unit);
  }

  let newDate = date.clone();
  const signal = number < 0 ? -1 : 1;
  let remaining = Math.abs(number);
  while (remaining > 0) {
    newDate = newDate.add(signal, unit);
    if (isBusinessDay(newDate)) {
      remaining -= 1;
    }
  }
  return newDate;
};

// TimeZone
export const currentTimezone = () => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (error) {
    console.error(error);
    return moment.tz.guess();
  }
};

export const isValidTimezone = timezone => {
  return !!moment.tz.zone(timezone);
};

export const validOrCurrentTimezone = timezone => {
  return isValidTimezone(timezone) ? timezone : currentTimezone();
};
