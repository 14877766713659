import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { InMemoryCache } from 'apollo-cache-inmemory';

// eslint-disable-next-line import/no-cycle
import { signOutUser } from '../auth/authentication';
import {
  chooseDBasedOnHostname,
  clientIdBasedOnHostname,
} from '../helpers/config-helpers';

const httpLink = new HttpLink({
  uri: chooseDBasedOnHostname(window.location.hostname),
});

// eslint-disable-next-line no-unused-vars
const authLink = setContext((request, previousContext) => ({
  headers: {
    // clientId: 'uFJM4TiCMHFCLWwq4VZC',
    clientId: clientIdBasedOnHostname(window.location.hostname.split('.')[0]),
    adminToken: localStorage.getItem('AUTH_TOKEN') || '',
    adminId: localStorage.getItem('USER_ID') || '',
  },
}));

// Error Handling
const errorLink = onError(({ graphQLErrors, networkError }) => {
  console.log('networkError', networkError);
  if (networkError && networkError.statusCode === 401) {
    signOutUser();
  } else if (graphQLErrors && graphQLErrors[0].message.includes('Not auth')) {
    signOutUser();
  }

  if (graphQLErrors) {
    // eslint-disable-next-line array-callback-return
    graphQLErrors.map(({ message, locations, path }) => {
      window.console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });
  }
  if (networkError) window.console.log(`[Network error]: ${networkError}`);
});

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: authLink.concat(errorLink.concat(httpLink)),
  cache: new InMemoryCache(),
  connectToDevTools: true,
  // enables  query cancellation
  queryDeduplication: false,
});
