import localForage from 'localforage';

class LocalStorage {
  constructor() {
    this._prefix = 'DELTA-';

    // set config with fallback to avoid failing in incognito on older devices
    localForage.config({
      driver: [
        localForage.LOCALSTORAGE,
        localForage.INDEXEDDB,
        localForage.WEBSQL,
      ],
      name: 'R-DELTA',
      version: 3,
    });
  }

  /**
   * @function getItem
   * @description Gets data from local storage based on key (localForage)
   * @param {string} item - item to get from local storage
   * @memberOf LocalStorage
   */
  getItem(item) {
    return localForage.getItem(this._prefix + item);
  }

  /**
   * @function setItem
   * @description Sets data from local storage based on item (localForage)
   * @param {string} item - item to set in local storage
   * @param {object} value - data to set
   * @memberOf LocalStorage
   */
  setItem(item, value) {
    return localForage.setItem(this._prefix + item, value);
  }

  /**
   * @function removeItem
   * @description remove data from local storage based on key (localForage)
   * @param {string} item - item to get from local storage
   * @memberOf LocalStorage
   */
  removeItem(item) {
    return localForage.removeItem(this._prefix + item);
  }

  /**
   * @function clear
   * @description Clears localstorage/localforage
   * @memberOf LocalStorage
   */
  clear() {
    return localForage.clear();
  }

  /**
   * @function getItemNative
   * @description Gets data from local storage based on key (native)
   * @param {string} item - item to get from local storage
   * @memberOf LocalStorage
   */
  getItemNative(item) {
    return localStorage.getItem(this._prefix + item);
  }

  /**
   * @function setItemNative
   * @description Sets data from local storage based on item (native)
   * @param {string} item - item to set in local storage
   * @param {object} value - data to set
   * @memberOf LocalStorage
   */
  setItemNative(item, value) {
    localStorage.setItem(this._prefix + item, value);
  }
}

export default new LocalStorage();
