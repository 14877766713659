import {
  SET_INSTRUCTORS,
  REMOVE_INSTRUCTOR,
} from './instructorManagement.actions';

const initialState = {
  instructors: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_INSTRUCTORS:
      return { ...state, instructors: action.payload };

    case REMOVE_INSTRUCTOR:
      const filteredResult = state.instructors.filter(template => {
        return template.id !== action.payload;
      });
      return { ...state, instructors: filteredResult };

    default:
      return state;
  }
};
