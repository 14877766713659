import { apolloClient } from '../apollo/apollo';
import {
  getTimeslots,
  getAppointmentTypes,
  getAppointmentDetails,
  getMedBookingAppointmentOverview,
  getPaymentDetails,
  getAppointmentsPerTimeSlotId,
  getTestTypes,
  getPackageTests,
  getReferrals,
  getReferralOverview,
  getReferralCsv,
  getReferralOverviewDetails,
  getDashboardStats,
  getClientDetails,
  getAllHomePageVersions,
  getCoupons,
  getAllClients,
  verifyCouponCode,
  calcTotalPayment,
  getAppointmentCategories,
  getPackageTypes,
  getTestCategories,
} from '../graphql/queries/newAppointments';
import {
  createTimeslots,
  createAppointmentTypes,
  deleteTimeslots,
  assignStaff,
  editAppointment,
  editTestType,
  deleteTestType,
  createTestType,
  editPackageTest,
  deletePackageTest,
  createPackageTest,
  createReferral,
  editReferral,
  deleteReferral,
  cancelAppointment,
  createGoogleCalToken,
  removeGoogleAccount,
  createOutlookCalToken,
  removeOutlookAccount,
  syncGoogleCalendar,
  syncOutlookCalendar,
  createClientDetails,
  editClientDetails,
  setReminderTime,
  createAppointmentRecord,
  createCoupon,
  editCoupon,
  deleteCoupon,
  saveEmailTemplateIds,
  resendPatientFinalizeEmail,
  setRescheduleLimit,
  createAppointmentCategory,
  editAppointmentCategory,
  createTestCategory,
  editTestCategory,
} from '../graphql/mutations/newAppointments';

const methods = {
  async getTimeslots(payload) {
    return apolloClient.query({
      query: getTimeslots,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },

  async getAppointmentDetails(appointmentId, isDev) {
    return apolloClient.query({
      query: getAppointmentDetails,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables: {
        appointmentId,
        isDev,
      },
    });
  },

  async createTimeslots(payload) {
    return apolloClient.mutate({
      mutation: createTimeslots,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },

  async cancelAppointment(payload) {
    return apolloClient.mutate({
      mutation: cancelAppointment,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },

  async deleteTimeslots(payload) {
    return apolloClient.mutate({
      mutation: deleteTimeslots,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },

  async getAppointmentTypes(payload) {
    return apolloClient.query({
      query: getAppointmentTypes,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },

  async getPaymentDetails(paymentId, isDev) {
    return apolloClient.query({
      query: getPaymentDetails,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables: {
        paymentId,
        isDev,
      },
    });
  },

  async createAppointmentTypes(
    clientId,
    name,
    type,
    price,
    category,
    currency
  ) {
    return apolloClient.query({
      query: createAppointmentTypes,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables: {
        clientId,
        name,
        type,
        price,
        category,
        currency,
      },
    });
  },
  async getBookingsOverview(payload) {
    return apolloClient.query({
      query: getMedBookingAppointmentOverview,
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },
  async getAppointmentsPerTimeSlotId({ timeSlotId, isDev }) {
    return apolloClient.query({
      query: getAppointmentsPerTimeSlotId,
      fetchPolicy: 'no-cache',
      variables: { timeSlotId, isDev },
    });
  },

  async assignStaff(payload) {
    return apolloClient.mutate({
      mutation: assignStaff,
      variables: payload,
    });
  },

  async editAppointment(payload) {
    return apolloClient.mutate({
      mutation: editAppointment,
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },
  async createTestType(payload) {
    return apolloClient.mutate({
      mutation: createTestType,
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },
  async getTestTypes(payload) {
    return apolloClient.query({
      query: getTestTypes,
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },
  async editTestType(payload) {
    return apolloClient.mutate({
      mutation: editTestType,
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },
  async deleteTestType({ id, isDev }) {
    return apolloClient.mutate({
      mutation: deleteTestType,
      fetchPolicy: 'no-cache',
      variables: { id, isDev },
    });
  },
  async createPackageTest(payload) {
    return apolloClient.mutate({
      mutation: createPackageTest,
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },
  async getPackageTests(payload) {
    return apolloClient.query({
      query: getPackageTests,
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },
  async editPackageTest(payload) {
    return apolloClient.mutate({
      mutation: editPackageTest,
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },
  async deletePackageTest({ id, isDev }) {
    return apolloClient.mutate({
      mutation: deletePackageTest,
      fetchPolicy: 'no-cache',
      variables: { id, isDev },
    });
  },
  async createReferral(payload) {
    return apolloClient.mutate({
      mutation: createReferral,
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },
  async getReferrals(payload) {
    return apolloClient.query({
      query: getReferrals,
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },
  async editReferral(payload) {
    return apolloClient.mutate({
      mutation: editReferral,
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },
  async deleteReferral(payload) {
    return apolloClient.mutate({
      mutation: deleteReferral,
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },
  async getReferralOverview(payload) {
    return apolloClient.query({
      query: getReferralOverview,
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },
  async getReferralCsv(payload) {
    return apolloClient.query({
      query: getReferralCsv,
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },
  async getReferralOverviewDetails(payload) {
    return apolloClient.query({
      query: getReferralOverviewDetails,
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },
  async createGoogleCalToken({ adminId, clientId, code, redirectUri }) {
    return apolloClient.mutate({
      mutation: createGoogleCalToken,
      fetchPolicy: 'no-cache',
      variables: { adminId, clientId, code, redirectUri },
    });
  },
  async removeGoogleAccount({ adminId, redirectUri }) {
    return apolloClient.mutate({
      mutation: removeGoogleAccount,
      fetchPolicy: 'no-cache',
      variables: { adminId, redirectUri },
    });
  },
  async createOutlookCalToken({ adminId, clientId, code, redirectUri }) {
    return apolloClient.mutate({
      mutation: createOutlookCalToken,
      fetchPolicy: 'no-cache',
      variables: { adminId, clientId, code, redirectUri },
    });
  },
  async removeOutlookAccount({ adminId, redirectUri }) {
    return apolloClient.mutate({
      mutation: removeOutlookAccount,
      fetchPolicy: 'no-cache',
      variables: { adminId, redirectUri },
    });
  },
  async getDashboardStats(payload) {
    return apolloClient.query({
      query: getDashboardStats,
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },
  async getAllHomePageVersions() {
    return apolloClient.query({
      query: getAllHomePageVersions,
      fetchPolicy: 'no-cache',
    });
  },
  async getClientDetails(payload) {
    return apolloClient.query({
      query: getClientDetails,
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },
  async createClientDetails(payload) {
    return apolloClient.mutate({
      mutation: createClientDetails,
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },
  async editClientDetails(payload) {
    return apolloClient.mutate({
      mutation: editClientDetails,
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },
  async setReminderTime(payload) {
    return apolloClient.mutate({
      mutation: setReminderTime,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },
  async setRescheduleLimit(payload) {
    return apolloClient.mutate({
      mutation: setRescheduleLimit,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },
  async syncGoogleCalendar({ adminId, clientId, redirectUri }) {
    return apolloClient.mutate({
      mutation: syncGoogleCalendar,
      fetchPolicy: 'no-cache',
      variables: { adminId, clientId, redirectUri },
    });
  },
  async syncOutlookCalendar({ adminId, clientId, redirectUri }) {
    return apolloClient.mutate({
      mutation: syncOutlookCalendar,
      fetchPolicy: 'no-cache',
      variables: { adminId, clientId, redirectUri },
    });
  },
  async createAppointmentRecord(payload) {
    return apolloClient.mutate({
      mutation: createAppointmentRecord,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },
  async createCoupon(payload) {
    return apolloClient.mutate({
      mutation: createCoupon,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },
  async editCoupon(payload) {
    return apolloClient.mutate({
      mutation: editCoupon,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },
  async getCoupons(payload) {
    return apolloClient.query({
      query: getCoupons,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },
  async deleteCoupon(payload) {
    return apolloClient.query({
      query: deleteCoupon,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },

  async getAllClients(payload) {
    return apolloClient.query({
      query: getAllClients,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },

  async saveEmailTemplateIds(payload) {
    return apolloClient.mutate({
      mutation: saveEmailTemplateIds,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },
  async resendPatientFinalizeEmail(payload) {
    return apolloClient.mutate({
      mutation: resendPatientFinalizeEmail,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },

  async verifyCouponCode(payload) {
    return apolloClient.query({
      query: verifyCouponCode,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },
  async calcTotalPayment(payload) {
    return apolloClient.query({
      query: calcTotalPayment,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },
  async getAppointmentCategories({ isDev }) {
    return apolloClient.query({
      query: getAppointmentCategories,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables: { isDev },
    });
  },
  async createAppointmentCategory(payload) {
    return apolloClient.mutate({
      mutation: createAppointmentCategory,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },
  async editAppointmentCategory(payload) {
    return apolloClient.mutate({
      mutation: editAppointmentCategory,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },
  async getPackageTypes({ isDev }) {
    return apolloClient.query({
      query: getPackageTypes,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables: { isDev },
    });
  },
  async getTestCategories({ isDev }) {
    return apolloClient.query({
      query: getTestCategories,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables: { isDev },
    });
  },
  async createTestCategory(payload) {
    return apolloClient.mutate({
      mutation: createTestCategory,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },
  async editTestCategory(payload) {
    return apolloClient.mutate({
      mutation: editTestCategory,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },
};

export default methods;
