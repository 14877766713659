import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import CircleLoader from 'react-spinners/CircleLoader';
import { toast } from 'react-toastify';

import TinyUrlService from '../../../api/tinyUrl';
import { ErrorToast } from '../../../components';

import './publicCertificateView.scss';

const TinyUrlView = props => {
  useEffect(() => {
    getUrl();
  }, []); // eslint-disable-line

  const getUrl = async () => {
    try {
      const tinyId = props.match.params.tinyId;
      const {
        data: { getTinyUrl },
      } = await TinyUrlService.getTinyUrl(tinyId);
      window.location = getTinyUrl.full;
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  };

  return (
    <div>
      <div className="public-certificate-view__loader-wrapper">
        <CircleLoader color="#455af7" />
      </div>
    </div>
  );
};

export default withRouter(TinyUrlView);
