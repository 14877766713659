import React from 'react';
import styled from 'styled-components';

const Page = props => {
  return <PageContent style={props.style}>{props.children}</PageContent>;
};

const PageContent = styled.div`
  position: relative;
  margin: auto;
  max-width: 1200px;
  min-width: 800px;
  margin-top: 10px;

  @media only screen and (max-width: 855px) {
    max-width: 100% !important;
    min-width: 100% !important;
  }
`;

export default Page;
