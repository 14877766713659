import {
  SET_COURSE_TEMPLATES,
  REMOVE_COURSE_TEMPLATE,
  SET_PLANNED_COURSES,
  SET_ARCHIVED_COURSES,
  SET_TODAYS_COURSES,
  SET_WAITING_COURSES,
  SET_CATEGORIES,
} from './courseManagement.actions';

const initialState = {
  templates: [],
  plannedCourses: [],
  archivedCourses: [],
  todaysCourses: [],
  waitingCourses: [],
  categories: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_COURSE_TEMPLATES:
      return { ...state, templates: action.payload };

    case SET_PLANNED_COURSES:
      return { ...state, plannedCourses: action.payload };

    case SET_ARCHIVED_COURSES:
      return { ...state, archivedCourses: action.payload };

    case SET_TODAYS_COURSES:
      return { ...state, todaysCourses: action.payload };

    case SET_CATEGORIES:
      return { ...state, categories: action.payload };

    case SET_WAITING_COURSES:
      return { ...state, waitingCourses: action.payload };

    case REMOVE_COURSE_TEMPLATE:
      const filteredResult = state.templates.filter(template => {
        return template.id !== action.payload;
      });
      return { ...state, templates: filteredResult };

    default:
      return state;
  }
};
