import { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { plannedCourseSelector } from 'redux/selectors';
import { toast } from 'react-toastify';
import { ErrorToast } from 'components';

import CourseService from 'api/courses';
import { setPlannedCourses } from 'pages/coursePages/courseManagement/courseManagement.actions';

export const usePlannedCourses = ({ clientId }) => {
  const dispatch = useDispatch();

  // planned courses from Redux
  const coursesFromRedux = useSelector(plannedCourseSelector);

  const getCoursesByClient = useCallback(async () => {
    try {
      const {
        data: { coursesByClientId },
      } = await CourseService.getCourseByClientId(
        clientId,
        false,
        null,
        null,
        null,
        null
      );
      dispatch(setPlannedCourses(coursesByClientId));
      return coursesByClientId;
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  }, [clientId, dispatch]);

  // if we already fetch planned courses before then just return it
  const plannedCourses = useMemo(() => {
    if (!!coursesFromRedux.length) return coursesFromRedux;
    return getCoursesByClient();
  }, [coursesFromRedux, getCoursesByClient]);

  return plannedCourses;
};
