import React, { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import Pin from '../../assets/icons/Pin';
import { Text, Input, Map, Select, ErrorToast } from '../../components';
import FacilitiesService from '../../api/facilities';
import TrainingCenterService from '../../api/trainingCenters';
import countries from 'helpers/countries';
import { useTimezone } from 'hooks';

import './courseLocation.scss';

const CourseLocation = ({
  isELearning,
  updateCourseDetails,
  courseDetails,
  errors,
  setFull,
  isTrainingCenters,
}) => {
  const [facilities, setFacilities] = useState([]);
  const [facilitiesOptions, setFacilitiesOptions] = useState([]);
  const clientId = useSelector(state => state.general.clientInformation.id);
  const { zonesByCountry } = useTimezone(courseDetails.country);

  useEffect(() => {
    geoLocation();
    const fetchFacilities = async () => {
      try {
        const {
          data: { facilities },
        } = await FacilitiesService.getFacilitiesByClientId(clientId);

        const facilitiesOptions = facilities.map(facility => ({
          text: facility.name,
          value: facility.id,
        }));
        setFacilities(facilities);
        setFacilitiesOptions(facilitiesOptions);
      } catch (error) {
        toast.error(<ErrorToast error={error} />);
      }
    };

    const fetchClinics = async () => {
      try {
        const {
          data: { trainingCenters },
        } = await TrainingCenterService.getTrainingCentersByClientId(clientId);

        const facilitiesOptions = trainingCenters.map(facility => ({
          text: facility.name,
          value: facility.id,
        }));
        setFacilities(trainingCenters);
        setFacilitiesOptions(facilitiesOptions);
      } catch (error) {
        toast.error(<ErrorToast error={error} />);
      }
    };

    if (isTrainingCenters) fetchClinics();
    else fetchFacilities();
  }, []); //eslint-disable-line

  useEffect(() => {
    if (zonesByCountry && zonesByCountry.length) {
      updateCourseDetails('timezone', zonesByCountry[0].text);
    }
  }, [zonesByCountry, updateCourseDetails]);

  // for fetching map the first time
  useEffect(() => {
    debounceGeoLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseDetails.country]);

  const getGeoLocation = async selectedLocation => {
    const locationString = selectedLocation.replace(' ', '+');
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${locationString}&key=AIzaSyCHwigbhM2m02cGdDaIQI1EdV2cHiY1Prw`
    ).then(response => {
      response.json().then(results => {
        if (results.results) {
          if (results.results.length === 0) return;
          updateCourseDetails(
            'geoLocation',
            results.results[0].geometry.location
          );
        }
      });
    });
  };

  const setAddress = value => {
    facilities.forEach(facility => {
      if (facility.id === value) {
        if (setFull) {
          updateCourseDetails({
            ...facility,
            street: facility.address,
            geoLocation: JSON.parse(facility.geoLocation),
          });
        } else {
          updateCourseDetails('street', facility.address);
          updateCourseDetails('id', facility.id);
          updateCourseDetails('zip', facility.zip);
          updateCourseDetails('city', facility.city);
          updateCourseDetails('country', facility.country);
          updateCourseDetails('geoLocation', JSON.parse(facility.geoLocation));
        }
        geoLocation(facility);
      }
    });
  };

  const geoLocation = (course = courseDetails) => {
    const locationAddress =
      (course.street !== undefined && course.street
        ? course.street + ', '
        : '') +
      (course.address !== undefined && course.address
        ? course.address + ', '
        : '') +
      (course.zip !== undefined && course.zip ? course.zip + ', ' : '') +
      (course.city !== undefined ? course.city + ', ' : '') +
      (course.country !== undefined && course.country ? course.country : '');

    if (!locationAddress || locationAddress === '') return;
    getGeoLocation(locationAddress);
  };

  const debounceGeoLocation = debounce(() => {
    geoLocation();
  }, 1000);
  return (
    <div className="course-location">
      <div className="course-location__info">
        <div className="course-location__info__header">
          <Pin className="course-location__info__header__icon" />
          <Text size="big" bold className="course-location__info__header__text">
            Address
          </Text>
          {isELearning && (
            <Text
              className="course-location__info__header__sub-text"
              size="tiny"
            >
              (optional)
            </Text>
          )}
        </div>
        <div className="course-location__info__select">
          <Select
            border
            search
            icon={false}
            text="Select address"
            options={facilitiesOptions}
            onChange={(e, { value }) => setAddress(value)}
            onBlur={debounceGeoLocation}
          />
        </div>

        <div style={{ display: 'flex' }}>
          <Input
            border
            onBlur={debounceGeoLocation}
            placeholder="Street Address"
            size="large"
            style={{ width: '520px' }}
            onChange={e => updateCourseDetails('street', e.target.value)}
            value={courseDetails.street}
            invalid={errors.street}
          />
          <Input
            border
            onBlur={debounceGeoLocation}
            placeholder="Zip"
            style={{ width: '80px', marginLeft: '20px' }}
            onChange={e => updateCourseDetails('zip', e.target.value)}
            invalid={errors.zip}
            value={courseDetails.zip}
          />
        </div>
        <div style={{ display: 'flex', marginTop: '20px' }}>
          <Input
            border
            placeholder="City"
            onBlur={debounceGeoLocation}
            onChange={e => updateCourseDetails('city', e.target.value)}
            invalid={errors.city}
            value={courseDetails.city}
          />
          <Select
            border
            search
            icon={false}
            placeholder="Country"
            style={{ marginLeft: '20px' }}
            options={countries}
            value={courseDetails.country}
            onChange={(e, { value }) => updateCourseDetails('country', value)}
            invalid={errors.nationality}
          />
          <Select
            border
            search
            icon={false}
            placeholder="Timezone"
            style={{ marginLeft: '20px' }}
            options={zonesByCountry}
            value={courseDetails.timezone}
            onChange={(e, { value }) => updateCourseDetails('timezone', value)}
            invalid={errors.timezone}
          />
        </div>
      </div>
      <div className="course-location__map-wrapper">
        <Map
          location={courseDetails.geoLocation}
          onMarkerMove={location =>
            updateCourseDetails('geoLocation', location)
          }
        />
        <div className="course-location__map-wrapper__text">
          <Text size="small" dark>
            Important
          </Text>
          <Text size="small">
            Make sure the address is pinned correctly on the map. If not, move
            the pin to the correct location.
          </Text>
        </div>
      </div>
    </div>
  );
};

export default CourseLocation;
