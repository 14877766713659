import localStorage from './localStorage';

/**
 *
 * @param {Object}
 * @description Wrapper around fetch to make sure all calls can have authentication headers, same content type etc
 */
export default function fetchWrapper({
  endpoint = '',
  method = 'GET',
  headers = {},
  payload = null,
}) {
  return localStorage.getItem('access_token').then(accessToken => {
    const header = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
      ...headers,
    };

    const params = {
      headers: header,
      method,
    };

    if (payload) params.body = JSON.stringify(payload);

    return fetch(process.env.REACT_APP_API_V1_ENDPOINT + endpoint, params).then(
      response => {
        // Wait for stream to complete before resolving
        if (
          response.headers.get('Content-Type').indexOf('application/json') > -1
        ) {
          if (response.status === 204) return response;
          else {
            return response.json().then(json => {
              return response.ok ? json : Promise.reject(json);
            });
          }
        } else
          return response.blob().then(blob => {
            return response.ok ? blob : Promise.reject(blob);
          });
      }
    );
  });
}
