const apolloConfig = {
  envUrls: {
    local: 'http://localhost:8080',
    development: 'https://riverr-tcm-dev.ew.r.appspot.com',
    staging: 'https://bold-catfish-261020.appspot.com',
    production: 'https://bold-catfish-261020.appspot.com',
  },
  clientId: 'zHAzw9fw2H4cGE8udj50', // Admin ClientID
  // clientId: 'J0ZWo9IZKYZzd3vZRiew', // Admin ClientID
  clientIdRemoteMedical: 'tOkuBMAEQHPuQO5lnfWe',
  clientIdBwGroup: 'KX04ZnelngsgosFAP3xt',
  clientIdHiPrecision: 'D4SToSgz2vDvYQIxPuMj',
  clientIdSuperCare: 'v9Bg9d9fZ1Nw0JqDpkLw',
  clientIdMartec: 'dYVxF1Ixymfocv2MjwMQ',
  clientIdBsm: 'N7Y1NWAMdxHx2DVdIUDF',
  clientIdCrowley: 'AOCEaIAQMW7Vv7VsjTbm',
  clientIdDemo: 'XgwaOpKAgagAHdzn4nSV',
  clientIdMtr: 'HH0m6Vqtod3cwIU5mOA0', // MTR dev
  clientIdNtc: 'c0PEUwXNwHnPNBYzsHfu',
  clientIdEurofinsSG: 'sIdzwYsvwehphIHNG2ce',
  clientIdMolecularLab: 'PDESfEoBpGqlNifthY1f',
  clientIdStLukesPH: 'CO2HIVy19TYpcuXJggew',
  stLukesGlobalCityId: 'v5hwUqzhDlSqjuLSMf79',
  clientIdMaritimeMedical: 'LiVPLIkM4249OY3UlTFw',
  clientIdMaerskDrilling: 'CgtaLzMX2YeT2f0ohW7G',
  clientIdMicah: '7S6X5TIsPU1cdnZPUy1o',
  clientIdPacc: 'MLxbdndi2qIrQQDIn2Qa',
  clientIdMedical: 'uFJM4TiCMHFCLWwq4VZC',
  newPathFamilyClinicId: 'PM18H0DTYQoY87f0Ioq3',
  aMedicalClinicId: 'moU8rblEsDEibF3sbHKC',
  wlhMedicalServicesId: 'kwdHnCmjVquco1Se3jYR',
  drWFamilyClinicId: 'WHN2xqX1qi4Vtp1mSfQU',
  internationalMedicalClinicCamdenId: 'F7Dg8iMySVvUx7In7hss',
  internationalMedicalClinicJelitaId: 'C8PqsJ60fQBVBE0HgH7e',
  internationalMedicalClinicKatongId: 'Q7tPwUEyiDyJ5Cu9mjpd',
  internationalMedicalClinicChildrenId: 'xdlohxnyYDhmqw5zjFDt',
  twinCityMedicalCentreId: 'rYDZg99dFPxghh6c1i5u',
  oslerHealthInternationalId: '8icDgjZcPaNzZ4Okfn9e',
  FemaClinicSurgeryId: 'STWKmmvXl3mJtiV10SYw',
  StarMedSpecialistCentreId: 'sNEz11b80GpdA8CVpGjd',
  cloverMedicalClinicId: 'oJkzaBwULFI8xalV8HE7',
  trueMedicalClinicTampinesWestId: '5bY5MVl8c58sCSuJm8qv',
  trueMedicalClinicPayaLebarQuarterId: 'kHo2AEBKHA8Wb31yPTlV',
  dtapNovenaId: 'eL9K53wFHFyvofE3UFf7',
  speedocId: 'NTlj2lkwkQmLigYWAuY1',
  soonLeeMedicalClinicId: 'r27Hqu3UGzd1sUxcTkxI',
  shanahId: '0Evis93bSP4B3fc9Tll8',
  clientIdSolverMinds: 'OwAlGja6LEvXZfnqNPG1',
  proHealthId: 'hGlFED2tyLUFQ3EU9Nzx',
  platoId: '25ZKfoSzHS8aHLtHmGnV',
  platinumId: 'sAE9R1aQYaDEaZf5IqPL',
  providenceClinicHillfordId: 'QeofbGCxaJ2SGhb4WimT',
  healthWerkzMedicalId: 'zIKHqx7dcsPaFPokSOkH',
  healthWerkzCckMedicalId: 'MC0EayZoQbDUCNiIPuy9',
  myClinicId: 'xaMZ16E8BkVl4ZTu0wSL',
  arisId: 'AbPZLBPfWtzSO4mqmvFy',
  gardenclinicId: 'h0bIiKzcZ28gtFXZw10w',
  fullertonId: 'U8iSK3g3oOoA4I2Y125K',
  urgentClinicId: 'ISSc6yeN4dyTpvvVIz58',
  c3ClinicId: 'HnQfz8wyNZghiTz2p64S',
  myHealthId: 'ZhBhMU8xgL5n4tFrhtZ9',
  doverId: '1Pix0SlT5JLxiYquword',
  oneworldclinicId: '3smuuLUpo673hsgu0WR3',
  cckClinicId: 'HYVntYb9BeaaJGoV18ek',
  blessClinicId: 'nkKfxbzs6sdva8A8Oflr',
  greenlinkClinicId: 'XE28ELkR09mhpBNtiO5y',
  nordicId: 'JpDBLN0vW2LQaTuegTbe',
  notredameId: 'SX7zcX78JFy790ZGuFqx',
  cghmcId: 'fWJp7kmM5chwbp2Y45NK',
  pdscId: 'Cknkbd08DkXEoEK5WH57',
  imlabId: 'QQBOFwBjjnZHa1luFTRR',
  nipponId: 'We7kRdErQB2rfvLfiwrm',
  zeabornId: 'IJn54KYdJnS4JccnF84j',
  platinumSurgeryId: 'saC4zmi2sVTZm2cWE117',
  street11Id: '5cuAOWB1CXTtfTrVqFQg',
  myDoctorlakeSideId: 'gd4glUOlM5TBxcE3NnL7',
  cityGPFamilyClinicId: 'S5CPWGD18buBwLKqp2AR',
  ongsclinicId: 'HkK1wctkvLggxJw422ho',
  bedokdaynightId: '9OC1GtPOTfi56ryX7CjD',
  jurongdaynightId: 'oHEmPX0HLBDjUAlZgk40',
  kingswaymedicalId: 'QjUbTJQT9gWd8LhIygyS',
  thegoodclinicId: 'Vva0xyr9sbwScK73lOQ3',
  tmedicalkallangId: 'pr015fuzimHkAK0AZODw',
  tmedicalcanberraId: '2jvdv1QIIIPKVKjZgFuD',
  tmedicalcitygateId: '4COQuSLXUkyUOS626Dnt',
  healthcaremedicalkovanId: 'qdtkIRaMxawECWdGU8kb',
  doctors4lifepickeringId: 'LQXRQ934VQISsyoas18p',
  shalomclinicsurgeryId: 'OEPgW6XwcrdxuMvPUt1G',
  acmsmedicalclinicId: 'JC5pDgl9A4UIzG8Gs3d5',
  mydoctorcanberraId: '7IRMN9IPC5BuY9fduK4V',
  healthlinkmedId: 'HOctYnOunOswaH4JNbfq',
  singapuraclinicId: 'kCGgI7xnxiDWmVs9sTa1',
  bukittimahclinicId: 'jB4kVLQw2xnGkx44kv5M',
  newcastleclinicId: '6i8BcmVjYREd4yY6mBfm',
  thedublinclinicId: 'SYn2rZquaW869wWi6TPW',
  unihealthclinicjurongeastId: '2yUCi9rb2UtTLLM7T7b5',
  belieffamilyclinicId: 'jJuKd8nPdKlIFF2dfBly',
  healthpartnershipmedicalId: 'cgBqpqIShSZDKwPLrhM2',
  minMedJurongId: '5XYeRqvwHGwJ02830Sb0',
  minMedHaigId: 'zQkLYHpdkK8rW4ngXKfn',
  minMedPasirId: '4nuq0kXcNPPIahOumAQa',
  minMedPunggolId: 'qJoDiEPzF0uSqe5qCMyC',
  minMedSengKangId: '271mOBMl847H71WAF4x1',
  minMedYiShunId: '5S6RuiWIhZ3pa0RgRegM',
  minMedHealthScreenersId: 'cwlOACloZJf5dvaEFG7Z',
  daySpringId: 'G6mm0HlfIHOmoUwRwVjm',
  docchangi: 'HpJhZDtCY8NwAMN7Jpvx',
  iswabxpresssilkroutessembawang: '7IaOfDYnlAAuNDdiH0qR',
  healthwayaljunied: 'aezdvP8CgsjpPnB6vquC',
  healthwaymarineterrace: 'FLl5tDyvKqSnHZtQGaV0',
  allswellmedicalclinicId: 'GKx4JO5ZGWsITqtlmiOy',
  iswabtravelcaredocId: '1c9DHUkeoYiLtdrVlVdE',
  intemedicaltampinesId: 'q9DAmyOMUyGiStGjWKgQ',
  amaritimeclinicId: 'VNnzLpjzHZlEIAeP1V3t',
  ourtampinesfamilyclinicId: '6nhkB0Sj0y6uG1J6eGAk',
  minmedboonlayId: 'd6UjZ1o9O7dIdrz50VeC',
  lohandlohclinicamkId: 'hSKJHt5FwXFZCdPG0IKo',
  swabnowwoodgrovefcId: 'abLkovRTYnEOPeaP5eqb',
  myclnqjurongId: 'YNtJVnUNNNoPWazi9h3c',
  sghealthcareunitedId: 'IGa4LFQxpRnYeeG1NHRi',
  kindredfamilyclinickallangbahruId: 'AkR4t47iMqMvauCnoFgr',
  minmedredhillId: '1u23hmlUeaxCHkKAv9R3',
  intemedicalteckgheeId: 'TBHaCtch1tHjXmUf1xdY',
  truecarecliniceunosId: '00dBhzyxD55RJKYcrJVk',
  nanyangsurgeryId: 't3cowWt7pWjdtuf5kBuc',
  zestclinicId: '29Rlq9UBysGOPSB9vlGt',
  justcaremedicalId: 'yqna3dPs7BUgdAUKKJtZ',
  onecaremedicalsenjaId: 'yVzei4disF3FRft0PdmQ',
  unihealthclinicyishunId: 'wJ2ZLYkQXUM5MRsPIcfS',
  thomsonjpfclinicId: 'JyCcdvPaTYoXTAEN4DxY',
  sohwellmedicalId: 'lIpB4rwH0vexF8Cd4wyl',
  northshorefamilyclinicId: 'qGOWhQtTQN0mCoUeRTc8',
  onecaremedicalbukitbatokwesteastId: 'paMtEPohlCksUyFIcJzk',
  myclnqmapexId: 'iLikXq4sknArE37eWloN',
  advantagemedicalbedokId: '0Oc4X2apaqbwGsRn9CD4',
  ardenneshealthId: '2G4MLmZjiR5T01mShgju',
  advantagemedicaljurongwestId: '4txpTg5PGmfXGzgrEbwU',
  mindefmedicalcentreId: 't9Zv5OMUFUxUqTlljP5G',
  onecaremedicalbishanId: 'HFUukF0l6OtxAlKcMTFM',
  acehealthmedicalId: 'Zkhyi9FYGVdCJ5woR7en',
  tmcbsId: 'aEs3tzI1Fsmjj9VpqQ1A',
  minmedmarinabayId: '6Mer7K8bJTHS1byTs5d7',
  minmedbedokId: 'iPEJGbaLaOE93mNnXapI',
  familymedical280Id: 'F1XpalB74lPLGcVNaG1j',
  manadrcitygateId: 'efqkb4W4a8Yr4wULJtuU',
  clarihealthId: 'c2BJ7sb0y2M1unr5yFab',
  minmedwoodlandsId: 'Hx7f7SjtvrOR62c8JyMj',
  myopiaspecialistcentreId: 'Z5k5rHUlV9FfdQSyfM1I',
  peakmedicalwpId: '7vZeuqX5dYYzviYMUvVy',
  docbedokId: 'KJeZXGnhHRTWoS0zcKyf',
  thetestingproId: 'CoDKxhea6A4z92zgb1kV',
  aljuniedmedId: 'tSSWTuFBhWmIuQT6kf09',
  onecaremedicaljunction9Id: 'UPlb4KOpWhw8ESqYjhZs',
  minmedamkId: 'WtSMeM90TK4a13f3gkbI',
  medstarmedical176Id: 'QV2CyfpZyv2D4BYoN0B8',
  dawsonfamilyclinicId: 'QWdmkJEZK20H0MG0FRDg',
  minmedclementiId: 'QswNwzywO2jxOHTkSFaC',
  gpjoochiatId: 'KSTB1AEQXHkOV1p6XB5X',
  onecaremedicalserangoonnorthId: 'DIc9vXNVm8wM332Jed0r',
  centralclinicmpId: '0JSrcKeWh0zZYIaG1I9j',
  fastpdtswabId: 'FeUiQdFEKZjybJDIGqjj',
  onecaremedicalpunggolsumangId: 'TAWV27Jb8U1eVkF4Q99V',
  drmpctampinesId: 'kj4AFg83sLzjuIt3r8y6',
  oglmedicalnovenaId: '3r09Bp80rTIwKc5ut3yA',
  unihealthclinicboonlayId: 'PWAqZQsXoxixXzM52O4e',
  healthwaymlwId: 'pFqvt6lGfXrTEtPXSjbO',
  inspiremedicaljalanbatuId: 'sszXbCGYSFMHcYWAdQyR',
  apollomedicalcentreId: 'Hu4jfCi1iPuOs2l4Thuo',
  onecaremedicaljurongwestId: 'TVZkxVk0U4aYuFOkeplj',
  samhsimeiId: 'fvi6OT0QVrg30xxflgvL',
  earlyhealthId: 'y80qofn9iCTDOPoWOGtA',
  kingstonmedicalaljuniedId: 'srS8oWgMF44MX429j494',
  curasiaendoscopyId: '8g9R6jYSINJ0XuBtwirW',
  bchpasirrisId: 'suek61MwnGmlqikunMOB',
  sengkangsrmedihealthId: 'rTBUUWCCulzQ5xsDpwho',
  sembawangsrmedihealthId: 'dUqnvEENxDJhxgOXUMYQ',
  farrerparklaboratoryId: 'NSKAZJomhl1bR0N0EBYg',
  simeiclinicsurgeryId: '9NWHXmgbo3mA2zm701uG',
  ashfordmedicalId: 'bELlrNxUTkhRpRBXMhdQ',
  sengkanggeneralhospitalId: '29UsLqojs7dgWUMyrLaf',
  aspenclinicId: '2fyYw91NmtdOKSPtohL2',
  meridianmedicalId: 'm8MxY3Hc3QVlRijnC6Eh',
  tfpskyvilleId: 'q3OacJKA3nt6QthwwFR6',
  pathlabsId: 'N8DWAqeo8vHhpovwWIVr',
  tractorsfullertonId: '8WPZKZVRXmDaE9s8LBDL',
  micronfab7fullertonId: 'Jpau0gXMNeHjY0laV6ug',
  micronfab10fullertonId: 'ghSoSJXM4Zi7aF61N1EC',
  micronmsbfullertonId: 'NTa2UpDv6SYMXyjAgAWt',
  ofsfullertonId: 'F8eY02wqT3kwhvbdzL3i',
  globalfoundriesfullertonId: 'leRSH2zyIVWxz2yrxBMO',
  lumiledsfullertonId: '8MwA7efbl9ciaAdLxODe',
  ubsfullertonId: '5kbbTrULtbns8PjPoSSH',
  gjmpfullertonId: 'mkaL01T9fPeapg8paZqH',
  alexandraaiafullertonId: '3jrn0hGX1ifLcvuhPubr',
  batfullertonId: 'DSIEREShjzgPWWjQDrT8',
  faircareyishunId: 's04D896GLLvstJWy3GyG',
  blessmcjurongeastId: 'wRlm16kFeb4vjRYQxvjO',
  rubinId: 'Qj3k3r26Omx5vVCg1tIk',
  acumedwoodlandsId: 'eFcbxycyQfxjQo4VkZs9',
  braddellmedicalId: 'dIurjkqJuy56ENpMrKKz',
  heartlandhealthtampinesId: 'IBns1W71IyYhEA0cgfJw',
  heartlandhealthjurongeastId: '6JFpJIFwK9RdfhXYYaJn',
  heartlandhealthtoapayohId: 'gEkaZgJF8xCA1T8iRgkv',
  heartlandhealthredhillcloseId: 'tbhRAjeXhIyvo28aP8Z2',
  heartlandhealthbukitbatokId: 'MZKOEcbgXGQKsgsDvuCb',
  heartlandhealthcircuitroadId: '8bt51LNEBE24YIvHNSaa',
  heartlandhealthjurongwestId: 'vFYjZ6F5EOO8oAhSlbJD',
  heartlandhealthbedoksouthId: 'gHIioL03VpAEvZVcCa9o',
  heartlandhealthkimkeatId: 'hm7MBLK3FVpxrKewIiWs',
  heartlandhealthnewupperchangiId: 'rCLtA42sRNb7b58acHzh',
  heartlandhealthwhampoaId: 'b7OrETQjyU6jTPxidQj7',
  intemedicalkovanId: 'N8OYwQniVfYHqsrjscOz',
  lifeplusmedicalsinmingId: 'T9ozeaZU1NrbtTgrP38y',
  maiphamedicalId: 'uN7F1rjDq4Ucf9ZnG1Hq',
  medilineclinicsgoongardenId: 'SvyFiIchuW4VLIHL9Vke',
  medilineclinicmarineparadeId: 'W7EmCWuYkvWCDVzANo5Z',
  neptunehealthcareId: 'MskaUC8biD3siCQONGHI',
  shalommedicalgroupId: 'wiPJdRuGC6MapSj6fpQE',
  aeromedicalcentreId: 'NHiaQOpbqyaMk4hsxTAP',
  tanyikclinicId: 'i8h18KPJZTiPSNWQq6hf',
  themedicalaestheticclinicId: '0Z58XF78UXnrqp7CC9dX',
  thomsonwellthclinicId: 'JX4GIOy3A2aXzOQ5Zifg',
  tuasmedicalId: 'n2nrW3xPyU3QN2pCGKD7',
  tuasviewmedicalId: 'eIOgoCRRgoQ4KIt05DNE',
  woodlandsmedicalcentreId: 'qhJk2fd4HOfqPD90fIrJ',
  babystepsvocoId: 'o8BalL2Bc0Uhdwym3w3I',
  wfc305Id: 'f1meqox5N7Wu8AHAjdZ8',
  minmedgeylangseraiId: '0ch4U8UdtLopdV1oLYFU',
  drssinghandpartnersId: 'HknPUlUYM5BjWMNiZGtI',
  hpfcwmId: 'QMdayo3TcnMUHAoB4Tcm',
  drpthomsonId: 'TVdNRZS7a43X5oGSxSZn',
  majuclinicsrmedihealthId: 'JfNY4WYAgZkLJZV8pWSe',
  yslaljuniedId: 'fGtw4D6ImJ8JmNCr379f',
  distinctclinicbbId: 'ptexW3OF8UgCFfShQCQG',
  drsohfamilyclinicId: 'tUeusAhQAlUUSJDD8Zhh',
  saudaraajgeneralphysiciansId: 'Kp6VSq8FABPsSpB32cnL',
  smhaneefaclinic11Id: 'eQKjEpH3Qi3G00OC9Ict',
  procross216Id: 'lX7lqzcpNq2FfFhfwUO1',
  familyclinicId: 'PdYF6GjWP4ndoc9u3b3T',
  procross405Id: 'tgfNgDQ5cwKQpL3cH4tA',
  procross411Id: '36DofR8V4bPttWalGTiP',
  ahealingheartmedicalId: '7iHktAE9VCXwEcJPTpPx',
  accordmedicalId: 'ApxxmdrPEGDcupzhJhbp',
  admiraltyfmcId: 'jCTpE3ClazMfa1de0NBs',
  aemedicalclinicId: 'RAXLsdyMFXiyKXJovlc9',
  allyhealthId: 'Zi8AiIzkLTOdrbijX960',
  amdasgId: 'qk3n0b0oGwu9Hetxe6Ic',
  aiclinicId: '2XtNtsrHUAXtNU1OE00U',
  ansariclinicId: '6wOAgLgpvserrYKBrmc2',
  apex2292Id: 'S5hxMCiEA7xjXD1HazMz',
  asiaretinaId: 's2rgFobjNd5ciMQHqDKA',
  assurancemedicareId: 'NPWRugHXB2rWCVvZkqIB',
  atlaspacificmedicalId: 'c6PaEBqLd67C9X9zdNVJ',
  avecomedicaljemId: 'OYIDkx375yN7r3QFsQZF',
  avecomedicalmarineparadeId: 'uEAivBvOvbZt9oRRPAF1',
  banyanclinicwoodlandsId: 'tqbuYdy9l12et7Lc9ETg',
  bethesdamedicalId: '1nupMVAyVDJ09mdljWEr',
  binjaimedicalclinicId: 'a7KebCBu1LGDLdABjOdz',
  blmedicalassociatesId: 'EDr6PFQDXXJJlHyXwapJ',
  blisscaremedicalId: 'S9oqmdACDKgL983X0rcT',
  cktanfamilyclinic125Id: 'YqueUDsPtYsANPiJrr4Z',
  camrymedicalcentreId: '2edS39bN3vfUXcePpkW7',
  caritassgId: '6qYHNCvswdZ99qNFTxfA',
  centralclinicpioneerId: 'HXnDYDuhqrUr0McbpW6O',
  centralclinicbedokId: 'TwPaoZOxGeNE6XjN48JK',
  centralclinicclementiId: 'ADxMi592TlMJXiU0fQ8N',
  centralclinichougangId: 'S2m157alBfr39kv0gZeL',
  centralclinicmarsilingId: 'kti58umIWy9ZuZlw0EQR',
  centralclinictampinesId: 'gEzRfsF2BYhQCGrtshEM',
  centralclinicyishunId: 'Va9cpWWHFKb2tXB7kY3z',
  centralclinicjurongId: 'ym7ENLKVd78cCtfH1Fbp',
  changclinicandsurgeryId: 'iQkF2YmvvrypIps9m6Ug',
  chenfcId: '0V0v6VNLAgIlVHw65hPN',
  chiaclinicsurgeryId: 'CQkE3mjdjY6bsslkKUxO',
  chinatownwellnessclinicId: 'PEIqiSRbNSdzP1ez2My5',
  chironmedicalclinicId: 'gXnUWliUXrBVkLm7VpVN',
  choongsclinicId: 'aV8O6DTbU1Rp6GOatdc2',
  choongsclinictampinesId: 'sZMYgFcqHbrKCpB4XvWh',
  chuahclinicsurgeryId: 'UGu1mrKOE6sISXsHT6jz',
  cityhealthId: '9ysmsBM6e6PlEP7nvCQ6',
  citymedId: 'nw6wHRvucKZ6uKUyAoYK',
  cornerstonemedicalId: 'BE9fEkatHDBaXEOJPLVx',
  cornerstonemedicalbidadariId: 'M5PPkFwDRiUg8qnFulTf',
  dedicaremedicalId: 'iF4AYWd2qqseMAC5gXMY',
  distinctclinicjeId: '0BxVoFusyo7aBDWarx0V',
  drchanpengmunId: 'MNVuH7KgCldJLOB1NZAh',
  drschantangfamilyclinicId: 'Zz0wINBBHAr4PVB1lZ3I',
  drstangandpartnersId: 'p8Dohd5nKV8Wfc3x1f9t',
  drsbainibpId: 'MRrG3iQaY9103en1CLoD',
  drsbainhfId: 'qxG26SG1KUmWOJv4VoaW',
  ejtanclinicsurgeryId: '506TpgWmJa1PcL8KGDrq',
  eastcoastfamilyId: 'jWje6LwHGMIGiSECtJof',
  easternmedicalcentreId: 'Bsp5YMUQI1XIU5j2pyP1',
  eeclinicId: 'WqLD0HXqsUl7k22cWAmC',
  ehmedicalwoodlandsId: '0daK5eXnEc7VvZf9q6Mz',
  ehmedicalbbId: 'IG28g6yu1pCBMp2yMxFk',
  ehaclinicjurongId: 'nZFLQ2aZJ9F3eUeVn8gq',
  elissamedicalId: 'BC3yRQB3tLjX7QflJp85',
  faithmedicalcsId: 'l6hWgSi4TOdl2r9dk6AA',
  familycarecsurgeryId: '0iA5XIRBGrU9g8nj18mS',
  finesthealthtpyId: 'oht9X8AbWobfu5TWWnrT',
  firstcare253Id: 'H4xr7mflyWn8hZnWTHLV',
  firstmedical167Id: '6XfaxUO08Sjg60aqBRsC',
  frasermedicalId: '65RnvcCXJVLBJ4J74j96',
  frontierhealthcareclementiId: 'Bit0icIVanpiH6Ojymln',
  frontierhealthcareadmiraltyId: '4rTOVWyrfD3Vxxb5XuZM',
  frontierhealthcarebuangkokId: 'I3y9AZmWdd7ELINRV84R',
  frontierhealthcarebbwrId: 'nCeIPB9rcnfQ6FCvA2db',
  frontierhealthcarebbwtId: '8pwV3DKdQ47ySDrP9h6C',
  frontierhealthcarecanberraId: 'sdLyIHYa7K9U7aI7bwJz',
  frontierhealthcarejurongwestId: 'Uaspte285yHYrfu4jf7c',
  frontierhealthcarewoodlandsId: 'NQL5hkvcsHIms0ZHjAi3',
  frontierhealthcarebedokId: 'd5L0ufX0nwgZVS7DieCv',
  generalpracticefcsId: '6g2UMx1fKzPKJLPeYGpx',
  hchillfordId: 'wsfaHzEE4HoB4r4MF0xS',
  healgroupId: 'uR81TvxNtuqqEx6mPw5B',
  healthifymedicalId: 'Hsjn7zIrEpfD6rR69ZgP',
  healthwerkzsembawangId: 'W37GJIg6pMnKTzVUh43y',
  hisemainnbbId: '5I0eh6giX8m65ONoxam1',
  hisemainnjwId: 'XGUIE46Gdt95GAJ61y8b',
  homedicalclinicId: '3PgOHFL4JfZraVOI7btx',
  imedicalaestheticsId: 'PsUElInINow8VPW7Q2fc',
  icaremedicalId: 'T8VPVi3lKcpSdfjpcuv2',
  intemedicalpotongpasirId: 'QPV2A5RZqcQKRb3KEytA',
  japangreenclinicId: 'sFzTCazlRsaFK0mjcmTH',
  jasmedicalId: 'WSIPThLUVD2jAc7kSonQ',
  joyfamilyclinicId: 'XihbnNa2k13GjiB7tTYC',
  knchincliffordId: 'OGq5ZBv3PPRQPDYa9NII',
  knchinsuntecId: 'IQxYyR8dluXEE4I5Q65Z',
  kbtanclinId: '6mhhNakSLaXJwMBvQlF1',
  kblhealthcareId: 'tXwUqqNNvywT6f2Qon6l',
  kentridgeclinicId: 'EgsnaWjRjOiGDdqvcZmH',
  killineymedicalclinicId: 'SbU6qO8e3HaRyt6Gwm8z',
  lavendermedicalId: 'vmbL3g7cT0EswwaEFURC',
  lee123clinicId: 'f1xx0WtCLFfkQYyKr3wF',
  leefamilyclinicId: 'BI9qEiFFUAeB6dZ02GQi',
  lianclinic18Id: 'wRYc5u0LoFn7ZixrKa6y',
  lifelinksnId: 'wPuWlfQNUGSyshP1wKAR',
  limclinicamkId: 'Daa6OqNp7sWYdgo16tiJ',
  linandsonsId: 'YtmPgJUyEdVsNjC7U7mc',
  loyangmedicalcentreId: 'tGuKwWd0Q3Gv660vOgbK',
  mlamclinicId: 'rse3VdKO00nKdFJU4yCr',
  macphersonmedicalclinicId: '9eI4IsMZjcYXmiXUl0fJ',
  mhcasiambsId: 'zZYdA2o0vyLqJHVRqvJH',
  medicareassociatesId: 'obT6nCsiI9PRd3RJUPdw',
  medilife474Id: 'px1cTcBeol28106ELOcD',
  melpartnersdrsId: 'WD25RDm9E3ItbSK7Q880',
  mhcasiaamaraId: 'AUEOiMaEHWxqWitSI8KA',
  millenniumwoodlandsId: 'wGFY46JAIAa4SsxRC38O',
  mutualavId: 'g6waOIA55jbb2Ztk4ivJ',
  mutualcvId: 'PXjWCxsfFwlo6IWVdcbH',
  myhealthpartnersId: 'ZFigeJARiprW8nCAqswx',
  neighbourhoodclinicId: 'EOQ8S6hyyr6dPW2Zt1fn',
  ntcpl2006Id: '5vmMsFhGkbIABHnVPEw9',
  nexusaestheticclinicId: 'm4qmd0jtVvluWUoL8bb8',
  nichiiiclId: 'WuqwBSuzSBUcxHclIw48',
  norwoodmedicalclinicId: 'Xc1HtdYeWF7CFeQ254di',
  onemedicalId: 'FxDBEo5OJdS6OVOEo19v',
  onewellnessmedicali12Id: '0Z3pGX7cwMNembhtyUjk',
  oslerhealthraffleshotelarcadeId: 'CNUtBczCqwnr6EKCN6Y0',
  pacificmdgrpId: 'NaY7dcihQolxcfrqht2H',
  parklanemedicalcentreId: 'tt36YAyRxsdcxGqHoEdt',
  pcsmedicalcentreId: 'Pw7cBVBqKO0BBpzFRNZa',
  phoenixmedicalseletarId: 'UPHfm3NQUxeOCNExJ595',
  phoenixmedicalnovenaId: 'NrYb6fZDSbFdfHtWJ4C4',
  phoenixmedicalhillviewId: 'D4nCc8VkBmu2BUVvuwLS',
  phoenixmedicalpayalebarId: 'WVXe8V2J0pc24zEmbAds',
  potongpasirmedicalId: '4ZT26A7vuYFVa02N1Jp4',
  preciousmedId: 'RmBOhtncHZOKwmFGm6gP',
  procrosshougangId: 'MMNeGgYDNmtALJ2cJp37',
  procrosswoodlandsId: 'wRBDtokBw4e9dFhwBtCr',
  punggolfamilyclinicId: 'wUD3e2R65BKb8JROfTf8',
  rcmclmgId: 'Sr10shh9NmTiBV3ukQJ2',
  reddotmedicalId: 'z7YHIfBQIukp8tkb8kgO',
  redhillclinicId: 'R2IdpsDokG9FUFa1hf5o',
  redwoodtpyId: 'hgwlWQWTwsXz2tsH58et',
  rochorcentreclinicId: 'v56wwt5vA54v844yScu3',
  slfamilyclinic729Id: '62WZEcrsYqZQo599Ev1h',
  slfamilyclinic139Id: 'Pc7KhnJxWVgbs00S593c',
  sataamkId: 'aQ7m5dvOzigSrcNzZVb0',
  satabedokId: 'RDOXLmEELP3eWCSznsTQ',
  satajurongId: '0WEAAHRMj4gJXmmYIVE6',
  satapotongpasirId: '1yKqLJmGC2SCHcqeRQ0S',
  satatampinesId: '8BHcs3h8skvcjbkrbTny',
  satawoodlandsId: 'Pi4FLk5p60817Tkrmfbu',
  sembmarineadmiraltyId: 'bSV0MvqPcjngP58oAv2X',
  sembmarinetuasId: 'GCo8LJlDh5UHcYlRaENK',
  simsdrivemedicalId: 'rjxLsbKkyyf4Xk795MKh',
  springmedicalsgId: 'iodbLZ2PuquNAv7w5Zam',
  stamfordmedicalId: 'b8xgvaPIJv3qVeEZc3Cx',
  standardclinicsurgeryId: 'JthdLsHSS1MV5qqJJvnD',
  sunbeamanchorvaleId: 'dVYH0814Mukk7ArU144Q',
  sunbeamfajarId: 'sFifwz6P7JGIe7geeemg',
  sunwaymedicalcentreId: 'ixxYMHBShGn8BWhmtnoK',
  tampinesclinicId: '5aLmrMWQMLv8XIY8xYs3',
  tampinesmedilifeclinicId: 'uV96Me9yYzAKR8e4oAKU',
  tampineswestmedicalId: '11iyXDpQb0dAfM49e0yP',
  tanstandtclinicId: 'yOchzJakatudf36uDZog',
  tayclinicgeylangId: 'ICi3WplogwdXF4M7woF1',
  thebalmoralclinicId: 'ESKp4DvVDNGc4TrqgHyP',
  chelseaclinicrochesterId: 'qEx3JNPPFKrCzACDd8QU',
  theclinicgroupcampusId: 'phWqXTBwaXdF3xrw0rsH',
  theclinicgroupogsId: 'fNIHsj4rQizs7Jjy026k',
  thefpclinicId: '9OwWMR2fIcfAyoUx7szT',
  jacsId: 't1yHl3xZuqpGRY1pk0wy',
  themedicalclinicId: 'OTRxIVb2iB6eiS2hXKAG',
  themedicalpracticeId: 'W5tWYIMXowhOj5FpLxxl',
  thomsongpclinicId: 'knZgsKDYKnDNfB3fELpf',
  thomsonmedicalId: 'pAjkea3Ge3J2cKignmbC',
  tiongbahrumedicalId: 'b15nkoNUcP9yNycIc0q2',
  tpcmedicalclinicId: 'CUnzjIRZ7IQYn1xKRdOy',
  ubifamilyclinicId: 'f5kQXlK648fDrhoc0YHd',
  uhcnusId: 'KfXzVw70YynZEHXgKCQJ',
  wellfamilycsId: 'PMFxxQ5QGrFMOwgE4bQ8',
  wenwengfamilyclinicId: 'oQskLSL1FsNZVZnTo6FK',
  wongclinic539Id: 'Lyuc2Jbo9eO1FRgvfxAe',
  yimclinicId: 'hAXMv1CyUHaMLfJi4p2t',
  farrerparklaboratoriesId: 'vjMSTxAlPieJxcSt21aE',
  royalhealthcarespecialistsId: 'OYkKBg5wBZn0uo7y2rPd',
  lifeplusmedicalbedokId: 'iU3Ycy2G7Ru7i5jSbsiO',
  kevinchuamedicalId: '0Hvc9bUNmHcbEIHJkcFi',
  ahpplId: 'h9keuFhT9FVIMaBKvsa2',
  affinitymedicalcityplazaId: 'Cutepl75JrAX0o3j5xzo',
  healthlightfmcId: 'xcKfd9of74a3YF4QpUFw',
  mhcasianovenaId: 'TZuxn3enNzoM3vRbKbkK',
  vivienrobinsonpracticeId: 'RyWfDYcHLqE3KfO4CgnT',
  keppelmedicalgroupId: '0y3BBbAC2lniwln7HOcb',
  amksummitId: 'MiJAjDbfjVaLXlKMKzfP',
  drslimandchanhougangId: 'cSjFWvZp68p2l5NhJMPt',
  medcommonwealthId: 'vCcyUKt5JoHddEJjRdmK',
  drslimandchanlorlewlianId: 'kp4oerV2KZWLhOeb78WK',
  innoquestId: 'YtPRYwGZMbfqhyAXVmmW',
  westcoastcsclementiId: '5tE4bgQ3LXVL9xf3JJzv',
  myswabchinatownId: 'nP4JNLOfdLqXjtzRw3tJ',
  cashewmedicalId: 'oqDVQFNDH86BiGR2C1lO',
  crestfamilyclinicId: 'NZpfV2eqXAPUMAQo13ZA',
  fareastmedicalId: 'dgC82giYJnB4SlujXakP',
  stewardclinicId: '5IAfXVhX656p8ec1FVhx',
  praisefamilyId: 'PhKIybVnEuxcZuBv6aod',
  innoquestdemoId: 'FWsFLtDYcCsLp520hYMe',
  chongsclinicId: 'CmNhENDM5xsIrHCJvyln',
  umcbukitbatokId: '6r1uyh1vBVKrCxgcmKk9',
  marymedicalId: '6nRLoAiOPgoHz89F4zS5',
  thefpId: 'RT0WUdw9a2kpp5RQa43c',
  eiddwenclinicId: 'mLeYGXzd2mDkwPhywvNj',
  bishanmedicalId: 'fd07So2SbCprG6DVTBDA',
  aidanmedicalId: 'nWy0SebKd4trKaR6dTLA',
  changiclinicId: 'Nw93so9rmun9Z3Gel7cT',
  comfortclinicId: 'URrHRYtYT7qAhslnFGO4',
  drskoolohId: '3F6DL5jI6aI60jxONG2S',
  dtapduoId: 'vVBApFSTDtqlXSSQFgUt',
  dtaphollandvId: 'VqkoWp8XqHa4mbr4cXTf',
  dtapkovanId: 'DvvUoZcPTYLTRYWSyP5h',
  dtapnovenaId: '99J2vM78H87Vyl67VpgK',
  dtaporchardId: 'ecTurH72IMhevfmcgYYC',
  dtaprobertsonId: 'wXwWtKmKtcLHGD1jb7g5',
  dtapexpressId: 'vBeuRRpzgeVQMAmL87af',
  healthlifefcId: 'VMAaKXQi4NMKQ29PdaGO',
  healthmarksimsId: 'fu0SmN9msO4hoPJIhnXY',
  yourhealthmattersId: 'OTLuULipAK2D1DD4TM6B',
  islandmedicalId: 'jroGwfqWqNVs7gjOz3as',
  japanclinicsurgeryId: 'LiVyOBWyirSWiu6vtlvX',
  plushealthmedicalId: '88x7M2HuzZbCUjOIZqGh',
  shentonclinicId: 'siyTIvUJ33VhdqHVtQkc',
  whampoaclinicId: 'sxj7DZYOGy6zXhvexbst',
  wongclinicsurgeryId: 'f8p54vnqjW00OklO1isp',
  excelfcId: 'nOzS9IXaNWO0yqojfPi0',
  docsincId: 'SDQcHPxovwCCHYfIVHIg',
  compassvale297aId: '5PGfXKIfTWqkNOmaWGAE',
  smhaneefaclinicId: '8zDQKSIhonLpPID1Fv0R',
  vistamedvivavistaId: 'TySAu591PCj6mD8zlqyy',
  thetenteramclinicId: 'aXbP5VA7nKlNnwzIBH3X',
  minmedmarineparadeId: 'X4Oq7Cu9SLJWVWxoZLwW',
  thebidadariclinicId: 'MHtO5iSJpPlkCGtqIDjZ',
  swabtpId: 'JoqmogAT04DrKGxCa6IM',
  woodgrovemedicalId: 'izvmCHKqySoIjK166r4E',
  sgstpanpacId: 'bTIJm5Eb9thLQSs57x3W',
  sbccbppId: 'mhwFXrYZuKD7oDNT72vb',
  sbccgmcId: 'YgPFTyEBSko6N6NAfmMy',
  sbccbishanId: 'DouHj2JCGWIs2fOVLVYi',
  sbccamkId: 'zjPXZSjNJRwIBpmE4u28',
  sbccrivervaleId: 'KGD4gkHPnDKAfmouXfyx',
  sbcctiongbahruId: 'QU69KHJoz6RMgWpl3AQQ',
  sbcclungId: 'zrAPbvAx3AWGh6XIWDMB',
  sbccmountaId: 'bKTbElSww1R65CHYkq8E',
  sbccnovenaId: '4uTbAsEITNrZq2wWCFfs',
  clementihealthfirstclinicId: 'gQQCeL4uEryoRJNppF0e',
  koowkId: 'NQ8kBLGcflSRdHdugMDm',
  fullertonhealthstatschippacId: '3W6cgSiEAhpQLlnRrVzu',
  fullertonhealthsiastcId: 'nLgfPUWQI6ZtDMEcW7hr',
  fullertonhealthsiaalhId: '3UIwCI7BgA9JTYw2EotN',
  fullertonhealthutacusg1Id: 'I5YMdYY4xOgYVCF5bZlP',
  fullertonhealthamsId: '4z9WfikPApS7JM8XVlKF',
  healthcarefcsId: 'tF25Lk0eAu30gE6p6zqH',
  sgstamkId: 'DNRErFSWFDCEMb06IYvg',
  sgstgoldenId: 'chZkBWiMr72mkafklX0I',
  sgsthollandvId: '7ioGH5X3Uca6Jf9BbWqt',
  maritimemedicalcentreId: 'N69UJHRzgitt5HraZwwz',
  minmedconradcentennialId: '7j7Yl6Z8lt7GDLrxBcz1',
  minmedcopthornekingsId: 'XatHaPT4VrZVEBOvwjSZ',
  minmedswissotelmerchantcourtId: 'D7lnziTzp4Kjt3Cxb0hv',
  swabngokinexId: 'ihiGcHEOPGXQYeVHHfrz',
  winsomeId: 'R3fVjfYRivZdEOQnoIlv',
  fajarfamilycsId: '3f85mAKNeYJyTsV0DH0E',
  lionhealthId: 'dBxoghddzR4Vtk8uxowk',
  theintegrativemedicalcentreId: 'ybLg65bmf13J8FVDGFBz',
  gainhealthclinicandhomecareId: 'Kw7NaXegk9MEshxTsG3q',
  shimclinicId: 'neZEXLeutLmBmOkttfdD',
  huangsurgeryId: 'fPzoZVzksWEVC4NJ9dmE',
  rmdyclinicId: 'ZvQjQUtNWd8eeVgo0xIK',
  gooddoctorsgeylangId: 'rPu1fmNw3KNj9JXaqTtC',
  medicalpwId: 'c4YOnHzq8nyotqmcKqvV',
  paddingtonmedicalId: 'SYtxWeg7gqzAtgIdLr2b',
  kanganbtcId: 'wjYyXEh2gEaUuHKkKi4T',
  bmc460018Id: 'OxnCPruVVbofSFZbkO46',
  clinic10Id: 'MaavNCISJbDhUTjmafht',
  asiafirsthealthId: 'hLMFGSUpYu2lUe2vtolD',
  lifecarefcsId: 'z90mt060xJCIStITL3K6',
  fcsnewlifeId: 'X2JmXtEsuK9J80UMckzM',
  hlfcsId: 'OQaw3mKozSEA40mxwF61',
  sterlingfamilyclinicId: 'E0AqGGWqWtx0yDgLkZQV',
  travelswabpeoplesparkId: 'zgV5h7WvYqvpjUAs9CbE',
  theedinburghclinicId: 'aWuxnJk8pI6IKpnbQiAW',
  oglmedicalqueenstownId: 'hibiLhml6tSjjlwnyRn3',
  babystepsfareastplazaId: 'fvKUj6HUBSupq6L2UC8Q',
  minmedjurongwestId: '7WTBIog239ZZz2WXRtPa',
  nyamfamilyclinicId: 'CR78u5NuEilUKuB3W2FI',
  huiclinicId: '5ieMVtJMPpXg4Ulr52qE',
  medipointponggolId: 'ijXErLdfAbLCti5xBbdD',
  jademedicalId: 'VC34chOESn6ExtSEyFdy',
  shippingDemoId: 'D7MdfBNC2qJvLdmvp64c',
  trainingDemoId: 'J0ZWo9IZKYZzd3vZRiew',
  recruitmentDemoId: 'GaZBNpq4i09F6ZtHnR1q',
  chuaandpartnersId: 'Sxz2CNoFT8B4ySABzUIj',
  healthwaykwshospitalId: 'epDBIGDzrWSwFs9aBR84',
  lavenderdivisionclinicId: 'Ns2jLwQdReP6mZ2NeSGQ',
  tanteohamkId: '00dXG9EEM2zfbZXbTu0T',
  tanteohtownerId: 'aIuJbNuQh6W1Pkduvx6x',
  novenamedicalcfcId: 'SkRTpp6k0BbTrMVLVsHj',
  marineterracefcId: '3dCrvL4egBQbrNtgNeof',
  manhattanmedicalId: 'UEEEa7UPZVsdE5b2wBv1',
  yimmedicalcentreId: 'EmSt9w1F2gBwDpsEQ9Me',
  chinatownfmcId: 'iCTURyHumTEl4XZN87CS',
  excelsiorclinicId: 'tGX4RviWHzqNjEo2Rs8m',
  lhpolyclinicId: 'hvpduG49t8swZC7b6tR4',
  heartlandfamilyclinicId: 'gnI8sOYwvLShQKI07bV5',
  tcfamilyclinicId: 'z2gq9MDZcNlW0csjrl3c',
  nutramedId: 'E1mKwd1QtxMgXs2yYgfZ',
  bedokclinicsurgeryId: 'NO0mJFG6lyvtIK3WZdPK',
  healthwayjapmedId: 'QwsAFr7pJk9wsltiM3Zk',
  pqtcjurongeastId: 'YvfFNFHl2iV94mJc4fb1',
  dawsonpcId: '2Nl60VSFVoMr0uEDqJP5',
  trinitymedicalpunggolId: 'jV3Hd1V7x3hZ2DHtVp9V',
  medihealthcareclinicId: 'cCfZRQthgUWOEJCIlac1',
  babystepsoxleybizhubId: 'X7w2aVjxLqaxhdL3fFqO',
  saudaraclinicId: '0GNKbzaXhpNH4M787UJf',
  xpressmedicalId: 'LMlHvVV0tNNHSewGVqsU',
  clifforddispensaryshawhouseId: 'BcuRlz1yaXeUTOsKm49Q',
  clifforddispensaryairportblvdId: 'Iwn4xrgFuF4CHj4ag5qU',
  biolidicsId: 'k1itkCZMWZDtdyBcPOYO',
  rafflesmedicalId: 'WWZUNFQ4vtKtAMDxiqJw',
  boonlaycorpclinicId: 'nzQ4cj6C9UP5U9mffRtK',
  healthwayadmiraltyId: 'avMCcFxMyt63iuyRCNmT',
  healthwayanchorvaleId: '84Fw3lBkDbDJAYP993wH',
  healthwayamk1Id: 'WlS6JhZrxlPuvzdCDDPl',
  healthwayamk10Id: 'CT987qINfKVNEWLULu7I',
  healthwayamk8Id: '4CcbNJJPOxkugOcEJBKl',
  healthwaybedoknorthId: 'Izo2QKst8N8ZzK62g9hd',
  healthwayboonkengId: 'HRwptCyINWYtO2G20dVp',
  healthwaybbwestId: 'mdEQM4kUigEhv9CMNDo2',
  healthwaybbId: 'khWY9GjgXs4mMLqU19CM',
  healthwaycompassvaleId: 'FiTo2HxONs6PyXbpdc88',
  healthwaydowntownId: 'fg8nALsU0kKSivBcRAsA',
  healthwayeliasId: '2PzvlUwB75M0KaQus5ST',
  healthwayfajarId: 'Sp3zwjoe3aik8MUuzfma',
  healthwayhougangId: 'zJ0L4pZZnI1eWjrUDZo8',
  healthwayjurongwestcentralId: 'FdOBhEInEKIeJHhpmLCf',
  healthwayjurongwestId: 'PuWWkUcFS1gNq4aFwwHu',
  healthwaylengkongId: 'IUtRaCac5XGeTifUJs8e',
  healthwaylimbangId: 'dXd0XWmvEA9El4cz3YBP',
  healthwaylasId: 'Sgv2C1Vxiyj73ZW7ogvr',
  healthwaynovenamcId: 'FzXYJRpyF6z887pFkjUC',
  healthwaypunggolId: 'p8a2acV5Rujreus7teIU',
  healthwayrivervaleId: 'GCJ7uk0uDfXhPXzcb492',
  healthwaysembawangId: 'aKvr7y8spfPZkbvVqbCB',
  healthwaysunplazaId: 'XQGP44Gy4dGXgoaU7XqR',
  healthwaysunshineplaceId: 'hYaHDKwB4OH60Rzq7lXb',
  healthwaytampinescentralId: 'DLTqLgNjzbRSV7oghMBm',
  healthwaytampines21Id: 'rRJ6VJItJfUh5unruxID',
  healthwaytampines71Id: 'xV8jZAOodnRHWy1Trax3',
  healthwaytpyId: 'RJi2Sh7k0fWdjVm9Vk6T',
  healthwaywestcoastId: '8fm2XXnO51lmV1tHTnkz',
  healthwaywoodlandsId: 'ZfdY8PsdOHmrR3nWPKVu',
  healthwayyishun11Id: 'iihljkVi5WdIjatoBgPl',
  healthwayyishun5Id: 'PuvwvfFPBuL5Dkh6uLIv',
  healthwayyishun6Id: '1dTuvg3NzLwFl1iROthY',
  healthwayyishun72Id: '8bFyhG54d3HoQY39Vkku',
  medicoclinicsurgeryId: 'KnKo8M0oVNPjbrotAn7D',
  pkangfamilyId: 'w9V76WixXBGwKdEGHIEL',
  unitedmedical139Id: 'ZMwQzKk937qY0AXIABEK',
  silvercrossjwId: 'ZoOxijuiConhSufjI5Fc',
  silvercrosshollandId: 'zSpkCiJr2RcWsvfqvUhf',
  silvercrossyishunId: 'g54ZhfZ5y4kureoNhWO8',
  silvercrossbukittimahId: 'MuJA8XJ1seIB0T8yutWf',
  daclinicyishunId: 'S0tldASw9Bmg6cGYVWPt',
  majuclinicId: 'X2Mo2jwSAtsOll1GySTp',
  theflametreeId: 'HCOAOjM6WmN0YjUPDFaU',
  familymedicareId: '04uwOKovYhqfzMKuEm4G',
  fklimfcId: 'JKbZEe8fiRJrUkoZHfBM',
  kaimedicalclinicId: '3jGbEmVcDfRB8hYR3W3s',
  gohclinicsurgeryId: '9Lpi0kLE1FD0Qma0vqwY',
  katongfamilyclinicId: '07RG39uYXY2Q1chFxbxB',
  kofamilyclinicId: 'FbVewA3m2t48qda8DkU1',
  healthplusfcsId: 'YWs7EUkOqbO1xkvrbdtZ',
  changigeneralhospitalId: 'ZpYFKit1yUViH5RTUXKR',
  dolwpId: 'r4xawZZ2Vfk2AXpzYQPX',
  drjoycepartnerspunggolId: 'OdTP6JCKlNrKm7gvzxf8',
  drjoycepartnerstpyId: 'gvDVjWJ9uZrM8Hkz8xhp',
  advantagemedicalgeylangeastId: 'ReVHyj1RW7KG6mdnoMZv',
  northlinkwoodlandsId: 'B6R7OIKVZTeGKFKfCH5d',
  lcclinicmsaId: 'O8QIF9eeUpGAAdxsPoGg',
  vicghimmohId: 'RXGzY8qL686FncGobQ7G',
  hougangcliniqId: 'TWBCYlaQ4lvEvN1pGq3y',
  canberracareclinicId: 'KzFc4IddLiRWwi7HIGkM',
  o2medicalclinicId: '6YQrXgpBQpKwXaqQo09B',
  thecliniqtpyId: '6Flh9bAKuKs9OmyUYaAq',
  dknmgciviccentreId: 'AF5xZP3AnftEkgHvrltq',
  truecareclinicrangoonId: 'UgKXk9M5rJ55JDkgBlnz',
  kingswaymedical24hrId: '8TWTzvZApz2QJNSDBf5E',
  yapmedicareId: 'hLJipa6BU7gkrWipOCkN',
  samwmcId: '0GLoEtdmLHWjuwCLy6Po',
  royalmedicalId: 'gLvN9OdcPHF8UDuJbV0z',
  drnahleefcId: 'hbvRYUiVzC7DOt0Ipy6z',
  clinicassistId: 'sUYGOoW8LiB4gkXRVxau',
  ifcseletarId: 'se3Swyjjg6IeLxgQ2TB2',
  taykamedicalfamilyclinicId: 'MwGnb40uQfkLDGey9aW0',
  babystepsmedicalId: 'XYbj8Xnuw4eyeqnuIfPb',
  smrtwoodlandsId: 'nWlF2EDH9utkTs2wsoAK',
  smrtbishanId: '0Ew51ExJVTvv36Gu4dHG',
  lilyneoclinicId: 'u1OeMePFisDfZg4aAHSW',
  cheongfamilyclinicId: 'q74q54oXHjYClC0qPhQv',
  siglapclinicId: 'bBJa8n5D3pueap3DVCnC',
  myclnqId: 'UBPnNvTGbxkGFEFWUVxd',
  meenaraclinicId: 'a0nsCFQpsWKBYkuJyoAF',
  appletreemedicalId: '85AZEZ8GkMkhghGrWAw5',
  doctorsclinicandsurgeryId: 'TfICzhRgCyp1AGmd5eoK',
  vitacareseletarId: 'CMCNaTuAHlq2hjCjt6kG',
  minmedpayalebarId: 'aIVxhwTfBbGCuHurdsBL',
  ddmcId: 'KVnTcR8sfiXZ4yBvGqGi',
  kallangbahrufcId: '74c9H7wE6PyS8tV5Xwcm',
  igenelabId: 'osx1fcI7l5zw8HWCDIOo',
  gooddoctorsyishunId: 'TYFPax0OMHqtb5bhu6r3',
  drpeckclinicId: 'tmwXbFtnxcMmiHtT73ED',
  pfcbuangkokId: '2rCtcbqs5LKkyMjnMCNK',
  pfccompassvaleId: 'ja1QjONINnTjEcLCjhvy',
  pfcnorthshoreId: 'jCOeBkL2iAakgNMvmBdT',
  pfcpasirrisId: 'kB332uHEO02sMchYXhBA',
  pfcrivervalleyId: 'g8nlfYlvkTvKsxA0EUjl',
  pfcserangoonId: '5aueLpbpyQ0VDmDsMsaB',
  pfcwoodlandsId: 'i4Oiz41f3pymJNBselcb',
  pfcyewteeId: 'APtxBSuLEDWQKHzir8Cs',
  lifescanparagonlabId: 'T68orZfUQNQtf4JUVgSG',
  panhealthhougangId: 'DMPccYKq8nRJDJxXnoZs',
  panhealthpunggolId: 'op98OwD4SXox5vOatgW9',
  crawfurdmedicalId: 'chYo2zswWg8uiES5DyPp',
  tanjongpagarmedId: 'hfmKVwPCTXsDbIyGSxaI',
  kensingtonfcId: 'SdO9lGJ1x9xPtEpJzVFd',
  ongclinicsurgeryId: 'BKMW1sbckdWQo9x2CHrF',
  wanmedicalId: 'ad5t9qFMdn8WmoSpsK8k',
  jagameId: 'CgBj2Ii0mQgsNsMctO5Q',
  medivaleId: 'mG3bDEqlIesbisyrxi3Z',
  novovitaId: 'U3haLyPe2zPckpXdvBxQ',
  trinitymedicalshentonId: 'QyDotpoXgDPe77K7tbMN',
  eternmedpunggollrtId: 'O7kpquT7zUxj8WQ62c7z',
  eternmedpunggolmrtId: 'MYCziiOvx3oyyMiLBd3U',
  eternmedfernvaleId: 'DMw6UWbJGiFFnU1atJJ1',
  wilmerclinicId: 'BP4nC8Tk1jgaAmx1VTik',
  hillspringId: 'z6dH40qo98cFWivhGQ46',
  healthplusbedokId: 'mwKfDisSLEhcXHOLeK1E',
  townclinicId: 'J7dpTUt2tGTpsI1MFYUt',
  everwellclinicId: 'GZKE0RwXl9nL1eyvFN1E',
  coronationmedicalId: 'DNUFA9ZXHn1d1TgTTbq0',
  statmchougangId: 'YySGjR3pjkTRHUVX33yK',
  fullertonhealthpasirpanjangId: 'oeLXIlWY4lMKgHGkvfie',
  lifelinepunggolId: 'SkFs2N6Ua5zkjnmzILYQ',
  bedokcentralId: '1QeTTFf9pXpTcWGdq6lW',
  lifecareId: 'myinLyFVyByFa7vIu4HE',
  temasekclinicId: 'c07YIhuKLo3znMmrgeeQ',
  hlclinicId: 'ExaVk7lAVRkQ2AIjTX3R',
  workhealthId: 'oCVmXihq6SxMB1V8XGSx',
  hlfamilyclinicbedokId: 'CTi0zhDVXjjhYQXOLDnn',
  hlfamilyclinicId: 'cUmsSWN8A7vmXnfuEpCu',
  hkfamilyclinicId: '0qA2lllu7TdNEFnx9NMX',
  meilingclinicId: 'PhOTnCrQRetFKv3oqt53',
  emedicalId: 'IYpKZydZTL5YaOLCYvmS',
  asiamedicId: 'jYhAhUbxowlrAy3JYHHy',
  pandamedicalId: 'cXSWXEPFx1BkPutgK5UK',
  onewellnessId: 'NinjthPBBVX9Reca6AUl',
  fullertonhealthntuId: '1ruKcbSM4XzCZXQLC4WQ',
  chewchewId: 'k6W1sHMCfHamNjlhYOoy',
  coronationclinicId: 'QLG6Lp7phYEpl7WI7ypj',
  clarionmedicalId: 'lKrpK9cNcPspKsxIYWay',
  drolmedicalId: 'wm07HWAQGbDGe2HIrDks',
  kimedicalId: 'CJyLAxhamAfRu3kh0nHs',
  nuffieldmedicalId: 'MP7PBZEqtjsWKxukfOqL',
  hfmcId: '6sABRIcnNnBOOIshH8pd',
  trucareclinicId: 'AS3s2efk3I4WFWRTIFis',
  camtechId: '0uZYGn4yBinjazvAiXJD',
  medinaId: 'SHdKmcVe68hB3fNNXjXF',
  mediclinicId: 'iY3dIm1Jrua6DSnSVuM3',
  goodshepherdId: 'slgfdAloI9LZMC4V85Lv',
  taykamedicalId: 'gvLtJYZijgHZ9486zsSI',
  qmmedicalcentreId: 'QkDSavdBaYll1ndXb6Jg',
  trucarehlId: 'xOi26DJIx7OfzCVH65jg',
  leongclinicId: 'O8vY1MerPqcDLCNK2Bsr',
  prohealthpnId: 'iBjTlCG5539WS6gqLFIJ',
  prohealthtpId: 'iLTdbEcrIoYrZlb2ttyh',
  kaotanId: 'hJkWGDYJneitbX9lJ9QJ',
  islandgroupclinicId: '3xr580vTdOCwSDb5jm2s',
  healthpartnersmedicalclinicId: 'sKqqrCtx9zKTFavVlYB4',
  prohealthbkId: 'VDGQIKRD5tNIAtnBSERW',
  prohealthbbId: '668eTtPBw9juJQFs49jt',
  prohealthfvId: 'lrtIbxvzHz6Pt59fOmor',
  prohealthhgId: 'kIzqktPSr8Tm0CfaRl9H',
  prohealthpdId: 'feyU58XFZgstBWXFxG5l',
  prohealthpsId: '3XCmh8S3Df2Nn1lhKvHL',
  prohealthtjId: 'z1jNr6SrRXDCefuTeKfS',
  drsexpressId: 'mEP9Aisfl3w3FEJN34Hb',
  pasirriscId: 'xjONHW3X02ttkQ94GjPd',
  prohealthlrtfernvaleId: 'Z4k3dBpDEMecc19A40Au',
  woodgrovefcId: 'BTn14nF4qkdugTCvIOgd',
  shinefamilyclinicId: 'tDbxmhuxWCybF6FXQzIA',
  fullertonhealthcaretrythalljurongId: 'iD55IaahfIZwJ6THCpXx',
  fullertonhealthdrstrythallId: '9Xifc8C0amYweiazwTzg',
  fullertonhealthofcId: 'nQf49jugyhBOVdWFL0hI',
  fullertonhealthcaremetropolisId: 'NQmwjzrpkVe5TO2D1Arw',
  fullertonhealthcaregethinId: 'Y2vaRRrprjIXeV8CEXOw',
  fullertonhealthcarembfcId: 'KPbfJ6Rk2tfZzrPb8byJ',
  aestheticsplusclinicId: 'l0rbiwpb1Cn1lvXrGG0q',
  vivamedicalId: '1nAIGXVnraiOrrGgnnle',
  vivamedicalgroupId: 'XUSODMGqZhkHJAiRvGYU',
  tanclinicandsurgeryId: 'ImCD1hP4qY2hDdP80Q0t',
  vivamedicalgroupcpId: '2ZIW8nSc9k6nDJnPE5QD',
  lifelinebtbatokId: 'g36gBE2WcmJnKRgtr1Cs',
  commcareId: 'dmVy2C0az0AgZsL9qNGw',
  kindredfamilyclinicId: '2tADJKq6M6jHzB0o6WeZ',
  sgclinicId: 'vUVqh8efZtepBspoJdYi',
  simonroadclinicId: 'VXjqcKW2TYt1s5I3gr2A',
  caredocmedicalId: 'OUmni8FGIck3Aag2KnaA',
  loandleeclinicId: 'U7ONbH53d56YMonlkhOS',
  kingstonmedicalId: 'ShNRSbmL4DFhjzM0rplH',
  famhthcsId: 'lIAzlvu22to3rUGLk49w',
  iogId: 'nvnDhjZHvuAKWP8e4vCF',
  drmpcId: 'hY98Y0CfMQIajpfac6I1',
  medihealthId: 'ZkroFPoeuReAa1dXkmUu',
  limcountryId: 'Daa6OqNp7sWYdgo16tiJ',
  mcrId: 'SNJiKkXiYDbLhW7Zim9V',
  evertonclinicId: 'YDVKwfdVALXvh9qvA3Yq',
  antehId: 'oeWrtHjvaTN9MBT5olkL',
  littlecrossId: 'mU9QBAUOOhBi1Crpb7rw',
  everhealthmedId: 'PrJRP489HdxomKyOdjcT',
  lifescanmcId: 'xcj8xcP6jeRkxkc9Z0It',
  smgdtecId: 'XzasX7NMIEbzVQO6cH6H',
  thebreastclinicId: 'o8KDIjOmZl2mDvdSPRHn',
  healthlineId: '8e0vCBYxzg8v1XFOtXJv',
  nemgamkId: 'x62M97NA31kH2bIc1fyh',
  nemgbedokId: 'gdjSWYSYmRezWaU2SMg9',
  nemgbbId: 'b39MZfMRGQCVPicCkXrn',
  nemggmId: 'U1x8BTJcAY2EVvKg3MCt',
  nemgkallangId: 'Y1nQNOwuf9PWCMS9eDdo',
  nemgsengkangId: 'kkZOrm2dgUgo5BaHwaNO',
  nemgsimeiId: 'lqeL4GoQys4pYtHuNn5f',
  nemgtampinesId: 'FROZ2pvl66gdp8A73H2V',
  nemgcbId: '7Clk6CZrlb3ITAcNXYoK',
  medicalteckgheeId: 'qc3NbSO4iVRGuLSjJWWg',
  atriofamilyclinicId: 'OwobS8HDhe9s6r5laOWI',
  atriofamilyclinicamkId: 'GGHqiMTbzBRTdozXpJct',
  avantorthoId: 'd8xpyXIvvyY3W8KhVvML',
  banyanclinicjwId: 'TXekMnjjOZ21VL5hkQlb',
  crossroadsFamilyClinicId: 'Alvn7Hho4igR6DIaDWGj',
  lifefamilyclinicamkId: 'l2J7ANHwEoXaNO9PUTmm',
  advantagemedicalId: 'O8ILeaIK3w3j6TkQn5CA',
  mediviewId: 'EscSzDwTkcWsObeLKksG',
  lifefamilycliniccckId: 'm6Md2qVmBxbkFwMtq2ab',
  boonkcnsId: '2pzhf3PHYA3JvaVIpsTn',
  everhealthId: 'iicCrDgm3dsuvKUNpEXP',
  chngclinicId: 'Dtumk08BKz8g06DpgUQq',
  bostonmedicalId: '207N68BMermYAcxKPZKF',
  karriId: 'wYRWi794Ezxzxg9sbih5',
  edgedaletampinesId: 'KQJ31w26QQ6OSgVHWCoM',
  edgedalecambridgeId: 'aGtPFzvO4pPtGUppXZG7',
  edgedaleId: 'Q7RMp3fsrmnMpQKG9XYG',
  greenforestId: 'yAFf4QcwYWM2Wc3TPlFU',
  blissMedicalClinicId: 'LzTfuwOpbEuotOMWjmli',
  onedoctorsFamilyClinicAmkId: 'sRyaMwByoSwyoIHdduh6',
  onedoctorsFamilyClinicHollandVillageId: '5V89OzHIAltlY0gD4eFm',
  onedoctorsFamilyClinicKhatibId: 'jNq6ah7yTXNK6bagXIZd',
  onedoctorsFamilyClinicPunggolId: '4mvondzCohX9EiQcvyc6',
  onedoctorsMedicalCentreId: 'lFhZXVfmk3asGlvpShZK',
  trinitymedicalId: 'HheGrZPKDmHFl4p9sHBn',
  vickycaresId: 'LF6dn77mf1eBYnXaR5d9',
  eightfcId: 'NhejU3RszZNJ8vtIhEKm',
  rivervaleId: 'SG8UlywSVAttVOYzMDdC',
  curieoncologyId: 'ww5DCfxGrcuH1lWt3uHT',
  tglmcId: '3EHxoN1lgCyHbIp0GggF',
  pathnovaId: 'h10eB8pRuovapJR3Rey8',
  careerpsId: 'xiubsJWpKE6l0csyPDBn',
  resteId: 'zsyMFltcIC4TI0NXhKBW',
  restelabdemoId: 'eQXshrrVGwXjdC6y49kF',
  clinicCostaId: 'NoARDn5u6mrSlfc86VK7',
  mountEId: '5mIKWRoiW1RlUelrih3r',
  ifcaId: 'BjZ590C0rZgxR1NiFlHD',
  marinapsId: 'mIGjLFNv9xl7ZbTJ6QlY',
  havelockId: 'J1T29m97tNm0SsJFV8Jh',
  ctrkidneyId: 'HZsn8mWFeWNGDe6d15fx',
  dknmgId: 'WjzvVyaxrEH67B5hjeNt',
  mcfclinicId: 'D7drVnnaFemuVWkzGNDj',
  qmfpId: 'IIykvbhonHc49LCWRqXQ',
  eightId: 'TXo7GEm7PEWBqaEAtmCu',
  integratedwellnessId: 'EVYn2Jnv4UomH3v4Kt5P',
  gleneaglesId: 'uBVRyfA3Bg5x5LBBGj7i',
  BishanGraceClinicId: 'qtsf7H6YAghM7ZNgCCEp',
  bestMedId: 'chcLd8ejvXKW21NRo4JX',
  trinityProjectId: 'LrMdHJkxJZ8M9Kklitrm',
  acumenId: 'K7MApvyhYUw1nHdvmjqG',
  arcadiaId: 'Z3LNSvJytGn5e03zD760',
  mfcanchorvaleId: 'r8C3nc1y2s8QSu4PKHEG',
  tekkaId: 'l9YbV78Sp5Fwfucx5qhb',
  westPointId: 'ka8umn8iyO0baFOsER8O',
  trinityId: 'CtfECy5eoVea0Bms3JA6',
  iDocClinicHongKahId: 'ne0B3oyd4LS4qiH4RFT6',
  iDocClinicHougangGreenId: 'rjUBkQaJgYxhCYhhlXDw',
  minMedOrchardId: 'SkiCGuZ8FwukiTqzI7Oc',
  equinoxBahariId: 'PcJn1JyrX2v1MDKuaNPY',
  faircareFamilyId: 'PJpfVDivbTtqV4xz1PwO',
  mirxesId: 'PgAFxFkci5SirlFF6HEM',
  mDiagnosticsId: 'QeMR4gZgmZumCpm3ndEM',
  kcsMedId: '1CgCzPvO0Hd7nPoDO4GN',
  geneRockId: '3sHm4CfZehIezkQR0ysf',
  homedicalId: 'CL1H4YRvpWNQh5pOFLpH',
  completeId: 'UTtQ1mJtrqiRiIGIxaYY',
  kingGeorgeId: '9MP0WhmJ6nJBx1ULpiE8',
  mayFairMarslingId: 'VS9lD3yXfpgXKRLh3Z8E',
  mayFairYiShunId: 'gcDqVPIV2YJ3KCzDR1ei',
  acaciaId: 'SNWGwq9wlEYmqIVkOVbW',
  FiftySevenMedicalClinicId: 'BqcbpfxRyRcnF3LUnJ10',
  FiftySevenMedicalYiShunId: 'S5F1Fi0cCfJbLAbkCOGj',
  disIncId: 'wTsLTfM8J1ynR8dnJVoR',
  lohLohId: 'FHuhHE5gpuZeu2G37Ajx',
  eternmedsengkangId: 'V3ruL9Xhyyq77LRvzejV',
  physiciansPracticeFamilyId: 'u1xc0vFWRQLZ86zQEyPy',
  fullertonArcId: 'Qt1Ijmu2FjIKwgcwLR1V',
  amosupId: 'CHjudNTWJcf1rzgkJwYh',
  providencenovenaId: '0FdcZILDLKqJkDFOyORu',
  greenxId: 'VmPh4spripaaRtwxmE9p',
  pmcyishunId: 'GBNov8e36ojwyWeYAr42',
  yarramedicalclinicId: 'hci8GEDOFpdhnkS1PtYj',
  pierxrayId: 'aMHNfbX81ImbVkNLmkC0',
  fusionwheelockId: '3A40aOpksGBW3koUsv81',
  fusionnexusId: 'uRgQf9R3BgMnFuZILyfe',
  newHealthId: 'HPIqrHCNRufDmeSztTU9',
  mintMedicalFunanId: 'xmSHAmPiwRNJgfEkIjsF',
  mintMedicalHarbourFrontId: 'PP0nWIXq64FwlbopG0WR',
  pulseId: 'LE5VVCOUgjcg5jYkfd4j',
  aventusId: 'hsW3LM835osCyqFbhsiU',
  neeSoonId: 'O63VKBaWFU0rkJ0wOwzl',
  zuelligId: 'GBOH1qoyM8U0o2LJA0s8',
  familyCareClinicId: 'LoV2vS5PDfh8P01ngVr9',
  noatunId: 'UwAqFrVr59UgXCXry9NA',
  faithfamilyclinicId: '793haGdeiy11RIU228Aa',
  aLifeClinicId: 'chyNz1dnQsyY2SIp56GZ',
  alexandraId: 'EnFou2DEyx0Vvx9D1iaV',
  affinityId: 'gKmUGKkx6gGVrkzHfOmM',
  pacificFamilyId: '3qejQcYVCNy8D7XmEgXz',
  medLifeId: 'yCGLqjhF4uY2iaQ6ouvU',
  healthCareRailmallId: 'lWmRUTe1hcMZqHNKyY8A',
  riverrMedicalId: '8rmGWhovj51atdgOET61',
  faithYishunId: 'BMgT7p0z8whW8rSwoMoL',
  faithBedokId: '1JG2jrWcMoT7TlnlmGzu',
  faithJurongWestId: 'qnYLipkdGmugZ3f8yAQ3',
  faithTampinesId: 'DVfhcyb30hox9XWesiJ6',
  faithToaPayohId: 'W0poSfdQr10eijIASzfT',
  faithSimeiId: 'JjoflwgaMwaQbAuoBj7m',
  faithRivervaleId: 'EQPcfA8sE17tS5IABXSi',
  sunCareSengKangId: 'KaHdr8c9zMB5kySOeMVx',
  sunCareMedicalSengKangId: 'CB3Uta9JwGJJ8LWavFNx',
  daClinicAnsonId: 'zjv6ooqucQsWcQ6hKv17',
  daClinicBukitBatokId: 'GaTjYciLJf1zFXsSkiCw',
  daClinicAnchorvaleId: 'FKKZ3m9EJFXovnleGm4G',
  daClinicBishanId: 'GYszunpVI62xlSI261To',
  daClinicPotongPasirId: 'MogG4zHu3p4CVgd1V8pr',
  daClinicTamanJurongId: 'IUtG7do5un16Un18v5xy',
  k2MedicalId: '5MGBlF7MasrE5crNs2FJ',
  interMedical24Id: 'DqzvlJ2mZXQkpIwv2Vnt',
  ChinChooMedicalId: 'Jrvw9wJ2P3KY5cv40RNO',
  SembawangMartMedicalCentreId: 'ctQHL1wE2dpVblXZWpB6',
  TheClinIQId: '3u67xCEAibVk2tb8bqR1',
  SengkangFamilyClinicId: 'Fiv9Nc72alZNRBXSp6Sq',
  iconMedicalClinicId: 'KQcyO0LYv8tc7Mfo0uq5',
  pTanId: 'KYVMxSAS04orva4s5ySD',
  ifcbedokId: 'CLYYJ7fRikGjsklAPlkN',
  ifcbukitId: 'ECnTcZespTctRbSHHSel',
  ifcsengkangId: '6Jj3mfVny5xxvFPNyiyW',
  ktmcId: 'ruDl97tDty8WJKhJngL8',
  lifelineskId: '6ixJv2xACAxgliHcFLTP',
  martinmedicalId: 'eMUhE5MzYq4QOl05JTkA',
  mmchoungangId: 'nuZSKT4xLYj6KcBM0nNh',
  mmcusId: 'BhyLCiep7zBQhuGKHyjN',
  mfcysaId: 'ue1siK7jqqyvKFHCnvbB',
  mfccckId: 'sK8rAynhtwDSQCiXxP8g',
  mfcclm325Id: 'VIKw2L4C2o6mIPxZ0nFc',
  mfcclementiId: '8W5vCr6un8PI75d0p602',
  mfchougangcentralId: 'am9VFJOsYV5iepp3wwH1',
  mfcpioneerId: 'vPL6zvQyc8rUwMvPJre6',
  mfcpunggolId: 'wPMIJqi5kNGA889wc7QO',
  mfcrivervaleId: '5f8mgo8U5MN1SFsbRjdc',
  mfcsegarId: 'IKliC82QEU0ekVnuxSm8',
  mfcsenjaId: '3i8jX4lo0bcNNS0lg6R0',
  mfcstgId: '75wxH9O5pFjeglKqWkCL',
  mfctanglinhaltId: '43i11dXcQjl9wLwqujAG',
  mfctoapayohId: 'eBOQq7nMZIaHOpXVAsPB',
  mfcwdgId: 'ASXcDBd6sTxVAe3y2eX4',
  mfcwoodlandsId: 'sowYRtRSQiK7mFlsGjFo',
  ourwfcId: 'r15iGOVW8YjPhtJHkPMi',
  pancaremedicalId: 'obrL97xt8D69C2uijqrR',
  qmtcId: 'WmBUBIn8DaviOQO9NH3U',
  qmbuangkokmrtId: '88H3yVWyLWJKyhorI9BZ',
  qmbbId: 'WMBlzPMcwO3IhTHvTOYo',
  qmscId: 'xKHFvS4NjfKhLo5HJj8h',
  tpsimId: 'ys0YiS8ja24VZID8IXLj',
  vitacarepcId: '00NZW5l7qNiTP3doyto9',
  vitacarecanberraId: '7NTCuNnkVCff3cnZL2ZH',
  vcareId: 'Z165b3B9I9JmaFfAmNwX',
  atamedId: 'ySg9dlpxzfpnG1CiKChI',
  temasekmedchoaId: 'HTpcEsg64KAj8iT1YNvn',

  carefamilyclinicId: 'xpD5iBbX41Zx2dBxeDCa',
  centralclinicprId: 'CckZvT7WCc9o3G2RhVYE',
  chaicheeclinicId: '4XEGUhiGNyAP9rayqfOa',
  veinandendovascularsurgeryId: '53kgpKlOAenGUFCw3QPo',
  chuamedicalcentreplId: 'qJNV3f9cQ77IjMLNMjXI',
  chuamedicalcentresurgeryId: 'rXkbZYnz1IrWrHz5A2y3',
  cemclinicId: 'VVgGm0aiyUBpThBHnnc3',
  clinicfordigestivesurgeryId: 'WUF82ZKkrOUGxPMgauRs',
  dermclinicorchardId: 'dYtxHKIFXR1FvDlvBNYq',
  earnosethroatId: '6Xg35ODV45e7EDnxCRYC',
  easternhougangId: '6x3Cg0gV56rLZ7Plqsv1',
  ejfamilyId: 'OlfqrNp3KCIsu4gxy1Ls',
  essentialfclinicId: '1e3oqmurvnjI9yYZzVWU',
  novenaeyesurgeonsId: '3Qp0qX21HCpx23SpuJ7i',
  firstlightId: 'DzLAOBdC7I9zQlm1Oryz',
  healthfirstId: 'e5Y1y3PzjmEtVFVplX4Y',
  tysiawId: 'I5t3IgJzmUMPRmYVDodG',
  healthmarkprId: 'a8Snn5mRrWwC42xJnNcw',
  healthmarkcompassvaleId: 'ZWhFvYImKNkr7eJtTTSR',
  healthmarkpgId: 'mdYtRPAMWgghwZrIuSiW',
  healthmarkpioneerId: 'C42c2Xaxc3jh0i9DxH9f',
  healthmedbpId: 'ZDbS08z1IjFe9wz5ZyVr',
  healthpointfcId: '2Ke4ZJw0yj7Km6Tz9jzi',
  healthspringsmedicallaserclinicId: 'n1X2sJLjqB78uRynYbwn',
  healthspringsorchardId: 'hlhJY23JN0dWWAyHPbgV',
  healthspringsmedicalclinicId: 'V4GFhBVLQS7VNHQ5MHRn',
  helixId: 'bkEVnbPyp7tioNOMdFba',
  holisticcareId: 'K7csJnhluTGKOFSKEbqE',
  ifcfernvaleId: '73gHjKUIEHHDguGYKQGZ',
  punggolgpId: 'JqBifPKAKX45IEUKprK0',
  yangyapclinicId: 'egVrXWYZE8wVTkhFNDFA',
  woodlandsclinicId: 'CK0khkUKWnRk9X0hqDAC',
  unihealthclinictoapayohId: 'KqUU8ELHgV9yM8dr7AWA',
  truecareusId: 'Wy1SUUlVRje4LEvCT9fM',
  truecareamkId: 'RS9UXAW5bdf1HPkYUedd',
  iclinicId: 'tgkUFYXX95qC7Be7AUaL',
  tampinesfmcId: 'RE5Yhjzo3AaHQXoF6saQ',
  soongclinicId: 'kCNzS0irQqiBZS3EIWDc',
  shifaclinicsurgeryId: 'pMuSWHpVV1JzoQBENXw3',
  seacareId: '3u6Orz9Xynwjw3rbXwR3',
  pioneermedicareId: 'esItzpq4ZJZ9e775vMaI',
  peakmedicalsgId: 'iDqtE6Qw35wE3rZIumjz',
  ofpcsId: 'NjpEiBtLOKEfQO9zNC6W',
  ortholimbId: 'NGjZ9ULZY2FxIq18UmSW',
  oasisId: 'Is0puPpogS9Yr97RjwHt',
  newestId: 'M5J9RWhT9H7qqZK8dEQo',
  mydoctoradmiraltyId: 'abrUUO2kgePIapqQKZid',
  mydoctorId: 'nP4JNLOfdLqXjtzRw3tJ',
  punggolripplesfamilyclinicId: 'D6I8CdcqHjP9XwasyEif',
  ecfamilyclinicId: '7YjeLqdsRF6avYOp8Ees',
  missionbbId: 'OMh2K0MA8keMMkMJ8U0p',
  millenniumckId: 'WCwClgsiAwFj5U3TboDf',
  mhcfamilyclinicId: 'MMM5qpNX0VynP8wgLtep',
  mediveneId: 'AVdQJ8pEDjxZU6DUAMnR',
  livewellmedicalId: 'cOgYCTqOYeV8wUVMqFRg',
  lilyawId: 'Gj5W27XHtiQ9qGwnB114',
  leeandtanId: 'j4zcbwUje38uK3ude2MB',
  langeyeId: 'CR2lIXKgZ7guJoJ1UnUQ',
  jerehheartId: 'lWmjZIPQ3RtqEdaxLGfv',
  onecaremedicalaljuniedmrtId: '3eVSosZDWei49acgsWBC',
  onecaremedicalbedokmrtId: '3BtQqw1RLxsdhe3We4i7',
  onecaremedicalboonlayId: 'OsaXqTwZEG6xE1825yhA',
  onecaremedicalbukitbatokId: 'BofjoCKsKiyKpsoYIGb4',
  onecaremedicalbukitbatokwestId: '1jhYsuGGxo1o6HTBvQ0E',
  onecaremedicalgombakmrtId: 'DYeMOx8t1fEgib7JVjdt',
  onecaremedicalbukitpanjangId: 'akKCdYWBqly3j8AqjSJm',
  onecaremedicalbukitpanjanginterchangeId: 'pD9FnSSuUuUSJ8NnXxLg',
  onecaremedicalcanberraId: 'LGtZMrr8T20BgGCoDRgq',
  onecaremedicalchoachukangId: '66PLcnnFDnSuSAhK0jXY',
  onecaremedicalclementiId: 'Oi8D9iwF2GRk2Ksnv7v7',
  onecaremedicalfernvaleId: 'TH9fyvLXVhPri8UpDt7k',
  onecaremedicalhougangId: 'lSh03UbuWXjk3PSv6Kq7',
  onecaremedicalhougang8Id: '1YliUpeC8VZVvHqk6kGp',
  onecaremedicalkeathongId: 'sU6zpdvLNx1w4NSyPG8Z',
  onecaremedicalloyangId: '6vueS36TmmgdHhVM6YtN',
  onecaremedicalmarineparadeId: 'hbLptIhhFE5Dn6CRj90A',
  onecaremedicalpunggolplazaId: 'oDv9YY1yQ0sj4vZpLBhr',
  onecaremedicaltampinesId: 'REKxJKrPXEc7E46RhJoq',
  onecaremedicaltampinesmrtId: 'n2benN0pAAB9IwqWLVxD',
  onecaremedicaltiongbahruId: 'ilEPshKE1hxHlVgysQS9',
  onecaremedicalwoodlandsId: 's5o4XAyGut3sbcwJLndz',
  onecaremedicalyishunId: 'gZ5U1vkipsNihiwE8p7U',
  onecaremedicalrafflesplaceId: 'CwLCeF1cIodzp6kgJMT9',
  calrosemedicalId: 'isGBXglWae5Mk1v4ZIex',
};

export default apolloConfig;
