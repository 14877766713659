import gql from 'graphql-tag';

export const appointmentsByClientId = gql`
  query appointmentsByClientId(
    $clientId: ID!
    $isCompleted: Boolean!
    $startAfter: String
    $indexBefore: Boolean
    $noLimit: Boolean
  ) {
    appointmentsByClientId(
      clientId: $clientId
      isCompleted: $isCompleted
      startAfter: $startAfter
      indexBefore: $indexBefore
      noLimit: $noLimit
    ) {
      id
      type
      clientId
      centerId
      physicianId
      patientId
      startDate
      startTime
      endTime
      street
      zip
      city
      country
      isCompleted
      description
      geoLocation
      patientFirstName
      patientLastName
      patientEmail
      sName
      physicianName
      name
      image
      clinicId
    }
  }
`;

export const getClientPatients = gql`
  query clientPatients($clientId: ID!, $centerId: ID) {
    clientPatients(clientId: $clientId, centerId: $centerId) {
      id
      name
      firstName
      lastName
      email
      midId
    }
  }
`;

export const patientsCount = gql`
  query patientsCount($clientId: ID!, $centerId: ID) {
    patientsCount(clientId: $clientId, centerId: $centerId)
  }
`;

export const appointmentsByCenterId = gql`
  query appointmentsByCenterId(
    $centerId: ID!
    $isCompleted: Boolean!
    $startAfter: String
    $physicianId: ID
    $indexBefore: Boolean
    $noLimit: Boolean
  ) {
    appointmentsByCenterId(
      centerId: $centerId
      isCompleted: $isCompleted
      startAfter: $startAfter
      physicianId: $physicianId
      indexBefore: $indexBefore
      noLimit: $noLimit
    ) {
      id
      type
      clientId
      centerId
      physicianId
      patientId
      startDate
      startTime
      endTime
      street
      zip
      city
      country
      isCompleted
      description
      geoLocation
      patientFirstName
      patientLastName
      patientEmail
      sName
      physicianName
      name
      image
      clinicId
    }
  }
`;

export const appointmentsByPhysicianId = gql`
  query appointmentsByPhysicianId($physicianId: ID!) {
    appointmentsByPhysicianId(physicianId: $physicianId) {
      id
      type
      clientId
      centerId
      physicianId
      patientId
      startDate
      startTime
      endTime
      street
      zip
      city
      country
      isCompleted
      description
      geoLocation
      patientFirstName
      patientLastName
      patientEmail
      sName
      physicianName
      name
      image
    }
  }
`;

export const patientAppointments = gql`
  query patientAppointments($id: ID!, $clientId: ID!, $centerId: ID) {
    patientAppointments(id: $id, clientId: $clientId, centerId: $centerId) {
      id
      type
      clientId
      centerId
      physicianId
      patientId
      startDate
      startTime
      endTime
      street
      zip
      city
      country
      isCompleted
      description
      geoLocation
      patientFirstName
      patientLastName
      patientEmail
      sName
      physicianName
      name
      image
    }
  }
`;

export const getClientAppointmentsLength = gql`
  query getClientAppointmentsLength(
    $clientId: ID
    $centerId: ID
    $instructorId: ID
    $isCompleted: Boolean!
  ) {
    getClientAppointmentsLength(
      clientId: $clientId
      centerId: $centerId
      instructorId: $instructorId
      isCompleted: $isCompleted
    ) {
      length
    }
  }
`;

export const searchAppointments = gql`
  query searchAppointments(
    $query: String!
    $clientId: ID
    $centerId: ID
    $physicianId: ID
    $isCompleted: Boolean!
    $startDate: String
  ) {
    searchAppointments(
      query: $query
      clientId: $clientId
      centerId: $centerId
      physicianId: $physicianId
      isCompleted: $isCompleted
      startDate: $startDate
    ) {
      id
      type
      clientId
      centerId
      physicianId
      patientId
      startDate
      startTime
      endTime
      street
      zip
      city
      country
      isCompleted
      description
      geoLocation
      patientFirstName
      patientLastName
      patientEmail
      sName
      physicianName
      name
      image
    }
  }
`;

export const getCompletedAppointmentsCount = gql`
  query getCompletedAppointmentsCount($clientId: ID!, $centerId: ID) {
    getCompletedAppointmentsCount(clientId: $clientId, centerId: $centerId)
  }
`;

export const appointment = gql`
  query appointment($id: ID!) {
    appointment(id: $id) {
      id
      type
      clientId
      centerId
      physicianId
      patientId
      startDate
      startTime
      endTime
      street
      zip
      city
      country
      isCompleted
      description
      geoLocation
      patientFirstName
      patientLastName
      patientEmail
      sName
      physicianName
      name
      image
      clinicId
    }
  }
`;

export const getAppointmentsByDate = gql`
  query getAppointmentsByDate(
    $clientId: ID!
    $startDate: String
    $endDate: String
  ) {
    getAppointmentsByDate(
      clientId: $clientId
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      type
      clientId
      centerId
      physicianId
      patientId
      startDate
      startTime
      endTime
      street
      zip
      city
      country
      isCompleted
      description
      geoLocation
      patientFirstName
      patientLastName
      patientEmail
      sName
      physicianName
      name
      image
    }
  }
`;
