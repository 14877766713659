export const SET_INSTRUCTORS = 'INSTRUCTOR_MANAGEMENT/SET_INSTRUCTORS';
export const REMOVE_INSTRUCTOR = 'INSTRUCTOR_MANAGEMENT/REMOVE_INSTRUCTOR';

// ****************
// STATE SETTERS
// ****************

/**
 * @function setInstructors
 * @description Set selected header section for certificate management
 */
export const setInstructors = instructors => ({
  type: SET_INSTRUCTORS,
  payload: instructors,
});

/**
 * @function removeInstructor
 * @description Set selected header section for certificate management
 */
export const removeInstructor = instructorId => ({
  type: REMOVE_INSTRUCTOR,
  payload: instructorId,
});
