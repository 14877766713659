import React from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Text from './Text';

const IconDropdownItem = props => {
  return (
    <DropDownItem onClick={props.onClick}>
      <DropDownItemIcon>
        <Icon name={props.icon} size="large" color="grey" />
      </DropDownItemIcon>
      <Text size="small">{props.text}</Text>
    </DropDownItem>
  );
};

IconDropdownItem.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
};

const DropDownItem = styled.div`
  height: 60px;
  width: 100%;
  vertical-align: middle;
  display: flex;
  cursor: pointer;
  padding: 0 23px;
  align-items: center;

  &:last-of-type {
    border-radius: 0 0 20px 20px;
  }

  &:hover {
    opacity: 0.8;
  }
`;

const DropDownItemIcon = styled.div`
  text-align: center;
  padding-right: 10px;
`;

export default IconDropdownItem;
