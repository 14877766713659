const constants = {
  screenSizes: {
    xs: 480,
    sm: 768,
    md: 1024,
    lg: 1240,
    xl: 1440,
  },
  clientHostNames: {
    dlm: 'dlm', // admin
    admin: 'admin', // admin
    localhost: 'localhost', // local
    mtr: 'mtr',
    ntc: 'ntc',
    remoteMedical: 'rmi',
    bwGroup: 'bw',
    hiPrecision: 'hi-precision',
    superCare: 'supercare',
    bsm: 'bsm',
    martec: 'martec',
    crowley: 'crowley',
    solverMinds: 'solverminds',
    demo: 'demo',
    medical: 'medical',
    eurofinsSG: 'eurofinssg',
    molecularLab: 'molecularlab',
    stLukesPH: 'stlukes',
    mmal: 'mmal',
    pacc: 'pacc',
    maerskDrilling: 'maersk-drilling',
    cityGPFamilyClinic: 'citygpfamilyclinic',
    myDoctorlakeSide: 'mydoctorlakeside',
    mydoctor: 'mydoctor',
    ongsclinic: 'ongsclinic',
    bedokdaynight: 'bedokdaynight',
    jurongdaynight: 'jurongdaynight',
    kingswaymedical: 'kingswaymedical',
    thegoodclinic: 'thegoodclinic',
    tmedicalkallang: 'tmedicalkallang',
    tmedicalcanberra: 'tmedicalcanberra',
    tmedicalcitygate: 'tmedicalcitygate',
    healthcaremedicalkovan: 'healthcaremedicalkovan',
    doctors4lifepickering: 'doctors4lifepickering',
    shalomclinicsurgery: 'shalomclinicsurgery',
    acmsmedicalclinic: 'acmsmedicalclinic',
    mydoctorcanberra: 'mydoctorcanberra',
    healthlinkmed: 'healthlinkmed',
    healthwaykwshospital: 'healthwaykwshospital',
    lavenderdivisionclinic: 'lavenderdivisionclinic',
    singapuraclinic: 'singapuraclinic',
    bukittimahclinic: 'bukittimahclinic',
    newcastleclinic: 'newcastleclinic',
    thedublinclinic: 'thedublinclinic',
    unihealthclinicjurongeast: 'unihealthclinicjurongeast',
    belieffamilyclinic: 'belieffamilyclinic',
    healthpartnershipmedical: 'healthpartnershipmedical',
    platinumSurgery: 'platinum',
    street11: 'street11',
    nippon: 'nippon',
    zeaborn: 'zeaborn',
    micah: 'micah',
    newPathFamilyClinic: 'npfc',
    aMedicalClinic: 'amc',
    wlhMedicalServices: 'wlhms',
    drWFamilyClinic: 'dwfc',
    internationalMedicalClinicCamden: 'imccamden',
    internationalMedicalClinicJelita: 'imcjelita',
    internationalMedicalClinicKatong: 'imckatong',
    internationalMedicalClinicChildren: 'imcchildrens',
    twinCityMedicalCentre: 'tcmc',
    oslerHealthInternational: 'ohi',
    FemaClinicSurgery: 'fema',
    StarMedSpecialistCentre: 'smsc',
    cloverMedicalClinic: 'cmc',
    trueMedicalClinicTampinesWest: 'tmctw',
    trueMedicalClinicPayaLebarQuarter: 'tmcplq',
    dtapNovena: 'dtap',
    speedoc: 'speedoc',
    soonLeeMedicalClinic: 'slmc',
    shanah: 'shanah',
    proHealth: 'prohealth',
    platinum: 'pmc',
    myHealth: 'mhmc',
    dover: 'dover',
    notredame: 'notredame',
    cghmc: 'cghmc',
    nordic: 'nordicmedical',
    providenceClinicHillford: 'pch',
    healthWerkzMedical: 'hwmc',
    healthWerkzCckMedical: 'healthwerkzcck',
    aris: 'arismedical',
    gardenclinic: 'gardenclinic',
    fullerton: 'fullertonhealthcaretrythall',
    fullertonhealthpasirpanjang: 'fullertonhealthpasirpanjang',
    lhpolyclinic: 'lhpolyclinic',
    sterlingfamilyclinic: 'sterlingfamilyclinic',
    comfortclinic: 'comfortclinic',
    daySpring: 'dayspring',
    docchangi: '1docchangi',
    iswabxpresssilkroutessembawang: 'iswabxpresssilkroutessembawang',
    healthwayaljunied: 'healthwayaljunied',
    healthwaymarineterrace: 'healthwaymarineterrace',
    iswabtravelcaredoc: 'iswabtravelcaredoc',
    allswellmedicalclinic: 'allswellmedicalclinic',
    intemedicaltampines: 'intemedicaltampines',
    amaritimeclinic: 'amaritimeclinic',
    myclnqjurong: 'myclnqjurong',
    sghealthcareunited: 'sghealthcareunited',
    kindredfamilyclinickallangbahru: 'kindredfamilyclinickallangbahru',
    minmedredhill: 'minmedredhill',
    ourtampinesfamilyclinic: 'ourtampinesfamilyclinic',
    minmedboonlay: 'minmedboonlay',
    lohandlohclinicamk: 'lohandlohclinicamk',
    swabnowwoodgrovefc: 'swabnowwoodgrovefc',
    intemedicalteckghee: 'intemedicalteckghee',
    nanyangsurgery: 'nanyangsurgery',
    truecarecliniceunos: 'truecarecliniceunos',
    zestclinic: 'zestclinic',
    justcaremedical: 'justcaremedical',
    onecaremedicalsenja: 'onecaremedicalsenja',
    unihealthclinicyishun: 'unihealthclinicyishun',
    thomsonjpfclinic: 'thomsonjpfclinic',
    sohwellmedical: 'sohwellmedical',
    northshorefamilyclinic: 'northshorefamilyclinic',
    onecaremedicalbukitbatokwesteast: 'onecaremedicalbukitbatokwesteast',
    ardenneshealth: 'ardenneshealth',
    myclnqmapex: 'myclnqmapex',
    advantagemedicalbedok: 'advantagemedicalbedok',
    advantagemedicaljurongwest: 'advantagemedicaljurongwest',
    mindefmedicalcentre: 'mindefmedicalcentre',
    onecaremedicalbishan: 'onecaremedicalbishan',
    tmcbs: 'tmcbs',
    minmedmarinabay: 'minmedmarinabay',
    acehealthmedical: 'acehealthmedical',
    minmedbedok: 'minmedbedok',
    familymedical280: 'familymedical280',
    clarihealth: 'clarihealth',
    manadrcitygate: 'manadrcitygate',
    minmedwoodlands: 'minmedwoodlands',
    myopiaspecialistcentre: 'myopiaspecialistcentre',
    peakmedicalwp: 'peakmedicalwp',
    docbedok: '1docbedok',
    thetestingpro: 'thetestingpro',
    aljuniedmed: '1aljuniedmed',
    onecaremedicaljunction9: 'onecaremedicaljunction9',
    minmedamk: 'minmedamk',
    medstarmedical176: 'medstarmedical176',
    minmedclementi: 'minmedclementi',
    dawsonfamilyclinic: '94dawsonfamilyclinic',
    onecaremedicalserangoonnorth: 'onecaremedicalserangoonnorth',
    gpjoochiat: 'gpjoochiat',
    fastpdtswab: 'fastpdtswab',
    centralclinicmp: 'centralclinicmp',
    oglmedicalnovena: 'oglmedicalnovena',
    onecaremedicalpunggolsumang: 'onecaremedicalpunggolsumang',
    drmpctampines: 'drmpctampines',
    healthwaymlw: 'healthwaymlw',
    inspiremedicaljalanbatu: 'inspiremedicaljalanbatu',
    unihealthclinicboonlay: 'unihealthclinicboonlay',
    apollomedicalcentre: 'apollomedicalcentre',
    onecaremedicaljurongwest: 'onecaremedicaljurongwest',
    samhsimei: 'samhsimei',
    earlyhealth: 'earlyhealth',
    kingstonmedicalaljunied: 'kingstonmedicalaljunied',
    curasiaendoscopy: 'curasiaendoscopy',
    bchpasirris: 'bchpasirris',
    sengkangsrmedihealth: 'sengkangsrmedihealth',
    sembawangsrmedihealth: 'sembawangsrmedihealth',
    farrerparklaboratory: 'farrerparklaboratory',
    simeiclinicsurgery: 'simeiclinicsurgery',
    rubin: 'rubin',
    acumedwoodlands: 'acumedwoodlands',
    braddellmedical: 'braddellmedical',
    heartlandhealthtampines: 'heartlandhealthtampines',
    heartlandhealthjurongeast: 'heartlandhealthjurongeast',
    heartlandhealthtoapayoh: 'heartlandhealthtoapayoh',
    heartlandhealthredhillclose: 'heartlandhealthredhillclose',
    heartlandhealthbukitbatok: 'heartlandhealthbukitbatok',
    heartlandhealthcircuitroad: 'heartlandhealthcircuitroad',
    heartlandhealthjurongwest: 'heartlandhealthjurongwest',
    heartlandhealthbedoksouth: 'heartlandhealthbedoksouth',
    heartlandhealthkimkeat: 'heartlandhealthkimkeat',
    heartlandhealthnewupperchangi: 'heartlandhealthnewupperchangi',
    heartlandhealthwhampoa: 'heartlandhealthwhampoa',
    intemedicalkovan: 'intemedicalkovan',
    lifeplusmedicalsinming: 'lifeplusmedicalsinming',
    maiphamedical: 'maiphamedical',
    medilineclinicsgoongarden: 'medilineclinicsgoongarden',
    medilineclinicmarineparade: 'medilineclinicmarineparade',
    neptunehealthcare: 'neptunehealthcare',
    shalommedicalgroup: 'shalommedicalgroup',
    aeromedicalcentre: 'aeromedicalcentre',
    tanyikclinic: 'tanyikclinic',
    themedicalaestheticclinic: 'themedicalaestheticclinic',
    thomsonwellthclinic: 'thomsonwellthclinic',
    tuasmedical: 'tuasmedical',
    tuasviewmedical: 'tuasviewmedical',
    woodlandsmedicalcentre: 'woodlandsmedicalcentre',
    tractorsfullerton: 'tractorsfullerton',
    micronfab7fullerton: 'micronfab7fullerton',
    micronfab10fullerton: 'micronfab10fullerton',
    micronmsbfullerton: 'micronmsbfullerton',
    ofsfullerton: 'ofsfullerton',
    globalfoundriesfullerton: 'globalfoundriesfullerton',
    lumiledsfullerton: 'lumiledsfullerton',
    ubsfullerton: 'ubsfullerton',
    gjmpfullerton: 'gjmpfullerton',
    alexandraaiafullerton: 'alexandraaiafullerton',
    batfullerton: 'batfullerton',
    ashfordmedical: 'ashfordmedical',
    aspenclinic: 'aspenclinic',
    sengkanggeneralhospital: 'sengkanggeneralhospital',
    faircareyishun: 'faircareyishun',
    meridianmedical: 'meridianmedical',
    blessmcjurongeast: 'blessmcjurongeast',
    babystepsvoco: 'babystepsvoco',
    wfc305: 'wfc305',
    pathlabs: 'pathlabs',
    tfpskyville: 'tfpskyville',
    minmedgeylangserai: 'minmedgeylangserai',
    hpfcwm: 'hpfcwm',
    drssinghandpartners: 'drssinghandpartners',
    majuclinicsrmedihealth: 'majuclinicsrmedihealth',
    drpthomson: 'drpthomson',
    yslaljunied: 'yslaljunied',
    distinctclinicbb: 'distinctclinicbb',
    drsohfamilyclinic: 'drsohfamilyclinic',
    saudaraajgeneralphysicians: 'saudaraajgeneralphysicians',
    smhaneefaclinic11: 'smhaneefaclinic11',
    ahppl: 'ahppl',
    procross216: 'procross216',
    familyclinic: '338familyclinic',
    procross405: 'procross405',
    procross411: 'procross411',
    ahealingheartmedical: 'ahealingheartmedical',
    accordmedical: 'accordmedical',
    admiraltyfmc: 'admiraltyfmc',
    aemedicalclinic: 'aemedicalclinic',
    allyhealth: 'allyhealth',
    amdasg: 'amdasg',
    aiclinic: 'aiclinic',
    ansariclinic: 'ansariclinic',
    apex2292: 'apex2292',
    asiaretina: 'asiaretina',
    assurancemedicare: 'assurancemedicare',
    atlaspacificmedical: 'atlaspacificmedical',
    avecomedicaljem: 'avecomedicaljem',
    avecomedicalmarineparade: 'avecomedicalmarineparade',
    banyanclinicwoodlands: 'banyanclinicwoodlands',
    bethesdamedical: 'bethesdamedical',
    binjaimedicalclinic: 'binjaimedicalclinic',
    blmedicalassociates: 'blmedicalassociates',
    blisscaremedical: 'blisscaremedical',
    cktanfamilyclinic125: 'cktanfamilyclinic125',
    camrymedicalcentre: 'camrymedicalcentre',
    caritassg: 'caritassg',
    centralclinicpioneer: 'centralclinicpioneer',
    centralclinicbedok: 'centralclinicbedok',
    centralclinicclementi: 'centralclinicclementi',
    centralclinichougang: 'centralclinichougang',
    centralclinicmarsiling: 'centralclinicmarsiling',
    centralclinictampines: 'centralclinictampines',
    centralclinicyishun: 'centralclinicyishun',
    centralclinicjurong: 'centralclinicjurong',
    changclinicandsurgery: 'changclinicandsurgery',
    chenfc: 'chenfc',
    chiaclinicsurgery: 'chiaclinicsurgery',
    chinatownwellnessclinic: 'chinatownwellnessclinic',
    chironmedicalclinic: 'chironmedicalclinic',
    choongsclinic: 'choongsclinic',
    choongsclinictampines: 'choongsclinictampines',
    chuahclinicsurgery: 'chuahclinicsurgery',
    cityhealth: 'cityhealth',
    citymed: 'citymed',
    cornerstonemedical: 'cornerstonemedical',
    cornerstonemedicalbidadari: 'cornerstonemedicalbidadari',
    dedicaremedical: 'dedicaremedical',
    distinctclinicje: 'distinctclinicje',
    drchanpengmun: 'drchanpengmun',
    drschantangfamilyclinic: 'drschantangfamilyclinic',
    drstangandpartners: 'drstangandpartners',
    drsbainibp: 'drsbainibp',
    drsbainhf: 'drsbainhf',
    ejtanclinicsurgery: 'ejtanclinicsurgery',
    eastcoastfamily: 'eastcoastfamily',
    easternmedicalcentre: 'easternmedicalcentre',
    eeclinic: 'eeclinic',
    ehmedicalwoodlands: 'ehmedicalwoodlands',
    ehmedicalbb: 'ehmedicalbb',
    ehaclinicjurong: 'ehaclinicjurong',
    elissamedical: 'elissamedical',
    faithmedicalcs: 'faithmedicalcs',
    familycarecsurgery: 'familycarecsurgery',
    finesthealthtpy: 'finesthealthtpy',
    firstcare253: 'firstcare253',
    firstmedical167: 'firstmedical167',
    frasermedical: 'frasermedical',
    frontierhealthcareclementi: 'frontierhealthcareclementi',
    frontierhealthcareadmiralty: 'frontierhealthcareadmiralty',
    frontierhealthcarebuangkok: 'frontierhealthcarebuangkok',
    frontierhealthcarebbwr: 'frontierhealthcarebbwr',
    frontierhealthcarebbwt: 'frontierhealthcarebbwt',
    frontierhealthcarecanberra: 'frontierhealthcarecanberra',
    frontierhealthcarejurongwest: 'frontierhealthcarejurongwest',
    frontierhealthcarewoodlands: 'frontierhealthcarewoodlands',
    frontierhealthcarebedok: 'frontierhealthcarebedok',
    generalpracticefcs: 'generalpracticefcs',
    hchillford: 'hchillford',
    healgroup: 'healgroup',
    healthifymedical: 'healthifymedical',
    healthwerkzsembawang: 'healthwerkzsembawang',
    hisemainnbb: 'hisemainnbb',
    hisemainnjw: 'hisemainnjw',
    homedicalclinic: 'homedicalclinic',
    imedicalaesthetics: 'imedicalaesthetics',
    icaremedical: 'icaremedical',
    intemedicalpotongpasir: 'intemedicalpotongpasir',
    japangreenclinic: 'japangreenclinic',
    jasmedical: 'jasmedical',
    joyfamilyclinic: 'joyfamilyclinic',
    knchinclifford: 'knchinclifford',
    knchinsuntec: 'knchinsuntec',
    kbtanclin: 'kbtanclin',
    kblhealthcare: 'kblhealthcare',
    kentridgeclinic: 'kentridgeclinic',
    killineymedicalclinic: 'killineymedicalclinic',
    lavendermedical: 'lavendermedical',
    lee123clinic: 'lee123clinic',
    leefamilyclinic: 'leefamilyclinic',
    lianclinic18: 'lianclinic18',
    lifelinksn: 'lifelinksn',
    limclinicamk: 'limclinicamk',
    linandsons: 'linandsons',
    loyangmedicalcentre: 'loyangmedicalcentre',
    mlamclinic: 'mlamclinic',
    macphersonmedicalclinic: 'macphersonmedicalclinic',
    mhcasiambs: 'mhcasiambs',
    medicareassociates: 'medicareassociates',
    medilife474: 'medilife474',
    melpartnersdrs: 'melpartnersdrs',
    mhcasiaamara: 'mhcasiaamara',
    millenniumwoodlands: 'millenniumwoodlands',
    mutualav: 'mutualav',
    mutualcv: 'mutualcv',
    myhealthpartners: 'myhealthpartners',
    neighbourhoodclinic: 'neighbourhoodclinic',
    ntcpl2006: 'ntcpl2006',
    nexusaestheticclinic: 'nexusaestheticclinic',
    nichiiicl: 'nichiiicl',
    norwoodmedicalclinic: 'norwoodmedicalclinic',
    onemedical: 'onemedical',
    onewellnessmedicali12: 'onewellnessmedicali12',
    oslerhealthraffleshotelarcade: 'oslerhealthraffleshotelarcade',
    pacificmdgrp: 'pacificmdgrp',
    parklanemedicalcentre: 'parklanemedicalcentre',
    pcsmedicalcentre: 'pcsmedicalcentre',
    phoenixmedicalseletar: 'phoenixmedicalseletar',
    phoenixmedicalnovena: 'phoenixmedicalnovena',
    phoenixmedicalhillview: 'phoenixmedicalhillview',
    phoenixmedicalpayalebar: 'phoenixmedicalpayalebar',
    potongpasirmedical: 'potongpasirmedical',
    preciousmed: 'preciousmed',
    procrosshougang: 'procrosshougang',
    procrosswoodlands: 'procrosswoodlands',
    punggolfamilyclinic: 'punggolfamilyclinic',
    rcmclmg: 'rcmclmg',
    reddotmedical: 'reddotmedical',
    redhillclinic: 'redhillclinic',
    redwoodtpy: 'redwoodtpy',
    rochorcentreclinic: 'rochorcentreclinic',
    slfamilyclinic729: 'slfamilyclinic729',
    slfamilyclinic139: 'slfamilyclinic139',
    sataamk: 'sataamk',
    satabedok: 'satabedok',
    satajurong: 'satajurong',
    satapotongpasir: 'satapotongpasir',
    satatampines: 'satatampines',
    satawoodlands: 'satawoodlands',
    sembmarineadmiralty: 'sembmarineadmiralty',
    sembmarinetuas: 'sembmarinetuas',
    simsdrivemedical: 'simsdrivemedical',
    springmedicalsg: 'springmedicalsg',
    stamfordmedical: 'stamfordmedical',
    standardclinicsurgery: 'standardclinicsurgery',
    sunbeamanchorvale: 'sunbeamanchorvale',
    sunbeamfajar: 'sunbeamfajar',
    sunwaymedicalcentre: 'sunwaymedicalcentre',
    tampinesclinic: 'tampinesclinic',
    tampinesmedilifeclinic: 'tampinesmedilifeclinic',
    tampineswestmedical: 'tampineswestmedical',
    tanstandtclinic: 'tanstandtclinic',
    tayclinicgeylang: 'tayclinicgeylang',
    thebalmoralclinic: 'thebalmoralclinic',
    chelseaclinicrochester: 'chelseaclinicrochester',
    theclinicgroupcampus: 'theclinicgroupcampus',
    theclinicgroupogs: 'theclinicgroupogs',
    thefpclinic: 'thefpclinic',
    jacs: 'jacs',
    themedicalclinic: 'themedicalclinic',
    themedicalpractice: 'themedicalpractice',
    thomsongpclinic: 'thomsongpclinic',
    thomsonmedical: 'thomsonmedical',
    tiongbahrumedical: 'tiongbahrumedical',
    tpcmedicalclinic: 'tpcmedicalclinic',
    ubifamilyclinic: 'ubifamilyclinic',
    uhcnus: 'uhcnus',
    wellfamilycs: 'wellfamilycs',
    wenwengfamilyclinic: 'wenwengfamilyclinic',
    wongclinic539: 'wongclinic539',
    yimclinic: 'yimclinic',
    farrerparklaboratories: 'farrerparklaboratories',
    royalhealthcarespecialists: 'royalhealthcarespecialists',
    lifeplusmedicalbedok: 'lifeplusmedicalbedok',
    kevinchuamedical: 'kevinchuamedical',
    affinitymedicalcityplaza: 'affinitymedicalcityplaza',
    medcommonwealth: 'medcommonwealth',
    healthlightfmc: 'healthlightfmc',
    vivienrobinsonpractice: 'vivienrobinsonpractice',
    mhcasianovena: 'mhcasianovena',
    ecfamilyclinic: 'ecfamilyclinic',
    punggolripplesfamilyclinic: 'punggolripplesfamilyclinic',
    keppelmedicalgroup: 'keppelmedicalgroup',
    amksummit: 'amksummit',
    drslimandchanhougang: 'drslimandchanhougang',
    drslimandchanlorlewlian: 'drslimandchanlorlewlian',
    innoquest: 'innoquest',
    westcoastcsclementi: 'westcoastcsclementi',
    myswabchinatown: 'myswabchinatown',
    bishanmedical: '1bishanmedical',
    aidanmedical: 'aidanmedical',
    changiclinic: 'changiclinic',
    drskooloh: 'drskooloh',
    dtapduo: 'dtapduo',
    dtaphollandv: 'dtaphollandv',
    dtapkovan: 'dtapkovan',
    dtapnovena: 'dtapnovena',
    dtaporchard: 'dtaporchard',
    dtaprobertson: 'dtaprobertson',
    dtapexpress: 'dtapexpress',
    healthlifefc: 'healthlifefc',
    healthmarksims: 'healthmarksims',
    yourhealthmatters: 'yourhealthmatters',
    islandmedical: 'islandmedical',
    japanclinicsurgery: 'japanclinicsurgery',
    plushealthmedical: 'plushealthmedical',
    shentonclinic: 'shentonclinic',
    whampoaclinic: 'whampoaclinic',
    wongclinicsurgery: 'wongclinicsurgery',
    cashewmedical: 'cashewmedical',
    crestfamilyclinic: 'crestfamilyclinic',
    fareastmedical: 'fareastmedical',
    stewardclinic: 'stewardclinic',
    praisefamily: 'praisefamily',
    innoquestdemo: 'innoquestdemo',
    chongsclinic: 'chongsclinic',
    umcbukitbatok: 'umcbukitbatok',
    marymedical: 'marymedical',
    thefp: 'thefp',
    eiddwenclinic: 'eiddwenclinic',
    excelfc: 'excelfc',
    docsinc: 'docsinc',
    compassvale297a: 'compassvale297a',
    smhaneefaclinic: 'smhaneefaclinic',
    thebidadariclinic: 'thebidadariclinic',
    swabtp: '1swabtp',
    minmedmarineparade: 'minmedmarineparade',
    vistamedvivavista: 'vistamedvivavista',
    thetenteramclinic: 'thetenteramclinic',
    woodgrovemedical: 'woodgrovemedical',
    sgstpanpac: 'sgstpanpac',
    sbccbpp: 'sbccbpp',
    sbccgmc: 'sbccgmc',
    sbccbishan: 'sbccbishan',
    sbccamk: 'sbccamk',
    sbccrivervale: 'sbccrivervale',
    sbcctiongbahru: 'sbcctiongbahru',
    sbcclung: 'sbcclung',
    sbccmounta: 'sbccmounta',
    sbccnovena: 'sbccnovena',
    clementihealthfirstclinic: 'clementihealthfirstclinic',
    koowk: 'koowk',
    fullertonhealthstatschippac: 'fullertonhealthstatschippac',
    fullertonhealthsiastc: 'fullertonhealthsiastc',
    fullertonhealthsiaalh: 'fullertonhealthsiaalh',
    fullertonhealthutacusg1: 'fullertonhealthutacusg1',
    fullertonhealthams: 'fullertonhealthams',
    healthcarefcs: 'healthcarefcs',
    hlfamilyclinic: 'hlfamilyclinic',
    sgstamk: 'sgstamk',
    sgstgolden: 'sgstgolden',
    sgsthollandv: 'sgsthollandv',
    maritimemedicalcentre: 'maritimemedicalcentre',
    minmedconradcentennial: 'minmedconradcentennial',
    minmedcopthornekings: 'minmedcopthornekings',
    minmedswissotelmerchantcourt: 'minmedswissotelmerchantcourt',
    kanganbtc: 'kanganbtc',
    bmc460018: 'bmc460018',
    clinic10: 'clinic10',
    asiafirsthealth: 'asiafirsthealth',
    lifecarefcs: 'lifecarefcs',
    fcsnewlife: 'fcsnewlife',
    hlfcs: 'hlfcs',
    swabngokinex: 'swabngokinex',
    lionhealth: 'lionhealth',
    winsome: 'winsome',
    fajarfamilycs: 'fajarfamilycs',
    theintegrativemedicalcentre: 'theintegrativemedicalcentre',
    gainhealthclinicandhomecare: 'gainhealthclinicandhomecare',
    shimclinic: 'shimclinic',
    huangsurgery: 'huangsurgery',
    rmdyclinic: 'rmdyclinic',
    gooddoctorsgeylang: 'gooddoctorsgeylang',
    medicalpw: '57medicalpw',
    paddingtonmedical: 'paddingtonmedical',
    travelswabpeoplespark: 'travelswabpeoplespark',
    theedinburghclinic: 'theedinburghclinic',
    minmedjurongwest: 'minmedjurongwest',
    nyamfamilyclinic: 'nyamfamilyclinic',
    huiclinic: 'huiclinic',
    medipointponggol: 'medipointponggol',
    jademedical: 'jademedical',
    chuaandpartners: 'chuaandpartners',
    trainingDemo: 'training',
    recruitmentDemo: 'recruitment',
    shippingDemo: 'shipping',
    tanteohamk: 'tanteohamk',
    tanteohtowner: 'tanteohtowner',
    novenamedicalcfc: 'novenamedicalcfc',
    marineterracefc: 'marineterracefc',
    manhattanmedical: 'manhattanmedical',
    yimmedicalcentre: 'yimmedicalcentre',
    chinatownfmc: 'chinatownfmc',
    excelsiorclinic: 'excelsiorclinic',
    heartlandfamilyclinic: 'heartlandfamilyclinic',
    tcfamilyclinic: 'tcfamilyclinic',
    nutramed: 'nutramed',
    bedokclinicsurgery: 'bedokclinicsurgery',
    healthwayjapmed: 'healthwayjapmed',
    pqtcjurongeast: 'pqtcjurongeast',
    dawsonpc: 'dawsonpc',
    trinitymedicalpunggol: 'trinitymedicalpunggol',
    medihealthcareclinic: 'medihealthcareclinic',
    babystepsoxleybizhub: 'babystepsoxleybizhub',
    babystepsfareastplaza: 'babystepsfareastplaza',
    babystepsmedical: 'babystepsmedical',
    saudaraclinic: 'saudaraclinic',
    xpressmedical: 'xpressmedical',
    clifforddispensaryshawhouse: 'clifforddispensaryshawhouse',
    clifforddispensaryairportblvd: 'clifforddispensaryairportblvd',
    biolidics: 'biolidics',
    rafflesmedical: 'rafflesmedical',
    boonlaycorpclinic: 'boonlaycorpclinic',
    healthwayadmiralty: 'healthwayadmiralty',
    healthwayanchorvale: 'healthwayanchorvale',
    healthwayamk1: 'healthwayamk1',
    healthwayamk10: 'healthwayamk10',
    healthwayamk8: 'healthwayamk8',
    healthwaybedoknorth: 'healthwaybedoknorth',
    healthwayboonkeng: 'healthwayboonkeng',
    healthwaybbwest: 'healthwaybbwest',
    healthwaybb: 'healthwaybb',
    healthwaycompassvale: 'healthwaycompassvale',
    healthwaydowntown: 'healthwaydowntown',
    healthwayelias: 'healthwayelias',
    healthwayfajar: 'healthwayfajar',
    healthwayhougang: 'healthwayhougang',
    healthwayjurongwestcentral: 'healthwayjurongwestcentral',
    healthwayjurongwest: 'healthwayjurongwest',
    healthwaylengkong: 'healthwaylengkong',
    healthwaylimbang: 'healthwaylimbang',
    healthwaylas: 'healthwaylas',
    healthwaynovenamc: 'healthwaynovenamc',
    healthwaypunggol: 'healthwaypunggol',
    healthwayrivervale: 'healthwayrivervale',
    healthwaysembawang: 'healthwaysembawang',
    healthwaysunplaza: 'healthwaysunplaza',
    healthwaysunshineplace: 'healthwaysunshineplace',
    healthwaytampinescentral: 'healthwaytampinescentral',
    healthwaytampines21: 'healthwaytampines21',
    healthwaytampines71: 'healthwaytampines71',
    healthwaytpy: 'healthwaytpy',
    healthwaywestcoast: 'healthwaywestcoast',
    healthwaywoodlands: 'healthwaywoodlands',
    healthwayyishun11: 'healthwayyishun11',
    healthwayyishun5: 'healthwayyishun5',
    healthwayyishun6: 'healthwayyishun6',
    healthwayyishun72: 'healthwayyishun72',
    medicoclinicsurgery: 'medicoclinicsurgery',
    pkangfamily: 'pkangfamily',
    unitedmedical139: 'unitedmedical139',
    silvercrossjw: 'silvercrossjw',
    silvercrossholland: 'silvercrossholland',
    silvercrossyishun: 'silvercrossyishun',
    silvercrossbukittimah: 'silvercrossbukittimah',
    daclinicyishun: 'daclinicyishun',
    majuclinic: 'majuclinic',
    theflametree: 'theflametree',
    familymedicare: 'familymedicare',
    fklimfc: 'fklimfc',
    kaimedicalclinic: 'kaimedicalclinic',
    gohclinicsurgery: 'gohclinicsurgery',
    katongfamilyclinic: 'katongfamilyclinic',
    healthplusfcs: 'healthplusfcs',
    changigeneralhospital: 'changigeneralhospital',
    dolwp: 'dolwp',
    drjoycepartnerspunggol: 'drjoycepartnerspunggol',
    drjoycepartnerstpy: 'drjoycepartnerstpy',
    advantagemedicalgeylangeast: 'advantagemedicalgeylangeast',
    kofamilyclinic: 'kofamilyclinic',
    northlinkwoodlands: 'northlinkwoodlands',
    lcclinicmsa: 'lcclinicmsa',
    vicghimmoh: 'vicghimmoh',
    hougangcliniq: 'hougangcliniq',
    o2medicalclinic: 'o2medicalclinic',
    thecliniqtpy: 'thecliniqtpy',
    canberracareclinic: 'canberracareclinic',
    dknmgciviccentre: 'dknmgciviccentre',
    truecareclinicrangoon: 'truecareclinicrangoon',
    yapmedicare: 'yapmedicare',
    kingswaymedical24hr: 'kingswaymedical24hr',
    drnahleefc: 'drnahleefc',
    samwmc: 'samwmc',
    clinicassist: 'clinicassist',
    royalmedical: 'royalmedical',
    ifcseletar: 'ifcseletar',
    calrosemedical: 'calrosemedical',
    taykamedicalfamilyclinic: 'taykamedicalfamilyclinic',
    smrtbishan: 'smrtbishan',
    smrtwoodlands: 'smrtwoodlands',
    lilyneoclinic: 'lilyneoclinic',
    cheongfamilyclinic: 'cheongfamilyclinic',
    siglapclinic: 'siglapclinic',
    myclnq: 'myclnq',
    meenaraclinic: 'meenaraclinic',
    appletreemedical: 'appletreemedical',
    doctorsclinicandsurgery: 'doctorsclinicandsurgery',
    vitacareseletar: 'vitacareseletar',
    minmedpayalebar: 'minmedpayalebar',
    ddmc: '303ddmc',
    kallangbahrufc: 'kallangbahrufc',
    igenelab: 'igenelab',
    gooddoctorsyishun: 'gooddoctorsyishun',
    drpeckclinic: 'drpeckclinic',
    pfcbuangkok: 'pfcbuangkok',
    pfccompassvale: 'pfccompassvale',
    pfcnorthshore: 'pfcnorthshore',
    pfcpasirris: 'pfcpasirris',
    pfcrivervalley: 'pfcrivervalley',
    pfcserangoon: 'pfcserangoon',
    pfcwoodlands: 'pfcwoodlands',
    pfcyewtee: 'pfcyewtee',
    lifescanparagonlab: 'lifescanparagonlab',
    panhealthhougang: 'panhealthhougang',
    panhealthpunggol: 'panhealthpunggol',
    crawfurdmedical: 'crawfurdmedical',
    tanjongpagarmed: 'tanjongpagarmed',
    kensingtonfc: 'kensingtonfc',
    ongclinicsurgery: 'ongclinicsurgery',
    wanmedical: 'wanmedical',
    medivale: 'medivale',
    trinitymedicalshenton: 'trinitymedicalshenton',
    eternmedpunggollrt: 'eternmedpunggollrt',
    eternmedpunggolmrt: 'eternmedpunggolmrt',
    eternmedfernvale: 'eternmedfernvale',
    wilmerclinic: 'wilmerclinic',
    novovita: 'novovita',
    onecaremedicalaljuniedmrt: 'onecaremedicalaljuniedmrt',
    onecaremedicalbedokmrt: 'onecaremedicalbedokmrt',
    onecaremedicalboonlay: 'onecaremedicalboonlay',
    onecaremedicalbukitbatok: 'onecaremedicalbukitbatok',
    onecaremedicalbukitbatokwest: 'onecaremedicalbukitbatokwest',
    onecaremedicalgombakmrt: 'onecaremedicalgombakmrt',
    onecaremedicalbukitpanjang: 'onecaremedicalbukitpanjang',
    onecaremedicalbukitpanjanginterchange:
      'onecaremedicalbukitpanjanginterchange',
    onecaremedicalcanberra: 'onecaremedicalcanberra',
    onecaremedicalchoachukang: 'onecaremedicalchoachukang',
    onecaremedicalclementi: 'onecaremedicalclementi',
    onecaremedicalfernvale: 'onecaremedicalfernvale',
    onecaremedicalhougang: 'onecaremedicalhougang',
    onecaremedicalhougang8: 'onecaremedicalhougang8',
    onecaremedicalkeathong: 'onecaremedicalkeathong',
    onecaremedicalloyang: 'onecaremedicalloyang',
    onecaremedicalmarineparade: 'onecaremedicalmarineparade',
    onecaremedicalpunggolplaza: 'onecaremedicalpunggolplaza',
    onecaremedicaltampines: 'onecaremedicaltampines',
    onecaremedicaltampinesmrt: 'onecaremedicaltampinesmrt',
    onecaremedicaltiongbahru: 'onecaremedicaltiongbahru',
    onecaremedicalwoodlands: 'onecaremedicalwoodlands',
    onecaremedicalyishun: 'onecaremedicalyishun',
    onecaremedicalrafflesplace: 'onecaremedicalrafflesplace',
    jagame: 'jagame',
    hillspring: 'hillspring',
    healthplusbedok: 'healthplusbedok',
    townclinic: 'townclinic',
    lifelinepunggol: 'lifelinepunggol',
    bedokcentral: 'bedokcentral',
    lifecare: 'lifecare',
    oglmedicalqueenstown: 'oglmedicalqueenstown',
    everwellclinic: 'everwellclinic',
    coronationmedical: 'coronationmedical',
    statmchougang: 'statmchougang',
    temasekclinic: 'temasekclinic',
    hlfamilyclinicbedok: 'hlfamilyclinicbedok',
    hkfamilyclinic: 'hkfamilyclinic',
    meilingclinic: 'meilingclinic',
    emedical: 'emedical',
    asiamedic: 'asiamedic',
    kimedical: 'kimedical',
    pandamedical: 'pandamedical',
    onewellness: 'onewellness',
    fullertonhealthntu: 'fullertonhealthntu',
    chewchew: 'chewchew',
    coronationclinic: 'coronationclinic',
    clarionmedical: 'clarionmedical',
    drolmedical: 'drolmedical',
    nuffieldmedical: 'nuffieldmedical',
    hfmc: 'hfmc',
    trucareclinic: 'truecareclinic',
    camtech: 'camtech',
    medina: 'medina',
    mediclinic: 'mediclinic',
    goodshepherd: 'goodshepherd',
    taykamedical: 'taykamedical',
    qmmedicalcentre: 'qmmedicalcentre',
    trucarehl: 'trucarehl',
    leongclinic: 'leongclinic',
    islandgroupclinic: 'islandgroupclinic',
    prohealthpn: 'prohealthpn',
    prohealthtp: 'prohealthtp',
    kaotan: 'kaotan',
    healthpartnersmedicalclinic: 'healthpartnersmedicalclinic',
    prohealthbk: 'prohealthbk',
    prohealthbb: 'prohealthbb',
    prohealthfv: 'prohealthfv',
    prohealthhg: 'prohealthhg',
    prohealthpd: 'prohealthpd',
    prohealthps: 'prohealthps',
    prohealthtj: 'prohealthtj',
    drsexpress: 'drsexpress',
    pasirrisc: 'pasirrisc',
    prohealthlrtfernvale: 'prohealthlrtfernvale',
    woodgrovefc: 'woodgrovefc',
    shinefamilyclinic: 'shinefamilyclinic',
    fullertonhealthcaretrythalljurong: 'fullertonhealthcaretrythalljurong',
    aestheticsplusclinic: 'aestheticsplusclinic',
    vivamedical: 'vivamedical',
    vivamedicalgroup: 'vivamedicalgroup',
    tanclinicandsurgery: 'tanclinicandsurgery',
    vivamedicalgroupcp: 'vivamedicalgroupcp',
    fullertonhealthdrstrythall: 'fullertonhealthdrstrythall',
    fullertonhealthofc: 'fullertonhealthofc',
    fullertonhealthcaremetropolis: 'fullertonhealthcaremetropolis',
    fullertonhealthcaregethin: 'fullertonhealthcaregethin',
    fullertonhealthcarembfc: 'fullertonhealthcarembfc',
    lifelinebtbatok: 'lifelinebtbatok',
    commcare: 'commcare',
    kindredfamilyclinic: 'kindredfamilyclinic',
    sgclinic: 'sgclinic',
    simonroadclinic: 'simonroadclinic',
    caredocmedical: 'caredocmedical',
    loandleeclinic: 'loandleeclinic',
    kingstonmedical: 'kingstonmedical',
    evertonclinic: 'evertonclinic',
    famhthcs: 'famhthcs',
    iog: 'iog',
    drmpc: 'drmpc',
    medihealth: 'medihealth',
    limcountry: 'limcountry',
    mcr: 'mcr',
    anteh: 'anteh',
    littlecross: 'littlecross',
    everhealthmed: 'everhealthmed',
    lifescanmc: 'lifescanmc',
    smgdtec: 'smgdtec',
    thebreastclinic: 'thebreastclinic',
    healthline: 'healthline',
    nemgamk: 'nemgamk',
    nemgbedok: 'nemgbedok',
    nemgbb: 'nemgbb',
    nemggm: 'nemggm',
    nemgkallang: 'nemgkallang',
    nemgsengkang: 'nemgsengkang',
    nemgsimei: 'nemgsimei',
    nemgtampines: 'nemgtampines',
    nemgcb: 'nemgcb',
    medicalteckghee: '1medicalteckghee',
    atriofamilyclinic: 'atriofamilyclinic',
    atriofamilyclinicamk: 'atriofamilyclinicamk',
    avantortho: 'avantortho',
    banyanclinicjw: 'banyanclinicjw',
    lifefamilyclinicamk: 'lifefamilyclinicamk',
    advantagemedical: 'advantagemedical',
    mediview: 'mediview',
    lifefamilycliniccck: 'lifefamilycliniccck',
    boonkcns: 'boonkcns',
    everhealth: 'everhealth',
    chngclinic: 'chngclinic',
    bostonmedical: 'bostonmedical',
    karri: 'karri',
    edgedaletampines: 'edgedaletampines',
    edgedalecambridge: 'edgedalecambridge',
    edgedale: 'edgedale',
    crossroadsFamilyClinic: 'crossroadsfamilyclinic',
    greenforest: 'greenforest',
    blissMedicalClinic: 'blissmedic',
    onedoctorsFamilyClinicAmk: 'onedoctorsamk',
    onedoctorsFamilyClinicHollandVillage: 'onedoctorshv',
    onedoctorsFamilyClinicKhatib: 'onedoctorsyishun',
    onedoctorsFamilyClinicPunggol: 'onedoctorspunggol',
    onedoctorsMedicalCentre: 'onedoctorsmedicalcentre',
    trinitymedical: 'trinitymedical',
    vickycares: 'vickycares',
    eightfc: '81fc',
    rivervale: 'rivervale',
    tglmc: 'tglmc',
    curieoncology: 'curieoncology',
    pathnova: 'pathnova',
    careerps: 'careerps',
    reste: 'restelab',
    restelabdemo: 'restelabdemo',
    clinicCosta: 'cliniccosta',
    mountE: 'meh',
    ifca: 'ifca',
    arcadia: 'arcadiaclinic',
    mfcanchorvale: 'mfcanchorvale',
    ctrkidney: 'ctrkidney',
    dknmg: 'dknmg',
    mcfclinic: 'mcfclinic',
    havelock: 'havelock',
    marinaps: 'marinaps',
    qmfp: 'qmfp',
    eight: '888',
    integratedwellness: 'integratedwellness',
    gleneagles: 'gleneagles',
    BishanGraceClinic: 'bishangrace',
    bestMed: 'bestmedfamilyclinic',
    acumen: 'acumen',
    tekka: 'tekka',
    trinity: 'trinity',
    trinityProject: 'trinitymedicalresponse',
    westPoint: 'westpoint',
    iDocClinicHougangGreen: 'idochougang',
    iDocClinicHongKah: 'idochongkah',
    FiftySevenMedicalYiShun: '57medicalyis',
    faircareFamily: 'faircare',
    mirxes: 'mirxes',
    mDiagnostics: 'mdiagnostics',
    kcsMed: 'kcsmedical',
    geneRock: 'generock',
    homedical: 'homedical',
    complete: 'chihealth',
    kingGeorge: 'kgmedclinic',
    mayFairYiShun: 'mayfairmedicalyishun',
    mayFairMarsling: 'mayfairmedicalmarsling',
    acacia: 'acaciamedical',
    FiftySevenMedicalClinic: '57medicalgb',
    disInc: 'disinc',
    lohLoh: 'lohandlohclinic',
    eternmedsengkang: 'eternmedsengkang',
    physiciansPracticeFamily: 'physicianspracticeclinic',
    fullertonArc: 'fullertonhealtharc',
    amosup: 'amosup',
    greenx: 'greenx',
    pmcyishun: 'pmcyishun',
    pierxray: 'pierxray',
    fusionnexus: 'fusionnexus',
    fusionwheelock: 'fusionwheelock',
    yarramedicalclinic: 'yarramedicalclinic',
    providencenovena: 'providencenovena',
    newHealth: 'newhealthgp',
    mintMedicalFunan: 'mintmedicalfunan',
    mintMedicalHarbourFront: 'mintmedicalharbourfront',
    pulse: 'pulseclinic',
    aventus: 'aventus',
    plato: 'plato',
    neeSoon: 'neesoonclinic',
    zuellig: 'zuellig',
    familyCareClinic: 'familycareclinic',
    pTan: 'ptanfmc',
    noatun: 'noatun',
    sunCareSengKang: 'suncare',
    sunCareMedicalSengKang: 'suncaremedical',
    faithfamilyclinic: 'faithfamilyclinic',
    aLifeClinic: 'alifeclinic',
    alexandra: 'alexandrafamilyclinic',
    urgentClinic: 'urgentcareclinic',
    c3Clinic: 'c3familyclinic',
    stLukesGlobalCity: 'stlukesglobalcity',
    myClinic: 'myclinic',
    cckClinic: 'cck',
    blessClinic: 'bless',
    greenlinkClinic: 'greenlink',
    oneworldclinic: 'oneworldclinic',
    pdsc: 'pdsc',
    imlab: 'imlab',
    minMedJurong: 'minmedjurongeast',
    minMedHaig: 'minmedjhaigroad',
    minMedPasir: 'minmedjpasirris',
    minMedPunggol: 'minmedpunggol',
    minMedSengKang: 'minmedsengkang',
    minMedYiShun: 'minmedyishun',
    minMedOrchard: 'minmedorchard',
    equinoxBahari: 'ebu',
    minMedHealthScreeners: 'minmedscreeners',
    affinity: 'affinitymedical',
    pacificFamily: 'pacificfamilyclinic',
    medLife: 'medlifeclinic',
    healthCareRailmall: 'healthcaremedicalrailmall',
    faithYishun: 'fmgfcy',
    faithBedok: 'fmgfcb',
    faithJurongWest: 'fmgffcj',
    faithTampines: 'fmgfht',
    faithToaPayoh: 'fmgfctp',
    faithSimei: 'fmgfcs',
    faithRivervale: 'fmgfcr',
    daClinicAnson: 'daclinicanson',
    daClinicBukitBatok: 'daclinicbukitbatok',
    daClinicAnchorvale: 'daclinicanchorvale',
    daClinicBishan: 'daclinicbishan',
    daClinicPotongPasir: 'daclinicpotongpasir',
    daClinicTamanJurong: 'daclinictamanjurong',
    k2Medical: 'dasimei',
    interMedical24: 'amk',
    ChinChooMedical: 'chinchoo',
    SembawangMartMedicalCentre: 'sembawangmart',
    TheClinIQ: 'thecliniq',
    SengkangFamilyClinic: 'sengkangfamily',
    iconMedicalClinic: 'iconmedical',
    ifcbedok: 'ifcbedok',
    ifcbukit: 'ifcbukit',
    ifcsengkang: 'ifcsengkang',
    ktmc: 'ktmc',
    lifelinesk: 'lifelinesk',
    martinmedical: 'martinmedical',
    mmchoungang: 'mmchoungang',
    mmcus: 'mmcus',
    mfcysa: 'mfcysa',
    mfccck: 'mfccck',
    mfcclm325: 'mfcclm325',
    mfcclementi: 'mfcclementi',
    mfchougangcentral: 'mfchougangcentral',
    mfcpioneer: 'mfcpioneer',
    mfcpunggol: 'mfcpunggol',
    mfcrivervale: 'mfcrivervale',
    mfcsegar: 'mfcsegar',
    mfcsenja: 'mfcsenja',
    mfcstg: 'mfcstg',
    mfctanglinhalt: 'mfctanglinhalt',
    mfctoapayoh: 'mfctoapayoh',
    mfcwdg: 'mfcwdg',
    mfcwoodlands: 'mfcwoodlands',
    ourwfc: 'ourwfc',
    pancaremedical: 'pancaremedical',
    qmtc: 'qmtc',
    qmbuangkokmrt: 'qmbuangkokmrt',
    qmbb: 'qmbb',
    qmsc: 'qmsc',
    tpsim: 'tpsim',
    vitacarepc: 'vitacarepc',
    vitacarecanberra: 'vitacarecanberra',
    vcare: 'vcare',
    atamed: 'atamed',
    temasekmedchoa: 'temasekmedchoa',

    carefamilyclinic: 'carefamilyclinic',
    centralclinicpr: 'centralclinicpr',
    chaicheeclinic: 'chaicheeclinic',
    veinandendovascularsurgery: 'veinandendovascularsurgery',
    chuamedicalcentrepl: 'chuamedicalcentrepl',
    chuamedicalcentresurgery: 'chuamedicalcentresurgery',
    cemclinic: 'cemclinic',
    clinicfordigestivesurgery: 'clinicfordigestivesurgery',
    dermclinicorchard: 'dermclinicorchard',
    earnosethroat: 'earnosethroat',
    easternhougang: 'easternhougang',
    ejfamily: 'ejfamily',
    essentialfclinic: 'essentialfclinic',
    novenaeyesurgeons: 'novenaeyesurgeons',
    firstlight: 'firstlight',
    healthfirst: 'healthfirst',
    tysiaw: 'tysiaw',
    healthmarkpr: 'healthmarkpr',
    healthmarkcompassvale: 'healthmarkcompassvale',
    healthmarkpg: 'healthmarkpg',
    healthmarkpioneer: 'healthmarkpioneer',
    healthmedbp: 'healthmedbp',
    healthpointfc: 'healthpointfc',
    healthspringsmedicallaserclinic: 'healthspringsmedicallaserclinic',
    healthspringsorchard: 'healthspringsorchard',
    healthspringsmedicalclinic: 'healthspringsmedicalclinic',
    helix: 'helix',
    hlclinic: 'hlclinic',
    workhealth: 'workhealth',
    holisticcare: 'holisticcare',
    ifcfernvale: 'ifcfernvale',
    punggolgp: 'punggolgp',
    yangyapclinic: 'yangyapclinic',
    woodlandsclinic: 'woodlandsclinic',
    unihealthclinictoapayoh: 'unihealthclinictoapayoh',
    truecareus: 'truecareus',
    truecareamk: 'truecareamk',
    iclinic: 'iclinic',
    tampinesfmc: 'tampinesfmc',
    soongclinic: 'soongclinic',
    shifaclinicsurgery: 'shifaclinicsurgery',
    seacare: 'seacare',
    pioneermedicare: 'pioneermedicare',
    peakmedicalsg: 'peakmedicalsg',
    ofpcs: 'ofpcs',
    ortholimb: 'ortholimb',
    oasis: 'oasis',
    newest: 'newest',
    mydoctoradmiralty: 'mydoctoradmiralty',
    missionbb: 'missionbb',
    millenniumck: 'millenniumck',
    mhcfamilyclinic: 'mhcfamilyclinic',
    medivene: 'medivene',
    livewellmedical: 'livewellmedical',
    lilyaw: 'lilyaw',
    leeandtan: 'leeandtan',
    langeye: 'langeye',
    jerehheart: 'jerehheart',
  },
  uploadModes: {
    certificateWatermark: 'certificateWatermark',
    certificateLogo: 'certificateLogoUploaded',
    stampImage: 'stampImages',
    certificateInstructorSignature: 'certificateInstructorSignature',
    certificatePhysicianSignature: 'certificatePhysicianSignature',
    certificateIssuerSignature: 'certificateIssuerSignature',
    clientLogo: 'clientLogo',
    image: 'image',
    locationImage: 'locationImage',
    instructorImage: 'instructorImage',
    courseImage: 'courseImage',
    certAttachments: 'certAttachments',
    newAppointmentClientLogo: 'newAppointmentClientLogo',
  },
};

export default constants;
