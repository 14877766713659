import React, { useState, useEffect } from 'react';
import { Table, Loader, Dropdown, Popup } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { sortBy } from 'lodash';
import { useDispatch } from 'react-redux';

import history from '../../history';
import { Text, ErrorToast, Search, Button } from '../../components';
import ClientsService from '../../api/clients';
import AddConnectionModal from '../connectedPages/connectedUsers/AddConnectionModal';

import { setCorpProfiles } from './store/dashboard.actions';

import './corporateDashboard.scss';

const CorpDashboard = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [isConnectionModal, setConnectionModal] = useState(false);
  const [tempParticipants, setTempParticipants] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [column, setColumn] = useState('name');
  const [direction, setDirection] = useState('ascending');
  const [status, onStatusChange] = useState('Accepted');

  const clientInfo = useSelector(state => state.general.clientInformation);
  const profiles = useSelector(state => state.dashboard.corpProfiles);

  useEffect(() => {
    setFilteredData(profiles);
  }, []); //eslint-disable-line

  useEffect(() => {
    doFetchConnectedUsers();
  }, [status]); // eslint-disable-line

  const handleSort = clickedColumn => () => {
    if (column !== clickedColumn) {
      setColumn(clickedColumn);
      if (
        clickedColumn === 'courseStartDate' ||
        clickedColumn === 'courseEndDate'
      ) {
        filteredData.sort(function(a, b) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(b[clickedColumn]) - new Date(a[clickedColumn]);
        });
        setFilteredData(filteredData);
      } else {
        setFilteredData(sortBy(filteredData, [clickedColumn]));
      }
      setDirection('ascending');
      return;
    }

    setFilteredData(filteredData.reverse());
    setDirection(direction === 'ascending' ? 'descending' : 'ascending');
  };

  const searchTable = (e, { value }) => {
    const filteredResults = profiles.filter(o => {
      return Object.keys(o).some(k => {
        if (
          k === 'firstName' ||
          k === 'lastName' ||
          k === 'rank' ||
          k === 'fromCountry'
        ) {
          if (o[k]) return o[k].toLowerCase().includes(value.toLowerCase());
        }
      });
    });
    setFilteredData(filteredResults);
  };

  const doFetchConnectedUsers = async () => {
    try {
      setIsLoading(true);
      const {
        data: { getConnectedUsers },
      } = await ClientsService.getConnectedUsers(
        clientInfo.id,
        status !== 'Accepted' ? status.toLowerCase() : undefined,
        status === 'Accepted'
      );
      const sortedResults = sortBy(getConnectedUsers, 'name').reverse();
      dispatch(setCorpProfiles(sortedResults));
      setFilteredData(sortedResults);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    } finally {
      setIsLoading(false);
    }
  };

  const renderTableData = () => {
    if (filteredData.length === 0)
      return (
        <div className="corp-dash__empty">
          <Text>No data</Text>
        </div>
      );
    return filteredData.map(participant => (
      <Table.Row
        className="table-row corp-dash__row"
        onClick={() => {
          if (participant.isActive)
            history.push({
              pathname: `/profile/${participant.id}/${participant.connectionId}`,
            });
        }}
      >
        <Table.Cell>
          <Text bold size="small">
            {participant.name
              ? participant.name
              : `${participant.firstName} ${participant.lastName}`}
          </Text>
        </Table.Cell>

        <Table.Cell>
          <Text size="small">{participant.rank}</Text>
        </Table.Cell>
        {clientInfo.role === 'recruitment' && (
          <Table.Cell>
            <Text size="small">{participant.positionOfInterest}</Text>
          </Table.Cell>
        )}
        <Table.Cell>
          <Text size="small">{participant.fromCountry}</Text>
        </Table.Cell>

        <Table.Cell>
          {participant.isVerified ? (
            <Popup
              content="Is verified"
              key="review"
              position="top center"
              trigger={
                <div className="corp-dash__revoked-status corp-dash__revoked-status--green" />
              }
            />
          ) : (
            <Popup
              content="Is not verified"
              key="review"
              position="top center"
              trigger={
                <div className="corp-dash__revoked-status corp-dash__revoked-status--red" />
              }
            />
          )}
        </Table.Cell>
        <Table.Cell>
          {participant.isActive && (
            <div className="corp-dash__revoked-status corp-dash__revoked-status--green" />
          )}
          {participant.status === 'requested' && (
            <div className="corp-dash__revoked-status corp-dash__revoked-status--orange" />
          )}
        </Table.Cell>
        {clientInfo.role === 'recruitment' && (
          <Table.Cell>
            {participant.isAccepted ? (
              <Popup
                content="Profile reviewed (accepted)"
                key="review"
                position="top center"
                trigger={
                  <div className="corp-dash__revoked-status corp-dash__revoked-status--green" />
                }
              />
            ) : (
              <Popup
                content="Profile not reviewed"
                key="review"
                position="top center"
                trigger={
                  <div className="corp-dash__revoked-status corp-dash__revoked-status--orange" />
                }
              />
            )}
          </Table.Cell>
        )}
      </Table.Row>
    ));
  };

  return (
    <div className="corp-dash">
      <div className="corp-dash__header-wrapper">
        <div className="corp-dash__search">
          <Search
            size="large"
            dropdown={false}
            onSearchChange={searchTable}
            isFilter={true}
          />
        </div>
        <Button color="green" onClick={() => setConnectionModal(true)} inverted>
          Add connection
        </Button>
      </div>

      <Table
        className="corp-dash__table"
        sortable
        striped
        aria-labelledby="header"
        selectable
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={column === 'firstName' ? direction : null}
              onClick={handleSort('firstName')}
            >
              Name
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'rank' ? direction : null}
              onClick={handleSort('rank')}
            >
              Rank
            </Table.HeaderCell>
            {clientInfo.role === 'recruitment' && (
              <Table.HeaderCell
                sorted={column === 'positionOfInterest' ? direction : null}
                onClick={handleSort('positionOfInterest')}
              >
                Position applied for
              </Table.HeaderCell>
            )}
            <Table.HeaderCell
              sorted={column === 'fromCountry' ? direction : null}
              onClick={handleSort('fromCountry')}
            >
              Nationality
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'isVerified' ? direction : null}
              onClick={handleSort('isVerified')}
            >
              Verified
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'status' ? direction : null}
              onClick={handleSort('status')}
              style={{ padding: '5px' }}
            >
              <Dropdown fluid text={status} selection>
                <Dropdown.Menu>
                  <Dropdown.Item
                    text="Accepted"
                    onClick={() => onStatusChange('Accepted')}
                  />
                  <Dropdown.Item
                    text="Requested"
                    onClick={() => onStatusChange('Requested')}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </Table.HeaderCell>
            {clientInfo.role === 'recruitment' && (
              <Table.HeaderCell
                sorted={column === 'isAccepted' ? direction : null}
                onClick={handleSort('isAccepted')}
              >
                Reviewed
              </Table.HeaderCell>
            )}
          </Table.Row>
        </Table.Header>
        <Table.Body className="corp-dash__table__body">
          {isLoading && profiles.length === 0 ? (
            <Loader active />
          ) : (
            renderTableData()
          )}
        </Table.Body>
      </Table>
      <AddConnectionModal
        isOpen={isConnectionModal}
        toggleModal={setConnectionModal}
      />
    </div>
  );
};

export default CorpDashboard;
