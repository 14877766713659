import styled from 'styled-components';
import { useSelector } from 'react-redux';

import './appointmentCard.scss';
import { groupBy, mapValues, omit } from 'lodash';
import { Icon, Popup } from 'semantic-ui-react';

export function AppointmentCard({
  className,
  name,
  type,
  price,
  currency,
  description,
  packageTests,
  packageType,
  extraFee,
  extraFeeDesc,
  onClick = () => {},
}) {
  const { logo } = useSelector(state => state.general.clientInformation);

  const categoryList = mapValues(groupBy(packageTests, 'category'), _ =>
    _.map(t => omit(t, 'category'))
  );
  const isMultiple = packageType === 'multiple';

  return (
    <>
      {/* <CardContent>
        {description && <CardNotice>{description}</CardNotice>}
        <CardHeader>{name}</CardHeader>
        <CardTestType color={'#1fa97f'}>
          <span>{isMultiple ? '(Package)' : type}</span>
          <CardLogo>
            <CardLogoImage src={logo} alt={name} />
          </CardLogo>
        </CardTestType> */}
      <div className={`appointment-card__card ${className}`} onClick={onClick}>
        <div
          className="appointment-card__card__content"
          style={{ background: '#F5FFF8' }}
        >
          <div className="appointment-card__card__meta-notice">
            {description}
          </div>
          <div className="appointment-card__card__header">{name}</div>
          <div className="appointment-card__card__test-type">
            <span>{isMultiple ? '(Package)' : type}</span>
            <div className="appointment-card__card__test-type__logo">
              <CardLogoImage src={logo} alt="logo" />
            </div>
          </div>
        </div>
        <PackageTestWrapper>
          {isMultiple && <PackageTestHeader>Tests conducted</PackageTestHeader>}
          {isMultiple && Object.keys(categoryList).length > 0
            ? Object.keys(categoryList).map(categoryKey => (
                <PackageTestItem key={categoryKey}>
                  <PackageTestItemCatName>{categoryKey}</PackageTestItemCatName>
                  {categoryList[categoryKey].map(test => (
                    <PackageTestItemDetials key={test.id}>
                      <PackageTestItemDetialsDot color={'#1fa97f'} />
                      <PackageTestItemDetialsName>
                        {test.name}
                      </PackageTestItemDetialsName>
                    </PackageTestItemDetials>
                  ))}
                </PackageTestItem>
              ))
            : ''}
        </PackageTestWrapper>
        <ExtraContent>
          <ExtraTotal>
            <h4>Total</h4>
          </ExtraTotal>
          <ExtraPriceWrapper>
            <ExtraPriceTag>
              <ExtraCurrency>{currency || 'SGD'}</ExtraCurrency>
              {price && <ExtraPrice>{price.toFixed(2)}</ExtraPrice>}
            </ExtraPriceTag>
            {extraFee ? (
              <ExtraCost>
                {`Extra Fee: ${extraFee.toFixed(2)} ${currency || 'SGD'}`}{' '}
                <Popup
                  className="extra-fee__popup"
                  trigger={<Icon circular name="info" size="small" />}
                  content={extraFeeDesc}
                />{' '}
              </ExtraCost>
            ) : (
              ''
            )}

            <PriceDescription>Price includes GST</PriceDescription>
          </ExtraPriceWrapper>
        </ExtraContent>
        {/* <div className="appointment-card__card__extra-content">
          <div className="appointment-card__card__extra-content__total">
            <h4>Total</h4>
          </div>
          <div className="appointment-card__card__extra-content__price-tag">
            <div>
              <span className="appointment-card__card__extra-content__currency">
                {currency || 'SGD'}
              </span>
              <span className="appointment-card__card__extra-content__price">
                {price.toFixed(2)}
              </span>
            </div>
            <span className="meta">Price includes GST</span>
          </div>
        </div> */}
        {/* </CardContent> */}
      </div>
    </>
  );
}

export default AppointmentCard;
const CardLogoImage = styled.img`
  height: 45px;
`;

export const CardLogo = styled.div`
  float: right;
  padding-right: 1rem;
`;

export const CardContent = styled.div`
  border-radius: 10px 10px 0 0;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  padding-top: 15px;
  line-height: normal;
  border-bottom: 1.5px solid ${props => props.borderColor || '#000000'};
  justify-content: space-between;
  background: ${props => `${props.bgColor}10` || `rgb(245, 255, 248)`};
`;

export const CardNotice = styled.div`
  padding: 10px 0 0px 0;
  color: black !important;
  font-size: 0.9rem !important;
  font-weight: 600;
`;

export const CardHeader = styled.div`
  font-size: 20px;
  font-weight: 900;
  color: black;
  margin-bottom: 8px;
`;

export const CardTestType = styled.div`
  color: ${props => props.color || '#1fa97f'};
  font-size: medium;
  padding-bottom: 0.5rem;
  font-weight: 900;
`;
const PackageTestWrapper = styled.div`
  color: black !important;
  position: relative;
  width: 100%;
  margin: 0;
  padding: 23px;
  border-bottom: 1px solid #1fa97f;
  background: rgb(245, 255, 248);
`;
const PackageTestHeader = styled.div`
  font-weight: bold;
  padding-bottom: 10px;
  font-size: 15px;
`;
const PackageTestItem = styled.div`
  margin-bottom: 10px;
`;
const PackageTestItemCatName = styled.div`
  margin-bottom: 6px;
  font-weight: 600;
`;
const PackageTestItemDetials = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;
const PackageTestItemDetialsDot = styled.div`
  width: 8px;
  height: 4px;
  background: ${props => props.color || '#1fa97f'};
  border-radius: 4px;
`;
const PackageTestItemDetialsName = styled.span`
  margin-left: 10px;
`;

const ExtraContent = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 17px 15px 9px 15px;
  border-radius: 0 0 10px 10px;
`;

const ExtraTotal = styled.div`
  color: black;
`;
const ExtraPriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const PriceDescription = styled.div`
  margin: 4px 4px 0 0;
  text-align: right;
  color: #6b779a;
  font-size: 10px;
`;

const ExtraCurrency = styled.span`
  color: #6b779a;
  font-size: medium;
  font-weight: bold;
  padding: 0 5px 0 5px;
`;
const ExtraPrice = styled.span`
  color: black;
  font-size: 30px;
  font-weight: bolder;
`;
const ExtraPriceTag = styled.span`
  text-align: right;
  margin: 4px 4px 0 0;
`;
const ExtraCost = styled.span`
  color: black;
  font-size: 15px;
  margin-top: 4px;
  font-weight: 500;
`;
