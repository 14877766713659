import React from 'react';
import { Text } from 'recharts';

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    let total = 0;

    const rows = payload.map(row => {
      total += row.value;
      return (
        <Text
          key={row.name}
          className="charts__label"
          style={{ color: row.color }}
        >{`${row.name} : ${row.value}`}</Text>
      );
    });

    return (
      <div className="charts__custom-tooltip">
        {rows}
        <Text className="charts__label">{`Total: ${total}`}</Text>
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
