import React, { Suspense, lazy, useEffect, useState } from 'react';
import { Route, Switch, useLocation, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import PageWrapper from './pages/pageWrapper';
import Login from './pages/login';
import LoaderPage from './pages/loaderPage';

import { signOutUser } from './auth/authentication';
import ClientService from './api/clients';
import UserService from './api/user';
import {
  setClientInformation,
  setDevMode,
  setUser,
} from './redux/generalState.actions';
import history from './history';
import { useClient } from 'hooks';

// *********************************
// DASHBOARD PAGES
// *********************************

import Dashboard from './pages/dashboard/DashboardContainer';
import Stats from './pages/stats/Stats';
import EmailActivity from './pages/stats/emailActivity/EmailActivity';
import EmailSettings from './pages/emails/EmailSettings';
import TinyUrlView from './pages/certificatePages/publicCertificateView/TinyUrlView';
import AppointmentStats from 'pages/webAppointments/appointmentStats/appointmentStats';
import BitAiUserGuide from 'pages/bitAi/BitAiUserGuide';

// *********************************
// APPOINTMENT PAGES
// *********************************

const CreateAppointment = lazy(() =>
  import(
    /* webpackChunkName: 'cousemanagement' */ './pages/appointmentPages/appointmentCreation/AppointmentCreation'
  )
);
const ScheduledAppointments = lazy(() =>
  import(
    /* webpackChunkName: 'cousemanagement' */ './pages/appointmentPages/scheduledAppointments/ScheduledAppointments'
  )
);
const AppointmentDetails = lazy(() =>
  import(
    /* webpackChunkName: 'cousemanagement' */ './pages/appointmentPages/appointmentDetails/AppointmentDetails'
  )
);

const PatientsOverview = lazy(() =>
  import(
    /* webpackChunkName: 'cousemanagement' */ './pages/appointmentPages/patientsOverview/PatientsOverview'
  )
);

const TemplateCreation = lazy(() =>
  import(
    /* webpackChunkName: 'cousemanagement' */ './pages/certificatePages/certificateTemplateCreation/templateTypeSelect/TemplateTypeSelect'
  )
);

// *********************************
// CLIENT PAGES
// *********************************

const ClientData = lazy(() =>
  import(
    /* webpackChunkName: 'cousemanagement' */ './pages/clientPages/dataManagement/DataManangement'
  )
);
const ClientManagement = lazy(() =>
  import(
    /* webpackChunkName: 'cousemanagement' */ './pages/clientPages/clientManagement/ClientManagement'
  )
);
const CreateClient = lazy(() =>
  import(
    /* webpackChunkName: 'cousemanagement' */ './pages/clientPages/createClient/CreateClient'
  )
);
const ClientDetails = lazy(() =>
  import(
    /* webpackChunkName: 'cousemanagement' */ './pages/clientPages/clientDetails/ClientDetails'
  )
);
const ConnectedUsers = lazy(() =>
  import(
    /* webpackChunkName: 'cousemanagement' */ './pages/connectedPages/connectedUsers/ConnectedUsers'
  )
);
const CreateEventPage = lazy(() =>
  import(
    /* webpackChunkName: 'cousemanagement' */ './pages/connectedPages/eventsPages/createEventPage/CreateEventPage'
  )
);
const EventsOverview = lazy(() =>
  import(
    /* webpackChunkName: 'cousemanagement' */ './pages/connectedPages/eventsPages/eventsOverview/EventsOverview'
  )
);
const EventDetails = lazy(() =>
  import(
    /* webpackChunkName: 'cousemanagement' */ './pages/connectedPages/eventsPages/eventDetails/EventDetails'
  )
);

// *********************************
// CERTIFICATE PAGES
// *********************************

const CertificateCreation = lazy(() =>
  import(
    /* webpackChunkName: 'cousemanagement' */ './pages/certificatePages/certificateCreation/certificates/MaritimeCertificate'
  )
);
const CertificateView = lazy(() =>
  import(
    /* webpackChunkName: 'cousemanagement' */ './pages/certificatePages/certificateView/CertificateView'
  )
);
const DocumentView = lazy(() =>
  import(
    /* webpackChunkName: 'cousemanagement' */ './pages/certificatePages/documentView/DocumentView'
  )
);
const CertificateTemplateView = lazy(() =>
  import(
    /* webpackChunkName: 'cousemanagement' */ './pages/certificatePages/certificateTemplateView/CertificateTemplateView'
  )
);
const CertificateEdit = lazy(() =>
  import(
    /* webpackChunkName: 'cousemanagement' */ './pages/certificatePages/certificateEdit/CertificateEditing'
  )
);
const PublicCertificateView = lazy(() =>
  import(
    /* webpackChunkName: 'publicCertificateView' */ './pages/certificatePages/publicCertificateView/PublicCertificateView'
  )
);
const PublicQRView = lazy(() =>
  import(
    /* webpackChunkName: 'publicCertificateView' */ './pages/certificatePages/publicQrCertView/PublicQrCertView'
  )
);
// const CertificateManagement = lazy(() =>
//   import(
//     /* webpackChunkName: 'certificatemanagement' */ './pages/certificatePages/certificateManagement'
//   )
// );
const PlatoIssue = lazy(() =>
  import(
    /* webpackChunkName: 'certificatemanagement' */ './pages/certificatePages/issueCertificate/PlatoIssue'
  )
);
const IssueCertificate = lazy(() =>
  import(
    /* webpackChunkName: 'certificatemanagement' */ './pages/certificatePages/issueCertificate/IssueCertificate'
  )
);
const BulkIssuePage = lazy(() =>
  import(
    /* webpackChunkName: 'certificatemanagement' */ './pages/certificatePages/issueCertificate/components/BulkIssuePage'
  )
);
const IssudeCertificates = lazy(() =>
  import(
    /* webpackChunkName: 'certificatemanagement' */ './pages/certificatePages/issuedCertificates'
  )
);
const TemplatesManagement = lazy(() =>
  import(
    /* webpackChunkName: 'certificatemanagement' */ './pages/certificatePages/templatesManagement'
  )
);
const LabDocuments = lazy(() =>
  import(
    /* webpackChunkName: 'certificatemanagement' */ './pages/certificatePages/labDocuments/LabDocuments'
  )
);
const LabDocumentIssue = lazy(() =>
  import(
    /* webpackChunkName: 'certificatemanagement' */ './pages/certificatePages/labDocuments/components/IssueLabPcrResult'
  )
);
const EditLabResult = lazy(() =>
  import(
    /* webpackChunkName: 'certificatemanagement' */ './pages/certificatePages/labDocuments/components/EditLabResult'
  )
);

// *********************************
// COURSE PAGES
// *********************************

const PlannedCourses = lazy(() =>
  import(
    /* webpackChunkName: 'cousemanagement' */ './pages/coursePages/courseManagement/plannedCourses'
  )
);
const CourseArchive = lazy(() =>
  import(
    /* webpackChunkName: 'cousemanagement' */ './pages/coursePages/courseManagement/courseArchive'
  )
);

const CourseTemplates = lazy(() =>
  import(
    /* webpackChunkName: 'cousemanagement' */ './pages/coursePages/courseManagement/courseTemplates'
  )
);

const CourseTemplate = lazy(() =>
  import(
    /* webpackChunkName: 'cousemanagement' */ './pages/coursePages/courseManagement/courseTemplateView'
  )
);

const CourseCreation = lazy(() =>
  import(
    /* webpackChunkName: 'coursecreation' */ './pages/coursePages/courseCreation/CourseCreation'
  )
);

const CompleteCourseOverview = lazy(() =>
  import(
    /* webpackChunkName: 'coursecreation' */ './pages/coursePages/completeCourseOverview/CompleteCourseOverview'
  )
);

const CourseTemplateCreation = lazy(() =>
  import(
    /* webpackChunkName: 'coursecreation' */ './pages/coursePages/courseTemplateCreation/CourseTemplateCreation'
  )
);

const CourseCreate = lazy(() =>
  import(
    /* webpackChunkName: 'coursecreation' */ './pages/coursePages/courseCreation/courseCreate'
  )
);

const CourseIssue = lazy(() =>
  import(
    /* webpackChunkName: 'publicCertificateView' */ './pages/coursePages/courseIssue/CourseIssue'
  )
);

const CourseOverview = lazy(() =>
  import(
    /* webpackChunkName: 'publicCertificateView' */ './pages/coursePages/courseOverview/CourseOverview'
  )
);

const ParticipantsOverview = lazy(() =>
  import(
    /* webpackChunkName: 'publicCertificateView' */ './pages/coursePages/participantsOverview/ParticipantsOverview'
  )
);

const SwabberReport = lazy(() =>
  import(
    /* webpackChunkName: 'publicCertificateView' */ './pages/swabberPages/SwabberPage'
  )
);

// *********************************
// INSTRUCTOR PAGES
// *********************************

const InstructorManagement = lazy(() =>
  import(
    /* webpackChunkName: 'cousemanagement' */ './pages/instructorPages/instructorManagement/InstructorManagement'
  )
);

const AddInstructor = lazy(() =>
  import(
    /* webpackChunkName: 'cousemanagement' */ './pages/instructorPages/addInstructorPage/AddInstuctorPage'
  )
);

const InstructorOverview = lazy(() =>
  import(
    /* webpackChunkName: 'cousemanagement' */ './pages/instructorPages/instructorOverview/InstructorOverview'
  )
);

const WebAppointments = lazy(() =>
  import(
    /* webpackChunkName: 'cousemanagement' */ './pages/webAppointments/calendar/CalendarContainer'
  )
);

// *********************************
// PROFILE PAGES
// *********************************

const ProfilePage = lazy(() =>
  import(
    /* webpackChunkName: 'cousemanagement' */ './pages/profilePages/profilePage'
  )
);

// *********************************
// INSIGHTS PAGES
// *********************************

const Insights = lazy(() =>
  import(/* webpackChunkName: 'insights' */ './pages/insights/insights')
);

// *********************************
// CALENDAR PAGES
// *********************************

const Calendar = lazy(() =>
  import(
    /* webpackChunkName: 'calendar' */ './pages/calendar/CalendarContainer'
  )
);

const Cms = lazy(() =>
  import(/* webpackChunkName: 'cms' */ './pages/cmsPages/contentPages/cms')
);

const CmsContentView = lazy(() =>
  import(
    /* webpackChunkName: 'cms' */ './pages/cmsPages/contentPages/contentView/contentView'
  )
);

const CmsManagement = lazy(() =>
  import(
    /* webpackChunkName: 'cmsManagement' */ './pages/cmsPages/cmsManagement/cmsManagement'
  )
);

// *********************************
// ASSESSMENT PAGES
// *********************************

const AssessmentPage = lazy(() =>
  import(
    /* webpackChunkName: 'calendar' */ './pages/assessmentPages/assessmentDetails/AssessmentDetails'
  )
);

const MedAppointmentsOverview = lazy(() =>
  import(
    /* webpackChunkName: 'cousemanagement' */ './pages/webAppointments/overview/Overview'
  )
);
const MedAppointments = lazy(() =>
  import(
    /* webpackChunkName: 'cousemanagement' */ './pages/webAppointments/overview/components/appointmentDetails/AppointmentDetails'
  )
);

const MedTestOverview = lazy(() =>
  import(
    /* webpackChunkName: 'cousemanagement' */ './pages/webAppointments/overview/testTypesOverview/testTypesOverview'
  )
);

const MedAccountDetails = lazy(() =>
  import(
    /* webpackChunkName: 'cousemanagement' */ './pages/webAppointments/overview/accountDetails/components/saveDetails/saveDetails'
  )
);

const MedScheduleAppointments = lazy(() =>
  import(
    /* webpackChunkName: 'cousemanagement' */ './pages/webAppointments/scheduleAppointment/scheduleAppointment'
  )
);

const CreateAccount = lazy(() =>
  import(
    /* webpackChunkName: 'createaccount' */ './pages/accountPages/createAccount/CreateAccount'
  )
);
const CreateAccountSuccess = lazy(() =>
  import(
    /* webpackChunkName: 'createaccountSuccess' */ './pages/accountPages/createAccountSuccess/CreateAccountSuccess'
  )
);

export const MainRoutes = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const userInfo = useSelector(state => state.general.user);
  // const clientInformation = useSelector(
  //   state => state.general.clientInformation
  // );
  const { hasCms, hasCreateAccount } = useClient();

  const [isLoading, setIsLoading] = useState(true);

  // Todo add a check to see if user is authenticated
  useEffect(() => {
    // Backward compatability to old app route to public cert, used in QR codes
    const splitUrl = history.location.hash.split('/');
    const splitUrl2 = history.location.pathname.split('/');
    if (splitUrl2[1] === 'certificate' && splitUrl2.length === 3)
      return setIsLoading(false);
    if (history.location.pathname.split('/')[1] === 'certificate') {
      return setIsLoading(false);
    }
    if (splitUrl[0] === '#' && splitUrl[1] === 'certificate') {
      history.push({
        pathname: `/certificate/${splitUrl[2]}`,
      });
    }
    if (splitUrl[0] === '#' && splitUrl[1] === 'emailValidate') {
      history.push({
        pathname: `/emailValidate/${splitUrl[2]}/${splitUrl[3]}`,
      });
    }
    getInitialData();
  }, []); //eslint-disable-line

  useEffect(() => {
    window.Intercom('update');
  }, [location]);

  useEffect(() => {
    window.Intercom('boot', {
      app_id: 'a1z5uy25',
      name: userInfo.name,
      email: userInfo.email,
      user_id: userInfo.id,
    });
  }, [userInfo]);

  const getInitialData = async () => {
    await Promise.all([
      fetchClientDetails(),
      fetchUserDetails(),
      getIsDevMode(),
    ]);
    setIsLoading(false);
  };

  const fetchClientDetails = async () => {
    try {
      const {
        data: { client },
      } = await ClientService.getClientTheme();
      dispatch(setClientInformation(client));
    } catch (error) {
      console.log('router on start error', error);
    }
  };

  const getIsDevMode = () => {
    const isDevMode = localStorage.getItem('IS_DEV_MODE');
    const formattedDevMode = isDevMode === 'true';
    dispatch(setDevMode(!!formattedDevMode));
  };

  const fetchUserDetails = async () => {
    try {
      const userId = localStorage.getItem('USER_ID');
      if (!userId) return;
      const {
        data: { admin },
      } = await UserService.getAdminById(userId);
      // window.Intercom('boot', {
      //   app_id: 'a1z5uy25',
      //   name: admin.name,
      //   email: admin.email,
      //   user_id: admin.id,
      // });

      window.intercomSettings = {
        name: admin.name,
        email: admin.email,
        user_id: admin.id,
      };

      dispatch(setUser(admin));
    } catch (error) {
      console.log('router on start error', error);
    }
  };

  const ProtectedRoute = ({ component: Component, restricted, ...props }) => {
    if (!localStorage.getItem('AUTH_TOKEN')) {
      signOutUser(userInfo.id);
      return <Route {...props} render={() => <LoaderPage />} />;
    }

    if (restricted?.includes(userInfo.role))
      return <Redirect to="/dashboard" />;
    return <Route {...props} render={props => <Component {...props} />} />;
  };

  return (
    <>
      {isLoading ? (
        <LoaderPage />
      ) : (
        <Suspense fallback={<LoaderPage />}>
          <Switch>
            <Route
              path="/certificate/:certificateId"
              exact
              render={() => <PublicCertificateView />}
            />
            <Route path="/d/:tinyId" exact render={() => <TinyUrlView />} />
            <Route
              path="/certificate/qr/:certificateId"
              exact
              render={() => <PublicQRView />}
            />
            <Route
              path="/passwordReset/:token/:email"
              exact
              component={() => <Login />}
            />
            <Route
              path="/activate/:token/:email"
              exact
              component={() => <Login />}
            />
            <Route
              path="/emailValidate/:token/:email"
              exact
              component={() => <Login />}
            />
            <Route
              path="/plato/documents/issue"
              exact
              component={() => <PlatoIssue type="medical" />}
            />
            {/* {hasCreateAccount && ( */}
            <Route
              path="/create-account-success/:id"
              component={() => <CreateAccountSuccess />}
            />
            {/* )} */}
            <Route exact path="/" component={() => <Login />} />
            <PageWrapper>
              <Switch>
                <ProtectedRoute
                  path="/dashboard"
                  component={() => <Dashboard />}
                />
                <ProtectedRoute path="/stats" component={() => <Stats />} />
                <ProtectedRoute
                  path="/email/activity"
                  component={() => <EmailActivity />}
                  restricted={['swabber']}
                />
                <ProtectedRoute
                  path="/email/settings"
                  component={() => <EmailSettings />}
                  restricted={['swabber']}
                />
                <ProtectedRoute
                  path="/insights"
                  component={() => <Insights />}
                  restricted={['swabber']}
                />
                <ProtectedRoute
                  path="/calendar"
                  component={() => <Calendar />}
                />
                <ProtectedRoute
                  path="/template/certificate/create"
                  exact
                  component={() => (
                    <CertificateCreation isEditing isTemplateCreator />
                  )}
                />
                <ProtectedRoute
                  path="/webshop/settings"
                  exact
                  component={() => <MedTestOverview />}
                />
                <ProtectedRoute
                  path="/webshop/settings/:action"
                  exact
                  component={() => <MedAccountDetails />}
                />
                <ProtectedRoute
                  path="/webshop/overview"
                  exact
                  component={() => <MedAppointmentsOverview />}
                />
                <ProtectedRoute
                  path="/webshop/overview/schedule*"
                  exact
                  component={() => <MedScheduleAppointments />}
                />
                <ProtectedRoute
                  path="/webshop/overview/:appointmentId"
                  exact
                  component={() => <MedAppointments />}
                />
                <ProtectedRoute
                  path="/webshop/stats"
                  exact
                  component={() => <AppointmentStats />}
                />
                <ProtectedRoute
                  path="/certificates/issue"
                  exact
                  component={() => <IssueCertificate />}
                />
                <ProtectedRoute
                  path="/certificates/issue/:certId"
                  exact
                  component={() => <IssueCertificate />}
                  restricted={['swabber']}
                />
                <ProtectedRoute
                  path="/certificates/issue/bulk"
                  exact
                  component={() => <BulkIssuePage />}
                  restricted={['swabber']}
                />
                <ProtectedRoute
                  path="/certificates/issued"
                  exact
                  component={() => <IssudeCertificates />}
                  restricted={['swabber']}
                />
                <ProtectedRoute
                  path="/template/certificate"
                  exact
                  component={() => <TemplatesManagement />}
                  restricted={['swabber']}
                />
                <ProtectedRoute
                  path="/template/certificate/:templateId"
                  exact
                  component={() => <CertificateTemplateView />}
                  restricted={['swabber']}
                />
                <ProtectedRoute
                  path="/certificates/view/:id"
                  exact
                  component={() => <CertificateView />}
                  restricted={['swabber']}
                />
                <ProtectedRoute
                  path="/document/view/:id"
                  exact
                  component={() => <DocumentView />}
                  restricted={['swabber']}
                />
                <ProtectedRoute
                  path="/swabber/report"
                  exact
                  component={() => <SwabberReport />}
                />
                <ProtectedRoute
                  path="/webshop/calendar"
                  exact
                  component={() => <WebAppointments />}
                />
                <ProtectedRoute
                  path="/user-guide"
                  exact
                  component={() => <BitAiUserGuide />}
                />
                <ProtectedRoute
                  path="/certificates/edit/:certificateId"
                  exact
                  component={() => <CertificateEdit />}
                  restricted={['swabber']}
                />
                <ProtectedRoute
                  path="/connections"
                  exact
                  component={() => <ConnectedUsers />}
                />
                <ProtectedRoute
                  path="/connections/event"
                  exact
                  component={() => <CreateEventPage />}
                />
                <ProtectedRoute
                  path="/connections/event/overview"
                  exact
                  component={() => <EventsOverview />}
                />
                <ProtectedRoute
                  path="/connections/event/:id"
                  exact
                  component={() => <EventDetails />}
                />
                <ProtectedRoute
                  path="/assessment/:id"
                  exact
                  component={() => <AssessmentPage />}
                />
                {/* <ProtectedRoute
                    path="/certificate"
                    exact
                    component={() => <CertificateManagement />}
                  /> */}
                <ProtectedRoute
                  path="/template/certificate/:id"
                  exact
                  component={() => <CertificateView />}
                />
                <ProtectedRoute
                  path="/course/planned"
                  exact
                  component={() => <PlannedCourses />}
                />
                <ProtectedRoute
                  path="/course/archive"
                  exact
                  component={() => <CourseArchive />}
                />
                <ProtectedRoute
                  path="/course/archive/:courseId"
                  exact
                  component={() => <CompleteCourseOverview />}
                />
                <ProtectedRoute
                  path="/template/course"
                  exact
                  component={() => <CourseTemplates />}
                />
                <ProtectedRoute
                  path="/template/course/view/:templateId"
                  exact
                  component={() => <CourseTemplate />}
                />
                <ProtectedRoute
                  path="/course/create"
                  exact
                  component={() => <CourseCreate />}
                />
                <ProtectedRoute
                  path="/course/create/structure"
                  component={() => <CourseCreation />}
                />
                <ProtectedRoute
                  path="/course/edit/structure"
                  component={() => <CourseCreation />}
                />
                <ProtectedRoute
                  path="/template/course/create"
                  component={() => <CourseTemplateCreation />}
                />
                <ProtectedRoute
                  path="/template/course/edit"
                  component={() => <CourseTemplateCreation />}
                />
                <ProtectedRoute
                  path="/course/issue/:id"
                  exact
                  component={() => <CourseIssue />}
                  restricted={['swabber']}
                />
                <ProtectedRoute
                  path="/entities/instructor"
                  exact
                  component={() => <InstructorManagement />}
                />
                <ProtectedRoute
                  path="/entites/participants"
                  exact
                  component={() => <ParticipantsOverview />}
                  restricted={['swabber']}
                />
                <ProtectedRoute
                  path="/entities/instructor/overview/:id"
                  exact
                  component={() => <InstructorOverview />}
                />
                <ProtectedRoute
                  path="/entities/instructor/add"
                  exact
                  component={() => <AddInstructor />}
                />
                <Route
                  path="/course/details/:courseId"
                  exact
                  render={() => <CourseOverview />}
                />
                <ProtectedRoute
                  path="/client/data"
                  exact
                  component={() => <ClientData />}
                  restricted={['swabber']}
                />
                <ProtectedRoute
                  path="/client"
                  exact
                  component={() => <ClientManagement />}
                  restricted={['swabber']}
                />
                <ProtectedRoute
                  path="/client/details/:id"
                  exact
                  component={() => <ClientDetails />}
                  restricted={['swabber']}
                />
                <ProtectedRoute
                  path="/client/create"
                  exact
                  component={() => <CreateClient />}
                  restricted={['swabber']}
                />
                <ProtectedRoute
                  path="/client/edit/:id"
                  exact
                  component={() => <CreateClient />}
                  restricted={['swabber']}
                />
                <ProtectedRoute
                  path="/appointment/create"
                  exact
                  component={() => <CreateAppointment />}
                />
                <ProtectedRoute
                  path="/appointment/planned"
                  exact
                  component={() => <ScheduledAppointments />}
                />
                <ProtectedRoute
                  path="/appointment/archive"
                  exact
                  component={() => <ScheduledAppointments archive={true} />}
                />
                <ProtectedRoute
                  path="/appointment/details/:id"
                  exact
                  component={() => <AppointmentDetails />}
                />
                <ProtectedRoute
                  path="/entities/staff/overview/:id"
                  exact
                  component={() => <InstructorOverview />}
                />
                <ProtectedRoute
                  path="/entities/staff"
                  exact
                  component={() => <InstructorManagement />}
                  restricted={['swabber']}
                />
                <ProtectedRoute
                  path="/entities/staff/add"
                  exact
                  component={() => <AddInstructor />}
                />
                <ProtectedRoute
                  path="/entities/patients"
                  exact
                  component={() => <PatientsOverview />}
                />
                <ProtectedRoute
                  path="/documents/issue"
                  exact
                  component={() => <IssueCertificate />}
                  restricted={['swabber']}
                />
                <ProtectedRoute
                  path="/documents/issue/bulk"
                  exact
                  component={() => <BulkIssuePage />}
                  restricted={['swabber']}
                />
                <ProtectedRoute
                  path="/documents/templates"
                  exact
                  component={() => <TemplatesManagement />}
                  restricted={['swabber']}
                />
                <ProtectedRoute
                  path="/documents/templates/create"
                  component={TemplateCreation}
                  restricted={['swabber']}
                />
                <ProtectedRoute
                  path="/documents/templates/view/:templateId"
                  exact
                  component={() => <CertificateTemplateView />}
                />
                <ProtectedRoute
                  path="/documents/issued"
                  exact
                  component={() => <IssudeCertificates />}
                  restricted={['swabber']}
                />
                <ProtectedRoute
                  path="/documents/lab"
                  exact
                  component={() => <LabDocuments />}
                />
                <ProtectedRoute
                  path="/documents/lab/issue/:id"
                  exact
                  component={() => <LabDocumentIssue />}
                  restricted={['swabber']}
                />
                <ProtectedRoute
                  path="/documents/lab/issue/:id/:secondId"
                  exact
                  component={() => <LabDocumentIssue />}
                />
                <ProtectedRoute
                  path="/documents/lab/document"
                  exact
                  component={() => <EditLabResult />}
                  restricted={['swabber']}
                />
                <ProtectedRoute
                  path="/profile/:id"
                  exact
                  component={() => <ProfilePage />}
                />
                <ProtectedRoute
                  path="/profile/:id/:connectionId"
                  exact
                  component={() => <ProfilePage />}
                />
                <ProtectedRoute
                  path="/connection/profile/:id"
                  exact
                  component={() => <ProfilePage />}
                />
                {/* {hasCms && ( */}
                <ProtectedRoute
                  path="/cms/content"
                  exact
                  component={() => <Cms />}
                />
                {/* )} */}
                {/* {hasCms && ( */}
                <ProtectedRoute
                  path="/cms/content/:id"
                  component={() => <CmsContentView />}
                />
                {/* )} */}
                {hasCms && (
                  <ProtectedRoute
                    path="/cms/management"
                    component={() => <CmsManagement />}
                  />
                )}
                {/* {hasCreateAccount && ( */}
                <ProtectedRoute
                  path="/create-account"
                  exact
                  component={() => <CreateAccount />}
                />
                {/* )} */}
                <Redirect to="/" />
              </Switch>
            </PageWrapper>
          </Switch>
        </Suspense>
      )}
    </>
  );
};
