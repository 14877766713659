import gql from 'graphql-tag';

export const getCourseByClientId = gql`
  query coursesByClientId(
    $clientId: ID!
    $isCompleted: Boolean!
    $startAfter: String
    $indexBefore: Boolean
    $filter: String
    $filterValue: String
    $noLimit: Boolean
  ) {
    coursesByClientId(
      clientId: $clientId
      isCompleted: $isCompleted
      startAfter: $startAfter
      indexBefore: $indexBefore
      filter: $filter
      filterValue: $filterValue
      noLimit: $noLimit
    ) {
      id
      name
      description
      type
      courseImage
      category
      startDate
      startTime
      country
      isCompleted
      endDate
      endTime
      instructorId
      durationDays
      durationTime
      street
      zip
      city
      participantsCount
      waitingCount
    }
  }
`;

export const getCourseByCenterId = gql`
  query coursesByCenterId(
    $centerId: ID!
    $instructorId: ID
    $isCompleted: Boolean
    $startAfter: String
    $indexBefore: Boolean
    $filter: String
    $filterValue: String
    $noLimit: Boolean
  ) {
    coursesByCenterId(
      centerId: $centerId
      instructorId: $instructorId
      isCompleted: $isCompleted
      startAfter: $startAfter
      indexBefore: $indexBefore
      filter: $filter
      filterValue: $filterValue
      noLimit: $noLimit
    ) {
      id
      name
      description
      type
      courseImage
      category
      startDate
      startTime
      country
      isCompleted
      endDate
      endTime
      instructorId
      durationDays
      durationTime
      street
      zip
      city
      participantsCount
      waitingCount
    }
  }
`;

export const getCoursesByInstructorId = gql`
  query coursesByInstructorId($instructorId: ID!) {
    coursesByInstructorId(instructorId: $instructorId) {
      id
      name
      description
      type
      courseImage
      startDate
      startTime
      country
      category
      isCompleted
      endDate
      endTime
      instructorId
      durationDays
      durationTime
      street
      zip
      city
      participantsCount
      waitingCount
    }
  }
`;

export const searchCourses = gql`
  query searchCourses(
    $query: String!
    $clientId: ID
    $centerId: ID
    $instructorId: ID
    $isCompleted: Boolean!
    $filter: String
    $filterValue: String
    $startDate: String
    $pageSize: Int
  ) {
    searchCourses(
      query: $query
      clientId: $clientId
      centerId: $centerId
      instructorId: $instructorId
      isCompleted: $isCompleted
      filter: $filter
      filterValue: $filterValue
      startDate: $startDate
      pageSize: $pageSize
    ) {
      id
      name
      tags
      description
      createdAt
      type
      category
      language
      timezone
      price
      courseImage
      currency
      advancementLevel
      startDate
      startTime
      country
      durationDays
      durationTime
      isCompleted
      endDate
      instructorId
      waitingCount
      participantsCount
      seatsLeft
      street
      zip
      city
    }
  }
`;

export const getCoursesByAssistantId = gql`
  query coursesByAssistantId($assistantId: ID!) {
    coursesByAssistantId(assistantId: $assistantId) {
      id
      name
      startDate
      endDate
      startTime
      endTime
      country
    }
  }
`;

export const coursesByUser = gql`
  query coursesByUser($userId: String!) {
    coursesByUser(userId: $userId) {
      id
      name
      startDate
      endDate
      startTime
      endTime
      country
    }
  }
`;

export const getClientCourseLength = gql`
  query getClientCourseLength(
    $clientId: ID
    $centerId: ID
    $instructorId: ID
    $isCompleted: Boolean!
    $filter: String
    $filterValue: String
  ) {
    getClientCourseLength(
      clientId: $clientId
      centerId: $centerId
      instructorId: $instructorId
      isCompleted: $isCompleted
      filter: $filter
      filterValue: $filterValue
    ) {
      length
    }
  }
`;

export const getCourse = gql`
  query course($id: ID!) {
    course(id: $id) {
      id
      certTemplateId
      name
      subName
      tags
      description
      preRequisites
      clientId
      userId
      type
      category
      subCategory
      industry
      language
      timezone
      validity
      advancementLevel
      price
      courseImage
      currency
      industryStandard
      targetGroup
      instructorId
      assistantId
      durationTime
      durationDays
      street
      zip
      city
      country
      geoLocation
      startTime
      endTime
      startDate
      endDate
      minParticipants
      maxParticipants
      isCompleted
      purpose
      publishToWeb
      waitingCount
      days {
        date
        startTime
        endTime
      }
    }
  }
`;

export const getCourseParticipants = gql`
  query courseParticipants($courseId: ID!, $status: String) {
    courseParticipants(courseId: $courseId, status: $status) {
      id
      name
      firstName
      lastName
      email
      midId
      passed
      ssn
      failReason
      phone
      completed
      dateOfBirth
      dateOfBirth
      bookFirstName
      bookLastName
      bookEmail
      bookPhone
      bookAddress
      bookCity
      bookVatNo
      bookZip
    }
  }
`;

export const getClientParticipants = gql`
  query clientParticipants($clientId: ID!, $centerId: ID) {
    clientParticipants(clientId: $clientId, centerId: $centerId) {
      id
      name
      firstName
      lastName
      email
      midId
      ssn
      courseName
      courseStartDate
      courseEndDate
      courseId
      courseStartTime
      category
      status
      bookCompany
    }
  }
`;

export const getCourseTitles = gql`
  query courseTitles($clientId: ID!) {
    courseTitles(clientId: $clientId) {
      id
      name
    }
  }
`;

export const courseParticipantsCount = gql`
  query courseParticipantsCount($clientId: ID!, $centerId: ID) {
    courseParticipantsCount(clientId: $clientId, centerId: $centerId)
  }
`;

export const getCompletedCourseCount = gql`
  query getCompletedCourseCount($clientId: ID!, $centerId: ID) {
    getCompletedCourseCount(clientId: $clientId, centerId: $centerId)
  }
`;

export const getCourseByDate = gql`
  query getCourseByDate($clientId: ID!, $startDate: String, $endDate: String) {
    getCourseByDate(
      clientId: $clientId
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      certTemplateId
      name
      subName
      tags
      description
      preRequisites
      clientId
      userId
      type
      category
      subCategory
      industry
      language
      timezone
      validity
      advancementLevel
      price
      courseImage
      currency
      industryStandard
      targetGroup
      instructorId
      assistantId
      durationTime
      durationDays
      street
      zip
      city
      country
      geoLocation
      startTime
      endTime
      startDate
      endDate
      minParticipants
      maxParticipants
      isCompleted
      purpose
      publishToWeb
      waitingCount
      days {
        date
        startTime
        endTime
      }
    }
  }
`;

export const getCoursesWithWaitingList = gql`
  query getCoursesWithWaitingList($clientId: ID!) {
    getCoursesWithWaitingList(clientId: $clientId) {
      id
      certTemplateId
      name
      subName
      tags
      description
      preRequisites
      clientId
      userId
      type
      category
      subCategory
      industry
      language
      timezone
      validity
      advancementLevel
      price
      courseImage
      currency
      industryStandard
      targetGroup
      instructorId
      assistantId
      durationTime
      durationDays
      street
      zip
      city
      country
      geoLocation
      startTime
      endTime
      startDate
      endDate
      minParticipants
      maxParticipants
      isCompleted
      purpose
      publishToWeb
      waitingCount
      days {
        date
        startTime
        endTime
      }
    }
  }
`;

export const getCourseImages = gql`
  query getCourseImages($clientId: ID!) {
    getCourseImages(clientId: $clientId) {
      id
      imageId
    }
  }
`;
