import React, { useState, useEffect } from 'react';
import { Loader, Popup, Dropdown } from 'semantic-ui-react';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { Card, Text, Icon } from '../../index';
import history from '../../../history';

import './courseItem.scss';
import { toLocalTime } from 'utils';

const CourseItem = ({
  course,
  handleOnEdit,
  handleOnDelete,
  handleOnView,
  onClick,
  selectable,
  fullDetails,
  archive = false,
}) => {
  const [dropDownLoading, setDropDownLoading] = useState(false);
  const [isELearning, setIsELearning] = useState(false);
  const userInfo = useSelector(state => state.general.user);

  useEffect(() => {
    if (course && course.type) {
      course.type.forEach(type => {
        if (type === 'e-learning') setIsELearning(true);
        if (type === 'E-learning') setIsELearning(true);
      });
    }
  });

  const deleteTemplate = async () => {
    setDropDownLoading(true);
    await handleOnDelete(course);
    setDropDownLoading(false);
  };

  const editTemplate = async () => {
    handleOnEdit(course);
  };

  const onCourseClick = () => {
    if (!onClick) return undefined;
    onClick(course);
  };

  const onInstructorClick = () => {
    if (!course.instructorId) return;
    if (userInfo.role !== 'superAdmin' && userInfo.id !== course.instructorId)
      return;
    history.push(`/entities/instructor/overview/${course.instructorId}`);
  };

  const getDuration = () => {
    if (course.durationTime && !course.durationDays) {
      if (course.durationTime > 1) return `${course.durationTime} hours`;
      return `${course.durationTime} hour`;
    }

    if (course.durationDays && !course.durationTime) {
      if (course.durationDays > 1) return `${course.durationDays} days`;
      return `${course.durationDays} day`;
    }

    return `${course.durationDays} days and ${course.durationTime} hours`;
  };

  return (
    <div
      className={
        !selectable ? 'course-item' : 'course-item course-item--selectable'
      }
      key={course.id || ''}
    >
      <Card>
        <div
          className="course-item__content"
          onClick={!fullDetails ? onCourseClick : undefined}
        >
          <div
            className="course-item__image"
            onClick={fullDetails ? onCourseClick : undefined}
          >
            <img src={course.courseImage} alt="course" />
            <Text
              className="course-item__image__text"
              dark
              size="small"
              color="white"
              style={{ margin: 0 }}
            >
              {course.name}
            </Text>
            <div className="course-item__image__overlay" />
          </div>
          {(handleOnEdit || handleOnDelete) && (
            <div className="course-item__content__dropdown">
              <Dropdown
                direction="left"
                disabled={dropDownLoading}
                trigger={
                  <div className="course-item__content__dropdown-icon">
                    {dropDownLoading ? (
                      <Loader size="small" active />
                    ) : (
                      <Icon name="more" fontSize="1.5em" />
                    )}
                  </div>
                }
                icon={null}
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={editTemplate}
                    icon="edit"
                    text="Edit"
                  />
                  <Dropdown.Item
                    onClick={deleteTemplate}
                    icon="trash"
                    text="Delete"
                  />
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
          <div className="course-item__data">
            <div className="course-item__data__top">
              {fullDetails && (
                <div
                  className={
                    course.instructorId
                      ? 'course-item__data__info course-item__data__info--selectable'
                      : 'course-item__data__info'
                  }
                >
                  <Text
                    size="tiny"
                    color="lightGrey"
                    className="course-item__data__info__header"
                  >
                    Assigned instructor
                  </Text>
                  <div onClick={() => onInstructorClick()}>
                    <Text size="small" bold color="black">
                      {!course.instructorId
                        ? 'None assigned'
                        : course.instructor}
                    </Text>
                    {course.instructor && (
                      <Text size="tinniest" color="lightGrey" lowercase>
                        @{course.instructor.replace(' ', '.')}
                      </Text>
                    )}
                  </div>
                </div>
              )}

              <div className="course-item__data__info">
                <Text
                  className="course-item__data__info__header"
                  size="tiny"
                  color="lightGrey"
                >
                  Type
                </Text>
                <div className="course-item__data__info__multi-wrapper">
                  {course.type &&
                    course.type.map((type, index) => (
                      <Text
                        size="small"
                        bold
                        className="course-item__data__info__multi"
                        color="black"
                        uppercase
                      >
                        {course.type.length > 0 &&
                        index !== course.type.length - 1
                          ? type + ', '
                          : type}
                      </Text>
                    ))}
                </div>
              </div>
              {!fullDetails && (
                <>
                  <div className="course-item__data__info">
                    <Text
                      className="course-item__data__info__header"
                      size="tiny"
                      color="lightGrey"
                    >
                      Language
                    </Text>
                    <Text size="small" bold color="black">
                      {course.language}
                    </Text>
                  </div>
                  <div className="course-item__data__info">
                    <Text
                      className="course-item__data__info__header"
                      size="tiny"
                      color="lightGrey"
                    >
                      Price
                    </Text>
                    <Text size="small" bold color="black">
                      {course.price} {course.currency}
                    </Text>
                  </div>
                </>
              )}
              {fullDetails && (
                <div className="course-item__data__info">
                  <Text
                    size="tiny"
                    color="lightGrey"
                    className="course-item__data__info__header"
                  >
                    Participants
                  </Text>
                  <Text size="small" bold color="black">
                    {course.participantsCount || 0}
                  </Text>
                </div>
              )}
            </div>
            {!fullDetails && course.description && (
              <div className="course-item__data__description">
                <Text size="small">
                  {course.description.substring(0, 250)}
                  {course.description.length > 250 && '...'}
                </Text>
              </div>
            )}
            {fullDetails && (
              <div className="course-item__data__bottom">
                <div className="course-item__data__info">
                  <Text
                    size="tiny"
                    color="lightGrey"
                    className="course-item__data__info__header"
                  >
                    Date
                  </Text>
                  <Text size="small" color="black">
                    {toLocalTime(
                      course.startDate,
                      'DD.MM.YYYY',
                      course.timezone
                    )}
                  </Text>
                </div>
                <div className="course-item__data__info">
                  <Text
                    size="tiny"
                    color="lightGrey"
                    className="course-item__data__info__header"
                  >
                    Duration
                  </Text>
                  <Text size="tiny" color="black">
                    {getDuration()}
                  </Text>
                </div>
                <div className="course-item__data__info">
                  <Text
                    size="tiny"
                    color="lightGrey"
                    className="course-item__data__info__header"
                  >
                    Location
                  </Text>
                  <Text size="tiny" color="black">
                    {isELearning
                      ? 'E-learning'
                      : `${course.street}, ${course.zip}, ${course.city}, ${course.country}`}
                  </Text>
                </div>
              </div>
            )}

            {toLocalTime(course.startDate) === moment().format('DD-MM-YYYY') &&
              moment().isBefore(
                toLocalTime(course.endDate, 'YYYY-MM-DD') +
                  `, ${course.endTime}`
              ) &&
              !archive && (
                <Popup
                  content="Check-in is ready"
                  key={course.id}
                  position="top right"
                  trigger={
                    <div className="course-item__data__icon">
                      <Icon name="info" fontSize="20px" color="#19b970" />
                    </div>
                  }
                />
              )}
            {moment().isAfter(
              toLocalTime(course.endDate, 'YYYY-MM-DD') + `, ${course.endTime}`
            ) &&
              !archive && (
                <Popup
                  content="Course is ready to be completed"
                  key={course.id}
                  position="top right"
                  trigger={
                    <div className="course-item__data__icon">
                      <Icon name="info" fontSize="20px" color="#f38753" />
                    </div>
                  }
                />
              )}
            {moment().isBefore(
              toLocalTime(course.endDate, 'YYYY-MM-DD') + `, ${course.endTime}`
            ) &&
              !course.instructorId && (
                <Popup
                  content="No instructor assigned to course"
                  key={course.id}
                  position="top right"
                  trigger={
                    <div className="course-item__data__icon">
                      <Icon name="info" fontSize="20px" color="#ff1f1f" />
                    </div>
                  }
                />
              )}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default CourseItem;
