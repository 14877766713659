import { Text } from 'components';
import React from 'react';
import DatePicker from 'react-date-picker';
import styled from 'styled-components';

import './datePicker.scss';

// We need to upgrade react-date-picker regarding this issue:
// https://github.com/wojtekmaj/react-date-picker/issues/387
const Date = props => {
  return (
    <Wrapper>
      <DatePicker
        className={props.className}
        onChange={props.onChange}
        value={props.value}
        format="dd-MM-yyyy"
        calendarIcon={null}
        disabled={props.disabled}
        minDate={props.minDate}
        dayPlaceholder={props.dayPlaceholder}
        monthPlaceholder={props.monthPlaceholder}
        yearPlaceholder={props.yearPlaceholder}
      />
      {props.invalid && (
        <Text className="input-error-text" color="red" size="tiny">
          {props.errorMessage || 'Required field'}
        </Text>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
`;
export default Date;
