import { apolloClient } from '../apollo/apollo';
import {
  searchAppointments,
  appointmentsByClientId,
  appointmentsByCenterId,
  appointmentsByPhysicianId,
  appointment,
  getAppointmentsByDate,
  getClientAppointmentsLength,
  getClientPatients,
  patientAppointments,
  getCompletedAppointmentsCount,
  patientsCount,
} from '../graphql/queries/appointments';
import {
  completeAppointment,
  completeFftAppointment,
  completePemeAppointment,
  createAppointment,
  updateAppointment,
  cancelAppointment,
} from '../graphql/mutations/appointments';

export default {
  async appointmentsByClientId(
    clientId,
    isCompleted,
    startAfter = '0',
    indexBefore,
    noLimit
  ) {
    return apolloClient.query({
      query: appointmentsByClientId,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables: {
        clientId,
        isCompleted,
        startAfter,
        indexBefore,
        noLimit,
      },
    });
  },

  async appointmentsByCenter(
    centerId,
    physicianId,
    isCompleted,
    startAfter = '0',
    indexBefore,
    noLimit
  ) {
    return apolloClient.query({
      query: appointmentsByCenterId,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables: {
        centerId,
        physicianId,
        isCompleted,
        startAfter,
        indexBefore,
        noLimit,
      },
    });
  },

  async getClientAppointmentsLength({
    clientId,
    centerId,
    instructorId,
    isCompleted,
  }) {
    return apolloClient.query({
      query: getClientAppointmentsLength,
      fetchPolicy: 'no-cache',
      variables: {
        clientId,
        centerId,
        instructorId,
        isCompleted,
      },
    });
  },

  async appointmentsByPhysicianId(physicianId) {
    return apolloClient.query({
      query: appointmentsByPhysicianId,
      errorPolicy: 'all',
      variables: {
        physicianId,
      },
    });
  },

  async patientAppointments(id, clientId, centerId) {
    return apolloClient.query({
      query: patientAppointments,
      errorPolicy: 'all',
      variables: {
        id,
        clientId,
        centerId,
      },
    });
  },

  searchAppointments({
    query,
    clientId,
    centerId,
    physicianId,
    isCompleted,
    startDate,
  }) {
    return apolloClient.watchQuery({
      query: searchAppointments,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables: {
        query,
        clientId,
        centerId,
        physicianId,
        isCompleted,
        startDate,
      },
    });
  },

  async appointment(id) {
    return apolloClient.query({
      query: appointment,
      fetchPolicy: 'no-cache',
      variables: {
        id,
      },
    });
  },

  async completeAppointment(payload) {
    return apolloClient.query({
      query: completeAppointment,
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },

  async completeFftAppointment(payload) {
    return apolloClient.query({
      query: completeFftAppointment,
      fetchPolicy: 'no-cache',
      variables: payload,
    });
  },

  async completePemeAppointment(payload) {
    return apolloClient.query({
      query: completePemeAppointment,
      fetchPolicy: 'no-cache',
      variables: { ...payload },
    });
  },

  async createAppointment(appointment) {
    return apolloClient.mutate({
      mutation: createAppointment,
      variables: {
        ...appointment,
        sName: appointment.name.toLowerCase(),
      },
    });
  },

  async updateAppointment(appointment) {
    return apolloClient.mutate({
      mutation: updateAppointment,
      variables: {
        ...appointment,
      },
    });
  },

  async cancelAppointment(appointmentId) {
    return apolloClient.mutate({
      mutation: cancelAppointment,
      variables: {
        id: appointmentId,
      },
    });
  },

  async getClientPatients(clientId, centerId) {
    return apolloClient.query({
      query: getClientPatients,
      fetchPolicy: 'no-cache',
      variables: {
        clientId,
        centerId,
      },
    });
  },

  async patientsCount(clientId, centerId) {
    return apolloClient.query({
      query: patientsCount,
      fetchPolicy: 'no-cache',
      variables: {
        clientId,
        centerId,
      },
    });
  },

  async getCompletedAppointmentsCount(clientId, centerId) {
    return apolloClient.query({
      query: getCompletedAppointmentsCount,
      variables: {
        clientId,
        centerId,
      },
    });
  },

  async getAppointmentsByDate(clientId, startDate, endDate) {
    return apolloClient.query({
      query: getAppointmentsByDate,
      variables: {
        clientId,
        startDate,
        endDate,
      },
    });
  },
};
