import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { Icon, Popup } from 'semantic-ui-react';
import CircleLoader from 'react-spinners/CircleLoader';

import { ErrorToast, PageHeader, TextArea, Button, Text } from 'components';
import ClientService from 'api/clients';
import { setClientInformation } from 'redux/generalState.actions';

import './emailSettings.scss';

const EmailSettings = props => {
  const dispatch = useDispatch();

  const clientInfo = useSelector(state => state.general.clientInformation);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [labResText, setLabResText] = useState('');
  const [extraText, setExtraText] = useState('');

  useEffect(() => {
    setExtraText(clientInfo?.customText?.additionalText);
    setLabResText(clientInfo?.customText?.labResultText);
  }, [clientInfo]);

  const updateClient = async () => {
    setIsSaving(true);
    const oldCI = { ...clientInfo };
    const payload = {
      customText: {
        additionalText: extraText,
        labResultText: labResText,
      },
    };

    try {
      dispatch(
        setClientInformation({
          ...clientInfo,
          ...payload,
        })
      );
      await ClientService.updateClientByID({
        clientId: clientInfo.id,
        ...payload,
      });
    } catch (error) {
      dispatch(setClientInformation({ ...oldCI }));
      toast.error(<ErrorToast error={error} />);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="email-settings">
      <div className="email-settings__header mb-4">
        <Text type="pageHeader">Email Settings</Text>
      </div>

      {isLoading ? (
        <div className="email-settings__loader">
          <CircleLoader color="#455af7" />
        </div>
      ) : (
        <div className="email-settings__content">
          <div className="email-settings__content__side-bar">
            <TextArea
              label="Additional Info Text"
              placeholder="Additional info text"
              style={{ minHeight: 100 }}
              onChange={e => setExtraText(e.target.value)}
              value={extraText}
            />
            <div style={{ position: 'relative', marginTop: '15px' }}>
              <Popup
                content="Only added to the email if there are lab results attached"
                key={0}
                position="top right"
                trigger={
                  <div
                    style={{
                      position: 'absolute',
                      top: '0px',
                      right: '6px',
                      zIndex: 1,
                    }}
                  >
                    <Icon name="info" fontSize="17px" />
                  </div>
                }
              />
              <TextArea
                label="Lab Result Text"
                placeholder="Text related to the lab results attached to the email"
                style={{
                  minHeight: 100,
                }}
                onChange={e => setLabResText(e.target.value)}
                value={labResText}
              />
            </div>
            <Button
              color="blue"
              isLoading={isSaving}
              onClick={updateClient}
              size="fullWidth"
              className="mt-3"
            >
              Save
            </Button>
          </div>
          <div className="email-settings__content__email-wrapper">
            <Text type="label">Sample Email Text</Text>
            <div className="email-settings__content__email">
              <Text size="small">Dear 'Patient Name'</Text>
              <Text size="small" style={{ marginTop: '15px' }}>
                To access your HealthCert click on the link below:
                <Text size="small" color="lightBlue">
                  https://medical.delta.riverr.ai/certificate/123456789
                </Text>
              </Text>
              <Text size="small">
                The document is password protected, use your date of birth as
                DDMMYY to gain access.
              </Text>
              <Text size="small" bold style={{ marginTop: '15px' }}>
                Important! Verify all information on the document is correct, if
                not contact your clinic.
              </Text>
              {labResText && (
                <Text size="small" style={{ marginTop: '15px' }}>
                  Attached is your lab result this document was based on.{' '}
                  {labResText}
                </Text>
              )}
              {extraText && (
                <Text size="small" style={{ marginTop: '15px' }}>
                  {extraText}
                </Text>
              )}
              <Text size="small" style={{ marginTop: '15px' }}>
                The document has been notarised and endorsed on your behalf, you
                do not need to submit the OA file to GovTech. You can download
                the file at the top right-hand corner, print it or take a
                screenshot for presentation when you check-in. View more
                information on HealthCerts here:{' '}
                <Text size="small" color="lightBlue">
                  https://www.healthcerts.gov.sg
                </Text>
              </Text>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(EmailSettings);
