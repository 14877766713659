import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Text } from '.';
import { ChromePicker } from 'react-color';
import colors from '../styles/colors';

const InputComponent = props => {
  const [colorPickerVisible, setColorPickerVisible] = useState(false);

  const handleColorChange = color => {
    props.onChange && props.onChange(color.hex);
  };

  return (
    <InputWrapper
      size={props.size}
      style={props.style}
      className={props.className}
    >
      {props.label && <Text type="label">{props.label}</Text>}
      {props.optional && (
        <Text size="tinniest" color="blue" className="input__optional-label">
          {props.optionalLabel || 'optional'}
        </Text>
      )}
      <InputGroup>
        <Input
          onClick={props.onClick}
          center={props.center}
          placeholder={props.placeholder}
          onChange={e => props.onChange && props.onChange(e.target.value)}
          value={props.value}
          disabled={props.disabled}
          invalid={props.invalid}
          onKeyDown={props.onKeyDown}
          autoComplete={props.autoComplete}
          errorMessage={props.errorMessage}
          onBlur={props.onBlur}
          style={props.inputStyle}
          max={props.max}
          min={props.min}
          pattern={props.pattern}
          step={props.step}
          onKeyPress={props.onKeyPress}
          border={!!props.border}
        />
        <ColorPreview
          onClick={() => setColorPickerVisible(true)}
          color={props.value}
        />
        {colorPickerVisible && (
          <PickerPopover>
            <PickerCover onClick={() => setColorPickerVisible(false)} />
            <ChromePicker
              onChangeComplete={handleColorChange}
              onChange={handleColorChange}
              color={props.value}
            />
          </PickerPopover>
        )}
      </InputGroup>
      {props.invalid && props.showErrorMessage && (
        <Text className="input-error-text" color="red" size="tiny">
          {props.errorMessage || 'Required field'}
        </Text>
      )}
    </InputWrapper>
  );
};

InputComponent.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
};

InputComponent.defaultProps = {
  size: 'medium',
  onChange: () => {},
  showErrorMessage: true,
};

const getSize = size => {
  if (size === 'tiny') return { width: '50px' };
  if (size === 'small') return { width: '150px' };
  if (size === 'medium') return { width: '300px' };
  if (size === 'large') return { width: '400px' };
  if (size === 'fullWidth') return { width: '100%' };
};

const getBorder = (border, invalid) => {
  if (border) {
    if (invalid)
      return `border: solid 1px #ff5252 !important; border-radius: 4px; padding: 10px; height: 45px;`;
    return `border: solid 1px ${colors.GREY}; border-radius: 4px; padding: 10px; height: 45px;`;
  }

  if (invalid) return `border-bottom: solid 1px #ff5252; border-radius: 0;`;
  return `border-bottom: solid 1px #e8e8e8; border-radius: 0;`;
};

const getBorderHighlight = border => {
  if (border)
    return `outline: none; border: solid 1px ${colors.HIGHLIGHT_BLUE};`;

  return `outline: none; border-bottom: solid 1px ${colors.HIGHLIGHT_BLUE};`;
};

const getDisabledStyles = border => {
  if (border) return 'background-color: #fafafa; border: solid 1px #bdbdbd;';

  return 'background-color: #fafafa;';
};

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${props => getSize(props.size)}
  position: relative;
  .input__optional-label {
    position: absolute;
    right: 0;
    top: 5px;
  }
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const Input = styled.input`
  border: none;
  height: 35px;
  outline-width: 0;
  font-size: 17px;
  color: ${colors.DARK_GREY};
  text-align: ${props => props.center && 'center'};
  font-weight: 200;
  background: transparent;
  ${props => getBorder(props.border, props.invalid)}

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  ::placeholder {
    color: ${colors.DARK_GREY};
    opacity: 0.6;
    transition: all 0.3s ease;
    font-weight: 200;
    font-size: 16px;
  }

  :-ms-input-placeholder {
    color: ${colors.GREY};
  }

  ::-ms-input-placeholder {
    color: ${colors.GREY};
  }

  &:focus {
    ${props => getBorderHighlight(props.border)}
    ::placeholder {
      color: ${colors.GREY};
      opacity: 1;
    }

    :-ms-input-placeholder {
      color: ${colors.DARK_GREY};
    }

    ::-ms-input-placeholder {
      color: ${colors.DARK_GREY};
    }
  }

  &:disabled {
    ${props => getDisabledStyles(props.border)}
  }
`;

const ColorPreview = styled.div`
  background-color: ${props => props.color || ''};
  border-radius: 4px;
  border: solid 1px #bdbdbd !important;
  width: 100px;
  border-bottom: none;
  height: 45px;
  padding: 0;
  &:hover {
    cursor: pointer;
  }
`;

const PickerPopover = styled.div`
  position: absolute;
  z-index: 2;
`;

const PickerCover = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export default InputComponent;
