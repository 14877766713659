import React, { memo } from 'react';
import './bitAiUserGuide.scss';

const BitAiUserGuide = () => {
  return (
    <div className="user-guide">
      <iframe
        title="User Guide"
        src={'https://riverr.bit.ai/docs/embed/VOBV4gyDCpWDgsej'}
        frameBorder="0"
      />
      {/* <div id="bitdoc-embed"></div>
      <script
        type="text/javascript"
        src="https://pym.nprapps.org/pym.v1.min.js"
      ></script>
      <script>
        var pymParent = new pym.Parent('bitdoc-embed',
        'https://riverr.bit.ai/docs/embed/VOBV4gyDCpWDgsej', {});
      </script> */}
    </div>
  );
};

export default BitAiUserGuide;
