import './sideBar.scss';

import { Icon, SideBarMenu, Text } from '../../components';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import classNames from 'classnames';
import { useClient } from 'hooks';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

const SideBar = props => {
  const sidebarRef = useRef();
  // const sidebarToggleButtonRef = useRef();
  const subMenuRef = useRef();

  const [isSidebarToggled, setIsSidebarToggled] = useState(false);
  const [selectedTab, setSelectedTab] = useState('/dashboard');
  const [subMenuItems, setSubMenuItems] = useState(false);
  const { hasCms, hasCreateAccount } = useClient();

  const userInfo = useSelector(state => state.general.user);
  const clientInformation = useSelector(
    state => state.general.clientInformation
  );

  const stateOfSideBar = useSelector(state => state.changeBarState);

  // const toggleSidebar = e => {
  //   setIsSidebarToggled(!isSidebarToggled);
  // };

  useEffect(() => {
    const getSelectedTab = () => {
      const splitPath = props.location.pathname.split('/');
      if (splitPath[1] === 'dashboard') return '/dashboard';
      if (splitPath[1] === 'insights') return '/insights';
      if (splitPath[1] === 'calendar') return '/calendar';
      if (splitPath[1] === 'cms') return '/cms';
      if (splitPath[1] === 'certificates') return '/certificates';
      if (splitPath[1] === 'course') return '/course/planned';
    };
    setSelectedTab(getSelectedTab());

    const handleClick = e => {
      if (
        sidebarRef.current.contains(e.target) ||
        // sidebarToggleButtonRef.current.contains(e.target) ||
        (subMenuRef.current && subMenuRef.current.contains(e.target))
      ) {
        return;
      }
      setIsSidebarToggled(false);
    };
    // add when mounted
    document.addEventListener('mousedown', handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []); // eslint-disable-line

  const navigationItems = [
    {
      title: 'Dashboard',
      icon: 'dashboard',
      urlKey: '/dashboard',
    },
    // {
    //   title: 'Insights',
    //   icon: 'barChart',
    //   urlKey: '/insights',
    // },
    // {
    //   title: 'Calendar',
    //   icon: 'calendar',
    //   urlKey: '/calendar',
    // },
    {
      title: 'Calendar',
      icon: 'calendar',
      urlKey: '/calendar',
      clientRole: { full: true },
      type: 'maritime',
    },
    {
      title: 'People',
      icon: 'profile',
      urlKey: '/entities',
      redirect: false,
      clientRole: { full: true },
      type: 'maritime',
      subMenu: [
        {
          icon: 'user outline',
          label: 'Instructors',
          path: '/entities/instructor',
          role: {
            superAdmin: true,
            trainingAdmin: true,
            instructor: true,
          },
          type: 'maritime',
        },
        {
          icon: 'audience',
          label: 'Participants',
          path: '/entities/participants',
          role: { superAdmin: true, trainingAdmin: true, clinicAdmin: true },
          localIcon: true,
          type: 'maritime',
        },
      ],
    },
    {
      title: 'Courses',
      icon: 'courses',
      urlKey: '/course',
      redirect: false,
      clientRole: { full: true },
      type: 'maritime',
      subMenu: [
        // {
        //   icon: 'plus',
        //   label: 'Planned courses',
        //   path: '/course/planned',
        // },
        {
          icon: 'plus',
          label: 'Schedule a course',
          path: '/course/create',
          role: {
            superAdmin: true,
            trainingAdmin: true,
            instructor: true,
          },
          type: 'maritime',
        },
        {
          icon: 'calendar outline',
          label: 'Planned courses',
          path: '/course/planned',
          type: 'maritime',
        },
        {
          icon: 'file archive outline',
          label: 'Course archive',
          path: '/course/archive',
          type: 'maritime',
        },
      ],
    },
    // {
    //   title: 'webshop',
    //   icon: 'courses',
    //   urlKey: '/appointment',
    //   redirect: false,
    //   clientRole: { full: true },
    //   type: 'medical',
    //   subMenu: [
    //     {
    //       icon: 'plus',
    //       label: 'New appointment',
    //       path: '/appointment/create',
    //       role: { superAdmin: true, trainingAdmin: true, clinicAdmin: true },
    //       type: 'medical',
    //     },
    //     {
    //       icon: 'calendar outline',
    //       label: 'Scheduled webshop',
    //       path: '/appointment/planned',
    //       type: 'medical',
    //     },
    //     {
    //       icon: 'file archive outline',
    //       label: 'Appointment archive',
    //       path: '/appointment/archive',
    //       type: 'medical',
    //     },
    //   ],
    // },
    {
      title: 'Entities',
      icon: 'profile',
      urlKey: '/entities',
      redirect: false,
      type: 'medical',
      clientRole: { full: true, lab: true },
      role: {
        superAdmin: true,
        trainingAdmin: true,
        clinicAdmin: true,
        instructor: true,
        appointmentHandler: false,
      },
      subMenu: [
        {
          label: 'Staff',
          path: '/entities/staff',
          localIcon: true,
          //icon: to_add
        },
        {
          label: 'Patients',
          path: '/entities/patients',
          localIcon: true,
          //icon: to_add
        },
      ],
    },
    {
      title: 'Certificates',
      icon: 'shieldPlus',
      urlKey: '/certificates',
      redirect: false,
      role: { superAdmin: true, trainingAdmin: true, clinicAdmin: true },
      clientRole: { full: true },
      type: 'maritime',
      subMenu: [
        {
          icon: 'plus',
          label: 'New certificate',
          path: '/certificates/issue',
          role: { superAdmin: true },
          type: 'maritime',
        },
        {
          icon: 'file outline',
          label: 'Issued certificates',
          path: '/certificates/issued',
          type: 'maritime',
        },
      ],
    },
    {
      title: 'Templates',
      icon: 'templates',
      urlKey: '/template',
      redirect: false,
      type: 'maritime',
      role: { superAdmin: true },
      clientRole: { full: true, templateManager: true },
      subMenu: [
        {
          icon: 'gradHat',
          label: 'Course templates',
          path: '/template/course',
          localIcon: true,
        },
        {
          icon: 'certificate',
          label: 'Certificate templates',
          path: '/template/certificate',
          localIcon: true,
        },
      ],
    },
    {
      title: 'Documents',
      icon: 'shieldPlus',
      urlKey: '/documents',
      redirect: false,
      role: {
        superAdmin: true,
        trainingAdmin: true,
        clinicAdmin: true,
        instructor: true,
        appointmentHandler: false,
      },
      clientRole: { full: true, lab: true },
      type: 'medical',
      subMenu: [
        {
          icon: 'plus',
          label: 'New document',
          path: '/documents/issue',
        },
        {
          icon: 'file outline',
          label: 'Issued documents',
          path: '/documents/issued',
        },
        {
          icon: 'file outline',
          label: 'Lab Results',
          path: '/documents/lab',
        },
        {
          icon: 'certificate',
          label: 'Document templates',
          path: '/documents/templates',
          localIcon: true,
        },
      ],
    },
    {
      title: 'Report ART',
      icon: 'courses',
      urlKey: '/swabber/report',
      role: { swabber: true },
      type: 'medical',
    },
    {
      title: 'Lab Results',
      icon: 'shieldPlus',
      urlKey: '/documents/lab',
      role: { labViewer: true, swabber: true },
      type: 'medical',
    },
    {
      title: 'Connections',
      icon: 'connect',
      urlKey: '/connections',
      redirect: false,
      clientRole: { full: false, corporate: true, lab: false },
      type: 'maritime',
      subMenu: [
        {
          icon: 'globe',
          label: 'Connected users',
          path: '/connections',
        },
        {
          icon: 'file outline',
          label: 'Events overview',
          path: '/connections/event/overview',
        },
      ],
    },
    {
      title: 'Emails',
      icon: 'spacedBarChart',
      urlKey: '/email',
      redirect: false,
      type: 'medical',
      clientRole: { full: true, lab: true },
      role: {
        superAdmin: true,
        clinicAdmin: true,
        instructor: true,
      },
      subMenu: [
        {
          icon: 'puzzle piece',
          label: 'Activity',
          path: '/email/activity',
          role: { superAdmin: true },
        },
        {
          icon: 'users',
          label: 'Settings',
          path: '/email/settings',
        },
      ],
    },
    {
      title: 'Help',
      icon: 'info',
      urlKey: '/user-guide',
      type: 'medical',
    },
    {
      title: 'Settings',
      icon: 'settings',
      urlKey: '/client',
      redirect: false,
      role: { superAdmin: true },
      clientRole: { full: true, templateManager: true },
      type: 'maritime',
      subMenu: [
        {
          icon: 'puzzle piece',
          label: 'Data management',
          path: '/client/data',
          role: { superAdmin: true },
        },
        {
          icon: 'users',
          label: 'Settings',
          path: '/client',
        },
      ],
    },
    {
      title: 'Settings',
      icon: 'settings',
      urlKey: '/client',
      role: { superAdmin: true, appointmentHandler: false },
      clientRole: { full: true, templateManager: true, lab: true },
      type: 'medical',
    },
    {
      title: 'Settings',
      icon: 'settings',
      urlKey: '/client',
      clientRole: { corporate: true, recruitment: true },
    },
  ];

  const containerClassNames = classNames('side-bar__container', {
    'side-bar__container--open': stateOfSideBar.sidebarShow,
    'side-bar__container--closed': !stateOfSideBar.sidebarShow,
  });

  const contentClassNames = classNames('side-bar__item__content', {
    'side-bar__item__content--open': isSidebarToggled,
    'side-bar__item__content--closed': !isSidebarToggled,
  });

  const selectMenuItem = item => {
    item.subMenu && setSubMenuItems(item.subMenu);
    !item.subMenu && setSubMenuItems([]);
    setSelectedTab(item.urlKey || item.path);

    if (typeof item.redirect !== 'undefined') return;
    props.history.push(item.urlKey);
    setSelectedTab(item.urlKey);
  };
  const pathName = props.location.pathname;
  const splitPath = pathName.split('/');

  // TODO: move this to useEffect
  const renderItems = useMemo(() => {
    let items = [];

    if (
      clientInformation?.id === 'zHAzw9fw2H4cGE8udj50' ||
      clientInformation.role === 'lab'
    ) {
      const item = {
        title: 'Analytics',
        icon: 'spacedBarChart',
        redirect: false,
        clientRole: { full: true, lab: true },
        role: {
          appointmentHandler: false,
          superAdmin: true,
        },
        subMenu: [
          {
            icon: 'users',
            label: 'Usage',
            path: '/stats',
          },
          {
            icon: 'puzzle piece',
            label: 'Emails',
            path: '/email/activity',
          },
        ],
      };

      navigationItems.push(item);
    }

    // if (
    //   userInfo?.email?.includes('@riverr.ai') ||
    //   clientInformation?.id === 'zHAzw9fw2H4cGE8udj50'
    // ) {
    //   const item = {
    //     title: 'CMS',
    //     icon: 'calendar',
    //     urlKey: '/cms/content',
    //     clientRole: { full: true, lab: true },
    //     type: 'medical',
    //   };

    //   navigationItems.push(item);
    // }

    if (hasCms) {
      const item = {
        title: 'Deployment',
        icon: 'calendar',
        urlKey: '/cms/management',
        clientRole: { full: true, lab: true },
        type: 'medical',
      };

      navigationItems.push(item);
    }

    if (
      userInfo?.email?.includes('@riverr.ai') ||
      clientInformation?.id === 'zHAzw9fw2H4cGE8udj50'
    ) {
      const item = {
        title: 'Accounts',
        icon: 'settings',
        urlKey: '/create-account',
        clientRole: { full: true, lab: true },
        type: 'medical',
      };

      navigationItems.push(item);
    }

    if (clientInformation.isWebShopClient) {
      const item = {
        title: 'Appointments',
        icon: 'calendar',
        urlKey: '/webshop',
        redirect: false,
        type: 'medical',
        clientRole: { full: true, lab: true },
        role: {
          superAdmin: true,
          clinicAdmin: true,
          instructor: true,
          appointmentHandler: true,
        },
        subMenu: [
          {
            label: 'Overview',
            path: '/webshop/overview',
          },
          {
            label: 'Calendar',
            path: '/webshop/calendar',
          },
          {
            label: 'Statistics',
            path: '/webshop/stats',
            role: {
              superAdmin: true,
              clinicAdmin: true,
              instructor: true,
              appointmentHandler: false,
            },
          },
          {
            label: 'Configuration',
            path: '/webshop/settings',
            role: {
              superAdmin: true,
              clinicAdmin: true,
              instructor: true,
              appointmentHandler: false,
            },
          },
        ],
      };

      navigationItems.splice(8, 0, item);
    }

    navigationItems.forEach((item, index) => {
      const isItemSelected =
        `/${splitPath[1]}` === item.urlKey || pathName === item.urlKey;
      if (
        (!item.role || item.role[userInfo.role]) &&
        (!item.clientRole || item.clientRole[clientInformation.role]) &&
        (!item.type || item.type === clientInformation.type)
      )
        items.push(
          <div
            className={
              isItemSelected
                ? 'side-bar__item side-bar__item--selected'
                : 'side-bar__item'
            }
            key={item.urlKey + index}
            onClick={() => selectMenuItem(item)}
          >
            {isItemSelected && <div className="side-bar__item__highlight" />}
            <div className="side-bar__item__icon-wrapper">
              <Icon
                name={item.icon}
                alt={item.icon}
                height={29}
                width={29}
                color={isItemSelected ? '#4698e7' : '#1b1b1b'}
              />
              <Text className="side-bar__item__title-new" color="grey">
                {item.title}
              </Text>
            </div>
            <div className={contentClassNames}>
              <div className="side-bar__item__title">
                <Text size="small" color="grey">
                  {item.title}
                </Text>
              </div>
            </div>
          </div>
        );
    });

    return items;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    clientInformation?.id,
    clientInformation.role,
    clientInformation.isWebShopClient,
    clientInformation.type,
    hasCreateAccount,
    userInfo?.email,
    userInfo.role,
    hasCms,
    navigationItems,
    splitPath,
    pathName,
    contentClassNames,
  ]);

  return (
    <div className="side-bar">
      <div className={containerClassNames} ref={sidebarRef}>
        <div className="side-bar__content">
          {renderItems}
          {/* <div
            className="side-bar__item side-bar__item--bottom"
            onClick={toggleSidebar}
            ref={sidebarToggleButtonRef}
          >
            {isSidebarToggled ? (
              <Icon
                name="angleLeft"
                style={{ marginRight: '5px' }}
                fontSize="25px"
              />
            ) : (
              <Icon
                name="angleRight"
                style={{ marginRight: '5px' }}
                height={20}
                width={20}
              />
            )}
          </div> */}
        </div>
      </div>
      {subMenuItems.length > 0 && (
        <div className="side-bar__sub-menu">
          <SideBarMenu
            selectMenuItem={selectMenuItem}
            clientType={clientInformation.type}
            role={userInfo.role}
            subMenuRef={subMenuRef}
            sidebarRef={sidebarRef}
            history={props.history}
            items={subMenuItems}
            closeMenu={() => setSubMenuItems([])}
            location={props.location}
            setIsSidebarToggled={setIsSidebarToggled}
          />
        </div>
      )}
    </div>
  );
};

export default withRouter(SideBar);
