export const SET_PROFILE = 'PROFILE/SET_PROFILE';

/**
 * @function setProfile
 * @description Set information of the selected user profile
 */
export const setProfile = profile => ({
  type: SET_PROFILE,
  payload: profile,
});
