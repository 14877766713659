import headerBar from './HeaderBar/HeaderBar';
import sideBar from './SideBar/SideBar';
import loginPreview from './LoginPreview';
import courseLocation from './CourseLocation/CourseLocation';
import courseCreationHeaderButtons from './CourseCreationHeaderButtons/CourseCreationHeaderButtons';

export const HeaderBar = headerBar;
export const SideBar = sideBar;
export const CourseLocation = courseLocation;
export const LoginPreview = loginPreview;
export const CourseCreationHeaderButtons = courseCreationHeaderButtons;
