import { SET_CLIENT_INFO } from './clientDetails.actions';

const initialState = {
  info: {},
  users: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CLIENT_INFO:
      return { ...state, info: action.payload };
    default:
      return state;
  }
};
