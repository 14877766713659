import React, { useState } from 'react';
import { Modal } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import {
  ErrorToast,
  SuccessToast,
  Text,
  Button,
  Input,
  Icon,
} from '../../../components';

import ClientService from '../../../api/clients';

import './addConnectionModal.scss';

const AddConnectionModal = ({ isOpen, toggleModal, status, onComplete }) => {
  const clientId = useSelector(state => state.general.clientInformation.id);

  const [userEmails, setUserEmails] = useState(['']);
  const [isLoading, setIsLoading] = useState(false);

  const addConnections = async () => {
    try {
      setIsLoading(true);

      await ClientService.addConnections(JSON.stringify(userEmails), clientId);
      toast.success(<SuccessToast message="Connection requests sent" />);

      if (status === 'Requested') await onComplete();
      setIsLoading(false);
      toggleModal(false);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
      setIsLoading(false);
    }
  };

  const onEmailChange = (index, value) => {
    const emails = [...userEmails];
    emails[index] = value;
    setUserEmails(emails);
  };

  const onAddConnection = () => {
    const emails = [...userEmails];
    emails.push('');
    setUserEmails(emails);
  };

  const removeEmail = index => {
    const emails = [...userEmails];
    emails.splice(index, 1);
    setUserEmails(emails);
  };

  return (
    <Modal
      className="add-connection"
      open={isOpen}
      onClose={() => toggleModal(false)}
    >
      <Modal.Header>Add new connections</Modal.Header>
      <Text style={{ textAlign: 'center' }}>
        A connection request will be sent to the user the email is associated
        with.
      </Text>
      <Modal.Content className="add-connection__content">
        {userEmails.map((email, index) => (
          <div className="add-connection__input">
            {index !== 0 && (
              <Icon
                className="add-connection__input__icon"
                name="cross"
                color="red"
                onClick={() => removeEmail(index)}
              />
            )}
            <Input
              border
              label="Email address"
              value={email}
              onChange={e => onEmailChange(index, e.target.value)}
            />
            {index === userEmails.length - 1 && (
              <Button
                className="add-connection__input__button"
                inverted
                color="green"
                onClick={onAddConnection}
              >
                Add another
              </Button>
            )}
          </div>
        ))}
        <div className="add-connection__content__buttons">
          <Button
            size="large"
            isLoading={isLoading}
            disabled={isLoading}
            color="blue"
            onClick={addConnections}
          >
            Add connections
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default AddConnectionModal;
