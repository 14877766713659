import {
  SET_EVENTS,
  SET_COURSE_NAMES,
  SET_CATEGORIES,
} from './calendar.actions';

const initialState = {
  events: [],
  courseNames: [],
  categories: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_EVENTS:
      return {
        ...state,
        events: action.payload,
      };
    case SET_COURSE_NAMES:
      return {
        ...state,
        courseNames: action.payload,
      };
    case SET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };

    default:
      return state;
  }
};
