import { useState, useEffect } from 'react';
import { addDate } from 'utils';
import moment from 'moment';

export const useDateTimeControl = ({
  durationDays,
  durationTime,
  onDateChange,
  date = {},
  includeWeekend,
}) => {
  const [startDate, setStartDate] = useState(
    date.startDate ?? moment().toDate()
  );
  const [endDate, setEndDate] = useState(date.endDate ?? moment().toDate());
  const [startTime, setStartTime] = useState(date.startTime ?? '');
  const [endTime, setEndTime] = useState(date.endTime ?? '');
  const [days, setDays] = useState(date.days ?? []);

  useEffect(() => {
    setStartDate(date.startDate);
  }, [date.startDate, setStartDate]);

  useEffect(() => {
    setEndDate(date.endDate);
  }, [date.endDate, setEndDate]);

  const handleStartDateChange = value => {
    setStartDate(value);
    onDateChange({ startDate: value });

    if (!durationDays) {
      setEndDate(value);
      onDateChange({ endDate: value });
      return;
    }

    const durationInWorkDays =
      durationTime > 0 ? durationDays + 1 : durationDays;

    const newEndDate = addDate(
      moment(value),
      parseInt(durationInWorkDays - 1, 10),
      'days',
      includeWeekend
    ).toDate();

    setEndDate(newEndDate);
    onDateChange({ endDate: newEndDate });
  };

  const handleStartTimeChange = value => {
    setStartTime(value);
    onDateChange({ startTime: value });

    if (durationTime === '') return;
    if (value === '') return;

    const splitValue = value.split(':');
    const endHour = parseInt(splitValue[0], 10) + parseInt(durationTime, 10);
    let newEndTime = endHour;

    if (!!splitValue.length) newEndTime = `${endHour}:${splitValue[1]}`;

    setEndTime(newEndTime);
    onDateChange({ endTime: newEndTime });
  };

  useEffect(() => {
    if (isNaN(durationDays)) return;
    const durationInWorkDays =
      durationTime > 0 ? durationDays + 1 : durationDays;
    const newEndDate = addDate(
      moment(startDate),
      durationInWorkDays - 1,
      'days',
      includeWeekend
    ).toDate();

    setEndDate(newEndDate);
    onDateChange({ endDate: newEndDate });
  }, [durationTime, durationDays, includeWeekend, startDate, setEndDate]);

  return {
    dateObj: {
      startDate,
      startTime,
      endDate,
      endTime,
      days,
    },
    handleStartDateChange,
    handleStartTimeChange,
    setStartDate,
    setStartTime,
    setEndDate,
    setEndTime,
    setDays,
  };
};
