import React from 'react';

const CustomLabel = ({ viewBox, data, color, includes, hoveredSubType }) => {
  const { cx, cy, innerRadius } = viewBox;
  let total = 0;
  let hoverCount = 0;

  data.forEach(ele => {
    if (ele.name === hoveredSubType) hoverCount += ele.pv;
    total += ele.pv;
  });

  const percent = Math.round((hoverCount / total) * 100);
  const visPercent = Number.isNaN(percent)
    ? 0
    : Math.round((hoverCount / total) * 100);

  return (
    <React.Fragment>
      {includes ? (
        <text textAnchor="middle" x={cx} y={cy - 5}>
          <tspan
            style={{
              fontWeight: 700,
              fontSize: '34px',
              fill: color,
              fontFamily: 'Roboto',
              textAlign: 'center',
            }}
          >
            {visPercent}%
          </tspan>
        </text>
      ) : null}
      <text x={cx - innerRadius * 0.5} y={cy + (includes ? 35 : 5)}>
        <tspan
          style={{
            fontSize: '20px',
            fill: '#A9A9A9',
            fontFamily: 'Roboto',
          }}
        >
          {hoverCount} issued
        </tspan>
      </text>
    </React.Fragment>
  );
};

export default CustomLabel;
