export const SET_USERS = 'CONNECTED_USERS/SET_USERS';
export const SET_CLIENT_EVENTS = 'CONNECTED_USERS/SET_CLIENT_EVENTS';

/**
 * @function setConnectedUsers
 * @description Set CONENCTED USERS
 */
export const setConnectedUsers = user => ({
  type: SET_USERS,
  payload: user,
});

/**
 * @function setClientEventsTypes
 * @description Set CONENCTED USERS
 */
export const setClientEventsTypes = events => ({
  type: SET_CLIENT_EVENTS,
  payload: events,
});
