import moment from 'moment';
import { DEFAULT_START_TIME, DEFAULT_END_TIME } from 'constant';

import {
  UPDATE_COURSE_DETAIL,
  CLEAR_COURSE_DETAIL,
  SET_COURSE,
  UPDATE_COURSE_DATE,
  ADD_COURSE_DATE,
  REMOVE_COURSE_DATE,
} from './courseCreation.actions';
import { addDate } from 'utils';

const initialState = {
  courseDetails: {
    industry: [],
    currency: 'EUR',
    advancementLevel: 'basic',
    language: 'Danish',
    country: 'Denmark',
    timezone: '',
    durationDays: '',
    durationTime: '',
    externalUrl: 'https://',
    includeWeekend: true,
  },
  dates: [
    {
      startTime: DEFAULT_START_TIME,
      endTime: DEFAULT_END_TIME,
      startDate: new Date(),
      endDate: new Date(),
      days: [],
    },
  ],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_COURSE_DETAIL:
      const keys = Object.keys(action.payload);

      if (
        ['durationDays', 'durationTime', 'includeWeekend'].includes(keys[0])
      ) {
        const durationDays =
          action.payload.durationDays ?? state.courseDetails.durationDays;
        const durationTime =
          action.payload.durationTime ?? state.courseDetails.durationTime;
        const includeWeekend =
          action.payload.includeWeekend ?? state.courseDetails.includeWeekend;

        const durationInWorkDays =
          durationTime && durationTime > 0 ? durationDays + 1 : durationDays;

        const dates = state.dates.map(date => {
          const days = [];
          const startDateMoment = moment(date.startDate);
          for (let i = 0; i < durationInWorkDays; i++) {
            days.push({
              date: addDate(startDateMoment, i, 'days', includeWeekend).format(
                'DD/MM/YYYY'
              ),
              startTime: date.startTime,
              endTime: date.endTime,
            });
          }
          return {
            ...date,
            endDate: addDate(
              startDateMoment,
              durationInWorkDays - 1,
              'days',
              includeWeekend
            ).toDate(),
            days,
          };
        });

        return {
          ...state,
          courseDetails: { ...state.courseDetails, ...action.payload },
          dates,
        };
      }

      return {
        ...state,
        courseDetails: { ...state.courseDetails, ...action.payload },
      };

    case ADD_COURSE_DATE:
      const days = [];

      const durationInWorkDays =
        state.courseDetails.durationTime > 0
          ? state.courseDetails.durationDays + 1
          : state.courseDetails.durationDays;
      const currentDateMoment = moment();
      for (let i = 0; i < durationInWorkDays; i++) {
        days.push({
          date: addDate(
            currentDateMoment,
            i,
            'days',
            state.courseDetails.includeWeekend
          ).format('DD/MM/YYYY'),
          startTime: DEFAULT_START_TIME,
          endTime: DEFAULT_END_TIME,
        });
      }

      return {
        ...state,
        dates: [
          ...state.dates,
          {
            startTime: DEFAULT_START_TIME,
            endTime: DEFAULT_END_TIME,
            startDate: new Date(),
            endDate: state.courseDetails.startDate
              ? addDate(
                  moment(state.courseDetails.startDate),
                  durationInWorkDays - 1,
                  'days',
                  state.courseDetails.includeWeekend
                ).toDate()
              : new Date(),
            days: days,
          },
        ],
      };

    case UPDATE_COURSE_DATE:
      const courseDates = state.dates.map((courseDate, index) => {
        if (index === action.payload.index) {
          courseDate = {
            ...courseDate,
            [action.payload.key]: action.payload.value,
          };

          if (action.payload.key === 'startDate') {
            // const durationInWorkDays =
            //   state.courseDetails.durationTime > 0
            //     ? state.courseDetails.durationDays + 1
            //     : state.courseDetails.durationDays;
            const dateValue = moment(action.payload.value);
            const days = courseDate.days.map((day, i) => {
              return {
                ...day,
                date: addDate(
                  dateValue,
                  i,
                  'days',
                  state.courseDetails.includeWeekend
                ).format('DD/MM/YYYY'),
              };
            });

            courseDate.days = days;
          }

          if (
            action.payload.key === 'endTime' ||
            action.payload.key === 'startTime'
          ) {
            const days = courseDate.days.map((day, i) => {
              return {
                ...day,
                [action.payload.key]: action.payload.value,
              };
            });

            courseDate.days = days;
          }
        }

        return courseDate;
      });

      return {
        ...state,
        dates: courseDates,
      };

    case SET_COURSE:
      return {
        ...state,
        courseDetails: action.payload,
      };

    case REMOVE_COURSE_DATE:
      if (action.payload <= 0)
        return {
          ...state,
        };

      return {
        ...state,
        dates: [
          ...state.dates.slice(0, action.payload),
          ...state.dates.slice(action.payload + 1),
        ],
      };

    case CLEAR_COURSE_DETAIL:
      return {
        ...state,
        courseDetails: { ...initialState.courseDetails },
        dates: [...initialState.dates],
      };

    default:
      return state;
  }
};
