import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';

import {
  Input,
  Button,
  Modal,
  ErrorToast,
  SuccessToast,
  Text,
} from '../../components';
import history from '../../history';
import UserService from '../../api/user';
import ClientService from '../../api/clients';
import PasswordReset from '../passwordReset/PasswordReset';
import ActivateUser from '../activateUser/ActivateUser';
import { createUserStorage, setClientStorage } from '../../auth/authentication';

import {
  setClientInformation,
  setUser,
} from '../../redux/generalState.actions';

import './login.scss';

const LoginPage = () => {
  const dispatch = useDispatch();

  const clientInformation = useSelector(
    state => state.general.clientInformation
  );

  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [resetEmail, setResetEmail] = useState('');
  const [isModalToggled, setIsModalToggled] = useState('');
  const [isResetting, setIsResetting] = useState('');
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [clientLogo, setClientLogo] = useState(null);
  const [background, setBackground] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const images = [
    'https://riverr-tcm.s3.eu-central-1.amazonaws.com/delta/images/coast-1149735_1920',
    'https://riverr-tcm.s3.eu-central-1.amazonaws.com/delta/images/pexels-matteo-badini-4064432',
    'https://riverr-tcm.s3.eu-central-1.amazonaws.com/delta/images/pexels-mudassir-ali-3309498',
    'https://riverr-tcm.s3.eu-central-1.amazonaws.com/delta/images/pexels-svanur-gabriele-1616222',
    'https://riverr-tcm.s3.eu-central-1.amazonaws.com/delta/images/pexels-trace-hudson-2400594',
  ];

  useEffect(() => {
    setBackgroundImage();
    fetchClientLogo();
    fetchClientDetails();
  }, []); // eslint-disable-line

  const setBackgroundImage = () => {
    const image = images[Math.floor(Math.random() * images.length)];
    setBackground(image);
  };

  const fetchClientDetails = async () => {
    const {
      data: { client },
    } = await ClientService.getClientTheme();
    setClientLogo(client.logo);
    dispatch(setClientInformation(client));
    setClientStorage(client);
  };

  const fetchClientLogo = () => {
    const logo = localStorage.getItem('CLIENT_LOGO');
    setClientLogo(logo);
  };

  const resetPassword = async () => {
    setIsResetting(true);
    try {
      await UserService.onForgotPassword(resetEmail);
      toast.success(<SuccessToast message="Reset email sent" />, {
        hideProgressBar: true,
        autoClose: 4000,
        pauseOnHover: true,
      });
    } catch (error) {
      toast.error(<ErrorToast message="User account does not exist" />);
    }
    setIsModalToggled(false);
    setResetEmail('');
    setIsResetting(false);
  };

  const login = async () => {
    setIsLoggingIn(true);
    try {
      const {
        data: { adminLogin },
      } = await UserService.onSignInUser({ password, email });
      dispatch(setUser(adminLogin.admin));
      createUserStorage(adminLogin);
      setIsLoggingIn(false);
      history.push('/dashboard');
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
      setIsLoggingIn(false);
    }
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      login();
    }
  };

  const domains = [
    'admin.delta.riverr.ai',
    'acumen.delta.riverr.ai',
    'biolidics.delta.riverr.ai',
    'camtech.delta.riverr.ai',
    'igenelab.delta.riverr.ai',
    'mdiagnostics.delta.riverr.ai',
    'pathlabs.delta.riverr.ai',
    'restelab.delta.riverr.ai',
    'eurofinssg.delta.riverr.ai',
  ];

  return (
    <div className="login">
      <div className="login__card">
        <div className="login__card__overlay">
          {clientLogo && (
            <img className="login__card__logo" alt="logo" src={clientLogo} />
          )}
          <div className="login__card__content">
            <Switch>
              <Route
                path="/passwordReset/:token/:email"
                exact
                component={() => (
                  <PasswordReset clientInformation={clientInformation} />
                )}
              />
              <Route
                path="/emailValidate/:token/:email"
                exact
                component={() => (
                  <ActivateUser clientInformation={clientInformation} />
                )}
              />
              <Route
                path="/activate/:token/:email"
                exact
                component={() => (
                  <ActivateUser clientInformation={clientInformation} />
                )}
              />
              <Route
                path="/"
                exact
                render={() => (
                  <>
                    {domains.includes(window.location.hostname) ? (
                      <>
                        <div className="login__card__header">
                          Welcome to Delta
                        </div>
                        <Input
                          className="login__card__input"
                          placeholder="E-mail address"
                          size="fullWidth"
                          type="email"
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                          autoComplete="username"
                        />
                        <Input
                          className="login__card__input"
                          placeholder="Password"
                          size="fullWidth"
                          type="password"
                          value={password}
                          onChange={e => setPassword(e.target.value)}
                          onKeyDown={handleKeyDown}
                          autoComplete="password"
                        />
                        <div className="login__card__forgot-wrapper">
                          <Button
                            className="login__card__forgot"
                            size="medium"
                            onClick={() => setIsModalToggled(true)}
                            inverted
                            color="white"
                          >
                            <span
                              className="login__card__forgot__text"
                              style={{
                                color:
                                  clientInformation && clientInformation.color
                                    ? clientInformation.color
                                    : '#53b1f3',
                              }}
                            >
                              Forgot password?
                            </span>
                          </Button>
                        </div>
                        <Button
                          className="login__card__button"
                          onClick={() => login()}
                          bold
                          color={
                            clientInformation && clientInformation.color
                              ? clientInformation.color
                              : 'lightBlue'
                          }
                          isLoading={isLoggingIn}
                        >
                          Login
                        </Button>
                      </>
                    ) : (
                      <>
                        <Text>Delta has closed down</Text>
                        <Text className="mt-3">
                          Use <a href="https://app.riverr.ai">app.riverr.ai</a>
                        </Text>
                      </>
                    )}
                  </>
                )}
              />
            </Switch>
          </div>
        </div>
      </div>
      <div className="login__image">
        <img
          src={background + '.jpg'}
          alt="background"
          onLoad={() => setImageLoaded(true)}
        />
        {!imageLoaded && (
          <img
            src={background + '-mini.jpg'}
            alt="background"
            className="login__image--mini"
          />
        )}
      </div>
      {isModalToggled && (
        <Modal
          closeModal={() => setIsModalToggled(false)}
          header="Reset your password"
          text="You forgot your password? Enter your email below to recieve a password reset link."
          className="login-modal"
          button={
            <Button
              size="fullWidth"
              onClick={() => {
                resetPassword();
              }}
              bold
              color={
                clientInformation && clientInformation.color
                  ? clientInformation.color
                  : 'blue'
              }
              isLoading={isResetting}
            >
              Reset Password
            </Button>
          }
        >
          <Input
            placeholder="Email"
            size="fullWidth"
            type="email"
            value={resetEmail}
            onChange={e => setResetEmail(e.target.value)}
          />
        </Modal>
      )}
    </div>
  );
};

export default withRouter(LoginPage);
