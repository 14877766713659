export const SET_CLIENT_INFO = 'CLIENT_DETAILS/SET_CLIENT_INFO';
// ****************
// STATE SETTERS
// ****************

/**
 * @function setClients
 * @description Set selected header section for certificate management
 */
export const setClientInfo = clientInfo => ({
  type: SET_CLIENT_INFO,
  payload: clientInfo,
});
