import React, { memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import colors from '../styles/colors';

const Text = memo(props => {
  return (
    <TextWrapper
      size={props.size}
      color={props.color}
      textType={props.type}
      bold={props.bold}
      center={props.center}
      underline={props.underline}
      dark={props.dark}
      uppercase={props.uppercase}
      lowercase={props.lowercase}
      height={props.height}
      ellipsis={props.ellipsis}
      style={props.style}
      className={props.wrapperClassName}
      onClick={props.onClick}
    >
      {/* Hack for getting the styles to work in the certificate printer in production. Need to get rid of this div */}
      <div className={props.className ? 'text ' + props.className : 'text'}>
        {props.children}
      </div>
    </TextWrapper>
  );
});

Text.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.node,
};

Text.defaultProps = {
  size: 'medium',
  height: 'medium',
  color: 'grey',
  type: 'default',
};

const getSize = size => {
  if (size === 'micro') return '9px';
  if (size === 'tinniest') return '12px';
  if (size === 'tiny') return '14px';
  if (size === 'small') return '16px';
  if (size === 'medium') return '18px';
  if (size === 'big') return '21px';
  if (size === 'bigger') return '24px';
  if (size === 'huge') return '28px';
  if (size === 'massive') return '45px';
};

const getLineHeight = size => {
  if (size === 'micro') return '12px';
  if (size === 'tinniest') return '15px';
  if (size === 'tiny') return '17px';
  if (size === 'small') return '20px';
  if (size === 'medium') return '22px';
  if (size === 'big') return '24px';
  if (size === 'bigger') return '27px';
  if (size === 'huge') return '31px';
  if (size === 'massive') return '46px';
};

// TODO: Move to helper
const getColor = color => {
  if (color === 'grey') return colors.DARK_GREY;
  if (color === 'lightGrey') return '#666666';
  if (color === 'link') return '#54b1f3';
  if (color === 'black') return 'black';
  if (color === 'white') return 'white';
  if (color === 'blue') return colors.BLUE;
  if (color === 'lightBlue') return colors.HIGHLIGHT_BLUE;
  if (color === 'darkBlue') return colors.DARK_BLUE;
  if (color === 'yellow') return colors.YELLOW;
  if (color === 'green') return colors.GREEN;
  if (color === 'hightlightGreen') return colors.HIGHLIGHT_GREEN;
  if (color === 'red') return '#ff5252';

  return color;
};

const textTypes = type => {
  if (type === 'button')
    return {
      'font-weight': '300',
    };
  if (type === 'pageHeader')
    return {
      marginTop: '10px',
      marginBottom: '10px',
      fontSize: '26px',
      lineHeight: '29px',
      // 'text-transform': 'uppercase',
      'font-weight': '400',
    };
  if (type === 'header')
    return {
      height: '19px',
      marginBottom: '15px',
      fontSize: '22px',
      'font-weight': '400',
      'text-transform': 'capitalize',
    };
  if (type === 'label')
    return {
      'font-weight': '400',
      'margin-bottom': '6px',
      fontSize: '16px',
    };

  return {
    'font-weight': '300',
  };
};

const ellipsis = ellipsis => {
  if (ellipsis)
    return {
      overflow: 'hidden',
      'text-overflow': 'ellipsis',
    };
  return null;
};

const fontStyles = props => {
  let styles = {};
  if (props.bold) styles['font-weight'] = '400';
  if (props.dark) styles['font-weight'] = '600';
  if (props.uppercase) styles['text-transform'] = 'capitalize';
  if (props.lowercase) styles['text-transform'] = 'lowercase';
  if (props.center) styles['text-align'] = 'center';
  if (props.underline) styles['text-decoration'] = 'underline';

  return styles;
};

const TextWrapper = styled.div`
  .text {
    font-family: 'Poppins', sans-serif;
    color: ${props => getColor(props.color)};
    font-size: ${props => getSize(props.size)};
    line-height: ${props => getLineHeight(props.size)};
    ${props => textTypes(props.textType)};
    ${props => fontStyles(props)};
    ${props => ellipsis(props.ellipsis)};
  }
`;

export default Text;
