import React, { useState } from 'react';

import { Icon } from 'semantic-ui-react';
import SignatureCanvas from 'react-signature-canvas';

import './signatureCanvas.scss';

const SigCanvas = ({ canvasRef }) => {
  const [color, setColor] = useState('black');

  const clearCanvas = () => {
    canvasRef.current.clear();
  };

  return (
    <div className="sign-canvas">
      <div className="sign-canvas__undo" onClick={clearCanvas}>
        <Icon name="undo" fontSize="1.5em" />
      </div>
      <SignatureCanvas
        ref={canvasRef}
        penColor={color}
        canvasProps={{
          width: 500,
          height: 200,
          className: 'sign-canvas__canvas',
        }}
      />
      <div className="sign-canvas__color">
        <div
          className="sign-canvas__color__button sign-canvas__color__button--black"
          onClick={() => setColor('black')}
        />
        <div
          className="sign-canvas__color__button sign-canvas__color__button--blue"
          onClick={() => setColor('#16264c')}
        />
        <div
          className="sign-canvas__color__button sign-canvas__color__button--brand"
          onClick={() => setColor('#5c53f3')}
        />
        <div
          className="sign-canvas__color__button sign-canvas__color__button--green"
          onClick={() => setColor('green')}
        />
        <div
          className="sign-canvas__color__button sign-canvas__color__button--red"
          onClick={() => setColor('red')}
        />
      </div>
    </div>
  );
};
export default SigCanvas;
