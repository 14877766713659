import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { PageHeader, PageContent } from '../components';

const Page = props => {
  return (
    <PageContainer className={props.className}>
      {!!props.header && (
        <PageHeader style={{ marginLeft: '60px' }}>{props.header}</PageHeader>
      )}
      <PageContent>
        <PageContentWapper style={props.style}>
          {props.children}
        </PageContentWapper>
      </PageContent>
    </PageContainer>
  );
};

Page.propTypes = {
  style: PropTypes.object,
  header: PropTypes.string,
  children: PropTypes.node,
};

Page.defaultProps = {
  clickHandler: () => {},
  checked: true,
  label: '',
};

const PageContainer = styled.div`
  position: relative;
  max-width: 1200px;
  min-width: 800px;
  width: 100%;
  margin: auto;
  margin-bottom: 25px;
  margin-top: 0;

  @media only screen and (max-width: 855px) {
    max-width: 100% !important;
    min-width: 100% !important;
  }
`;

const PageContentWapper = styled.div`
  background: white;
  box-shadow: 0px 0px 3px 1px rgba(189, 189, 189, 0.4);
  width: 100%;
  border-radius: 4px;
  min-height: 100px;
  padding: 40px 30px;
  @media only screen and (max-width: 855px) {
    padding: 25px 15px;
  }
  @media only screen and (max-width: 600px) {
    padding: 20px 10px !important;
  }
`;

export default Page;
