import React from 'react';
import PropTypes from 'prop-types';
import ScrollMenu from 'react-horizontal-scrolling-menu';

import AngleLeft from '../../assets/icons/AngleLeft';
import AngleRight from '../../assets/icons/AngleRight';

import './horizontalScroller.scss';

const HorizontalScroller = props => {
  return (
    <ScrollMenu
      data={props.data}
      arrowLeft={<AngleLeft fontSize="1.7em" />}
      arrowRight={<AngleRight fontSize="1.7em" />}
      selected={props.selected}
      onSelect={props.onSelect}
      wheel={false}
      alignCenter={false}
    />
  );
};

HorizontalScroller.propTypes = {
  children: PropTypes.node,
};

HorizontalScroller.defaultProps = {
  data: [],
};

export default HorizontalScroller;
