import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 15 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.572 2.493l-8.094 8.08c-.57.57-1.498.57-2.069 0L.43 6.595a1.46 1.46 0 010-2.066 1.465 1.465 0 012.068 0l2.948 2.944L12.504.428a1.465 1.465 0 012.069 0c.57.569.57 1.493-.002 2.065z"
        fill={props.color || '#616C8D'}
      />
    </svg>
  );
}

export default SvgComponent;
