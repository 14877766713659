import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Phone from 'react-phone-number-input';

import colors from '../styles/colors';
import { Text } from '../components';

const PhoneInput = props => {
  return (
    <Wrapper className={props.className}>
      {props.optional && (
        <Text size="tinniest" color="blue" className="input__optional-label">
          optional
        </Text>
      )}
      <Text type="label">{props.label}</Text>
      <Input
        onChange={props.onChange}
        value={props.value}
        placeholder="Enter phone number"
        country={props.country}
        invalid={props.invalid}
        border={!!props.border}
      />
      {props.invalid && (
        <Text className="input-error-text" color="red" size="tiny">
          Required field
        </Text>
      )}
    </Wrapper>
  );
};

PhoneInput.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  country: PropTypes.string,
};

PhoneInput.defaultProps = {
  label: 'Phone number',
  onChange: () => {},
  value: '',
};

const getBorder = (border, invalid) => {
  if (border) {
    if (invalid)
      return `border: solid 1px #ff5252 !important; border-radius: 4px !important; padding: 10px; height: 45px;`;
    return `border: solid 1px ${colors.GREY}; border-radius: 4px !important; padding: 10px; height: 45px;`;
  }

  if (invalid)
    return `border-bottom: solid 1px ${colors.HIGHLIGHT_BLUE}; border-radius: 0;`;
  return `border-bottom: solid 1px #e8e8e8; border-radius: 0;`;
};

const Wrapper = styled.div`
  position: relative;
  .input__optional-label {
    position: absolute;
    right: 0;
    top: 5px;
  }
`;

const Input = styled(Phone)`
  .PhoneInput__row {
    height: 100%;
    .PhoneInputCountry,
    .PhoneInputCountry--native {
      .PhoneInput__icon {
        width: 25px;
        height: 19px;
      }
    }
  }

  .PhoneInputInput {
    height: 35px;
    border: none;
    ${props => getBorder(props.border, props.invalid)}
    border-radius: 0;
    font-size: 17px;
    color: ${colors.DARK_GREY};
    txt-align: ${props => props.center && 'center'};
    font-weight: 200;

    ::placeholder {
      color: ${colors.DARK_GREY};
      opacity: 0.6;
      transition: all 0.3s ease;
      font-weight: 200;
      font-size: 16px;
    }

    :-ms-input-placeholder {
      color: ${colors.GREY};
    }

    ::-ms-input-placeholder {
      color: ${colors.GREY};
    }
    &:focus-visible {
      outline: none;
    }
    &:focus {
      border-color: #54b1f3;

      ::placeholder {
        color: ${colors.GREY};
        opacity: 1;
      }

      :-ms-input-placeholder {
        color: ${colors.DARK_GREY};
      }

      ::-ms-input-placeholder {
        color: ${colors.DARK_GREY};
      }
    }
  }
`;

export default PhoneInput;
