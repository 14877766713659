import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import Chart from '../../../stats/charts/charts';
import Legend from '../Legend/Legend';

import CertificatesService from '../../../../api/certificates';
import LabResultService from '../../../../api/labResults';
import {
  setAnalyticData,
  setSubTypesOptions,
  doSetSelectedSubType,
} from '../../../stats/charts/charts.actions';

import './dataOverview.scss';

const DataOverview = () => {
  const analyticData = useSelector(state => state.charts.analyticData);
  const clientInformation = useSelector(
    state => state.general.clientInformation
  );
  const subTypeOptions = useSelector(state => state.general.docTypesObj);
  const subTypes = useSelector(state => state.charts.subTypes);
  const selectedSubType = useSelector(state => state.charts.selectedSubTypes);

  const [hoveredSubType, setHoveredSubType] = useState(null);
  const [subTypeOptionsObj, setSubTypeOptionsObj] = useState({});
  const [hovering, setHovering] = useState(false);
  const [currentGraph, setCurrentGraph] = useState('bar');
  const [graphData, setGraphData] = useState(analyticData);
  const [hidden, setHidden] = useState([]);

  const [startDate, setStartDate] = useState(
    moment()
      .subtract(5, 'months')
      .toDate()
  );

  const dispatch = useDispatch();

  const labResultTypes = {
    PDT: 'Pre-departure PCR Test',
    SEROLOGY: 'Serology IgM',
    SEROLOGY_IGG: 'Serology',
    SG_PDT_PCR_TEST: 'SG Pre-departure Test',
    CH_PDT_PCR_TEST: 'CH Pre-departure Test',
    SG_LAMP: 'LAMP',
    PCR_RESULT: 'RT-PCR',
    PCR_SEROLOGY_COMBINED: 'RT-PCR + Serology',
    TW_PCR_TEST: 'Taiwan RT-PCR',
    CH_PCR_PRO_ANTIBODY_TEST: 'Chinese N Protein memo',
    CH_PCR_PRO_S_ANTIBODY_TEST: 'Chinese S Protein memo',
  };

  const getCountInMonthLabResults = async (
    numberOfMonths,
    isClientGroup,
    dispatch,
    setSubTypes,
    renderGraphBySubType,
    startDate,
    clientInformation
  ) => {
    const {
      data: { labResultsAnalyticsData },
    } = await LabResultService.getLabResultsCountInMonth(
      clientInformation.id,
      numberOfMonths != null
        ? moment(new Date())
            .subtract(numberOfMonths, 'months')
            .toDate()
            .getMonth()
        : startDate.getMonth(),
      new Date().getMonth(),
      null,
      isClientGroup ?? false
    );
    dispatch(setAnalyticData(labResultsAnalyticsData));
    setSubTypes(labResultsAnalyticsData, true);
    renderGraphBySubType(labResultsAnalyticsData);
  };

  useEffect(() => {
    if (!subTypeOptionsObj || Object.keys(subTypeOptionsObj).length === 0)
      return;

    const getCountInMonth = async (
      numberOfMonths,
      isClientGroup,
      dispatch,
      setSubTypes,
      renderGraphBySubType,
      startDate,
      clientInformation
    ) => {
      if (clientInformation.role === 'lab')
        return getCountInMonthLabResults(
          numberOfMonths,
          isClientGroup,
          dispatch,
          setSubTypes,
          renderGraphBySubType,
          startDate,
          clientInformation
        );
      const {
        data: { certificatesCountInMonth },
      } = await CertificatesService.getCertificatesCountInMonth(
        clientInformation.id,
        numberOfMonths != null
          ? moment(new Date())
              .subtract(numberOfMonths, 'months')
              .toDate()
              .getMonth()
          : startDate.getMonth(),
        new Date().getMonth(),
        null,
        isClientGroup ?? false
      );
      dispatch(setAnalyticData(certificatesCountInMonth));
      setSubTypes(certificatesCountInMonth);
      renderGraphBySubType(certificatesCountInMonth);
    };

    const setSubTypes = (data, isLabRez = false) => {
      const selected = [];
      const subTypes = [...new Set(data.map(item => item._id.subType))];
      const formattedTypes = subTypes.map(type => {
        selected.push(subTypeOptionsObj[type]);
        return {
          text: subTypeOptionsObj[type],
          value: type,
        };
      });
      dispatch(setSubTypesOptions(formattedTypes));
      setSelectedSubType(selected);
    };

    const renderGraphBySubType = async (data, type) => {
      if (data) {
        const results = {};
        let hasDate = true;
        // value is in [int] form ordered in months ascendingly
        data.forEach(value => {
          let monthNum = value._id.date ? value._id.date : value._id.month - 1;
          hasDate = !!value._id.date;
          const month = moment(value._id.date)
            .toDate()
            .toLocaleString('default', { month: 'long' });

          if (!results[monthNum]) results[monthNum] = { name: month };

          results[monthNum] = {
            ...results[monthNum],
            [subTypeOptionsObj[value._id.subType]]: value.count,
          };
        });

        const arrRes = hasDate
          ? Object.keys(results)
              .sort((a, b) => new Date(a) - new Date(b))
              .map(key => results[key])
          : Object.keys(results)
              .sort((a, b) => a - b)
              .map(key => results[key]);

        setGraphData(arrRes);
      }
    };

    if (analyticData.length === 0) {
      getCountInMonth(
        null,
        clientInformation.role === 'lab',
        dispatch,
        setSubTypes,
        renderGraphBySubType,
        startDate,
        clientInformation
      );
    } else {
      renderGraphBySubType(analyticData);
    }
  }, [subTypeOptionsObj]); // eslint-disable-line

  useEffect(() => {
    if (subTypes != null) {
      const inverseOfSelectedSubType = subTypes
        .map(y => y.text)
        .filter(x =>
          selectedSubType != null ? !selectedSubType.includes(x) : true
        );
      setHidden(inverseOfSelectedSubType);
    }
  }, [selectedSubType, subTypes]);

  useEffect(() => {
    if (clientInformation.role === 'lab') setSubTypeOptionsObj(labResultTypes);
    else setSubTypeOptionsObj(subTypeOptions);
  }, [clientInformation, subTypeOptions]);

  const setSelectedSubType = value => {
    dispatch(doSetSelectedSubType(value));
  };

  return (
    <div className="data-overview">
      <div className="data-overview__data-wrapper">
        <div className="data-overview__data-wrapper__main-chart">
          <div className="data-overview__data-wrapper__main-chart__menubar">
            {clientInformation.role === 'lab'
              ? 'Lab Results Sent'
              : 'Documents issued'}
            <div className="data-overview__data-wrapper__main-chart__menubar-buttons">
              <div
                className={
                  currentGraph === 'bar'
                    ? 'data-overview__data-wrapper__main-chart__menubar-buttons--highlighted'
                    : ''
                }
                onClick={() => setCurrentGraph('bar')}
              >
                Bar Chart
              </div>
              <div
                className={
                  currentGraph === 'line'
                    ? 'data-overview__data-wrapper__main-chart__menubar-buttons--highlighted'
                    : ''
                }
                onClick={() => setCurrentGraph('line')}
              >
                Line Chart
              </div>
            </div>
          </div>
          <Chart
            hidden={hidden}
            subTypes={subTypes}
            graphData={graphData}
            subTypeOptionsObj={subTypeOptionsObj}
            selectedSubType={selectedSubType}
            chartType={currentGraph}
            hoveredSubType={hoveredSubType}
            setHoveredSubType={setHoveredSubType}
            setSelectedSubType={setSelectedSubType}
            hovering={hovering}
          />
        </div>

        <div className="data-overview__data-wrapper__container">
          <div className="data-overview__data-wrapper__pie-chart">
            <div className="data-overview__data-wrapper__pie-chart__menubar">
              <div>Current month issuance</div>
            </div>
            <Chart
              hidden={hidden}
              subTypes={subTypes}
              graphData={graphData}
              subTypeOptionsObj={subTypeOptionsObj}
              selectedSubType={selectedSubType}
              hoveredSubType={hoveredSubType}
              hovering={hovering}
              chartType="pie"
              setHoveredSubType={setHoveredSubType}
              setSelectedSubType={setSelectedSubType}
            />
          </div>
          <div className="data-overview__data-wrapper__legend">
            <Legend
              setSelectedSubType={setSelectedSubType}
              selectedSubType={selectedSubType}
              hoveredSubType={hoveredSubType}
              setHoveredSubType={setHoveredSubType}
              setHovering={setHovering}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataOverview;
