import maritimeTerms from './maritimeTerms.json';
import medicalTerms from './medicalTerms.json';

const getTerms = type => {
  switch (type) {
    case 'medical':
      return medicalTerms;
    default:
      return maritimeTerms;
  }
};

export default getTerms;
