import React, { useRef } from 'react';
import Editor from '@draft-js-plugins/editor';
// import createSideToolbarPlugin from '@draft-js-plugins/side-toolbar';
import createToolbarPlugin, {
  Separator,
} from '@draft-js-plugins/static-toolbar';
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  CodeButton,
  UnorderedListButton,
  OrderedListButton,
  BlockquoteButton,
  CodeBlockButton,
  AlignBlockDefaultButton,
  AlignBlockCenterButton,
  AlignBlockLeftButton,
  AlignBlockRightButton,
} from '@draft-js-plugins/buttons';

import { Text } from '../index';

import 'draft-js/dist/Draft.css';
import './richTextEditor.scss';
import '@draft-js-plugins/static-toolbar/lib/plugin.css';

const toolbarPlugin = createToolbarPlugin();
const { Toolbar } = toolbarPlugin;
const plugins = [toolbarPlugin];

const RichTextEditor = props => {
  const editorRef = useRef();

  return (
    <div className={props.viewer ? 'rich-text rich-text--viewer' : 'rich-text'}>
      <Text type="label">{props.label}</Text>
      <div
        className="rich-text__wrapper"
        onClick={() => editorRef.current.focus()}
      >
        <Editor
          ref={editorRef}
          placeholder="Enter document description"
          plugins={plugins}
          {...props}
        />
        {!props.readOnly && (
          <Toolbar>
            {externalProps => (
              <div>
                <BoldButton {...externalProps} />
                <ItalicButton {...externalProps} />
                <UnderlineButton {...externalProps} />
                <CodeButton {...externalProps} />
                <Separator {...externalProps} />
                <UnorderedListButton {...externalProps} />
                <OrderedListButton {...externalProps} />
                <BlockquoteButton {...externalProps} />
                <CodeBlockButton {...externalProps} />
                <Separator {...externalProps} />
                <AlignBlockDefaultButton {...externalProps} />
                <AlignBlockCenterButton {...externalProps} />
                <AlignBlockLeftButton {...externalProps} />
                <AlignBlockRightButton {...externalProps} />
              </div>
            )}
          </Toolbar>
        )}
      </div>
    </div>
  );
};

RichTextEditor.defaultProps = {
  onChange: () => {},
};

export default RichTextEditor;
