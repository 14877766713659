import { SET_USERS, SET_CLIENT_EVENTS } from './connectedUsers.actions';

const initialState = {
  users: [],
  eventTypes: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USERS:
      return { ...state, users: action.payload };

    case SET_CLIENT_EVENTS:
      return { ...state, eventTypes: action.payload };

    default:
      return state;
  }
};
