import React from 'react';

import { Text, Button } from '../../components';

import './loginPreview.scss';

const loginPreivew = props => {
  return (
    <div className="login-preview-aspect-ratio">
      <div className="login-preview">
        <div className="login-preview__header">
          <span className="login-preview__header__dots" />
          <div className="login-preview__header__link">
            {props.domain}
            .delta.riverr.ai
          </div>
        </div>
        <div className="login-preview__content">
          <div className="login-preview__content__login">
            {props.logo && (
              <img
                alt="logo"
                className="login-preview__content__login__logo"
                src={props.logo}
              />
            )}
            <div className="login-preview__content__login__line-wrapper">
              <div className="login-preview__content__login__line" />
              <div className="login-preview__content__login__line" />
            </div>
            <Button
              className="login-preview__content__login__button"
              size="tiny"
              color="blue"
              style={{ background: props.brandColor }}
            >
              <Text size="tinniest" color="white">
                Sign in
              </Text>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default loginPreivew;
