import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import './headerDropdown.scss';

const HeaderDropdown = props => {
  const dropdownRef = useRef();

  useEffect(() => {
    const handleClick = e => {
      if (
        dropdownRef.current.contains(e.target) ||
        props.toggleButtonRef.current.contains(e.target)
      ) {
        return;
      }
      props.closeModal();
    };
    // add when mounted
    document.addEventListener('mousedown', handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []); // eslint-disable-line

  return (
    <div className="header-dropdown" ref={dropdownRef}>
      {props.children}
    </div>
  );
};

HeaderDropdown.propTypes = {
  toggleButtonRef: PropTypes.object,
  closeModal: PropTypes.func,
  children: PropTypes.node,
};

export default HeaderDropdown;
