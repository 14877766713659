import gql from 'graphql-tag';

export const createCourse = gql`
  mutation courseSet(
    $certTemplateId: ID
    $name: String
    $subName: String
    $tags: [String]
    $description: String
    $preRequisites: [String]
    $clientId: ID
    $userId: ID
    $type: [String]
    $category: [String]
    $subCategory: [String]
    $industry: [String]
    $language: String
    $timezone: String
    $validity: String
    $advancementLevel: String
    $price: String
    $courseImage: String
    $currency: String
    $industryStandard: [String]
    $targetGroup: String
    $instructorId: String
    $assistantId: String
    $durationTime: String
    $durationDays: String
    $street: String
    $zip: String
    $city: String
    $country: String
    $geoLocation: String
    $startTime: String
    $endTime: String
    $startDate: String
    $endDate: String
    $assistantEmail: String
    $instructorEmail: String
    $minParticipants: Int
    $maxParticipants: Int
    $centerId: ID
    $sName: String
    $days: String
    $courseTemplateId: ID
    $publishToWeb: Boolean
  ) {
    courseSet(
      certTemplateId: $certTemplateId
      name: $name
      subName: $subName
      tags: $tags
      description: $description
      preRequisites: $preRequisites
      clientId: $clientId
      userId: $userId
      type: $type
      category: $category
      subCategory: $subCategory
      language: $language
      timezone: $timezone
      validity: $validity
      advancementLevel: $advancementLevel
      price: $price
      courseImage: $courseImage
      currency: $currency
      industry: $industry
      industryStandard: $industryStandard
      targetGroup: $targetGroup
      instructorId: $instructorId
      assistantId: $assistantId
      durationTime: $durationTime
      durationDays: $durationDays
      street: $street
      zip: $zip
      city: $city
      country: $country
      geoLocation: $geoLocation
      startTime: $startTime
      endTime: $endTime
      startDate: $startDate
      endDate: $endDate
      assistantEmail: $assistantEmail
      instructorEmail: $instructorEmail
      minParticipants: $minParticipants
      maxParticipants: $maxParticipants
      centerId: $centerId
      sName: $sName
      days: $days
      courseTemplateId: $courseTemplateId
      publishToWeb: $publishToWeb
    ) {
      success
      message
    }
  }
`;

export const updateCourse = gql`
  mutation courseUpdate(
    $id: ID
    $certTemplateId: ID
    $name: String
    $subName: String
    $tags: [String]
    $description: String
    $preRequisites: [String]
    $clientId: ID
    $userId: ID
    $type: [String]
    $category: [String]
    $subCategory: [String]
    $industry: [String]
    $language: String
    $timezone: String
    $validity: String
    $advancementLevel: String
    $price: String
    $courseImage: String
    $currency: String
    $industryStandard: [String]
    $targetGroup: String
    $instructorId: String
    $assistantId: String
    $durationTime: String
    $durationDays: String
    $street: String
    $zip: String
    $city: String
    $country: String
    $geoLocation: String
    $startTime: String
    $endTime: String
    $startDate: String
    $endDate: String
    $assistantEmail: String
    $instructorEmail: String
    $minParticipants: Int
    $maxParticipants: Int
    $centerId: ID
    $sName: String
    $days: String
    $publishToWeb: Boolean
  ) {
    courseUpdate(
      id: $id
      certTemplateId: $certTemplateId
      name: $name
      subName: $subName
      tags: $tags
      description: $description
      preRequisites: $preRequisites
      clientId: $clientId
      userId: $userId
      type: $type
      category: $category
      subCategory: $subCategory
      language: $language
      timezone: $timezone
      validity: $validity
      advancementLevel: $advancementLevel
      price: $price
      courseImage: $courseImage
      currency: $currency
      industry: $industry
      industryStandard: $industryStandard
      targetGroup: $targetGroup
      instructorId: $instructorId
      assistantId: $assistantId
      durationTime: $durationTime
      durationDays: $durationDays
      street: $street
      zip: $zip
      city: $city
      country: $country
      geoLocation: $geoLocation
      startTime: $startTime
      endTime: $endTime
      startDate: $startDate
      endDate: $endDate
      assistantEmail: $assistantEmail
      instructorEmail: $instructorEmail
      minParticipants: $minParticipants
      maxParticipants: $maxParticipants
      centerId: $centerId
      sName: $sName
      days: $days
      publishToWeb: $publishToWeb
    ) {
      success
      message
    }
  }
`;

export const deleteCourse = gql`
  mutation courseDelete($id: ID!) {
    courseDelete(id: $id)
  }
`;

export const deleteParticipant = gql`
  mutation deleteParticipant($id: ID!) {
    deleteParticipant(id: $id)
  }
`;

export const addCourseImage = gql`
  mutation addCourseImage($clientId: ID!, $imageId: String!) {
    addCourseImage(clientId: $clientId, imageId: $imageId)
  }
`;

export const addCourseParticipant = gql`
  mutation addCourseParticipant(
    $courseId: ID!
    $firstName: String!
    $lastName: String!
    $email: String!
    $midId: String
    $phone: String
    $ssn: String
    $dateOfBirth: String
    $bookFirstName: String
    $bookLastName: String
    $bookEmail: String
    $bookPhone: String
    $emailTemplate: String
  ) {
    addCourseParticipant(
      courseId: $courseId
      firstName: $firstName
      lastName: $lastName
      email: $email
      phone: $phone
      midId: $midId
      ssn: $ssn
      dateOfBirth: $dateOfBirth
      bookFirstName: $bookFirstName
      bookLastName: $bookLastName
      bookEmail: $bookEmail
      bookPhone: $bookPhone
      emailTemplate: $emailTemplate
    ) {
      success
      message
    }
  }
`;

export const moveParticipants = gql`
  mutation moveParticipants(
    $fromCourseId: ID!
    $toCourseId: ID!
    $participants: String!
  ) {
    moveParticipants(
      fromCourseId: $fromCourseId
      toCourseId: $toCourseId
      participants: $participants
    ) {
      success
      message
    }
  }
`;

export const setCourseTitle = gql`
  mutation courseTitleSet($clientId: ID!, $name: String!) {
    courseTitleSet(clientId: $clientId, name: $name) {
      success
      message
    }
  }
`;

export const deleteCourseTitle = gql`
  mutation courseTitleDelete($id: ID!) {
    courseTitleDelete(id: $id)
  }
`;

export const deleteCourseImage = gql`
  mutation deleteCourseImage($imageId: ID!) {
    deleteCourseImage(imageId: $imageId)
  }
`;

export const completeCourse = gql`
  mutation completeCourse($courseId: ID!, $passedParticipants: String!) {
    completeCourse(courseId: $courseId, passedParticipants: $passedParticipants)
  }
`;

export const participantsUpdate = gql`
  mutation participantsUpdate($courseId: ID!, $passedParticipants: String!) {
    participantsUpdate(
      courseId: $courseId
      passedParticipants: $passedParticipants
    )
  }
`;
