import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { setTemplates } from 'pages/certificatePages/templatesManagement/templatesManagement.actions.js';
import CertificateTemplatesService from 'api/certificateTemplates';
import { sortAlphabetically } from 'helpers/helpers';
import { ErrorToast } from 'components';

export const useTemplates = (
  clientId = localStorage.getItem('ROOT_CLIENT_ID')
) => {
  const dispatch = useDispatch();

  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingError, setIsFetchingError] = useState(false);
  const [templates, setDataTemplates] = useState([]);

  const fetchTemplates = async () => {
    setIsFetching(true);
    setIsFetchingError(false);
    try {
      const {
        data: { certificateTemplatesByClientId },
      } = await CertificateTemplatesService.getCertificateTemplatessByClientId(
        clientId
      );
      const sortedData = sortAlphabetically(certificateTemplatesByClientId);
      dispatch(setTemplates(sortedData));
      setDataTemplates(sortedData);
    } catch (error) {
      setIsFetchingError(true);
      toast.error(<ErrorToast message="Error fetching templates" />);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  return { isFetching, isFetchingError, templates, fetchTemplates };
};
