import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { XAxis, YAxis, Tooltip, ResponsiveContainer, Text } from 'recharts';

import CustomTooltip from './components/CustomToolTip';
import PieGraph from './components/graphs/PieChart';
import BarChart from './components/graphs/BarChart';

import './charts.scss';
import LineGraph from './components/graphs/LineGraph';

const CustomizedAxisTick = ({ x, y, payload }) => {
  return (
    <Text
      x={x}
      y={y}
      width={100}
      textAnchor="middle"
      fontSize="12"
      verticalAnchor="start"
    >
      {payload.value}
    </Text>
  );
};

const DynamicChartByType = ({
  data,
  children,
  hoveredSubType,
  hovering,
  hidden,
  setHoveredSubType,
  chartType,
  width,
  height,
  subTypeOptions,
}) => {
  let keys = [];
  // extract the name for each subtype category
  if (subTypeOptions) keys = subTypeOptions.map(type => type.text);

  switch (chartType) {
    case 'line':
      return (
        <LineGraph
          height={height}
          hidden={hidden}
          data={data}
          width={width}
          children={children}
          hoveredSubType={hoveredSubType}
          keys={keys}
        />
      );
    case 'bar':
      return (
        <BarChart
          height={height}
          hidden={hidden}
          data={data}
          width={width}
          children={children}
          hoveredSubType={hoveredSubType}
          keys={keys}
          hovering={hovering}
        />
      );
    case 'pie':
      return (
        <PieGraph
          hidden={hidden}
          data={data}
          width={width}
          setHoveredSubType={setHoveredSubType}
          hoveredSubType={hoveredSubType}
          keys={keys}
        />
      );
    default:
      return <span> Incorrect Chart type or Props!</span>;
  }
};

const Chart = ({
  chartType,
  hoveredSubType,
  setHoveredSubType,
  hovering,
  graphData,
  selectedSubType,
  hidden,
  subTypes,
}) => {
  return (
    graphData.length !== 0 && (
      <ResponsiveContainer width="100%" height="100%">
        <DynamicChartByType
          key={chartType + 'dynamicChart'}
          subTypeOptions={subTypes}
          data={graphData}
          chartType={chartType}
          hidden={hidden}
          hoveredSubType={hoveredSubType}
          setHoveredSubType={setHoveredSubType}
          hovering={hovering}
          selectedSubType={selectedSubType}
        >
          <XAxis dataKey="name" interval={0} tick={<CustomizedAxisTick />} />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
        </DynamicChartByType>
      </ResponsiveContainer>
    )
  );
};

export default Chart;
