import gql from 'graphql-tag';

export const getInstructorsByClientId = gql`
  query instructors($clientId: ID!) {
    instructorsByClientId(clientId: $clientId) {
      id
      name
      email
      type
      phoneNumber
      signature
      image
      centerId
      licenseNo
      certAuthority
    }
  }
`;

export const getInstructorsByCenterId = gql`
  query instructors($centerId: ID!) {
    instructorsByCenterId(centerId: $centerId) {
      id
      name
      email
      type
      phoneNumber
      signature
      image
      centerId
      licenseNo
      certAuthority
    }
  }
`;

export const getInstructor = gql`
  query instructor($id: ID!) {
    instructor(id: $id) {
      id
      name
      email
      type
      phoneNumber
      image
      courses
      signature
      centerId
      licenseNo
      certAuthority
      ogukPin
    }
  }
`;

export const getInstructorCount = gql`
  query getInstructorCount($clientId: ID!, $centerId: ID) {
    getInstructorCount(clientId: $clientId, centerId: $centerId)
  }
`;
