import React from 'react';
import PropTypes from 'prop-types';

import { Text, Icon } from '../index';

const SuccessToast = props => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Icon
        name="infoTick"
        color="white"
        style={{ marginLeft: '15px', marginRight: '15px' }}
        fontSize="1.5em"
      />
      <Text color="white" size="small">
        {props.message}
      </Text>
    </div>
  );
};

SuccessToast.propTypes = {
  message: PropTypes.string,
};

SuccessToast.defaultProps = {
  message: '',
};

export default SuccessToast;
