import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import general from './redux/generalState.reducer';

import dashboard from './pages/dashboard/store/dashboard.reducer';
import charts from './pages/stats/charts/charts.reducer';
import stats from './pages/stats/store/stats.reducer';
import insights from './pages/insights/insights.reducers';

// course management
import courseCreation from './pages/coursePages/courseCreation/courseCreation.reducer';
import courseManagement from './pages/coursePages/courseManagement/courseManagement.reducer';
import connectedUsers from './pages/connectedPages/connectedUsers/store/connectedUsers.reducer';
// import courseEntryRequirements from './pages/coursePages/courseCreation/courseEntryRequirements/courseEntryRequirements.reducer';

// certificate management
import certificateManagement from './pages/certificatePages/certificateManagement/certificateManagement.reducer';
import templatesManagement from './pages/certificatePages/templatesManagement/templatesManagement.reducer';
import issuedCertificates from './pages/certificatePages/issuedCertificates/store/issuedCertificates.reducer';
import labDocuments from './pages/certificatePages/labDocuments/store/labDocuments.reducer';

// client management
import clientManagement from './pages/clientPages/clientManagement/store/clientManagement.reducer';
import clientDetails from './pages/clientPages/clientDetails/store/clientDetails.reducer';

// profile
import profile from './pages/profilePages/profilePage/state/profilePage.reducer';

// calendar
import calendar from './pages/calendar/state/calendar.reducer';
import medCalendar from './pages/webAppointments/calendar/state/calendar.reducer';

// instructorManagement
import instructorManagement from './pages/instructorPages/instructorManagement/store/instructorManagement.reducer';

//Siderbar state management
import { changeBarState } from './redux/sideBarState.reducer';
import { setGlobalVariable } from 'helpers/windowHelpers';

//web appointments
import webAppointments from 'pages/webAppointments/scheduleAppointment/store/webAppointments.reducer';

const reducer = combineReducers({
  courseManagement,
  certificateManagement,
  templatesManagement,
  courseCreation,
  charts,
  // courseEntryRequirements,
  dashboard,
  insights,
  calendar,
  medCalendar,
  issuedCertificates,
  labDocuments,
  clientManagement,
  clientDetails,
  general,
  profile,
  instructorManagement,
  connectedUsers,
  stats,
  changeBarState,
  webAppointments,
});

const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));
if (process.env.NODE_ENV === 'development') {
  store.subscribe(() => {
    setGlobalVariable('redux', store.getState());
  });
}

export default store;
