import React, { useState, useEffect } from 'react';
import BarChart from '../graphs/BarChart';
import LineGraph from '../graphs/LineChart';

import './statsGraph.scss';

const StatsGraph = props => {
  const [currentGraph, setCurrentGraph] = useState('bar');

  return (
    <div className="appointment-stats-graph">
      <div className="appointment-stats-graph__data-wrapper">
        <div className="appointment-stats-graph__data-wrapper__main-chart">
          <div className="appointment-stats-graph__data-wrapper__main-chart__menubar">
            Monthly Revenue
            <div className="appointment-stats-graph__data-wrapper__main-chart__menubar-buttons">
              <div
                className={
                  currentGraph === 'bar'
                    ? 'appointment-stats-graph__data-wrapper__main-chart__menubar-buttons--highlighted'
                    : ''
                }
                onClick={() => setCurrentGraph('bar')}
              >
                Bar Chart
              </div>
              <div
                className={
                  currentGraph === 'line'
                    ? 'appointment-stats-graph__data-wrapper__main-chart__menubar-buttons--highlighted'
                    : ''
                }
                onClick={() => setCurrentGraph('line')}
              >
                Line Chart
              </div>
            </div>
          </div>
          <div className="appointment-stats-graph__data-wrapper__charts">
            {currentGraph === 'bar' ? (
              <BarChart data={props.data} />
            ) : (
              <LineGraph data={props.data} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsGraph;
