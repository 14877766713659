import { Text } from 'components';
import React, { useState } from 'react';

import {
  PieChart,
  Pie,
  Legend,
  Cell,
  Tooltip,
  ResponsiveContainer,
  Label,
} from 'recharts';

const PieDiagram = ({ header, data, colors }) => {
  const [hoveredSub, setHoveredSub] = useState(null);
  return (
    <div style={{ height: '100%', padding: '10px', paddingBottom: '0' }}>
      <Text size="tiny" dark>
        {header}
      </Text>
      <ResponsiveContainer width="100%" height="90%">
        <PieChart width={400} height={400}>
          <Pie
            dataKey="value"
            isAnimationActive={true}
            data={data}
            cx="50%"
            cy="50%"
            outerRadius={60}
            innerRadius={40}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={colors[index]}
                onMouseEnter={() => setHoveredSub(entry)}
              />
            ))}
            <Label
              value={hoveredSub?.value ?? 0}
              position="center"
              color="#fff"
            />
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};
export default PieDiagram;
