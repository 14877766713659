import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { HeaderBar, SideBar } from '../features';
import { Button, Input, Modal, SuccessToast, ErrorToast } from '../components';
import UserService from '../api/user';

import { changeBarState } from '../redux/sideBarState.reducer';

const PageWrapper = props => {
  const contentRef = useRef();
  const dispatch = useDispatch();
  const [isModalToggled, setIsModalToggled] = useState(false);
  const [isResetting, setIsResetting] = useState('');
  const [resetEmail, setResetEmail] = useState('');
  const userEmail = useSelector(state => state.general.user.email);

  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });

  // This is used to scroll to the top after every route change
  useEffect(() => {
    const unlisten = props.history.listen(() => {
      if (contentRef && contentRef.current) contentRef.current.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    setResetEmail(userEmail);
  }, [userEmail]);

  const resetPassword = async () => {
    setIsResetting(true);
    try {
      await UserService.onForgotPassword(resetEmail);
      toast.success(<SuccessToast message="Reset email sent" />, {
        hideProgressBar: true,
        autoClose: 4000,
        pauseOnHover: true,
      });
    } catch (error) {
      toast.error(<ErrorToast message="User account does not exist" />);
    }
    setIsModalToggled(false);
    setResetEmail('');
    setIsResetting(false);
  };

  const onPageContentClick = () => {
    if (isPortrait) {
      dispatch(changeBarState('', { type: 'set', sidebarShow: false }));
    }
  };

  return (
    <>
      <Wrapper>
        <HeaderBar auth={props.auth} setIsModalToggled={setIsModalToggled} />
        <SideBar />
        <PageContent
          onClick={() => onPageContentClick()}
          ref={contentRef}
          auth={props.auth}
        >
          {props.children}
        </PageContent>
      </Wrapper>
      {isModalToggled && (
        <Modal
          closeModal={() => setIsModalToggled(false)}
          header="Reset your password"
          text="Enter your email below to recieve a password reset link."
          className="login-modal"
          button={
            <Button
              size="fullWidth"
              onClick={() => {
                resetPassword();
              }}
              bold
              color={
                clientInformation && clientInformation.color
                  ? clientInformation.color
                  : 'blue'
              }
              isLoading={isResetting}
            >
              Reset Password
            </Button>
          }
        >
          <Input
            placeholder="Email"
            size="fullWidth"
            type="email"
            value={resetEmail}
            onChange={e => setResetEmail(e.target.value)}
          />
        </Modal>
      )}
    </>
  );
};

const Wrapper = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  background: #f8f9ff;
`;

const PageContent = styled.div`
  padding: 7px 30px;
  padding-bottom: 20px;
  scrollbar-width: none; // Firefox
  position: absolute;
  right: 0;
  left: 90px;
  bottom: 0;
  top: 80px;
  overflow: auto;

  @media (max-width: 600px) {
    width: 99vw !important;
    left: 0px;
    overflow-x: hidden;
    padding: 0px 1px 0px 4px;
    padding-top: 15px;
  }

  &::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
`;

export default withRouter(PageWrapper);
