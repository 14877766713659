export const SET_EVENTS = 'CALENDAR/SET_EVENTS';
export const SET_COURSE_NAMES = 'CALENDAR/SET_COURSE_NAMES';
export const SET_CATEGORIES = 'CALENDAR/SET_CATEGORIES';

/**
 * @function setEvents
 * @description update course details in redux state
 */
export const setEvents = payload => ({
  type: SET_EVENTS,
  payload,
});

/**
 * @function setCourseNames
 * @description update course details in redux state
 */
export const setCourseNames = payload => ({
  type: SET_COURSE_NAMES,
  payload,
});

/**
 * @function setCategories
 * @description update course details in redux state
 */
export const setCategories = payload => ({
  type: SET_CATEGORIES,
  payload,
});
