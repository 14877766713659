import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Dropdown } from 'semantic-ui-react';

import { Text } from '../components';
import colors from '../styles/colors';

const InputComponent = props => {
  return (
    <Wrapper size={props.size} style={props.style} className={props.className}>
      {props.label && <Text type="label">{props.label}</Text>}
      {props.optional && (
        <Text size="tinniest" color="blue" className="input__optional-label">
          {props.optionalLabel || 'optional'}
        </Text>
      )}
      <Select
        invalid={props.invalid}
        border={!!props.border}
        multiple={props.multiple}
        placeholder={props.placeholder}
        className="select-document"
      >
        <Dropdown
          className="select-dropdown"
          clearable={props.clearable}
          placeholder={props.placeholder}
          multiple={props.multiple}
          search={props.search}
          selection
          options={props.options}
          onChange={props.onChange}
          value={!props.value && props.multiple ? [] : props.value}
          text={props.text}
          disabled={props.disabled}
        />
      </Select>
      {props.invalid && (
        <Text className="input-error-text" color="red" size="tiny">
          {props.errorMessage || 'Required field'}
        </Text>
      )}
    </Wrapper>
  );
};

InputComponent.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  invalid: PropTypes.bool,
};

InputComponent.defaultProps = {
  size: 'normal',
  onChange: () => {},
  placeholder: '',
  multiple: false,
  search: '',
  options: [],
  invalid: false,
  clearable: false,
};

const getSize = size => {
  if (size === 'tiny') return { width: '100px' };
  if (size === 'small') return { width: '200px' };
  if (size === 'normal') return { width: '300px' };
  if (size === 'fluide') return { width: 'initial' };
  if (size === 'large') return { width: '400px' };
  if (size === 'halfWidth') return { width: '50%' };
  if (size === 'fullWidth') return { width: '100%' };
};

const getBorder = (border, invalid) => {
  if (border) {
    if (invalid)
      return `border: solid 1px #ff5252 !important; border-radius: 4px !important;`;
    return `border: solid 1px ${colors.GREY} !important; border-radius: 4px !important;`;
  }

  if (invalid)
    return `border-bottom: solid 1px #ff5252 !important; border-radius: 0 !important;`;
  return `border-bottom: solid 1px #e8e8e8 !important; border-radius: 0 !important;`;
};

const getBorderHighlight = border => {
  if (border)
    return `outline: none !important; border: solid 1px ${colors.HIGHLIGHT_BLUE} !important;`;

  return `outline: none !important; border-bottom: solid 1px ${colors.HIGHLIGHT_BLUE} !important;`;
};

const getMinHeight = props => {
  if (props.border) return '45px !important';
  if (props.multiple) return 'initial';
  return '35px !important';
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${props => getSize(props.size)}
  position: relative;
  outline: none !important;

  .ui.active {
    .default.text {
      color: ${colors.GREY} !important;
      transition: all 0.3s ease;
    }
  }
`;

const Select = styled.div`
  .select-dropdown {
    width: 100%;
    border: none !important;
    outline-width: 0 !important;
    font-size: 17px !important;
    box-shadow: none !important;
    margin: 0 !important;
    padding: ${props =>
      props.search ? '0px 0px !important' : '0px 10px !important'};
    height:  ${props => (props.multiple ? 'initial' : '35px !important')};
    ${props => getBorder(props.border, props.invalid)}
    display: flex !important;
    align-items: center !important;   
  }

    .default.text {
      display: flex !important;
      align-items: center !important;
      color: ${colors.DARK_GREY} !important;
      opacity: 0.6 !important;
      transition: all 0.3s ease !important;
      font-weight: 200 !important;
      font-size: 16px !important;
      height: ${props =>
        props.border ? '30px !important' : '20px !important'};
    }

    .search {
      padding: ${props =>
        props.border ? '0px 10px !important' : '0px !important'};
      margin: 0 !important;
      height:  ${props => (props.multiple ? 'initial' : '100% !important')};
      min-height: ${props => getMinHeight(props)};
      font-weight: 100 !important;
      flex-wrap: ${props => (props.border ? 'wrap' : 'initial')};
    }

    .ui.disabled.search {
      background-color: #fafafa;
      border: solid 1px #bdbdbd !important;
      color: #1b1b1b !important;
      opacity: 1 !important;
    }

    .ui.selection.active.dropdown {
      ${props => getBorderHighlight(props.border)}
    }

    .ui.selection.dropdown {
      min-height: ${props => getMinHeight(props)};
      height: ${props => getMinHeight(props)};
    }

    .visible.menu.transition {
      margin-top: 3px !important;
      border: solid 1px #d4d4d5 !important;
      border-radius: 4px;
    }

    .text {
      font-weight: 300 !important;
    }

    .ui.label {
      margin: 0px !important;
      margin-top: 2px !important;
      margin-bottom: 2px !important;
      margin-right: 5px !important;
      background: none !important;
      color: color.DARK_GREY;
      font-weight: 300 !important;

      &:active {
        color: color.DARK_GREY !important;
      }
      &:hover {
        color: color.DARK_GREY !important;
      }
    }
  }
`;

export default InputComponent;
