import {
  SET_COMPLETED_COUNT,
  SET_PARTICIPANT_COUNT,
  SET_CORP_PROFILES,
} from './dashboard.actions';

const initialState = {
  selectedSection: '/dashboard',
  centerCount: '0',
  participantsCount: '0',
  corpProfiles: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPLETED_COUNT:
      return { ...state, completedCourses: action.payload };

    case SET_PARTICIPANT_COUNT:
      return { ...state, participantsCount: action.payload };

    case SET_CORP_PROFILES:
      return { ...state, corpProfiles: action.payload };

    default:
      return state;
  }
};
