import gql from 'graphql-tag';

export const updateClientByID = gql`
  mutation clientSet(
    $clientId: ID
    $name: String
    $subdomain: String
    $color: String
    $logo: String
    $accountHolderName: String
    $accountHolderEmail: String
    $accountHolderPhone: String
    $emailTemplate: String
    $address: String
    $city: String
    $zip: String
    $country: String
    $type: String
    $role: String
    $isWebShopClient: Boolean
    $hciCode: String
    $issuerName: CertificateIssuer
    $cost: String
    $planType: String
    $documents: String
    $pdfCallback: String
    $platoDatabase: String
    $dnsDomain: String
    $emailNotif: String
    $notAttachLabRes: Boolean
    $customText: CustomEmailTextInput
    $notCcAll: Boolean
  ) {
    clientSet(
      clientId: $clientId
      name: $name
      subdomain: $subdomain
      color: $color
      logo: $logo
      accountHolderName: $accountHolderName
      accountHolderEmail: $accountHolderEmail
      accountHolderPhone: $accountHolderPhone
      platoDatabase: $platoDatabase
      dnsDomain: $dnsDomain
      address: $address
      city: $city
      zip: $zip
      country: $country
      emailTemplate: $emailTemplate
      issuerName: $issuerName
      type: $type
      pdfCallback: $pdfCallback
      role: $role
      isWebShopClient: $isWebShopClient
      hciCode: $hciCode
      cost: $cost
      planType: $planType
      documents: $documents
      emailNotif: $emailNotif
      notAttachLabRes: $notAttachLabRes
      notCcAll: $notCcAll
      customText: $customText
    ) {
      success
      message
    }
  }
`;

export const bulkSetClients = gql`
  mutation bulkSetClients($clients: [ClientSetPayload]) {
    bulkSetClients(clients: $clients)
  }
`;

export const inviteUserToClient = gql`
  mutation adminAdd($clientId: ID!, $name: String!, $email: String!) {
    adminAdd(clientId: $clientId, name: $name, email: $email) {
      success
      message
    }
  }
`;

export const deleteClient = gql`
  mutation clientDelete($id: ID!) {
    clientDelete(id: $id)
  }
`;

export const removeConnectedUsers = gql`
  mutation removeConnectedUsers($users: String!, $clientId: ID!) {
    removeConnectedUsers(users: $users, clientId: $clientId)
  }
`;

export const updateConnectionRequest = gql`
  mutation updateConnectionRequest(
    $userId: ID!
    $status: String
    $clientId: ID!
    $connectionId: ID!
  ) {
    updateConnectionRequest(
      userId: $userId
      status: $status
      clientId: $clientId
      connectionId: $connectionId
    )
  }
`;

export const addConnections = gql`
  mutation addConnections($connections: String!, $clientId: ID!) {
    addConnections(connections: $connections, clientId: $clientId)
  }
`;

export const pushDeployClientCommandToQueue = gql`
  mutation pushDeployClientCommandToQueue($client: CmsClientDeployPayload!) {
    pushDeployClientCommandToQueue(client: $client) {
      success
      message
    }
  }
`;

export const deployClients = gql`
  mutation deployClients($clients: [CmsClientDeployPayload!]) {
    deployClients(clients: $clients) {
      success
      message
    }
  }
`;
