import React from 'react';

const SvgArrowCircle = props => (
  <svg width="1em" height="1em" viewBox="0 0 42 32" {...props}>
    <g
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      stroke="#1b1b1b"
      strokeWidth={3}
    >
      <path d="M34 21.177V22a5 5 0 01-5 5H14a5 5 0 01-5-5v-3M34 12.823V10a5 5 0 00-5-5H14a5 5 0 00-5 5v1" />
      <path d="M5 21l4.025-4L13 21M38 10l-4.025 4L30 10" />
    </g>
  </svg>
);

export default SvgArrowCircle;
