export const UPDATE_COURSE_DETAIL = 'COURSE_DETAILS/UPDATE_COURSE_DETAIL';
export const UPDATE_COURSE_DATE = 'COURSE_DETAILS/UPDATE_COURSE_DATE';
export const CLEAR_COURSE_DETAIL = 'COURSE_DETAILS/CLEAR_COURSE_DETAIL';
export const SET_COURSE = 'COURSE_DETAILS/SET_COURSE';
export const ADD_COURSE_DATE = 'COURSE_DETAILS/ADD_COURSE_DATE';
export const REMOVE_COURSE_DATE = 'COURSE_DETAILS/REMOVE_COURSE_DATE';

/**
 * @function updateCourseDetail
 * @description update course details in redux state
 */
export const updateCourseDetail = courseDetail => ({
  type: UPDATE_COURSE_DETAIL,
  payload: courseDetail,
});

/**
 * @function updateCourseDate
 * @description update course details in redux state
 */
export const updateCourseDate = (key, value, index) => ({
  type: UPDATE_COURSE_DATE,
  payload: { key, value, index },
});

/**
 * @function updateCourseDetail
 * @description update course details in redux state
 */
export const setCourseDetails = course => ({
  type: SET_COURSE,
  payload: course,
});

/**
 * @function addCourseDate
 * @description update course details in redux state
 */
export const addCourseDate = () => ({
  type: ADD_COURSE_DATE,
});

/**
 * @function removeCourseDate
 * @description update course details in redux state
 */
export const removeCourseDate = index => ({
  type: REMOVE_COURSE_DATE,
  payload: index,
});

/**
 * @function clearCourseDetail
 * @description redsux - Empty the course details object
 */
export const clearCourseDetail = () => ({
  type: CLEAR_COURSE_DETAIL,
});
