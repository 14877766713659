import constants from '../../../constants';
import fetchWrapper from '../../../services/fetchWrapper';

export const SET_TEMPLATES = 'TEMPLATE_MANAGEMENT/SET_TEMPLATES';

// ****************
// API CALLS
// ****************

/**
 * @function fetchTemplates
 * @description API call to fetch template previews
 *
 */
export const fetchTemplates = () =>
  fetchWrapper({ endpoint: constants.API_V1_ENDPOINT });

// ****************
// STATE SETTERS
// ****************

/**
 * @function setIndustryList
 * @description Set industry list
 */
export const setTemplates = industyList => ({
  type: SET_TEMPLATES,
  payload: industyList,
});
