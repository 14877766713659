import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Text from './Text';

const PageHeader = props => {
  return (
    <Header className={props.className} style={props.style}>
      <Text type="pageHeader">{props.children}</Text>
    </Header>
  );
};

PageHeader.defaultProps = {
  children: PropTypes.node,
};

const Header = styled.div`
  max-width: 1200px;
  min-width: 800px;
  display: flex;
  width: 100%;
  margin: auto;
  margin-bottom: 8px;
  position: relative;
`;

export default PageHeader;
