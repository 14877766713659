import * as React from 'react';

const SvgComponent = props => (
  <svg
    style={{
      enableBackground: 'new 0 0 48 48',
    }}
    viewBox="0 0 48 48"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M24 26c6.6 0 12-5.4 12-12S30.6 2 24 2 12 7.4 12 14s5.4 12 12 12zm0-22c5.5 0 10 4.5 10 10s-4.5 10-10 10-10-4.5-10-10S18.5 4 24 4zM33 28H15C7.8 28 2 33.8 2 41v5h2v-5c0-6.1 4.9-11 11-11h18c6.1 0 11 4.9 11 11v5h2v-5c0-7.2-5.8-13-13-13z" />
  </svg>
);

export default SvgComponent;
