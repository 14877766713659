import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/react-hooks';
import { Router } from 'react-router-dom';

import * as serviceWorker from './serviceWorker';
import store from './store';
import history from './history';

import { MainRoutes } from './routes';
import { apolloClient } from './apollo/apollo';
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './styles/main.scss';
import 'semantic-ui-css/semantic.min.css';
import 'react-phone-number-input/style.css';
import { ClientThemeProvider, UserProvider } from 'hooks';
// import 'react-image-crop/lib/ReactCrop.scss';

toast.configure();

ReactDOM.render(
  <ApolloProvider client={apolloClient}>
    <Provider store={store}>
      <Router history={history}>
        <ClientThemeProvider>
          <UserProvider>
            <MainRoutes />
          </UserProvider>
        </ClientThemeProvider>
      </Router>
    </Provider>
  </ApolloProvider>,

  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
