import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import CircleLoader from 'react-spinners/CircleLoader';
import moment from 'moment';
import terms from '../../terms';
import DataOverview from './components/DataOverview/DataOverview';

import {
  setTodaysCourses,
  setWaitingCourses,
} from '../coursePages/courseManagement/courseManagement.actions';
import {
  // setAnalyticData,
  setCompletedCount,
  setParticipantCount,
} from './store/dashboard.actions';
import { setInstructors } from '../instructorPages/instructorManagement/store/instructorManagement.actions';

import { Text, ErrorToast, CourseItem } from '../../components';
import Widget from './components/Widget/Widget';

import courseService from '../../api/courses';
import InstructorService from '../../api/instructors';
import appointmentService from '../../api/appointments';
import LabResultsService from '../../api/labResults';
import CertificatesService from '../../api/certificates';

import './dashboard.scss';

const Dashboard = props => {
  const dispatch = useDispatch();

  const clientInformation = useSelector(
    state => state.general.clientInformation
  );
  const userInfo = useSelector(state => state.general.user);
  const completedCourses = useSelector(
    state => state.dashboard.completedCourses
  );
  const participantsCount = useSelector(
    state => state.dashboard.participantsCount
  );
  const todaysCourses = useSelector(
    state => state.courseManagement.todaysCourses
  );
  const instructors = useSelector(
    state => state.instructorManagement.instructors
  );

  const [isLoadingCourses, setIsLoadingCourses] = useState(true);
  const [completedResults, setCompletedResults] = useState(0);
  const [todayIssuedCount, setTodayIssuedCount] = useState(0);

  useEffect(() => {
    getInitialData();
  }, []); //eslint-disable-line

  // Have it here instead of routes.js as it causes random infinite loop bugs
  useEffect(() => {
    window.Intercom('update', { type: clientInformation.type });
  }, [clientInformation]);

  const getInitialData = async () => {
    const clientId = await localStorage.getItem('ROOT_CLIENT_ID');

    if (clientInformation.type === 'medical') {
      // getPatientsCount(clientId);
      getMedicalData(clientId);
    } else {
      getCompletedCourseCount(clientId);
      getParticipantsCount(clientId);
      getCoursesWithWaitingList(clientId);
      if (todaysCourses.length === 0) getCourses(clientId);
      else formatCoursesData(todaysCourses, instructors);
    }
  };

  const fetchInstructors = clientId => {
    if (userInfo.centerId) return fetchInstructorsByCenter();
    return fetchInstructorsByClient(clientId);
  };

  const getCoursesWithWaitingList = async clientId => {
    try {
      const {
        data: { getCoursesWithWaitingList },
      } = await courseService.getCoursesWithWaitingList(clientId);

      dispatch(setWaitingCourses(getCoursesWithWaitingList));
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
    setIsLoadingCourses(false);
  };

  const getCourseByDate = async clientId => {
    try {
      const {
        data: { getCourseByDate },
      } = await courseService.getCourseByDate(
        clientId,
        moment().format('DD-MM-YYYY'),
        moment().format('DD-MM-YYYY')
      );

      dispatch(setTodaysCourses(getCourseByDate));
      formatCoursesData(getCourseByDate, instructors);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
    setIsLoadingCourses(false);
  };

  const getCertificatesCountInRange = async clientId => {
    try {
      const {
        data: { certificatesCountInRange },
      } = await CertificatesService.getCertificatesCountInRange(
        clientId,
        moment()
          .startOf('day')
          .toISOString(),
        moment()
          .endOf('day')
          .toISOString()
      );
      return certificatesCountInRange;
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
    setIsLoadingCourses(false);
  };

  const fetchInstructorsByClient = async clientId => {
    try {
      const {
        data: { instructorsByClientId },
      } = await InstructorService.getInstructorsByClientId(clientId);
      dispatch(setInstructors(instructorsByClientId));
      return instructorsByClientId;
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  };

  const fetchInstructorsByCenter = async () => {
    try {
      const {
        data: { instructorsByCenterId },
      } = await InstructorService.getInstructorsByCenterId(userInfo.centerId);
      dispatch(setInstructors(instructorsByCenterId));
      return instructorsByCenterId;
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  };

  const formatCoursesData = (planCourses, instructors) => {
    const obj = {};
    let courses = planCourses;

    if (instructors.length > 0) {
      instructors.forEach(inst => {
        obj[inst.id] = inst.name;
      });

      courses = planCourses.map(course => {
        return {
          ...course,
          instructor: obj[course.instructorId],
        };
      });
    }
    dispatch(setTodaysCourses(courses));
  };

  const getCompletedCourseCount = async clientId => {
    const {
      data: { getCompletedCourseCount },
    } = await courseService.getCompletedCourseCount(
      clientId,
      userInfo.centerId
    );

    dispatch(setCompletedCount(getCompletedCourseCount));
  };

  // const getCompletedAppointmentsCount = async clientId => {
  //   const {
  //     data: { getCompletedAppointmentsCount },
  //   } = await appointmentService.getCompletedAppointmentsCount(
  //     clientId,
  //     userInfo.centerId
  //   );

  //   dispatch(setCompletedCount(getCompletedAppointmentsCount));
  // };

  const getCourses = async clientId => {
    const instructors = await fetchInstructors(clientId);
    if (userInfo.centerId) await getCoursesByCenter(instructors);
    else await getCourseByDate(clientId, instructors);
  };

  const getParticipantsCount = async clientId => {
    const {
      data: { courseParticipantsCount },
    } = await courseService.courseParticipantsCount(
      clientId,
      userInfo.centerId
    );

    dispatch(setParticipantCount(courseParticipantsCount));
  };

  const getPatientsCount = async clientId => {
    const {
      data: { patientsCount },
    } = await appointmentService.patientsCount(clientId, userInfo.centerId);

    dispatch(setParticipantCount(patientsCount));
  };

  const getMedicalData = async id => {
    const promise1 = async () => {
      const totalRes = await getLabResultsCount(id, null);
      dispatch(setCompletedCount(totalRes));
    };
    const promise2 = async () => {
      const issuedRes = await getLabResultsCount(id, 'complete');
      setCompletedResults(issuedRes);
    };
    const promise3 = async () => {
      const todayCount = await getCertificatesCountInRange(id);
      setTodayIssuedCount(todayCount);
    };

    await Promise.all([promise1(), promise2(), promise3()]);
  };

  const getLabResultsCount = async (id, filter) => {
    try {
      const requestParams = {
        clinicId: clientInformation.role !== 'lab' ? id : null,
        clientId: clientInformation.role === 'lab' ? id : null,
        filter,
      };

      const {
        data: { getLabResultsCount },
      } = await LabResultsService.getLabResultsCount(requestParams);
      return getLabResultsCount;
    } catch (error) {
      console.log('router on start error', error);
    }
  };

  const getCoursesByCenter = async instructors => {
    setIsLoadingCourses(true);
    try {
      const {
        data: { coursesByInstructorId },
      } = await courseService.getCoursesByInstructorId(userInfo.id);

      dispatch(setTodaysCourses(coursesByInstructorId));
      formatCoursesData(coursesByInstructorId, instructors);
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
    setIsLoadingCourses(false);
  };

  const renderCourses = () => {
    if (isLoadingCourses && todaysCourses.length === 0)
      return (
        <div className="dashboard__footer-wrapper__ongoing-courses__loader">
          <CircleLoader color="#455af7" loading />
        </div>
      );

    if (todaysCourses.length > 0)
      return (
        <div className="dashboard-courses">
          {todaysCourses.map(course => (
            <CourseItem
              fullDetails
              key={course.id}
              course={course}
              onClick={() => props.history.push(`/course/details/${course.id}`)}
              instructor={
                userInfo.role === 'trainingInstructor' ||
                userInfo.role === 'instructor'
                  ? userInfo.name
                  : 'None assigned'
              }
            />
          ))}
        </div>
      );

    return (
      <div className="dashboard__footer-wrapper__map__content">
        <Text size="big" bold>
          {terms(clientInformation.type).dashboard.ongoing.emptyOverview}
        </Text>
      </div>
    );
  };

  const getFirstWidgetText = () => {
    if (clientInformation.role === 'lab') return 'Sent';
    if (clientInformation.type === 'medical') return 'Received';
    return 'Completed';
  };

  return (
    <div className="dashboard">
      <div className="dashboard__widgets">
        <Widget
          data={completedCourses}
          header={terms(clientInformation.type).dashboard.widgets.courses}
          label={getFirstWidgetText()}
        />
        <Widget
          data={
            clientInformation.type === 'medical'
              ? completedResults
              : instructors.length
          }
          header={terms(clientInformation.type).dashboard.widgets.instructors}
          label={clientInformation.type === 'medical' ? 'Issued' : 'Total'}
        />
        {clientInformation.type === 'maritime' && (
          <Widget
            data={participantsCount}
            header={terms(clientInformation.type).dashboard.widgets.students}
            label="Total"
          />
        )}
        {clientInformation.type === 'medical' && (
          <Widget
            data={todayIssuedCount}
            header="Documents Issued"
            label="Today"
          />
        )}
        <Widget
          data={clientInformation.certificatesIssued}
          header={terms(clientInformation.type).dashboard.widgets.certificates}
          label="Total"
        />
      </div>
      {clientInformation.type === 'medical' ? (
        <DataOverview />
      ) : (
        <div className="dashboard__footer-wrapper__ongoing-courses">
          <Text
            size="bigger"
            color="black"
            bold
            className="dashboard__footer-wrapper__ongoing-courses__title"
          >
            {terms(clientInformation.type).dashboard.ongoing.overviewHeader}
          </Text>
          {renderCourses()}
        </div>
      )}
    </div>
  );
};

export default withRouter(Dashboard);
