import React from 'react';
import { CSVReader, readString } from 'react-papaparse';
import { Icon } from 'semantic-ui-react';

import { Text } from '../../components';

import './csvReader.scss';

const CsvReader = props => {
  const handleOnFileLoad = data => {
    props.onUpload(data);
  };

  const handleOnError = (err, file, inputElem, reason) => {
    props.onError();
  };

  const handleOnRemoveFile = data => {
    props.onRemoveFile();
  };

  return (
    <CSVReader
      onDrop={handleOnFileLoad}
      addRemoveButton
      onError={handleOnError}
      onRemoveFile={handleOnRemoveFile}
      config={{
        header: true,
        skipEmptyLines: true,
      }}
      style={{
        dropArea: {
          borderRadius: 10,
        },
        dropFile: {
          width: '100%',
          background: 'linear-gradient(rgb(238, 238, 238), rgb(221, 221, 221))',
          borderRadius: '10px',
        },
        fileSizeInfo: {
          borderRadius: 5,
          padding: '0.2em 0.4em',
          marginBottom: '5px',
        },
        fileNameInfo: {
          borderRadius: 5,
          fontSize: 15,
          padding: '0.2em 0.4em',
        },
      }}
    >
      <Text size="small" bold>
        <Icon name="upload" className="mr-3" />
        Drop CSV file or click to upload
      </Text>
    </CSVReader>
  );
};

export default CsvReader;
