import { useCallback, useMemo } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { ErrorToast } from 'components';

import InstructorService from 'api/instructors';
import { setInstructors } from 'pages/instructorPages/instructorManagement/store/instructorManagement.actions';

export const useStaff = ({ clientId }) => {
  const dispatch = useDispatch();
  const userInfo = useSelector(state => state.general.user);

  // planned courses from Redux
  const instructorsFromRedux = useSelector(
    state => state.instructorManagement.instructors
  );

  const fetchInstructors = useCallback(async () => {
    let staff;
    if (userInfo.centerId) staff = await fetchInstructorsByCenter();
    staff = await fetchInstructorsByClient();
    return staff;
  }, [clientId, dispatch]);

  const fetchInstructorsByClient = async centers => {
    try {
      const {
        data: { instructorsByClientId },
      } = await InstructorService.getInstructorsByClientId(clientId);
      dispatch(setInstructors(instructorsByClientId));
      return instructorsByClientId;
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  };

  const fetchInstructorsByCenter = async centers => {
    try {
      const {
        data: { instructorsByCenterId },
      } = await InstructorService.getInstructorsByCenterId(userInfo.centerId);
      dispatch(setInstructors(instructorsByCenterId));
      return instructorsByCenterId;
    } catch (error) {
      toast.error(<ErrorToast error={error} />);
    }
  };

  // if we already fetch planned courses before then just return it
  const staff = useMemo(() => {
    if (!!instructorsFromRedux.length) return instructorsFromRedux;
    return fetchInstructors();
  }, [instructorsFromRedux, fetchInstructors]);

  return staff;
};
