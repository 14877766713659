// eslint-disable-next-line import/no-cycle
import UserService from '../api/user';
import history from '../history';

export const clearUserStorage = () => {
  // clearInterval(window.refreshToken);
  sessionStorage.clear();
  localStorage.clear();
};

export const createUserStorage = data => {
  localStorage.setItem('AUTH_TOKEN', data.message);
  localStorage.setItem('USER_ID', data.admin.id);
  localStorage.setItem('USER_INFO', JSON.stringify(data.admin));
};

export const setClientStorage = client => {
  localStorage.setItem('ISSUER', client.name);
  localStorage.setItem('ROOT_CLIENT_ID', client.id);
  localStorage.setItem('CLIENT_LOGO', client.logo);
};

export const signOutUser = async id => {
  try {
    window.Intercom('shutdown');
    clearUserStorage();
    if (id) await UserService.logOff(id);
    // window.location.href = '/';
    history.push('/');
  } catch (error) {
    console.log('Error', error);
    history.push('/');
  }
};

export const checkIfTokenExist = () => !!localStorage.getItem('AUTH_TOKEN');
