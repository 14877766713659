import gql from 'graphql-tag';

export const issueLogByClient = gql`
  query issueLogByClient(
    $clientId: String!
    $startDate: String
    $endDate: String
  ) {
    issueLogByClient(
      clientId: $clientId
      startDate: $startDate
      endDate: $endDate
    ) {
      adminId
      certType
      subType
      certificateId
      clientId
      createdAt
      saveToBlock
    }
  }
`;
