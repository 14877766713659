export default {
  FADED_WHITE: '#FBFBFB',
  GREY: '#bdbdbd',
  LIGHT_GREY: '#f5f5f5',
  DARK_GREY: '#1b1b1b',
  BLACK: 'black',
  WHITE: '#FFFFFF',
  BLUE: '#5c53f3',
  PURPLE: '#5d53f3',
  DARK_BLUE: '#2E1760',
  YELLOW: '#ffc727',
  GREEN: '#19b970',
  HIGHLIGHT_BLUE: '#53b1f3',
  HIGHLIGHT_GREEN: '#53f393',
  RED: '#d41515',
  ORANGE: '#FF9B71',
};
