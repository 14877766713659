import React from 'react';

const SvgAngleRight = props => (
  <svg width="1em" height="1em" viewBox="0 0 20 29" {...props}>
    <path
      d="M5.5 24.5l8.398-9.331a1 1 0 000-1.338L5.5 4.5"
      stroke="#1b1b1b"
      strokeWidth={3}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
    />
  </svg>
);

export default SvgAngleRight;
