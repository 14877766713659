import React from 'react';
import { Text, Icon } from '../../../../components';

import './widget.scss';

const Widget = props => {
  return (
    <div className="dashboard-widget">
      <Text
        bold
        size="massive"
        color="black"
        className="dashboard-widget__number"
      >
        {props.data || '0'}
      </Text>
      <div className="dashboard-widget__underline" />
      <Text
        bold
        size="small"
        color="black"
        className="dashboard-widget__header"
      >
        {props.header}
      </Text>
      <Text size="tiny" className="dashboard-widget__label">
        {props.label}
      </Text>
      {/* <Icon name="graph" className="dashboard-widget__icon" width="180" /> */}
    </div>
  );
};

export default Widget;
