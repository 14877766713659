import constants from './constants';
import apolloConfig from '../apollo.config';

export const clientIdBasedOnHostname = host => {
  let clientId;

  if (host === constants.clientHostNames.localhost)
    clientId = apolloConfig.clientId;
  if (host === constants.clientHostNames.admin)
    clientId = apolloConfig.clientId;
  if (host === 'delta') clientId = apolloConfig.clientId;
  if (host === 'www') clientId = apolloConfig.clientId;
  if (host === constants.clientHostNames.dlm) clientId = apolloConfig.clientId;
  if (host === constants.clientHostNames.remoteMedical)
    clientId = apolloConfig.clientIdRemoteMedical;
  if (host === constants.clientHostNames.maerskDrilling)
    clientId = apolloConfig.clientIdMaerskDrilling;
  if (host === constants.clientHostNames.platinumSurgery)
    clientId = apolloConfig.platinumSurgeryId;
  if (host === constants.clientHostNames.street11)
    clientId = apolloConfig.street11Id;
  if (host === constants.clientHostNames.myDoctorlakeSide)
    clientId = apolloConfig.myDoctorlakeSideId;
  if (host === constants.clientHostNames.ongsclinic)
    clientId = apolloConfig.ongsclinicId;
  if (host === constants.clientHostNames.bedokdaynight)
    clientId = apolloConfig.bedokdaynightId;
  if (host === constants.clientHostNames.jurongdaynight)
    clientId = apolloConfig.jurongdaynightId;
  if (host === constants.clientHostNames.kingswaymedical)
    clientId = apolloConfig.kingswaymedicalId;
  if (host === constants.clientHostNames.thegoodclinic)
    clientId = apolloConfig.thegoodclinicId;
  if (host === constants.clientHostNames.tmedicalkallang)
    clientId = apolloConfig.tmedicalkallangId;
  if (host === constants.clientHostNames.tmedicalcanberra)
    clientId = apolloConfig.tmedicalcanberraId;
  if (host === constants.clientHostNames.tmedicalcitygate)
    clientId = apolloConfig.tmedicalcitygateId;
  if (host === constants.clientHostNames.healthcaremedicalkovan)
    clientId = apolloConfig.healthcaremedicalkovanId;
  if (host === constants.clientHostNames.doctors4lifepickering)
    clientId = apolloConfig.doctors4lifepickeringId;
  if (host === constants.clientHostNames.shalomclinicsurgery)
    clientId = apolloConfig.shalomclinicsurgeryId;
  if (host === constants.clientHostNames.acmsmedicalclinic)
    clientId = apolloConfig.acmsmedicalclinicId;
  if (host === constants.clientHostNames.mydoctorcanberra)
    clientId = apolloConfig.mydoctorcanberraId;
  if (host === constants.clientHostNames.healthlinkmed)
    clientId = apolloConfig.healthlinkmedId;
  if (host === constants.clientHostNames.singapuraclinic)
    clientId = apolloConfig.singapuraclinicId;
  if (host === constants.clientHostNames.bukittimahclinic)
    clientId = apolloConfig.bukittimahclinicId;
  if (host === constants.clientHostNames.newcastleclinic)
    clientId = apolloConfig.newcastleclinicId;
  if (host === constants.clientHostNames.thedublinclinic)
    clientId = apolloConfig.thedublinclinicId;
  if (host === constants.clientHostNames.unihealthclinicjurongeast)
    clientId = apolloConfig.unihealthclinicjurongeastId;
  if (host === constants.clientHostNames.belieffamilyclinic)
    clientId = apolloConfig.belieffamilyclinicId;
  if (host === constants.clientHostNames.healthpartnershipmedical)
    clientId = apolloConfig.healthpartnershipmedicalId;
  if (host === constants.clientHostNames.cityGPFamilyClinic)
    clientId = apolloConfig.cityGPFamilyClinicId;
  if (host === constants.clientHostNames.nippon)
    clientId = apolloConfig.nipponId;
  if (host === constants.clientHostNames.zeaborn)
    clientId = apolloConfig.zeabornId;
  if (host === constants.clientHostNames.micah)
    clientId = apolloConfig.clientIdMicah;
  if (host === constants.clientHostNames.pacc)
    clientId = apolloConfig.clientIdPacc;
  if (host === constants.clientHostNames.eurofinsSG)
    clientId = apolloConfig.clientIdEurofinsSG;
  if (host === constants.clientHostNames.molecularLab)
    clientId = apolloConfig.clientIdMolecularLab;
  if (host === constants.clientHostNames.stLukesPH)
    clientId = apolloConfig.clientIdStLukesPH;
  if (host === constants.clientHostNames.mmal)
    clientId = apolloConfig.clientIdMaritimeMedical;
  if (host === constants.clientHostNames.bwGroup)
    clientId = apolloConfig.clientIdBwGroup;
  if (host === constants.clientHostNames.hiPrecision)
    clientId = apolloConfig.clientIdHiPrecision;
  if (host === constants.clientHostNames.superCare)
    clientId = apolloConfig.clientIdSuperCare;
  if (host === constants.clientHostNames.bsm)
    clientId = apolloConfig.clientIdBsm;
  if (host === constants.clientHostNames.demo)
    clientId = apolloConfig.clientIdDemo;
  if (host === constants.clientHostNames.martec)
    clientId = apolloConfig.clientIdMartec;
  if (host === constants.clientHostNames.mtr)
    clientId = apolloConfig.clientIdMtr;
  if (host === constants.clientHostNames.ntc)
    clientId = apolloConfig.clientIdNtc;
  if (host === constants.clientHostNames.crowley)
    clientId = apolloConfig.clientIdCrowley;
  if (host === constants.clientHostNames.solverMinds)
    clientId = apolloConfig.clientIdSolverMinds;
  if (host === constants.clientHostNames.medical)
    clientId = apolloConfig.clientIdMedical;
  if (host === constants.clientHostNames.newPathFamilyClinic)
    clientId = apolloConfig.newPathFamilyClinicId;
  if (host === constants.clientHostNames.shanah)
    clientId = apolloConfig.shanahId;
  if (host === constants.clientHostNames.proHealth)
    clientId = apolloConfig.proHealthId;
  if (host === constants.clientHostNames.aMedicalClinic)
    clientId = apolloConfig.aMedicalClinicId;
  if (host === constants.clientHostNames.wlhMedicalServices)
    clientId = apolloConfig.wlhMedicalServicesId;
  if (host === constants.clientHostNames.drWFamilyClinic)
    clientId = apolloConfig.drWFamilyClinicId;
  if (host === constants.clientHostNames.internationalMedicalClinicCamden)
    clientId = apolloConfig.internationalMedicalClinicCamdenId;
  if (host === constants.clientHostNames.internationalMedicalClinicJelita)
    clientId = apolloConfig.internationalMedicalClinicJelitaId;
  if (host === constants.clientHostNames.internationalMedicalClinicKatong)
    clientId = apolloConfig.internationalMedicalClinicKatongId;
  if (host === constants.clientHostNames.internationalMedicalClinicChildren)
    clientId = apolloConfig.internationalMedicalClinicChildrenId;
  if (host === constants.clientHostNames.twinCityMedicalCentre)
    clientId = apolloConfig.twinCityMedicalCentreId;
  if (host === constants.clientHostNames.platinum)
    clientId = apolloConfig.platinumId;
  if (host === constants.clientHostNames.providenceClinicHillford)
    clientId = apolloConfig.providenceClinicHillfordId;
  if (host === constants.clientHostNames.healthWerkzMedical)
    clientId = apolloConfig.healthWerkzMedicalId;
  if (host === constants.clientHostNames.healthWerkzCckMedical)
    clientId = apolloConfig.healthWerkzCckMedicalId;
  if (host === constants.clientHostNames.gardenclinic)
    clientId = apolloConfig.gardenclinicId;
  if (host === constants.clientHostNames.fullerton)
    clientId = apolloConfig.fullertonId;
  if (host === constants.clientHostNames.affinity)
    clientId = apolloConfig.affinityId;
  if (host === constants.clientHostNames.pacificFamily)
    clientId = apolloConfig.pacificFamilyId;
  if (host === constants.clientHostNames.medLife)
    clientId = apolloConfig.medLifeId;
  if (host === constants.clientHostNames.healthCareRailmall)
    clientId = apolloConfig.healthCareRailmallId;
  if (host === constants.clientHostNames.gleneagles)
    clientId = apolloConfig.gleneaglesId;
  if (host === constants.clientHostNames.lifelinebtbatok)
    clientId = apolloConfig.lifelinebtbatokId;
  if (host === constants.clientHostNames.commcare)
    clientId = apolloConfig.commcareId;
  if (host === constants.clientHostNames.faircareyishun)
    clientId = apolloConfig.faircareyishunId;
  if (host === constants.clientHostNames.daySpring)
    clientId = apolloConfig.daySpringId;
  if (host === constants.clientHostNames.docchangi)
    clientId = apolloConfig.docchangi;
  if (host === constants.clientHostNames.iswabxpresssilkroutessembawang)
    clientId = apolloConfig.iswabxpresssilkroutessembawang;
  if (host === constants.clientHostNames.healthwayaljunied)
    clientId = apolloConfig.healthwayaljunied;
  if (host === constants.clientHostNames.healthwaymarineterrace)
    clientId = apolloConfig.healthwaymarineterrace;
  if (host === constants.clientHostNames.iswabtravelcaredoc)
    clientId = apolloConfig.iswabtravelcaredocId;
  if (host === constants.clientHostNames.allswellmedicalclinic)
    clientId = apolloConfig.allswellmedicalclinicId;
  if (host === constants.clientHostNames.intemedicaltampines)
    clientId = apolloConfig.intemedicaltampinesId;
  if (host === constants.clientHostNames.amaritimeclinic)
    clientId = apolloConfig.amaritimeclinicId;
  if (host === constants.clientHostNames.ourtampinesfamilyclinic)
    clientId = apolloConfig.ourtampinesfamilyclinicId;
  if (host === constants.clientHostNames.minmedboonlay)
    clientId = apolloConfig.minmedboonlayId;
  if (host === constants.clientHostNames.lohandlohclinicamk)
    clientId = apolloConfig.lohandlohclinicamkId;
  if (host === constants.clientHostNames.swabnowwoodgrovefc)
    clientId = apolloConfig.swabnowwoodgrovefcId;
  if (host === constants.clientHostNames.myclnqjurong)
    clientId = apolloConfig.myclnqjurongId;
  if (host === constants.clientHostNames.sghealthcareunited)
    clientId = apolloConfig.sghealthcareunitedId;
  if (host === constants.clientHostNames.kindredfamilyclinickallangbahru)
    clientId = apolloConfig.kindredfamilyclinickallangbahruId;
  if (host === constants.clientHostNames.minmedredhill)
    clientId = apolloConfig.minmedredhillId;
  if (host === constants.clientHostNames.intemedicalteckghee)
    clientId = apolloConfig.intemedicalteckgheeId;
  if (host === constants.clientHostNames.nanyangsurgery)
    clientId = apolloConfig.nanyangsurgeryId;
  if (host === constants.clientHostNames.truecarecliniceunos)
    clientId = apolloConfig.truecarecliniceunosId;
  if (host === constants.clientHostNames.zestclinic)
    clientId = apolloConfig.zestclinicId;
  if (host === constants.clientHostNames.justcaremedical)
    clientId = apolloConfig.justcaremedicalId;
  if (host === constants.clientHostNames.onecaremedicalsenja)
    clientId = apolloConfig.onecaremedicalsenjaId;
  if (host === constants.clientHostNames.unihealthclinicyishun)
    clientId = apolloConfig.unihealthclinicyishunId;
  if (host === constants.clientHostNames.thomsonjpfclinic)
    clientId = apolloConfig.thomsonjpfclinicId;
  if (host === constants.clientHostNames.sohwellmedical)
    clientId = apolloConfig.sohwellmedicalId;
  if (host === constants.clientHostNames.northshorefamilyclinic)
    clientId = apolloConfig.northshorefamilyclinicId;
  if (host === constants.clientHostNames.onecaremedicalbukitbatokwesteast)
    clientId = apolloConfig.onecaremedicalbukitbatokwesteastId;
  if (host === constants.clientHostNames.myclnqmapex)
    clientId = apolloConfig.myclnqmapexId;
  if (host === constants.clientHostNames.ardenneshealth)
    clientId = apolloConfig.ardenneshealthId;
  if (host === constants.clientHostNames.advantagemedicalbedok)
    clientId = apolloConfig.advantagemedicalbedokId;
  if (host === constants.clientHostNames.advantagemedicaljurongwest)
    clientId = apolloConfig.advantagemedicaljurongwestId;
  if (host === constants.clientHostNames.mindefmedicalcentre)
    clientId = apolloConfig.mindefmedicalcentreId;
  if (host === constants.clientHostNames.onecaremedicalbishan)
    clientId = apolloConfig.onecaremedicalbishanId;
  if (host === constants.clientHostNames.acehealthmedical)
    clientId = apolloConfig.acehealthmedicalId;
  if (host === constants.clientHostNames.minmedmarinabay)
    clientId = apolloConfig.minmedmarinabayId;
  if (host === constants.clientHostNames.tmcbs) clientId = apolloConfig.tmcbsId;
  if (host === constants.clientHostNames.minmedbedok)
    clientId = apolloConfig.minmedbedokId;
  if (host === constants.clientHostNames.familymedical280)
    clientId = apolloConfig.familymedical280Id;
  if (host === constants.clientHostNames.manadrcitygate)
    clientId = apolloConfig.manadrcitygateId;
  if (host === constants.clientHostNames.clarihealth)
    clientId = apolloConfig.clarihealthId;
  if (host === constants.clientHostNames.minmedwoodlands)
    clientId = apolloConfig.minmedwoodlandsId;
  if (host === constants.clientHostNames.myopiaspecialistcentre)
    clientId = apolloConfig.myopiaspecialistcentreId;
  if (host === constants.clientHostNames.peakmedicalwp)
    clientId = apolloConfig.peakmedicalwpId;
  if (host === constants.clientHostNames.docbedok)
    clientId = apolloConfig.docbedokId;
  if (host === constants.clientHostNames.thetestingpro)
    clientId = apolloConfig.thetestingproId;
  if (host === constants.clientHostNames.onecaremedicaljunction9)
    clientId = apolloConfig.onecaremedicaljunction9Id;
  if (host === constants.clientHostNames.aljuniedmed)
    clientId = apolloConfig.aljuniedmedId;
  if (host === constants.clientHostNames.minmedamk)
    clientId = apolloConfig.minmedamkId;
  if (host === constants.clientHostNames.medstarmedical176)
    clientId = apolloConfig.medstarmedical176Id;
  if (host === constants.clientHostNames.minmedclementi)
    clientId = apolloConfig.minmedclementiId;
  if (host === constants.clientHostNames.dawsonfamilyclinic)
    clientId = apolloConfig.dawsonfamilyclinicId;
  if (host === constants.clientHostNames.onecaremedicalserangoonnorth)
    clientId = apolloConfig.onecaremedicalserangoonnorthId;
  if (host === constants.clientHostNames.gpjoochiat)
    clientId = apolloConfig.gpjoochiatId;
  if (host === constants.clientHostNames.fastpdtswab)
    clientId = apolloConfig.fastpdtswabId;
  if (host === constants.clientHostNames.centralclinicmp)
    clientId = apolloConfig.centralclinicmpId;
  if (host === constants.clientHostNames.oglmedicalnovena)
    clientId = apolloConfig.oglmedicalnovenaId;
  if (host === constants.clientHostNames.onecaremedicalpunggolsumang)
    clientId = apolloConfig.onecaremedicalpunggolsumangId;
  if (host === constants.clientHostNames.drmpctampines)
    clientId = apolloConfig.drmpctampinesId;
  if (host === constants.clientHostNames.healthwaymlw)
    clientId = apolloConfig.healthwaymlwId;
  if (host === constants.clientHostNames.inspiremedicaljalanbatu)
    clientId = apolloConfig.inspiremedicaljalanbatuId;
  if (host === constants.clientHostNames.unihealthclinicboonlay)
    clientId = apolloConfig.unihealthclinicboonlayId;
  if (host === constants.clientHostNames.apollomedicalcentre)
    clientId = apolloConfig.apollomedicalcentreId;
  if (host === constants.clientHostNames.onecaremedicaljurongwest)
    clientId = apolloConfig.onecaremedicaljurongwestId;
  if (host === constants.clientHostNames.samhsimei)
    clientId = apolloConfig.samhsimeiId;
  if (host === constants.clientHostNames.earlyhealth)
    clientId = apolloConfig.earlyhealthId;
  if (host === constants.clientHostNames.kingstonmedicalaljunied)
    clientId = apolloConfig.kingstonmedicalaljuniedId;
  if (host === constants.clientHostNames.curasiaendoscopy)
    clientId = apolloConfig.curasiaendoscopyId;
  if (host === constants.clientHostNames.bchpasirris)
    clientId = apolloConfig.bchpasirrisId;
  if (host === constants.clientHostNames.sengkangsrmedihealth)
    clientId = apolloConfig.sengkangsrmedihealthId;
  if (host === constants.clientHostNames.sembawangsrmedihealth)
    clientId = apolloConfig.sembawangsrmedihealthId;
  if (host === constants.clientHostNames.farrerparklaboratory)
    clientId = apolloConfig.farrerparklaboratoryId;
  if (host === constants.clientHostNames.simeiclinicsurgery)
    clientId = apolloConfig.simeiclinicsurgeryId;
  if (host === constants.clientHostNames.rubin) clientId = apolloConfig.rubinId;
  if (host === constants.clientHostNames.acumedwoodlands)
    clientId = apolloConfig.acumedwoodlandsId;
  if (host === constants.clientHostNames.braddellmedical)
    clientId = apolloConfig.braddellmedicalId;
  if (host === constants.clientHostNames.heartlandhealthtampines)
    clientId = apolloConfig.heartlandhealthtampinesId;
  if (host === constants.clientHostNames.heartlandhealthjurongeast)
    clientId = apolloConfig.heartlandhealthjurongeastId;
  if (host === constants.clientHostNames.heartlandhealthtoapayoh)
    clientId = apolloConfig.heartlandhealthtoapayohId;
  if (host === constants.clientHostNames.heartlandhealthredhillclose)
    clientId = apolloConfig.heartlandhealthredhillcloseId;
  if (host === constants.clientHostNames.heartlandhealthbukitbatok)
    clientId = apolloConfig.heartlandhealthbukitbatokId;
  if (host === constants.clientHostNames.heartlandhealthcircuitroad)
    clientId = apolloConfig.heartlandhealthcircuitroadId;
  if (host === constants.clientHostNames.heartlandhealthjurongwest)
    clientId = apolloConfig.heartlandhealthjurongwestId;
  if (host === constants.clientHostNames.heartlandhealthbedoksouth)
    clientId = apolloConfig.heartlandhealthbedoksouthId;
  if (host === constants.clientHostNames.heartlandhealthkimkeat)
    clientId = apolloConfig.heartlandhealthkimkeatId;
  if (host === constants.clientHostNames.heartlandhealthnewupperchangi)
    clientId = apolloConfig.heartlandhealthnewupperchangiId;
  if (host === constants.clientHostNames.heartlandhealthwhampoa)
    clientId = apolloConfig.heartlandhealthwhampoaId;
  if (host === constants.clientHostNames.intemedicalkovan)
    clientId = apolloConfig.intemedicalkovanId;
  if (host === constants.clientHostNames.lifeplusmedicalsinming)
    clientId = apolloConfig.lifeplusmedicalsinmingId;
  if (host === constants.clientHostNames.maiphamedical)
    clientId = apolloConfig.maiphamedicalId;
  if (host === constants.clientHostNames.medilineclinicsgoongarden)
    clientId = apolloConfig.medilineclinicsgoongardenId;
  if (host === constants.clientHostNames.medilineclinicmarineparade)
    clientId = apolloConfig.medilineclinicmarineparadeId;
  if (host === constants.clientHostNames.neptunehealthcare)
    clientId = apolloConfig.neptunehealthcareId;
  if (host === constants.clientHostNames.shalommedicalgroup)
    clientId = apolloConfig.shalommedicalgroupId;
  if (host === constants.clientHostNames.aeromedicalcentre)
    clientId = apolloConfig.aeromedicalcentreId;
  if (host === constants.clientHostNames.tanyikclinic)
    clientId = apolloConfig.tanyikclinicId;
  if (host === constants.clientHostNames.themedicalaestheticclinic)
    clientId = apolloConfig.themedicalaestheticclinicId;
  if (host === constants.clientHostNames.thomsonwellthclinic)
    clientId = apolloConfig.thomsonwellthclinicId;
  if (host === constants.clientHostNames.tuasmedical)
    clientId = apolloConfig.tuasmedicalId;
  if (host === constants.clientHostNames.tuasviewmedical)
    clientId = apolloConfig.tuasviewmedicalId;
  if (host === constants.clientHostNames.woodlandsmedicalcentre)
    clientId = apolloConfig.woodlandsmedicalcentreId;
  if (host === constants.clientHostNames.tractorsfullerton)
    clientId = apolloConfig.tractorsfullertonId;
  if (host === constants.clientHostNames.micronfab7fullerton)
    clientId = apolloConfig.micronfab7fullertonId;
  if (host === constants.clientHostNames.micronfab10fullerton)
    clientId = apolloConfig.micronfab10fullertonId;
  if (host === constants.clientHostNames.micronmsbfullerton)
    clientId = apolloConfig.micronmsbfullertonId;
  if (host === constants.clientHostNames.ofsfullerton)
    clientId = apolloConfig.ofsfullertonId;
  if (host === constants.clientHostNames.globalfoundriesfullerton)
    clientId = apolloConfig.globalfoundriesfullertonId;
  if (host === constants.clientHostNames.lumiledsfullerton)
    clientId = apolloConfig.lumiledsfullertonId;
  if (host === constants.clientHostNames.ubsfullerton)
    clientId = apolloConfig.ubsfullertonId;
  if (host === constants.clientHostNames.gjmpfullerton)
    clientId = apolloConfig.gjmpfullertonId;
  if (host === constants.clientHostNames.alexandraaiafullerton)
    clientId = apolloConfig.alexandraaiafullertonId;
  if (host === constants.clientHostNames.batfullerton)
    clientId = apolloConfig.batfullertonId;
  if (host === constants.clientHostNames.ashfordmedical)
    clientId = apolloConfig.ashfordmedicalId;
  if (host === constants.clientHostNames.aspenclinic)
    clientId = apolloConfig.aspenclinicId;
  if (host === constants.clientHostNames.sengkanggeneralhospital)
    clientId = apolloConfig.sengkanggeneralhospitalId;
  if (host === constants.clientHostNames.meridianmedical)
    clientId = apolloConfig.meridianmedicalId;
  if (host === constants.clientHostNames.blessmcjurongeast)
    clientId = apolloConfig.blessmcjurongeastId;
  if (host === constants.clientHostNames.babystepsvoco)
    clientId = apolloConfig.babystepsvocoId;
  if (host === constants.clientHostNames.wfc305)
    clientId = apolloConfig.wfc305Id;
  if (host === constants.clientHostNames.pathlabs)
    clientId = apolloConfig.pathlabsId;
  if (host === constants.clientHostNames.tfpskyville)
    clientId = apolloConfig.tfpskyvilleId;
  if (host === constants.clientHostNames.minmedgeylangserai)
    clientId = apolloConfig.minmedgeylangseraiId;
  if (host === constants.clientHostNames.hpfcwm)
    clientId = apolloConfig.hpfcwmId;
  if (host === constants.clientHostNames.drssinghandpartners)
    clientId = apolloConfig.drssinghandpartnersId;
  if (host === constants.clientHostNames.drpthomson)
    clientId = apolloConfig.drpthomsonId;
  if (host === constants.clientHostNames.majuclinicsrmedihealth)
    clientId = apolloConfig.majuclinicsrmedihealthId;
  if (host === constants.clientHostNames.yslaljunied)
    clientId = apolloConfig.yslaljuniedId;
  if (host === constants.clientHostNames.distinctclinicbb)
    clientId = apolloConfig.distinctclinicbbId;
  if (host === constants.clientHostNames.drsohfamilyclinic)
    clientId = apolloConfig.drsohfamilyclinicId;
  if (host === constants.clientHostNames.saudaraajgeneralphysicians)
    clientId = apolloConfig.saudaraajgeneralphysiciansId;
  if (host === constants.clientHostNames.smhaneefaclinic11)
    clientId = apolloConfig.smhaneefaclinic11Id;
  if (host === constants.clientHostNames.ahppl) clientId = apolloConfig.ahpplId;
  if (host === constants.clientHostNames.procross216)
    clientId = apolloConfig.procross216Id;
  if (host === constants.clientHostNames.familyclinic)
    clientId = apolloConfig.familyclinicId;
  if (host === constants.clientHostNames.procross405)
    clientId = apolloConfig.procross405Id;
  if (host === constants.clientHostNames.procross411)
    clientId = apolloConfig.procross411Id;
  if (host === constants.clientHostNames.ahealingheartmedical)
    clientId = apolloConfig.ahealingheartmedicalId;
  if (host === constants.clientHostNames.accordmedical)
    clientId = apolloConfig.accordmedicalId;
  if (host === constants.clientHostNames.admiraltyfmc)
    clientId = apolloConfig.admiraltyfmcId;
  if (host === constants.clientHostNames.aemedicalclinic)
    clientId = apolloConfig.aemedicalclinicId;
  if (host === constants.clientHostNames.allyhealth)
    clientId = apolloConfig.allyhealthId;
  if (host === constants.clientHostNames.amdasg)
    clientId = apolloConfig.amdasgId;
  if (host === constants.clientHostNames.aiclinic)
    clientId = apolloConfig.aiclinicId;
  if (host === constants.clientHostNames.ansariclinic)
    clientId = apolloConfig.ansariclinicId;
  if (host === constants.clientHostNames.apex2292)
    clientId = apolloConfig.apex2292Id;
  if (host === constants.clientHostNames.asiaretina)
    clientId = apolloConfig.asiaretinaId;
  if (host === constants.clientHostNames.assurancemedicare)
    clientId = apolloConfig.assurancemedicareId;
  if (host === constants.clientHostNames.atlaspacificmedical)
    clientId = apolloConfig.atlaspacificmedicalId;
  if (host === constants.clientHostNames.avecomedicaljem)
    clientId = apolloConfig.avecomedicaljemId;
  if (host === constants.clientHostNames.avecomedicalmarineparade)
    clientId = apolloConfig.avecomedicalmarineparadeId;
  if (host === constants.clientHostNames.banyanclinicwoodlands)
    clientId = apolloConfig.banyanclinicwoodlandsId;
  if (host === constants.clientHostNames.bethesdamedical)
    clientId = apolloConfig.bethesdamedicalId;
  if (host === constants.clientHostNames.binjaimedicalclinic)
    clientId = apolloConfig.binjaimedicalclinicId;
  if (host === constants.clientHostNames.blmedicalassociates)
    clientId = apolloConfig.blmedicalassociatesId;
  if (host === constants.clientHostNames.blisscaremedical)
    clientId = apolloConfig.blisscaremedicalId;
  if (host === constants.clientHostNames.cktanfamilyclinic125)
    clientId = apolloConfig.cktanfamilyclinic125Id;
  if (host === constants.clientHostNames.camrymedicalcentre)
    clientId = apolloConfig.camrymedicalcentreId;
  if (host === constants.clientHostNames.caritassg)
    clientId = apolloConfig.caritassgId;
  if (host === constants.clientHostNames.centralclinicpioneer)
    clientId = apolloConfig.centralclinicpioneerId;
  if (host === constants.clientHostNames.centralclinicbedok)
    clientId = apolloConfig.centralclinicbedokId;
  if (host === constants.clientHostNames.centralclinicclementi)
    clientId = apolloConfig.centralclinicclementiId;
  if (host === constants.clientHostNames.centralclinichougang)
    clientId = apolloConfig.centralclinichougangId;
  if (host === constants.clientHostNames.centralclinicmarsiling)
    clientId = apolloConfig.centralclinicmarsilingId;
  if (host === constants.clientHostNames.centralclinictampines)
    clientId = apolloConfig.centralclinictampinesId;
  if (host === constants.clientHostNames.centralclinicyishun)
    clientId = apolloConfig.centralclinicyishunId;
  if (host === constants.clientHostNames.centralclinicjurong)
    clientId = apolloConfig.centralclinicjurongId;
  if (host === constants.clientHostNames.changclinicandsurgery)
    clientId = apolloConfig.changclinicandsurgeryId;
  if (host === constants.clientHostNames.chenfc)
    clientId = apolloConfig.chenfcId;
  if (host === constants.clientHostNames.chiaclinicsurgery)
    clientId = apolloConfig.chiaclinicsurgeryId;
  if (host === constants.clientHostNames.chinatownwellnessclinic)
    clientId = apolloConfig.chinatownwellnessclinicId;
  if (host === constants.clientHostNames.chironmedicalclinic)
    clientId = apolloConfig.chironmedicalclinicId;
  if (host === constants.clientHostNames.choongsclinic)
    clientId = apolloConfig.choongsclinicId;
  if (host === constants.clientHostNames.choongsclinictampines)
    clientId = apolloConfig.choongsclinictampinesId;
  if (host === constants.clientHostNames.chuahclinicsurgery)
    clientId = apolloConfig.chuahclinicsurgeryId;
  if (host === constants.clientHostNames.cityhealth)
    clientId = apolloConfig.cityhealthId;
  if (host === constants.clientHostNames.citymed)
    clientId = apolloConfig.citymedId;
  if (host === constants.clientHostNames.cornerstonemedical)
    clientId = apolloConfig.cornerstonemedicalId;
  if (host === constants.clientHostNames.cornerstonemedicalbidadari)
    clientId = apolloConfig.cornerstonemedicalbidadariId;
  if (host === constants.clientHostNames.dedicaremedical)
    clientId = apolloConfig.dedicaremedicalId;
  if (host === constants.clientHostNames.distinctclinicje)
    clientId = apolloConfig.distinctclinicjeId;
  if (host === constants.clientHostNames.drchanpengmun)
    clientId = apolloConfig.drchanpengmunId;
  if (host === constants.clientHostNames.drschantangfamilyclinic)
    clientId = apolloConfig.drschantangfamilyclinicId;
  if (host === constants.clientHostNames.drstangandpartners)
    clientId = apolloConfig.drstangandpartnersId;
  if (host === constants.clientHostNames.drsbainibp)
    clientId = apolloConfig.drsbainibpId;
  if (host === constants.clientHostNames.drsbainhf)
    clientId = apolloConfig.drsbainhfId;
  if (host === constants.clientHostNames.ejtanclinicsurgery)
    clientId = apolloConfig.ejtanclinicsurgeryId;
  if (host === constants.clientHostNames.eastcoastfamily)
    clientId = apolloConfig.eastcoastfamilyId;
  if (host === constants.clientHostNames.easternmedicalcentre)
    clientId = apolloConfig.easternmedicalcentreId;
  if (host === constants.clientHostNames.eeclinic)
    clientId = apolloConfig.eeclinicId;
  if (host === constants.clientHostNames.ehmedicalwoodlands)
    clientId = apolloConfig.ehmedicalwoodlandsId;
  if (host === constants.clientHostNames.ehmedicalbb)
    clientId = apolloConfig.ehmedicalbbId;
  if (host === constants.clientHostNames.ehaclinicjurong)
    clientId = apolloConfig.ehaclinicjurongId;
  if (host === constants.clientHostNames.elissamedical)
    clientId = apolloConfig.elissamedicalId;
  if (host === constants.clientHostNames.faithmedicalcs)
    clientId = apolloConfig.faithmedicalcsId;
  if (host === constants.clientHostNames.familycarecsurgery)
    clientId = apolloConfig.familycarecsurgeryId;
  if (host === constants.clientHostNames.finesthealthtpy)
    clientId = apolloConfig.finesthealthtpyId;
  if (host === constants.clientHostNames.firstcare253)
    clientId = apolloConfig.firstcare253Id;
  if (host === constants.clientHostNames.firstmedical167)
    clientId = apolloConfig.firstmedical167Id;
  if (host === constants.clientHostNames.frasermedical)
    clientId = apolloConfig.frasermedicalId;
  if (host === constants.clientHostNames.frontierhealthcareclementi)
    clientId = apolloConfig.frontierhealthcareclementiId;
  if (host === constants.clientHostNames.frontierhealthcareadmiralty)
    clientId = apolloConfig.frontierhealthcareadmiraltyId;
  if (host === constants.clientHostNames.frontierhealthcarebuangkok)
    clientId = apolloConfig.frontierhealthcarebuangkokId;
  if (host === constants.clientHostNames.frontierhealthcarebbwr)
    clientId = apolloConfig.frontierhealthcarebbwrId;
  if (host === constants.clientHostNames.frontierhealthcarebbwt)
    clientId = apolloConfig.frontierhealthcarebbwtId;
  if (host === constants.clientHostNames.frontierhealthcarecanberra)
    clientId = apolloConfig.frontierhealthcarecanberraId;
  if (host === constants.clientHostNames.frontierhealthcarejurongwest)
    clientId = apolloConfig.frontierhealthcarejurongwestId;
  if (host === constants.clientHostNames.frontierhealthcarewoodlands)
    clientId = apolloConfig.frontierhealthcarewoodlandsId;
  if (host === constants.clientHostNames.frontierhealthcarebedok)
    clientId = apolloConfig.frontierhealthcarebedokId;
  if (host === constants.clientHostNames.generalpracticefcs)
    clientId = apolloConfig.generalpracticefcsId;
  if (host === constants.clientHostNames.hchillford)
    clientId = apolloConfig.hchillfordId;
  if (host === constants.clientHostNames.healgroup)
    clientId = apolloConfig.healgroupId;
  if (host === constants.clientHostNames.healthifymedical)
    clientId = apolloConfig.healthifymedicalId;
  if (host === constants.clientHostNames.healthwerkzsembawang)
    clientId = apolloConfig.healthwerkzsembawangId;
  if (host === constants.clientHostNames.hisemainnbb)
    clientId = apolloConfig.hisemainnbbId;
  if (host === constants.clientHostNames.hisemainnjw)
    clientId = apolloConfig.hisemainnjwId;
  if (host === constants.clientHostNames.homedicalclinic)
    clientId = apolloConfig.homedicalclinicId;
  if (host === constants.clientHostNames.imedicalaesthetics)
    clientId = apolloConfig.imedicalaestheticsId;
  if (host === constants.clientHostNames.icaremedical)
    clientId = apolloConfig.icaremedicalId;
  if (host === constants.clientHostNames.intemedicalpotongpasir)
    clientId = apolloConfig.intemedicalpotongpasirId;
  if (host === constants.clientHostNames.japangreenclinic)
    clientId = apolloConfig.japangreenclinicId;
  if (host === constants.clientHostNames.jasmedical)
    clientId = apolloConfig.jasmedicalId;
  if (host === constants.clientHostNames.joyfamilyclinic)
    clientId = apolloConfig.joyfamilyclinicId;
  if (host === constants.clientHostNames.knchinclifford)
    clientId = apolloConfig.knchincliffordId;
  if (host === constants.clientHostNames.knchinsuntec)
    clientId = apolloConfig.knchinsuntecId;
  if (host === constants.clientHostNames.kbtanclin)
    clientId = apolloConfig.kbtanclinId;
  if (host === constants.clientHostNames.kblhealthcare)
    clientId = apolloConfig.kblhealthcareId;
  if (host === constants.clientHostNames.kentridgeclinic)
    clientId = apolloConfig.kentridgeclinicId;
  if (host === constants.clientHostNames.killineymedicalclinic)
    clientId = apolloConfig.killineymedicalclinicId;
  if (host === constants.clientHostNames.lavendermedical)
    clientId = apolloConfig.lavendermedicalId;
  if (host === constants.clientHostNames.lee123clinic)
    clientId = apolloConfig.lee123clinicId;
  if (host === constants.clientHostNames.leefamilyclinic)
    clientId = apolloConfig.leefamilyclinicId;
  if (host === constants.clientHostNames.lianclinic18)
    clientId = apolloConfig.lianclinic18Id;
  if (host === constants.clientHostNames.lifelinksn)
    clientId = apolloConfig.lifelinksnId;
  if (host === constants.clientHostNames.limclinicamk)
    clientId = apolloConfig.limclinicamkId;
  if (host === constants.clientHostNames.linandsons)
    clientId = apolloConfig.linandsonsId;
  if (host === constants.clientHostNames.loyangmedicalcentre)
    clientId = apolloConfig.loyangmedicalcentreId;
  if (host === constants.clientHostNames.mlamclinic)
    clientId = apolloConfig.mlamclinicId;
  if (host === constants.clientHostNames.macphersonmedicalclinic)
    clientId = apolloConfig.macphersonmedicalclinicId;
  if (host === constants.clientHostNames.mhcasiambs)
    clientId = apolloConfig.mhcasiambsId;
  if (host === constants.clientHostNames.medicareassociates)
    clientId = apolloConfig.medicareassociatesId;
  if (host === constants.clientHostNames.medilife474)
    clientId = apolloConfig.medilife474Id;
  if (host === constants.clientHostNames.melpartnersdrs)
    clientId = apolloConfig.melpartnersdrsId;
  if (host === constants.clientHostNames.mhcasiaamara)
    clientId = apolloConfig.mhcasiaamaraId;
  if (host === constants.clientHostNames.millenniumwoodlands)
    clientId = apolloConfig.millenniumwoodlandsId;
  if (host === constants.clientHostNames.mutualav)
    clientId = apolloConfig.mutualavId;
  if (host === constants.clientHostNames.mutualcv)
    clientId = apolloConfig.mutualcvId;
  if (host === constants.clientHostNames.myhealthpartners)
    clientId = apolloConfig.myhealthpartnersId;
  if (host === constants.clientHostNames.neighbourhoodclinic)
    clientId = apolloConfig.neighbourhoodclinicId;
  if (host === constants.clientHostNames.ntcpl2006)
    clientId = apolloConfig.ntcpl2006Id;
  if (host === constants.clientHostNames.nexusaestheticclinic)
    clientId = apolloConfig.nexusaestheticclinicId;
  if (host === constants.clientHostNames.nichiiicl)
    clientId = apolloConfig.nichiiiclId;
  if (host === constants.clientHostNames.norwoodmedicalclinic)
    clientId = apolloConfig.norwoodmedicalclinicId;
  if (host === constants.clientHostNames.onemedical)
    clientId = apolloConfig.onemedicalId;
  if (host === constants.clientHostNames.onewellnessmedicali12)
    clientId = apolloConfig.onewellnessmedicali12Id;
  if (host === constants.clientHostNames.oslerhealthraffleshotelarcade)
    clientId = apolloConfig.oslerhealthraffleshotelarcadeId;
  if (host === constants.clientHostNames.pacificmdgrp)
    clientId = apolloConfig.pacificmdgrpId;
  if (host === constants.clientHostNames.parklanemedicalcentre)
    clientId = apolloConfig.parklanemedicalcentreId;
  if (host === constants.clientHostNames.pcsmedicalcentre)
    clientId = apolloConfig.pcsmedicalcentreId;
  if (host === constants.clientHostNames.phoenixmedicalseletar)
    clientId = apolloConfig.phoenixmedicalseletarId;
  if (host === constants.clientHostNames.phoenixmedicalnovena)
    clientId = apolloConfig.phoenixmedicalnovenaId;
  if (host === constants.clientHostNames.phoenixmedicalhillview)
    clientId = apolloConfig.phoenixmedicalhillviewId;
  if (host === constants.clientHostNames.phoenixmedicalpayalebar)
    clientId = apolloConfig.phoenixmedicalpayalebarId;
  if (host === constants.clientHostNames.potongpasirmedical)
    clientId = apolloConfig.potongpasirmedicalId;
  if (host === constants.clientHostNames.preciousmed)
    clientId = apolloConfig.preciousmedId;
  if (host === constants.clientHostNames.procrosshougang)
    clientId = apolloConfig.procrosshougangId;
  if (host === constants.clientHostNames.procrosswoodlands)
    clientId = apolloConfig.procrosswoodlandsId;
  if (host === constants.clientHostNames.punggolfamilyclinic)
    clientId = apolloConfig.punggolfamilyclinicId;
  if (host === constants.clientHostNames.rcmclmg)
    clientId = apolloConfig.rcmclmgId;
  if (host === constants.clientHostNames.reddotmedical)
    clientId = apolloConfig.reddotmedicalId;
  if (host === constants.clientHostNames.redhillclinic)
    clientId = apolloConfig.redhillclinicId;
  if (host === constants.clientHostNames.redwoodtpy)
    clientId = apolloConfig.redwoodtpyId;
  if (host === constants.clientHostNames.rochorcentreclinic)
    clientId = apolloConfig.rochorcentreclinicId;
  if (host === constants.clientHostNames.slfamilyclinic729)
    clientId = apolloConfig.slfamilyclinic729Id;
  if (host === constants.clientHostNames.slfamilyclinic139)
    clientId = apolloConfig.slfamilyclinic139Id;
  if (host === constants.clientHostNames.sataamk)
    clientId = apolloConfig.sataamkId;
  if (host === constants.clientHostNames.satabedok)
    clientId = apolloConfig.satabedokId;
  if (host === constants.clientHostNames.satajurong)
    clientId = apolloConfig.satajurongId;
  if (host === constants.clientHostNames.satapotongpasir)
    clientId = apolloConfig.satapotongpasirId;
  if (host === constants.clientHostNames.satatampines)
    clientId = apolloConfig.satatampinesId;
  if (host === constants.clientHostNames.satawoodlands)
    clientId = apolloConfig.satawoodlandsId;
  if (host === constants.clientHostNames.sembmarineadmiralty)
    clientId = apolloConfig.sembmarineadmiraltyId;
  if (host === constants.clientHostNames.sembmarinetuas)
    clientId = apolloConfig.sembmarinetuasId;
  if (host === constants.clientHostNames.simsdrivemedical)
    clientId = apolloConfig.simsdrivemedicalId;
  if (host === constants.clientHostNames.springmedicalsg)
    clientId = apolloConfig.springmedicalsgId;
  if (host === constants.clientHostNames.stamfordmedical)
    clientId = apolloConfig.stamfordmedicalId;
  if (host === constants.clientHostNames.standardclinicsurgery)
    clientId = apolloConfig.standardclinicsurgeryId;
  if (host === constants.clientHostNames.sunbeamanchorvale)
    clientId = apolloConfig.sunbeamanchorvaleId;
  if (host === constants.clientHostNames.sunbeamfajar)
    clientId = apolloConfig.sunbeamfajarId;
  if (host === constants.clientHostNames.sunwaymedicalcentre)
    clientId = apolloConfig.sunwaymedicalcentreId;
  if (host === constants.clientHostNames.tampinesclinic)
    clientId = apolloConfig.tampinesclinicId;
  if (host === constants.clientHostNames.tampinesmedilifeclinic)
    clientId = apolloConfig.tampinesmedilifeclinicId;
  if (host === constants.clientHostNames.tampineswestmedical)
    clientId = apolloConfig.tampineswestmedicalId;
  if (host === constants.clientHostNames.tanstandtclinic)
    clientId = apolloConfig.tanstandtclinicId;
  if (host === constants.clientHostNames.tayclinicgeylang)
    clientId = apolloConfig.tayclinicgeylangId;
  if (host === constants.clientHostNames.thebalmoralclinic)
    clientId = apolloConfig.thebalmoralclinicId;
  if (host === constants.clientHostNames.chelseaclinicrochester)
    clientId = apolloConfig.chelseaclinicrochesterId;
  if (host === constants.clientHostNames.theclinicgroupcampus)
    clientId = apolloConfig.theclinicgroupcampusId;
  if (host === constants.clientHostNames.theclinicgroupogs)
    clientId = apolloConfig.theclinicgroupogsId;
  if (host === constants.clientHostNames.thefpclinic)
    clientId = apolloConfig.thefpclinicId;
  if (host === constants.clientHostNames.jacs) clientId = apolloConfig.jacsId;
  if (host === constants.clientHostNames.themedicalclinic)
    clientId = apolloConfig.themedicalclinicId;
  if (host === constants.clientHostNames.themedicalpractice)
    clientId = apolloConfig.themedicalpracticeId;
  if (host === constants.clientHostNames.thomsongpclinic)
    clientId = apolloConfig.thomsongpclinicId;
  if (host === constants.clientHostNames.thomsonmedical)
    clientId = apolloConfig.thomsonmedicalId;
  if (host === constants.clientHostNames.tiongbahrumedical)
    clientId = apolloConfig.tiongbahrumedicalId;
  if (host === constants.clientHostNames.tpcmedicalclinic)
    clientId = apolloConfig.tpcmedicalclinicId;
  if (host === constants.clientHostNames.ubifamilyclinic)
    clientId = apolloConfig.ubifamilyclinicId;
  if (host === constants.clientHostNames.uhcnus)
    clientId = apolloConfig.uhcnusId;
  if (host === constants.clientHostNames.wellfamilycs)
    clientId = apolloConfig.wellfamilycsId;
  if (host === constants.clientHostNames.wenwengfamilyclinic)
    clientId = apolloConfig.wenwengfamilyclinicId;
  if (host === constants.clientHostNames.wongclinic539)
    clientId = apolloConfig.wongclinic539Id;
  if (host === constants.clientHostNames.yimclinic)
    clientId = apolloConfig.yimclinicId;
  if (host === constants.clientHostNames.farrerparklaboratories)
    clientId = apolloConfig.farrerparklaboratoriesId;
  if (host === constants.clientHostNames.royalhealthcarespecialists)
    clientId = apolloConfig.royalhealthcarespecialistsId;
  if (host === constants.clientHostNames.lifeplusmedicalbedok)
    clientId = apolloConfig.lifeplusmedicalbedokId;
  if (host === constants.clientHostNames.kevinchuamedical)
    clientId = apolloConfig.kevinchuamedicalId;
  if (host === constants.clientHostNames.affinitymedicalcityplaza)
    clientId = apolloConfig.affinitymedicalcityplazaId;
  if (host === constants.clientHostNames.medcommonwealth)
    clientId = apolloConfig.medcommonwealthId;
  if (host === constants.clientHostNames.healthlightfmc)
    clientId = apolloConfig.healthlightfmcId;
  if (host === constants.clientHostNames.vivienrobinsonpractice)
    clientId = apolloConfig.vivienrobinsonpracticeId;
  if (host === constants.clientHostNames.mhcasianovena)
    clientId = apolloConfig.mhcasianovenaId;
  if (host === constants.clientHostNames.ecfamilyclinic)
    clientId = apolloConfig.ecfamilyclinicId;
  if (host === constants.clientHostNames.punggolripplesfamilyclinic)
    clientId = apolloConfig.punggolripplesfamilyclinicId;
  if (host === constants.clientHostNames.keppelmedicalgroup)
    clientId = apolloConfig.keppelmedicalgroupId;
  if (host === constants.clientHostNames.amksummit)
    clientId = apolloConfig.amksummitId;
  if (host === constants.clientHostNames.drslimandchanhougang)
    clientId = apolloConfig.drslimandchanhougangId;
  if (host === constants.clientHostNames.drslimandchanlorlewlian)
    clientId = apolloConfig.drslimandchanlorlewlianId;
  if (host === constants.clientHostNames.innoquest)
    clientId = apolloConfig.innoquestId;
  if (host === constants.clientHostNames.myswabchinatown)
    clientId = apolloConfig.myswabchinatownId;
  if (host === constants.clientHostNames.westcoastcsclementi)
    clientId = apolloConfig.westcoastcsclementiId;
  if (host === constants.clientHostNames.bishanmedical)
    clientId = apolloConfig.bishanmedicalId;
  if (host === constants.clientHostNames.aidanmedical)
    clientId = apolloConfig.aidanmedicalId;
  if (host === constants.clientHostNames.changiclinic)
    clientId = apolloConfig.changiclinicId;
  if (host === constants.clientHostNames.drskooloh)
    clientId = apolloConfig.drskoolohId;
  if (host === constants.clientHostNames.dtapduo)
    clientId = apolloConfig.dtapduoId;
  if (host === constants.clientHostNames.dtaphollandv)
    clientId = apolloConfig.dtaphollandvId;
  if (host === constants.clientHostNames.dtapkovan)
    clientId = apolloConfig.dtapkovanId;
  if (host === constants.clientHostNames.dtapnovena)
    clientId = apolloConfig.dtapnovenaId;
  if (host === constants.clientHostNames.dtaporchard)
    clientId = apolloConfig.dtaporchardId;
  if (host === constants.clientHostNames.dtaprobertson)
    clientId = apolloConfig.dtaprobertsonId;
  if (host === constants.clientHostNames.dtapexpress)
    clientId = apolloConfig.dtapexpressId;
  if (host === constants.clientHostNames.healthlifefc)
    clientId = apolloConfig.healthlifefcId;
  if (host === constants.clientHostNames.healthmarksims)
    clientId = apolloConfig.healthmarksimsId;
  if (host === constants.clientHostNames.yourhealthmatters)
    clientId = apolloConfig.yourhealthmattersId;
  if (host === constants.clientHostNames.islandmedical)
    clientId = apolloConfig.islandmedicalId;
  if (host === constants.clientHostNames.japanclinicsurgery)
    clientId = apolloConfig.japanclinicsurgeryId;
  if (host === constants.clientHostNames.plushealthmedical)
    clientId = apolloConfig.plushealthmedicalId;
  if (host === constants.clientHostNames.shentonclinic)
    clientId = apolloConfig.shentonclinicId;
  if (host === constants.clientHostNames.whampoaclinic)
    clientId = apolloConfig.whampoaclinicId;
  if (host === constants.clientHostNames.wongclinicsurgery)
    clientId = apolloConfig.wongclinicsurgeryId;
  if (host === constants.clientHostNames.cashewmedical)
    clientId = apolloConfig.cashewmedicalId;
  if (host === constants.clientHostNames.crestfamilyclinic)
    clientId = apolloConfig.crestfamilyclinicId;
  if (host === constants.clientHostNames.fareastmedical)
    clientId = apolloConfig.fareastmedicalId;
  if (host === constants.clientHostNames.stewardclinic)
    clientId = apolloConfig.stewardclinicId;
  if (host === constants.clientHostNames.praisefamily)
    clientId = apolloConfig.praisefamilyId;
  if (host === constants.clientHostNames.innoquestdemo)
    clientId = apolloConfig.innoquestdemoId;
  if (host === constants.clientHostNames.chongsclinic)
    clientId = apolloConfig.chongsclinicId;
  if (host === constants.clientHostNames.umcbukitbatok)
    clientId = apolloConfig.umcbukitbatokId;
  if (host === constants.clientHostNames.marymedical)
    clientId = apolloConfig.marymedicalId;
  if (host === constants.clientHostNames.thefp) clientId = apolloConfig.thefpId;
  if (host === constants.clientHostNames.eiddwenclinic)
    clientId = apolloConfig.eiddwenclinicId;
  if (host === constants.clientHostNames.comfortclinic)
    clientId = apolloConfig.comfortclinicId;
  if (host === constants.clientHostNames.excelfc)
    clientId = apolloConfig.excelfcId;
  if (host === constants.clientHostNames.docsinc)
    clientId = apolloConfig.docsincId;
  if (host === constants.clientHostNames.compassvale297a)
    clientId = apolloConfig.compassvale297aId;
  if (host === constants.clientHostNames.smhaneefaclinic)
    clientId = apolloConfig.smhaneefaclinicId;
  if (host === constants.clientHostNames.vistamedvivavista)
    clientId = apolloConfig.vistamedvivavistaId;
  if (host === constants.clientHostNames.thebidadariclinic)
    clientId = apolloConfig.thebidadariclinicId;
  if (host === constants.clientHostNames.swabtp)
    clientId = apolloConfig.swabtpId;
  if (host === constants.clientHostNames.minmedmarineparade)
    clientId = apolloConfig.minmedmarineparadeId;
  if (host === constants.clientHostNames.thetenteramclinic)
    clientId = apolloConfig.thetenteramclinicId;
  if (host === constants.clientHostNames.sgstpanpac)
    clientId = apolloConfig.sgstpanpacId;
  if (host === constants.clientHostNames.woodgrovemedical)
    clientId = apolloConfig.woodgrovemedicalId;
  if (host === constants.clientHostNames.sbccbpp)
    clientId = apolloConfig.sbccbppId;
  if (host === constants.clientHostNames.sbccgmc)
    clientId = apolloConfig.sbccgmcId;
  if (host === constants.clientHostNames.sbccbishan)
    clientId = apolloConfig.sbccbishanId;
  if (host === constants.clientHostNames.sbccamk)
    clientId = apolloConfig.sbccamkId;
  if (host === constants.clientHostNames.sbccrivervale)
    clientId = apolloConfig.sbccrivervaleId;
  if (host === constants.clientHostNames.sbcctiongbahru)
    clientId = apolloConfig.sbcctiongbahruId;
  if (host === constants.clientHostNames.sbcclung)
    clientId = apolloConfig.sbcclungId;
  if (host === constants.clientHostNames.sbccmounta)
    clientId = apolloConfig.sbccmountaId;
  if (host === constants.clientHostNames.sbccnovena)
    clientId = apolloConfig.sbccnovenaId;
  if (host === constants.clientHostNames.clementihealthfirstclinic)
    clientId = apolloConfig.clementihealthfirstclinicId;
  if (host === constants.clientHostNames.koowk) clientId = apolloConfig.koowkId;
  if (host === constants.clientHostNames.fullertonhealthstatschippac)
    clientId = apolloConfig.fullertonhealthstatschippacId;
  if (host === constants.clientHostNames.fullertonhealthsiastc)
    clientId = apolloConfig.fullertonhealthsiastcId;
  if (host === constants.clientHostNames.fullertonhealthsiaalh)
    clientId = apolloConfig.fullertonhealthsiaalhId;
  if (host === constants.clientHostNames.fullertonhealthutacusg1)
    clientId = apolloConfig.fullertonhealthutacusg1Id;
  if (host === constants.clientHostNames.fullertonhealthams)
    clientId = apolloConfig.fullertonhealthamsId;
  if (host === constants.clientHostNames.healthcarefcs)
    clientId = apolloConfig.healthcarefcsId;
  if (host === constants.clientHostNames.sgstamk)
    clientId = apolloConfig.sgstamkId;
  if (host === constants.clientHostNames.sgstgolden)
    clientId = apolloConfig.sgstgoldenId;
  if (host === constants.clientHostNames.sgsthollandv)
    clientId = apolloConfig.sgsthollandvId;
  if (host === constants.clientHostNames.maritimemedicalcentre)
    clientId = apolloConfig.maritimemedicalcentreId;
  if (host === constants.clientHostNames.minmedconradcentennial)
    clientId = apolloConfig.minmedconradcentennialId;
  if (host === constants.clientHostNames.minmedcopthornekings)
    clientId = apolloConfig.minmedcopthornekingsId;
  if (host === constants.clientHostNames.minmedswissotelmerchantcourt)
    clientId = apolloConfig.minmedswissotelmerchantcourtId;
  if (host === constants.clientHostNames.swabngokinex)
    clientId = apolloConfig.swabngokinexId;
  if (host === constants.clientHostNames.winsome)
    clientId = apolloConfig.winsomeId;
  if (host === constants.clientHostNames.fajarfamilycs)
    clientId = apolloConfig.fajarfamilycsId;
  if (host === constants.clientHostNames.lionhealth)
    clientId = apolloConfig.lionhealthId;
  if (host === constants.clientHostNames.theintegrativemedicalcentre)
    clientId = apolloConfig.theintegrativemedicalcentreId;
  if (host === constants.clientHostNames.gainhealthclinicandhomecare)
    clientId = apolloConfig.gainhealthclinicandhomecareId;
  if (host === constants.clientHostNames.shimclinic)
    clientId = apolloConfig.shimclinicId;
  if (host === constants.clientHostNames.huangsurgery)
    clientId = apolloConfig.huangsurgeryId;
  if (host === constants.clientHostNames.gooddoctorsgeylang)
    clientId = apolloConfig.gooddoctorsgeylangId;
  if (host === constants.clientHostNames.rmdyclinic)
    clientId = apolloConfig.rmdyclinicId;
  if (host === constants.clientHostNames.medicalpw)
    clientId = apolloConfig.medicalpwId;
  if (host === constants.clientHostNames.paddingtonmedical)
    clientId = apolloConfig.paddingtonmedicalId;
  if (host === constants.clientHostNames.clinic10)
    clientId = apolloConfig.clinic10Id;
  if (host === constants.clientHostNames.asiafirsthealth)
    clientId = apolloConfig.asiafirsthealthId;
  if (host === constants.clientHostNames.lifecarefcs)
    clientId = apolloConfig.lifecarefcsId;
  if (host === constants.clientHostNames.fcsnewlife)
    clientId = apolloConfig.fcsnewlifeId;
  if (host === constants.clientHostNames.kanganbtc)
    clientId = apolloConfig.kanganbtcId;
  if (host === constants.clientHostNames.bmc460018)
    clientId = apolloConfig.bmc460018Id;
  if (host === constants.clientHostNames.hlfcs) clientId = apolloConfig.hlfcsId;
  if (host === constants.clientHostNames.travelswabpeoplespark)
    clientId = apolloConfig.travelswabpeoplesparkId;
  if (host === constants.clientHostNames.theedinburghclinic)
    clientId = apolloConfig.theedinburghclinicId;
  if (host === constants.clientHostNames.sterlingfamilyclinic)
    clientId = apolloConfig.sterlingfamilyclinicId;
  if (host === constants.clientHostNames.babystepsfareastplaza)
    clientId = apolloConfig.babystepsfareastplazaId;
  if (host === constants.clientHostNames.minmedjurongwest)
    clientId = apolloConfig.minmedjurongwestId;
  if (host === constants.clientHostNames.nyamfamilyclinic)
    clientId = apolloConfig.nyamfamilyclinicId;
  if (host === constants.clientHostNames.huiclinic)
    clientId = apolloConfig.huiclinicId;
  if (host === constants.clientHostNames.medipointponggol)
    clientId = apolloConfig.medipointponggolId;
  if (host === constants.clientHostNames.jademedical)
    clientId = apolloConfig.jademedicalId;
  if (host === constants.clientHostNames.chuaandpartners)
    clientId = apolloConfig.chuaandpartnersId;
  if (host === constants.clientHostNames.shippingDemo)
    clientId = apolloConfig.shippingDemoId;
  if (host === constants.clientHostNames.trainingDemo)
    clientId = apolloConfig.trainingDemoId;
  if (host === constants.clientHostNames.recruitmentDemo)
    clientId = apolloConfig.recruitmentDemoId;
  if (host === constants.clientHostNames.tanteohamk)
    clientId = apolloConfig.tanteohamkId;
  if (host === constants.clientHostNames.tanteohtowner)
    clientId = apolloConfig.tanteohtownerId;
  if (host === constants.clientHostNames.novenamedicalcfc)
    clientId = apolloConfig.novenamedicalcfcId;
  if (host === constants.clientHostNames.marineterracefc)
    clientId = apolloConfig.marineterracefcId;
  if (host === constants.clientHostNames.lavenderdivisionclinic)
    clientId = apolloConfig.lavenderdivisionclinicId;
  if (host === constants.clientHostNames.healthwaykwshospital)
    clientId = apolloConfig.healthwaykwshospitalId;
  if (host === constants.clientHostNames.manhattanmedical)
    clientId = apolloConfig.manhattanmedicalId;
  if (host === constants.clientHostNames.yimmedicalcentre)
    clientId = apolloConfig.yimmedicalcentreId;
  if (host === constants.clientHostNames.chinatownfmc)
    clientId = apolloConfig.chinatownfmcId;
  if (host === constants.clientHostNames.lhpolyclinic)
    clientId = apolloConfig.lhpolyclinicId;
  if (host === constants.clientHostNames.excelsiorclinic)
    clientId = apolloConfig.excelsiorclinicId;
  if (host === constants.clientHostNames.heartlandfamilyclinic)
    clientId = apolloConfig.heartlandfamilyclinicId;
  if (host === constants.clientHostNames.tcfamilyclinic)
    clientId = apolloConfig.tcfamilyclinicId;
  if (host === constants.clientHostNames.nutramed)
    clientId = apolloConfig.nutramedId;
  if (host === constants.clientHostNames.bedokclinicsurgery)
    clientId = apolloConfig.bedokclinicsurgeryId;
  if (host === constants.clientHostNames.healthwayjapmed)
    clientId = apolloConfig.healthwayjapmedId;
  if (host === constants.clientHostNames.pqtcjurongeast)
    clientId = apolloConfig.pqtcjurongeastId;
  if (host === constants.clientHostNames.dawsonpc)
    clientId = apolloConfig.dawsonpcId;
  if (host === constants.clientHostNames.trinitymedicalpunggol)
    clientId = apolloConfig.trinitymedicalpunggolId;
  if (host === constants.clientHostNames.medihealthcareclinic)
    clientId = apolloConfig.medihealthcareclinicId;
  if (host === constants.clientHostNames.babystepsoxleybizhub)
    clientId = apolloConfig.babystepsoxleybizhubId;
  if (host === constants.clientHostNames.saudaraclinic)
    clientId = apolloConfig.saudaraclinicId;
  if (host === constants.clientHostNames.xpressmedical)
    clientId = apolloConfig.xpressmedicalId;
  if (host === constants.clientHostNames.clifforddispensaryairportblvd)
    clientId = apolloConfig.clifforddispensaryairportblvdId;
  if (host === constants.clientHostNames.clifforddispensaryshawhouse)
    clientId = apolloConfig.clifforddispensaryshawhouseId;
  if (host === constants.clientHostNames.rafflesmedical)
    clientId = apolloConfig.rafflesmedicalId;
  if (host === constants.clientHostNames.biolidics)
    clientId = apolloConfig.biolidicsId;
  if (host === constants.clientHostNames.boonlaycorpclinic)
    clientId = apolloConfig.boonlaycorpclinicId;
  if (host === constants.clientHostNames.healthwayadmiralty)
    clientId = apolloConfig.healthwayadmiraltyId;
  if (host === constants.clientHostNames.healthwayanchorvale)
    clientId = apolloConfig.healthwayanchorvaleId;
  if (host === constants.clientHostNames.healthwayamk1)
    clientId = apolloConfig.healthwayamk1Id;
  if (host === constants.clientHostNames.healthwayamk10)
    clientId = apolloConfig.healthwayamk10Id;
  if (host === constants.clientHostNames.healthwayamk8)
    clientId = apolloConfig.healthwayamk8Id;
  if (host === constants.clientHostNames.healthwaybedoknorth)
    clientId = apolloConfig.healthwaybedoknorthId;
  if (host === constants.clientHostNames.healthwayboonkeng)
    clientId = apolloConfig.healthwayboonkengId;
  if (host === constants.clientHostNames.healthwaybbwest)
    clientId = apolloConfig.healthwaybbwestId;
  if (host === constants.clientHostNames.healthwaybb)
    clientId = apolloConfig.healthwaybbId;
  if (host === constants.clientHostNames.healthwaycompassvale)
    clientId = apolloConfig.healthwaycompassvaleId;
  if (host === constants.clientHostNames.healthwaydowntown)
    clientId = apolloConfig.healthwaydowntownId;
  if (host === constants.clientHostNames.healthwayelias)
    clientId = apolloConfig.healthwayeliasId;
  if (host === constants.clientHostNames.healthwayfajar)
    clientId = apolloConfig.healthwayfajarId;
  if (host === constants.clientHostNames.healthwayhougang)
    clientId = apolloConfig.healthwayhougangId;
  if (host === constants.clientHostNames.healthwayjurongwestcentral)
    clientId = apolloConfig.healthwayjurongwestcentralId;
  if (host === constants.clientHostNames.healthwayjurongwest)
    clientId = apolloConfig.healthwayjurongwestId;
  if (host === constants.clientHostNames.healthwaylengkong)
    clientId = apolloConfig.healthwaylengkongId;
  if (host === constants.clientHostNames.healthwaylimbang)
    clientId = apolloConfig.healthwaylimbangId;
  if (host === constants.clientHostNames.healthwaylas)
    clientId = apolloConfig.healthwaylasId;
  if (host === constants.clientHostNames.healthwaynovenamc)
    clientId = apolloConfig.healthwaynovenamcId;
  if (host === constants.clientHostNames.healthwaypunggol)
    clientId = apolloConfig.healthwaypunggolId;
  if (host === constants.clientHostNames.healthwayrivervale)
    clientId = apolloConfig.healthwayrivervaleId;
  if (host === constants.clientHostNames.healthwaysembawang)
    clientId = apolloConfig.healthwaysembawangId;
  if (host === constants.clientHostNames.healthwaysunplaza)
    clientId = apolloConfig.healthwaysunplazaId;
  if (host === constants.clientHostNames.healthwaysunshineplace)
    clientId = apolloConfig.healthwaysunshineplaceId;
  if (host === constants.clientHostNames.healthwaytampinescentral)
    clientId = apolloConfig.healthwaytampinescentralId;
  if (host === constants.clientHostNames.healthwaytampines21)
    clientId = apolloConfig.healthwaytampines21Id;
  if (host === constants.clientHostNames.healthwaytampines71)
    clientId = apolloConfig.healthwaytampines71Id;
  if (host === constants.clientHostNames.healthwaytpy)
    clientId = apolloConfig.healthwaytpyId;
  if (host === constants.clientHostNames.healthwaywestcoast)
    clientId = apolloConfig.healthwaywestcoastId;
  if (host === constants.clientHostNames.healthwaywoodlands)
    clientId = apolloConfig.healthwaywoodlandsId;
  if (host === constants.clientHostNames.healthwayyishun11)
    clientId = apolloConfig.healthwayyishun11Id;
  if (host === constants.clientHostNames.healthwayyishun5)
    clientId = apolloConfig.healthwayyishun5Id;
  if (host === constants.clientHostNames.healthwayyishun6)
    clientId = apolloConfig.healthwayyishun6Id;
  if (host === constants.clientHostNames.healthwayyishun72)
    clientId = apolloConfig.healthwayyishun72Id;
  if (host === constants.clientHostNames.medicoclinicsurgery)
    clientId = apolloConfig.medicoclinicsurgeryId;
  if (host === constants.clientHostNames.pkangfamily)
    clientId = apolloConfig.pkangfamilyId;
  if (host === constants.clientHostNames.unitedmedical139)
    clientId = apolloConfig.unitedmedical139Id;
  if (host === constants.clientHostNames.silvercrossjw)
    clientId = apolloConfig.silvercrossjwId;
  if (host === constants.clientHostNames.silvercrossholland)
    clientId = apolloConfig.silvercrosshollandId;
  if (host === constants.clientHostNames.silvercrossyishun)
    clientId = apolloConfig.silvercrossyishunId;
  if (host === constants.clientHostNames.silvercrossbukittimah)
    clientId = apolloConfig.silvercrossbukittimahId;
  if (host === constants.clientHostNames.daclinicyishun)
    clientId = apolloConfig.daclinicyishunId;
  if (host === constants.clientHostNames.majuclinic)
    clientId = apolloConfig.majuclinicId;
  if (host === constants.clientHostNames.theflametree)
    clientId = apolloConfig.theflametreeId;
  if (host === constants.clientHostNames.familymedicare)
    clientId = apolloConfig.familymedicareId;
  if (host === constants.clientHostNames.fklimfc)
    clientId = apolloConfig.fklimfcId;
  if (host === constants.clientHostNames.kaimedicalclinic)
    clientId = apolloConfig.kaimedicalclinicId;
  if (host === constants.clientHostNames.gohclinicsurgery)
    clientId = apolloConfig.gohclinicsurgeryId;
  if (host === constants.clientHostNames.katongfamilyclinic)
    clientId = apolloConfig.katongfamilyclinicId;
  if (host === constants.clientHostNames.healthplusfcs)
    clientId = apolloConfig.healthplusfcsId;
  if (host === constants.clientHostNames.changigeneralhospital)
    clientId = apolloConfig.changigeneralhospitalId;
  if (host === constants.clientHostNames.dolwp) clientId = apolloConfig.dolwpId;
  if (host === constants.clientHostNames.drjoycepartnerspunggol)
    clientId = apolloConfig.drjoycepartnerspunggolId;
  if (host === constants.clientHostNames.drjoycepartnerstpy)
    clientId = apolloConfig.drjoycepartnerstpyId;
  if (host === constants.clientHostNames.advantagemedicalgeylangeast)
    clientId = apolloConfig.advantagemedicalgeylangeastId;
  if (host === constants.clientHostNames.kofamilyclinic)
    clientId = apolloConfig.kofamilyclinicId;
  if (host === constants.clientHostNames.northlinkwoodlands)
    clientId = apolloConfig.northlinkwoodlandsId;
  if (host === constants.clientHostNames.lcclinicmsa)
    clientId = apolloConfig.lcclinicmsaId;
  if (host === constants.clientHostNames.vicghimmoh)
    clientId = apolloConfig.vicghimmohId;
  if (host === constants.clientHostNames.hougangcliniq)
    clientId = apolloConfig.hougangcliniqId;
  if (host === constants.clientHostNames.canberracareclinic)
    clientId = apolloConfig.canberracareclinicId;
  if (host === constants.clientHostNames.o2medicalclinic)
    clientId = apolloConfig.o2medicalclinicId;
  if (host === constants.clientHostNames.thecliniqtpy)
    clientId = apolloConfig.thecliniqtpyId;
  if (host === constants.clientHostNames.dknmgciviccentre)
    clientId = apolloConfig.dknmgciviccentreId;
  if (host === constants.clientHostNames.truecareclinicrangoon)
    clientId = apolloConfig.truecareclinicrangoonId;
  if (host === constants.clientHostNames.kingswaymedical24hr)
    clientId = apolloConfig.kingswaymedical24hrId;
  if (host === constants.clientHostNames.yapmedicare)
    clientId = apolloConfig.yapmedicareId;
  if (host === constants.clientHostNames.samwmc)
    clientId = apolloConfig.samwmcId;
  if (host === constants.clientHostNames.drnahleefc)
    clientId = apolloConfig.drnahleefcId;
  if (host === constants.clientHostNames.royalmedical)
    clientId = apolloConfig.royalmedicalId;
  if (host === constants.clientHostNames.clinicassist)
    clientId = apolloConfig.clinicassistId;
  if (host === constants.clientHostNames.ifcseletar)
    clientId = apolloConfig.ifcseletarId;
  if (host === constants.clientHostNames.calrosemedical)
    clientId = apolloConfig.calrosemedicalId;
  if (host === constants.clientHostNames.onecaremedicalaljuniedmrt)
    clientId = apolloConfig.onecaremedicalaljuniedmrtId;
  if (host === constants.clientHostNames.onecaremedicalbedokmrt)
    clientId = apolloConfig.onecaremedicalbedokmrtId;
  if (host === constants.clientHostNames.onecaremedicalboonlay)
    clientId = apolloConfig.onecaremedicalboonlayId;
  if (host === constants.clientHostNames.onecaremedicalbukitbatok)
    clientId = apolloConfig.onecaremedicalbukitbatokId;
  if (host === constants.clientHostNames.onecaremedicalbukitbatokwest)
    clientId = apolloConfig.onecaremedicalbukitbatokwestId;
  if (host === constants.clientHostNames.onecaremedicalgombakmrt)
    clientId = apolloConfig.onecaremedicalgombakmrtId;
  if (host === constants.clientHostNames.onecaremedicalbukitpanjang)
    clientId = apolloConfig.onecaremedicalbukitpanjangId;
  if (host === constants.clientHostNames.onecaremedicalbukitpanjanginterchange)
    clientId = apolloConfig.onecaremedicalbukitpanjanginterchangeId;
  if (host === constants.clientHostNames.onecaremedicalcanberra)
    clientId = apolloConfig.onecaremedicalcanberraId;
  if (host === constants.clientHostNames.onecaremedicalchoachukang)
    clientId = apolloConfig.onecaremedicalchoachukangId;
  if (host === constants.clientHostNames.onecaremedicalclementi)
    clientId = apolloConfig.onecaremedicalclementiId;
  if (host === constants.clientHostNames.onecaremedicalfernvale)
    clientId = apolloConfig.onecaremedicalfernvaleId;
  if (host === constants.clientHostNames.onecaremedicalhougang)
    clientId = apolloConfig.onecaremedicalhougangId;
  if (host === constants.clientHostNames.onecaremedicalhougang8)
    clientId = apolloConfig.onecaremedicalhougang8Id;
  if (host === constants.clientHostNames.onecaremedicalkeathong)
    clientId = apolloConfig.onecaremedicalkeathongId;
  if (host === constants.clientHostNames.onecaremedicalloyang)
    clientId = apolloConfig.onecaremedicalloyangId;
  if (host === constants.clientHostNames.onecaremedicalmarineparade)
    clientId = apolloConfig.onecaremedicalmarineparadeId;
  if (host === constants.clientHostNames.onecaremedicalpunggolplaza)
    clientId = apolloConfig.onecaremedicalpunggolplazaId;
  if (host === constants.clientHostNames.onecaremedicaltampines)
    clientId = apolloConfig.onecaremedicaltampinesId;
  if (host === constants.clientHostNames.onecaremedicaltampinesmrt)
    clientId = apolloConfig.onecaremedicaltampinesmrtId;
  if (host === constants.clientHostNames.onecaremedicaltiongbahru)
    clientId = apolloConfig.onecaremedicaltiongbahruId;
  if (host === constants.clientHostNames.onecaremedicalwoodlands)
    clientId = apolloConfig.onecaremedicalwoodlandsId;
  if (host === constants.clientHostNames.onecaremedicalyishun)
    clientId = apolloConfig.onecaremedicalyishunId;
  if (host === constants.clientHostNames.onecaremedicalrafflesplace)
    clientId = apolloConfig.onecaremedicalrafflesplaceId;
  if (host === constants.clientHostNames.taykamedicalfamilyclinic)
    clientId = apolloConfig.taykamedicalfamilyclinicId;
  if (host === constants.clientHostNames.babystepsmedical)
    clientId = apolloConfig.babystepsmedicalId;
  if (host === constants.clientHostNames.smrtwoodlands)
    clientId = apolloConfig.smrtwoodlandsId;
  if (host === constants.clientHostNames.smrtbishan)
    clientId = apolloConfig.smrtbishanId;
  if (host === constants.clientHostNames.lilyneoclinic)
    clientId = apolloConfig.lilyneoclinicId;
  if (host === constants.clientHostNames.cheongfamilyclinic)
    clientId = apolloConfig.cheongfamilyclinicId;
  if (host === constants.clientHostNames.siglapclinic)
    clientId = apolloConfig.siglapclinicId;
  if (host === constants.clientHostNames.myclnq)
    clientId = apolloConfig.myclnqId;
  if (host === constants.clientHostNames.meenaraclinic)
    clientId = apolloConfig.meenaraclinicId;
  if (host === constants.clientHostNames.appletreemedical)
    clientId = apolloConfig.appletreemedicalId;
  if (host === constants.clientHostNames.doctorsclinicandsurgery)
    clientId = apolloConfig.doctorsclinicandsurgeryId;
  if (host === constants.clientHostNames.vitacareseletar)
    clientId = apolloConfig.vitacareseletarId;
  if (host === constants.clientHostNames.minmedpayalebar)
    clientId = apolloConfig.minmedpayalebarId;
  if (host === constants.clientHostNames.ddmc) clientId = apolloConfig.ddmcId;
  if (host === constants.clientHostNames.kallangbahrufc)
    clientId = apolloConfig.kallangbahrufcId;
  if (host === constants.clientHostNames.igenelab)
    clientId = apolloConfig.igenelabId;
  if (host === constants.clientHostNames.gooddoctorsyishun)
    clientId = apolloConfig.gooddoctorsyishunId;
  if (host === constants.clientHostNames.drpeckclinic)
    clientId = apolloConfig.drpeckclinicId;
  if (host === constants.clientHostNames.pfcbuangkok)
    clientId = apolloConfig.pfcbuangkokId;
  if (host === constants.clientHostNames.pfccompassvale)
    clientId = apolloConfig.pfccompassvaleId;
  if (host === constants.clientHostNames.pfcnorthshore)
    clientId = apolloConfig.pfcnorthshoreId;
  if (host === constants.clientHostNames.pfcpasirris)
    clientId = apolloConfig.pfcpasirrisId;
  if (host === constants.clientHostNames.pfcrivervalley)
    clientId = apolloConfig.pfcrivervalleyId;
  if (host === constants.clientHostNames.pfcserangoon)
    clientId = apolloConfig.pfcserangoonId;
  if (host === constants.clientHostNames.pfcwoodlands)
    clientId = apolloConfig.pfcwoodlandsId;
  if (host === constants.clientHostNames.pfcyewtee)
    clientId = apolloConfig.pfcyewteeId;
  if (host === constants.clientHostNames.lifescanparagonlab)
    clientId = apolloConfig.lifescanparagonlabId;
  if (host === constants.clientHostNames.panhealthhougang)
    clientId = apolloConfig.panhealthhougangId;
  if (host === constants.clientHostNames.panhealthpunggol)
    clientId = apolloConfig.panhealthpunggolId;
  if (host === constants.clientHostNames.crawfurdmedical)
    clientId = apolloConfig.crawfurdmedicalId;
  if (host === constants.clientHostNames.tanjongpagarmed)
    clientId = apolloConfig.tanjongpagarmedId;
  if (host === constants.clientHostNames.kensingtonfc)
    clientId = apolloConfig.kensingtonfcId;
  if (host === constants.clientHostNames.ongclinicsurgery)
    clientId = apolloConfig.ongclinicsurgeryId;
  if (host === constants.clientHostNames.wanmedical)
    clientId = apolloConfig.wanmedicalId;
  if (host === constants.clientHostNames.wilmerclinic)
    clientId = apolloConfig.wilmerclinicId;
  if (host === constants.clientHostNames.novovita)
    clientId = apolloConfig.novovitaId;
  if (host === constants.clientHostNames.medivale)
    clientId = apolloConfig.medivaleId;
  if (host === constants.clientHostNames.trinitymedicalshenton)
    clientId = apolloConfig.trinitymedicalshentonId;
  if (host === constants.clientHostNames.eternmedfernvale)
    clientId = apolloConfig.eternmedfernvaleId;
  if (host === constants.clientHostNames.eternmedpunggolmrt)
    clientId = apolloConfig.eternmedpunggolmrtId;
  if (host === constants.clientHostNames.eternmedpunggollrt)
    clientId = apolloConfig.eternmedpunggollrtId;
  if (host === constants.clientHostNames.jagame)
    clientId = apolloConfig.jagameId;
  if (host === constants.clientHostNames.hillspring)
    clientId = apolloConfig.hillspringId;
  if (host === constants.clientHostNames.healthplusbedok)
    clientId = apolloConfig.healthplusbedokId;
  if (host === constants.clientHostNames.townclinic)
    clientId = apolloConfig.townclinicId;
  if (host === constants.clientHostNames.oglmedicalqueenstown)
    clientId = apolloConfig.oglmedicalqueenstownId;
  if (host === constants.clientHostNames.everwellclinic)
    clientId = apolloConfig.everwellclinicId;
  if (host === constants.clientHostNames.coronationmedical)
    clientId = apolloConfig.coronationmedicalId;
  if (host === constants.clientHostNames.statmchougang)
    clientId = apolloConfig.statmchougangId;
  if (host === constants.clientHostNames.fullertonhealthpasirpanjang)
    clientId = apolloConfig.fullertonhealthpasirpanjangId;
  if (host === constants.clientHostNames.lifelinepunggol)
    clientId = apolloConfig.lifelinepunggolId;
  if (host === constants.clientHostNames.bedokcentral)
    clientId = apolloConfig.bedokcentralId;
  if (host === constants.clientHostNames.lifecare)
    clientId = apolloConfig.lifecareId;
  if (host === constants.clientHostNames.temasekclinic)
    clientId = apolloConfig.temasekclinicId;
  if (host === constants.clientHostNames.hlclinic)
    clientId = apolloConfig.hlclinicId;
  if (host === constants.clientHostNames.workhealth)
    clientId = apolloConfig.workhealthId;
  if (host === constants.clientHostNames.hlfamilyclinicbedok)
    clientId = apolloConfig.hlfamilyclinicbedokId;
  if (host === constants.clientHostNames.hlfamilyclinic)
    clientId = apolloConfig.hlfamilyclinicId;
  if (host === constants.clientHostNames.hkfamilyclinic)
    clientId = apolloConfig.hkfamilyclinicId;
  if (host === constants.clientHostNames.meilingclinic)
    clientId = apolloConfig.meilingclinicId;
  if (host === constants.clientHostNames.emedical)
    clientId = apolloConfig.emedicalId;
  if (host === constants.clientHostNames.asiamedic)
    clientId = apolloConfig.asiamedicId;
  if (host === constants.clientHostNames.kimedical)
    clientId = apolloConfig.kimedicalId;
  if (host === constants.clientHostNames.pandamedical)
    clientId = apolloConfig.pandamedicalId;
  if (host === constants.clientHostNames.onewellness)
    clientId = apolloConfig.onewellnessId;
  if (host === constants.clientHostNames.fullertonhealthntu)
    clientId = apolloConfig.fullertonhealthntuId;
  if (host === constants.clientHostNames.chewchew)
    clientId = apolloConfig.chewchewId;
  if (host === constants.clientHostNames.coronationclinic)
    clientId = apolloConfig.coronationclinicId;
  if (host === constants.clientHostNames.clarionmedical)
    clientId = apolloConfig.clarionmedicalId;
  if (host === constants.clientHostNames.drolmedical)
    clientId = apolloConfig.drolmedicalId;
  if (host === constants.clientHostNames.nuffieldmedical)
    clientId = apolloConfig.nuffieldmedicalId;
  if (host === constants.clientHostNames.hfmc) clientId = apolloConfig.hfmcId;
  if (host === constants.clientHostNames.trucareclinic)
    clientId = apolloConfig.trucareclinicId;
  if (host === constants.clientHostNames.camtech)
    clientId = apolloConfig.camtechId;
  if (host === constants.clientHostNames.medina)
    clientId = apolloConfig.medinaId;
  if (host === constants.clientHostNames.mediclinic)
    clientId = apolloConfig.mediclinicId;
  if (host === constants.clientHostNames.goodshepherd)
    clientId = apolloConfig.goodshepherdId;
  if (host === constants.clientHostNames.taykamedical)
    clientId = apolloConfig.taykamedicalId;
  if (host === constants.clientHostNames.qmmedicalcentre)
    clientId = apolloConfig.qmmedicalcentreId;
  if (host === constants.clientHostNames.trucarehl)
    clientId = apolloConfig.trucarehlId;
  if (host === constants.clientHostNames.leongclinic)
    clientId = apolloConfig.leongclinicId;
  if (host === constants.clientHostNames.prohealthpn)
    clientId = apolloConfig.prohealthpnId;
  if (host === constants.clientHostNames.prohealthtp)
    clientId = apolloConfig.prohealthtpId;
  if (host === constants.clientHostNames.kaotan)
    clientId = apolloConfig.kaotanId;
  if (host === constants.clientHostNames.islandgroupclinic)
    clientId = apolloConfig.islandgroupclinicId;
  if (host === constants.clientHostNames.healthpartnersmedicalclinic)
    clientId = apolloConfig.healthpartnersmedicalclinicId;
  if (host === constants.clientHostNames.prohealthbk)
    clientId = apolloConfig.prohealthbkId;
  if (host === constants.clientHostNames.prohealthbb)
    clientId = apolloConfig.prohealthbbId;
  if (host === constants.clientHostNames.prohealthfv)
    clientId = apolloConfig.prohealthfvId;
  if (host === constants.clientHostNames.prohealthhg)
    clientId = apolloConfig.prohealthhgId;
  if (host === constants.clientHostNames.prohealthpd)
    clientId = apolloConfig.prohealthpdId;
  if (host === constants.clientHostNames.prohealthps)
    clientId = apolloConfig.prohealthpsId;
  if (host === constants.clientHostNames.prohealthtj)
    clientId = apolloConfig.prohealthtjId;
  if (host === constants.clientHostNames.drsexpress)
    clientId = apolloConfig.drsexpressId;
  if (host === constants.clientHostNames.pasirrisc)
    clientId = apolloConfig.pasirriscId;
  if (host === constants.clientHostNames.prohealthlrtfernvale)
    clientId = apolloConfig.prohealthlrtfernvaleId;
  if (host === constants.clientHostNames.woodgrovefc)
    clientId = apolloConfig.woodgrovefcId;
  if (host === constants.clientHostNames.shinefamilyclinic)
    clientId = apolloConfig.shinefamilyclinicId;
  if (host === constants.clientHostNames.fullertonhealthcaretrythalljurong)
    clientId = apolloConfig.fullertonhealthcaretrythalljurongId;
  if (host === constants.clientHostNames.fullertonhealthdrstrythall)
    clientId = apolloConfig.fullertonhealthdrstrythallId;
  if (host === constants.clientHostNames.fullertonhealthofc)
    clientId = apolloConfig.fullertonhealthofcId;
  if (host === constants.clientHostNames.fullertonhealthcaremetropolis)
    clientId = apolloConfig.fullertonhealthcaremetropolisId;
  if (host === constants.clientHostNames.fullertonhealthcaregethin)
    clientId = apolloConfig.fullertonhealthcaregethinId;
  if (host === constants.clientHostNames.fullertonhealthcarembfc)
    clientId = apolloConfig.fullertonhealthcarembfcId;
  if (host === constants.clientHostNames.aestheticsplusclinic)
    clientId = apolloConfig.aestheticsplusclinicId;
  if (host === constants.clientHostNames.vivamedical)
    clientId = apolloConfig.vivamedicalId;
  if (host === constants.clientHostNames.vivamedicalgroup)
    clientId = apolloConfig.vivamedicalgroupId;
  if (host === constants.clientHostNames.tanclinicandsurgery)
    clientId = apolloConfig.tanclinicandsurgeryId;
  if (host === constants.clientHostNames.vivamedicalgroupcp)
    clientId = apolloConfig.vivamedicalgroupcpId;
  if (host === constants.clientHostNames.kindredfamilyclinic)
    clientId = apolloConfig.kindredfamilyclinicId;
  if (host === constants.clientHostNames.sgclinic)
    clientId = apolloConfig.sgclinicId;
  if (host === constants.clientHostNames.simonroadclinic)
    clientId = apolloConfig.simonroadclinicId;
  if (host === constants.clientHostNames.caredocmedical)
    clientId = apolloConfig.caredocmedicalId;
  if (host === constants.clientHostNames.loandleeclinic)
    clientId = apolloConfig.loandleeclinicId;
  if (host === constants.clientHostNames.kingstonmedical)
    clientId = apolloConfig.kingstonmedicalId;
  if (host === constants.clientHostNames.famhthcs)
    clientId = apolloConfig.famhthcsId;
  if (host === constants.clientHostNames.iog) clientId = apolloConfig.iogId;
  if (host === constants.clientHostNames.drmpc) clientId = apolloConfig.drmpcId;
  if (host === constants.clientHostNames.medihealth)
    clientId = apolloConfig.medihealthId;
  if (host === constants.clientHostNames.limcountry)
    clientId = apolloConfig.limcountryId;
  if (host === constants.clientHostNames.mcr) clientId = apolloConfig.mcrId;
  if (host === constants.clientHostNames.evertonclinic)
    clientId = apolloConfig.evertonclinicId;
  if (host === constants.clientHostNames.littlecross)
    clientId = apolloConfig.littlecrossId;
  if (host === constants.clientHostNames.everhealthmed)
    clientId = apolloConfig.everhealthmedId;
  if (host === constants.clientHostNames.lifescanmc)
    clientId = apolloConfig.lifescanmcId;
  if (host === constants.clientHostNames.smgdtec)
    clientId = apolloConfig.smgdtecId;
  if (host === constants.clientHostNames.thebreastclinic)
    clientId = apolloConfig.thebreastclinicId;
  if (host === constants.clientHostNames.anteh) clientId = apolloConfig.antehId;
  if (host === constants.clientHostNames.healthline)
    clientId = apolloConfig.healthlineId;
  if (host === constants.clientHostNames.nemgamk)
    clientId = apolloConfig.nemgamkId;
  if (host === constants.clientHostNames.nemgbedok)
    clientId = apolloConfig.nemgbedokId;
  if (host === constants.clientHostNames.nemgbb)
    clientId = apolloConfig.nemgbbId;
  if (host === constants.clientHostNames.nemggm)
    clientId = apolloConfig.nemggmId;
  if (host === constants.clientHostNames.nemgkallang)
    clientId = apolloConfig.nemgkallangId;
  if (host === constants.clientHostNames.nemgsengkang)
    clientId = apolloConfig.nemgsengkangId;
  if (host === constants.clientHostNames.nemgsimei)
    clientId = apolloConfig.nemgsimeiId;
  if (host === constants.clientHostNames.nemgtampines)
    clientId = apolloConfig.nemgtampinesId;
  if (host === constants.clientHostNames.nemgcb)
    clientId = apolloConfig.nemgcbId;
  if (host === constants.clientHostNames.medicalteckghee)
    clientId = apolloConfig.medicalteckgheeId;
  if (host === constants.clientHostNames.atriofamilyclinic)
    clientId = apolloConfig.atriofamilyclinicId;
  if (host === constants.clientHostNames.atriofamilyclinicamk)
    clientId = apolloConfig.atriofamilyclinicamkId;
  if (host === constants.clientHostNames.avantortho)
    clientId = apolloConfig.avantorthoId;
  if (host === constants.clientHostNames.banyanclinicjw)
    clientId = apolloConfig.banyanclinicjwId;
  if (host === constants.clientHostNames.lifefamilyclinicamk)
    clientId = apolloConfig.lifefamilyclinicamkId;
  if (host === constants.clientHostNames.advantagemedical)
    clientId = apolloConfig.advantagemedicalId;
  if (host === constants.clientHostNames.mediview)
    clientId = apolloConfig.mediviewId;
  if (host === constants.clientHostNames.lifefamilycliniccck)
    clientId = apolloConfig.lifefamilycliniccckId;
  if (host === constants.clientHostNames.boonkcns)
    clientId = apolloConfig.boonkcnsId;
  if (host === constants.clientHostNames.everhealth)
    clientId = apolloConfig.everhealthId;
  if (host === constants.clientHostNames.chngclinic)
    clientId = apolloConfig.chngclinicId;
  if (host === constants.clientHostNames.bostonmedical)
    clientId = apolloConfig.bostonmedicalId;
  if (host === constants.clientHostNames.karri) clientId = apolloConfig.karriId;
  if (host === constants.clientHostNames.edgedaletampines)
    clientId = apolloConfig.edgedaletampinesId;
  if (host === constants.clientHostNames.edgedalecambridge)
    clientId = apolloConfig.edgedalecambridgeId;
  if (host === constants.clientHostNames.edgedale)
    clientId = apolloConfig.edgedaleId;
  if (host === constants.clientHostNames.crossroadsFamilyClinic)
    clientId = apolloConfig.crossroadsFamilyClinicId;
  if (host === constants.clientHostNames.greenforest)
    clientId = apolloConfig.greenforestId;
  if (host === constants.clientHostNames.blissMedicalClinic)
    clientId = apolloConfig.blissMedicalClinicId;
  if (host === constants.clientHostNames.onedoctorsFamilyClinicAmk)
    clientId = apolloConfig.onedoctorsFamilyClinicAmkId;
  if (host === constants.clientHostNames.onedoctorsFamilyClinicHollandVillage)
    clientId = apolloConfig.onedoctorsFamilyClinicHollandVillageId;
  if (host === constants.clientHostNames.onedoctorsFamilyClinicKhatib)
    clientId = apolloConfig.onedoctorsFamilyClinicKhatibId;
  if (host === constants.clientHostNames.onedoctorsFamilyClinicPunggol)
    clientId = apolloConfig.onedoctorsFamilyClinicPunggolId;
  if (host === constants.clientHostNames.onedoctorsMedicalCentre)
    clientId = apolloConfig.onedoctorsMedicalCentreId;
  if (host === constants.clientHostNames.trinitymedical)
    clientId = apolloConfig.trinitymedicalId;
  if (host === constants.clientHostNames.vickycares)
    clientId = apolloConfig.vickycaresId;
  if (host === constants.clientHostNames.eightfc)
    clientId = apolloConfig.eightfcId;
  if (host === constants.clientHostNames.rivervale)
    clientId = apolloConfig.rivervaleId;
  if (host === constants.clientHostNames.tglmc) clientId = apolloConfig.tglmcId;
  if (host === constants.clientHostNames.curieoncology)
    clientId = apolloConfig.curieoncologyId;
  if (host === constants.clientHostNames.pathnova)
    clientId = apolloConfig.pathnovaId;
  if (host === constants.clientHostNames.careerps)
    clientId = apolloConfig.careerpsId;
  if (host === constants.clientHostNames.reste) clientId = apolloConfig.resteId;
  if (host === constants.clientHostNames.restelabdemo)
    clientId = apolloConfig.restelabdemoId;
  if (host === constants.clientHostNames.clinicCosta)
    clientId = apolloConfig.clinicCostaId;
  if (host === constants.clientHostNames.ifcbedok)
    clientId = apolloConfig.ifcbedokId;
  if (host === constants.clientHostNames.ifcbukit)
    clientId = apolloConfig.ifcbukitId;
  if (host === constants.clientHostNames.ifcsengkang)
    clientId = apolloConfig.ifcsengkangId;
  if (host === constants.clientHostNames.ktmc) clientId = apolloConfig.ktmcId;
  if (host === constants.clientHostNames.lifelinesk)
    clientId = apolloConfig.lifelineskId;
  if (host === constants.clientHostNames.martinmedical)
    clientId = apolloConfig.martinmedicalId;
  if (host === constants.clientHostNames.mmchoungang)
    clientId = apolloConfig.mmchoungangId;
  if (host === constants.clientHostNames.mmcus) clientId = apolloConfig.mmcusId;
  if (host === constants.clientHostNames.mfcysa)
    clientId = apolloConfig.mfcysaId;
  if (host === constants.clientHostNames.mfccck)
    clientId = apolloConfig.mfccckId;
  if (host === constants.clientHostNames.mfcclm325)
    clientId = apolloConfig.mfcclm325Id;
  if (host === constants.clientHostNames.mfcclementi)
    clientId = apolloConfig.mfcclementiId;
  if (host === constants.clientHostNames.mfchougangcentral)
    clientId = apolloConfig.mfchougangcentralId;
  if (host === constants.clientHostNames.mfcpioneer)
    clientId = apolloConfig.mfcpioneerId;
  if (host === constants.clientHostNames.mfcpunggol)
    clientId = apolloConfig.mfcpunggolId;
  if (host === constants.clientHostNames.mfcrivervale)
    clientId = apolloConfig.mfcrivervaleId;
  if (host === constants.clientHostNames.mfcsegar)
    clientId = apolloConfig.mfcsegarId;
  if (host === constants.clientHostNames.mfcsenja)
    clientId = apolloConfig.mfcsenjaId;
  if (host === constants.clientHostNames.mfcstg)
    clientId = apolloConfig.mfcstgId;
  if (host === constants.clientHostNames.mfctanglinhalt)
    clientId = apolloConfig.mfctanglinhaltId;
  if (host === constants.clientHostNames.mfctoapayoh)
    clientId = apolloConfig.mfctoapayohId;
  if (host === constants.clientHostNames.mfcwdg)
    clientId = apolloConfig.mfcwdgId;
  if (host === constants.clientHostNames.mfcwoodlands)
    clientId = apolloConfig.mfcwoodlandsId;
  if (host === constants.clientHostNames.ourwfc)
    clientId = apolloConfig.ourwfcId;
  if (host === constants.clientHostNames.pancaremedical)
    clientId = apolloConfig.pancaremedicalId;
  if (host === constants.clientHostNames.atamed)
    clientId = apolloConfig.atamedId;
  if (host === constants.clientHostNames.temasekmedchoa)
    clientId = apolloConfig.temasekmedchoaId;
  if (host === constants.clientHostNames.qmtc) clientId = apolloConfig.qmtcId;
  if (host === constants.clientHostNames.qmbuangkokmrt)
    clientId = apolloConfig.qmbuangkokmrtId;
  if (host === constants.clientHostNames.qmbb) clientId = apolloConfig.qmbbId;
  if (host === constants.clientHostNames.qmsc) clientId = apolloConfig.qmscId;
  if (host === constants.clientHostNames.tpsim) clientId = apolloConfig.tpsimId;
  if (host === constants.clientHostNames.vitacarepc)
    clientId = apolloConfig.vitacarepcId;
  if (host === constants.clientHostNames.vitacarecanberra)
    clientId = apolloConfig.vitacarecanberraId;
  if (host === constants.clientHostNames.vcare) clientId = apolloConfig.vcareId;
  if (host === constants.clientHostNames.ifca) clientId = apolloConfig.ifcaId;
  if (host === constants.clientHostNames.mountE)
    clientId = apolloConfig.mountEId;
  if (host === constants.clientHostNames.arcadia)
    clientId = apolloConfig.arcadiaId;
  if (host === constants.clientHostNames.mfcanchorvale)
    clientId = apolloConfig.mfcanchorvaleId;
  if (host === constants.clientHostNames.ctrkidney)
    clientId = apolloConfig.ctrkidneyId;
  if (host === constants.clientHostNames.dknmg) clientId = apolloConfig.dknmgId;
  if (host === constants.clientHostNames.marinaps)
    clientId = apolloConfig.marinapsId;
  if (host === constants.clientHostNames.havelock)
    clientId = apolloConfig.havelockId;
  if (host === constants.clientHostNames.mcfclinic)
    clientId = apolloConfig.mcfclinicId;
  if (host === constants.clientHostNames.qmfp) clientId = apolloConfig.qmfpId;
  if (host === constants.clientHostNames.eight) clientId = apolloConfig.eightId;
  if (host === constants.clientHostNames.integratedwellness)
    clientId = apolloConfig.integratedwellnessId;
  if (host === constants.clientHostNames.BishanGraceClinic)
    clientId = apolloConfig.BishanGraceClinicId;
  if (host === constants.clientHostNames.bestMed)
    clientId = apolloConfig.bestMedId;
  if (host === constants.clientHostNames.acumen)
    clientId = apolloConfig.acumenId;
  if (host === constants.clientHostNames.tekka) clientId = apolloConfig.tekkaId;
  if (host === constants.clientHostNames.trinity)
    clientId = apolloConfig.trinityId;
  if (host === constants.clientHostNames.trinityProject)
    clientId = apolloConfig.trinityProjectId;
  if (host === constants.clientHostNames.westPoint)
    clientId = apolloConfig.westPointId;
  if (host === constants.clientHostNames.iDocClinicHougangGreen)
    clientId = apolloConfig.iDocClinicHougangGreenId;
  if (host === constants.clientHostNames.iDocClinicHongKah)
    clientId = apolloConfig.iDocClinicHongKahId;
  if (host === constants.clientHostNames.equinoxBahari)
    clientId = apolloConfig.equinoxBahariId;
  if (host === constants.clientHostNames.minMedOrchard)
    clientId = apolloConfig.minMedOrchardId;
  if (host === constants.clientHostNames.FiftySevenMedicalYiShun)
    clientId = apolloConfig.FiftySevenMedicalYiShunId;
  if (host === constants.clientHostNames.faircareFamily)
    clientId = apolloConfig.faircareFamilyId;
  if (host === constants.clientHostNames.mirxes)
    clientId = apolloConfig.mirxesId;
  if (host === constants.clientHostNames.mDiagnostics)
    clientId = apolloConfig.mDiagnosticsId;
  if (host === constants.clientHostNames.kcsMed)
    clientId = apolloConfig.kcsMedId;
  if (host === constants.clientHostNames.geneRock)
    clientId = apolloConfig.geneRockId;
  if (host === constants.clientHostNames.homedical)
    clientId = apolloConfig.homedicalId;
  if (host === constants.clientHostNames.complete)
    clientId = apolloConfig.completeId;
  if (host === constants.clientHostNames.kingGeorge)
    clientId = apolloConfig.kingGeorgeId;
  if (host === constants.clientHostNames.mayFairYiShun)
    clientId = apolloConfig.mayFairYiShunId;
  if (host === constants.clientHostNames.mayFairMarsling)
    clientId = apolloConfig.mayFairMarslingId;
  if (host === constants.clientHostNames.acacia)
    clientId = apolloConfig.acaciaId;
  if (host === constants.clientHostNames.FiftySevenMedicalClinic)
    clientId = apolloConfig.FiftySevenMedicalClinicId;
  if (host === constants.clientHostNames.disInc)
    clientId = apolloConfig.disIncId;
  if (host === constants.clientHostNames.lohLoh)
    clientId = apolloConfig.lohLohId;
  if (host === constants.clientHostNames.eternmedsengkang)
    clientId = apolloConfig.eternmedsengkangId;
  if (host === constants.clientHostNames.physiciansPracticeFamily)
    clientId = apolloConfig.physiciansPracticeFamilyId;
  if (host === constants.clientHostNames.fullertonArc)
    clientId = apolloConfig.fullertonArcId;
  if (host === constants.clientHostNames.amosup)
    clientId = apolloConfig.amosupId;
  if (host === constants.clientHostNames.providencenovena)
    clientId = apolloConfig.providencenovenaId;
  if (host === constants.clientHostNames.greenx)
    clientId = apolloConfig.greenxId;
  if (host === constants.clientHostNames.pmcyishun)
    clientId = apolloConfig.pmcyishunId;
  if (host === constants.clientHostNames.pierxray)
    clientId = apolloConfig.pierxrayId;
  if (host === constants.clientHostNames.yarramedicalclinic)
    clientId = apolloConfig.yarramedicalclinicId;
  if (host === constants.clientHostNames.fusionnexus)
    clientId = apolloConfig.fusionnexusId;
  if (host === constants.clientHostNames.fusionwheelock)
    clientId = apolloConfig.fusionwheelockId;
  if (host === constants.clientHostNames.newHealth)
    clientId = apolloConfig.newHealthId;
  if (host === constants.clientHostNames.mintMedicalFunan)
    clientId = apolloConfig.mintMedicalFunanId;
  if (host === constants.clientHostNames.mintMedicalHarbourFront)
    clientId = apolloConfig.mintMedicalHarbourFrontId;
  if (host === constants.clientHostNames.pulse) clientId = apolloConfig.pulseId;
  if (host === constants.clientHostNames.aventus)
    clientId = apolloConfig.aventusId;
  if (host === constants.clientHostNames.plato) clientId = apolloConfig.platoId;
  if (host === constants.clientHostNames.neeSoon)
    clientId = apolloConfig.neeSoonId;
  if (host === constants.clientHostNames.zuellig)
    clientId = apolloConfig.zuelligId;
  if (host === constants.clientHostNames.familyCareClinic)
    clientId = apolloConfig.familyCareClinicId;
  if (host === constants.clientHostNames.pTan) clientId = apolloConfig.pTanId;
  if (host === constants.clientHostNames.noatun)
    clientId = apolloConfig.noatunId;
  if (host === constants.clientHostNames.sunCareSengKang)
    clientId = apolloConfig.sunCareSengKangId;
  if (host === constants.clientHostNames.sunCareMedicalSengKang)
    clientId = apolloConfig.sunCareMedicalSengKangId;
  if (host === constants.clientHostNames.sunCareSengKang)
    clientId = apolloConfig.sunCareSengKangId;
  if (host === constants.clientHostNames.faithfamilyclinic)
    clientId = apolloConfig.faithfamilyclinicId;
  if (host === constants.clientHostNames.riverrMedical)
    clientId = apolloConfig.riverrMedicalId;
  if (host === constants.clientHostNames.aLifeClinic)
    clientId = apolloConfig.aLifeClinicId;
  if (host === constants.clientHostNames.alexandra)
    clientId = apolloConfig.alexandraId;
  if (host === constants.clientHostNames.minMedJurong)
    clientId = apolloConfig.minMedJurongId;
  if (host === constants.clientHostNames.minMedHaig)
    clientId = apolloConfig.minMedHaigId;
  if (host === constants.clientHostNames.minMedPasir)
    clientId = apolloConfig.minMedPasirId;
  if (host === constants.clientHostNames.minMedPunggol)
    clientId = apolloConfig.minMedPunggolId;
  if (host === constants.clientHostNames.minMedSengKang)
    clientId = apolloConfig.minMedSengKangId;
  if (host === constants.clientHostNames.minMedYiShun)
    clientId = apolloConfig.minMedYiShunId;
  if (host === constants.clientHostNames.minMedHealthScreeners)
    clientId = apolloConfig.minMedHealthScreenersId;
  if (host === constants.clientHostNames.urgentClinic)
    clientId = apolloConfig.urgentClinicId;
  if (host === constants.clientHostNames.aris) clientId = apolloConfig.arisId;
  if (host === constants.clientHostNames.c3Clinic)
    clientId = apolloConfig.c3ClinicId;
  if (host === constants.clientHostNames.stLukesGlobalCity)
    clientId = apolloConfig.stLukesGlobalCityId;
  if (host === constants.clientHostNames.myClinic)
    clientId = apolloConfig.myClinicId;
  if (host === constants.clientHostNames.greenlinkClinic)
    clientId = apolloConfig.greenlinkClinicId;
  if (host === constants.clientHostNames.myHealth)
    clientId = apolloConfig.myHealthId;
  if (host === constants.clientHostNames.dover) clientId = apolloConfig.doverId;
  if (host === constants.clientHostNames.notredame)
    clientId = apolloConfig.notredameId;
  if (host === constants.clientHostNames.pdsc) clientId = apolloConfig.pdscId;
  if (host === constants.clientHostNames.imlab) clientId = apolloConfig.imlabId;
  if (host === constants.clientHostNames.cghmc) clientId = apolloConfig.cghmcId;
  if (host === constants.clientHostNames.oneworldclinic)
    clientId = apolloConfig.oneworldclinicId;
  if (host === constants.clientHostNames.nordic)
    clientId = apolloConfig.nordicId;
  if (host === constants.clientHostNames.blessClinic)
    clientId = apolloConfig.blessClinicId;
  if (host === constants.clientHostNames.cckClinic)
    clientId = apolloConfig.cckClinicId;
  if (host === constants.clientHostNames.oslerHealthInternational)
    clientId = apolloConfig.oslerHealthInternationalId;
  if (host === constants.clientHostNames.FemaClinicSurgery)
    clientId = apolloConfig.FemaClinicSurgeryId;
  if (host === constants.clientHostNames.StarMedSpecialistCentre)
    clientId = apolloConfig.StarMedSpecialistCentreId;
  if (host === constants.clientHostNames.cloverMedicalClinic)
    clientId = apolloConfig.cloverMedicalClinicId;
  if (host === constants.clientHostNames.trueMedicalClinicTampinesWest)
    clientId = apolloConfig.trueMedicalClinicTampinesWestId;
  if (host === constants.clientHostNames.trueMedicalClinicPayaLebarQuarter)
    clientId = apolloConfig.trueMedicalClinicPayaLebarQuarterId;
  if (host === constants.clientHostNames.dtapNovena)
    clientId = apolloConfig.dtapNovenaId;
  if (host === constants.clientHostNames.speedoc)
    clientId = apolloConfig.speedocId;
  if (host === constants.clientHostNames.soonLeeMedicalClinic)
    clientId = apolloConfig.soonLeeMedicalClinicId;
  if (host === constants.clientHostNames.faithYishun)
    clientId = apolloConfig.faithYishunId;
  if (host === constants.clientHostNames.faithBedok)
    clientId = apolloConfig.faithBedokId;
  if (host === constants.clientHostNames.faithJurongWest)
    clientId = apolloConfig.faithJurongWestId;
  if (host === constants.clientHostNames.faithTampines)
    clientId = apolloConfig.faithTampinesId;
  if (host === constants.clientHostNames.faithToaPayoh)
    clientId = apolloConfig.faithToaPayohId;
  if (host === constants.clientHostNames.faithSimei)
    clientId = apolloConfig.faithSimeiId;
  if (host === constants.clientHostNames.faithRivervale)
    clientId = apolloConfig.faithRivervaleId;
  if (host === constants.clientHostNames.daClinicAnson)
    clientId = apolloConfig.daClinicAnsonId;
  if (host === constants.clientHostNames.daClinicBukitBatok)
    clientId = apolloConfig.daClinicBukitBatokId;
  if (host === constants.clientHostNames.daClinicAnchorvale)
    clientId = apolloConfig.daClinicAnchorvaleId;
  if (host === constants.clientHostNames.daClinicBishan)
    clientId = apolloConfig.daClinicBishanId;
  if (host === constants.clientHostNames.daClinicPotongPasir)
    clientId = apolloConfig.daClinicPotongPasirId;
  if (host === constants.clientHostNames.daClinicTamanJurong)
    clientId = apolloConfig.daClinicTamanJurongId;
  if (host === constants.clientHostNames.k2Medical)
    clientId = apolloConfig.k2MedicalId;
  if (host === constants.clientHostNames.interMedical24)
    clientId = apolloConfig.interMedical24Id;
  if (host === constants.clientHostNames.ChinChooMedical)
    clientId = apolloConfig.ChinChooMedicalId;
  if (host === constants.clientHostNames.SembawangMartMedicalCentre)
    clientId = apolloConfig.SembawangMartMedicalCentreId;
  if (host === constants.clientHostNames.TheClinIQ)
    clientId = apolloConfig.TheClinIQId;
  if (host === constants.clientHostNames.SengkangFamilyClinic)
    clientId = apolloConfig.SengkangFamilyClinicId;
  if (host === constants.clientHostNames.iconMedicalClinic)
    clientId = apolloConfig.iconMedicalClinicId;

  if (host === constants.clientHostNames.carefamilyclinic)
    clientId = apolloConfig.carefamilyclinicId;
  if (host === constants.clientHostNames.centralclinicpr)
    clientId = apolloConfig.centralclinicprId;
  if (host === constants.clientHostNames.chaicheeclinic)
    clientId = apolloConfig.chaicheeclinicId;
  if (host === constants.clientHostNames.veinandendovascularsurgery)
    clientId = apolloConfig.veinandendovascularsurgeryId;
  if (host === constants.clientHostNames.chuamedicalcentrepl)
    clientId = apolloConfig.chuamedicalcentreplId;
  if (host === constants.clientHostNames.chuamedicalcentresurgery)
    clientId = apolloConfig.chuamedicalcentresurgeryId;
  if (host === constants.clientHostNames.cemclinic)
    clientId = apolloConfig.cemclinicId;
  if (host === constants.clientHostNames.clinicfordigestivesurgery)
    clientId = apolloConfig.clinicfordigestivesurgeryId;
  if (host === constants.clientHostNames.dermclinicorchard)
    clientId = apolloConfig.dermclinicorchardId;
  if (host === constants.clientHostNames.earnosethroat)
    clientId = apolloConfig.earnosethroatId;
  if (host === constants.clientHostNames.easternhougang)
    clientId = apolloConfig.easternhougangId;
  if (host === constants.clientHostNames.ejfamily)
    clientId = apolloConfig.ejfamilyId;
  if (host === constants.clientHostNames.essentialfclinic)
    clientId = apolloConfig.essentialfclinicId;
  if (host === constants.clientHostNames.novenaeyesurgeons)
    clientId = apolloConfig.novenaeyesurgeonsId;
  if (host === constants.clientHostNames.firstlight)
    clientId = apolloConfig.firstlightId;
  if (host === constants.clientHostNames.healthfirst)
    clientId = apolloConfig.healthfirstId;
  if (host === constants.clientHostNames.tysiaw)
    clientId = apolloConfig.tysiawId;
  if (host === constants.clientHostNames.healthmarkpr)
    clientId = apolloConfig.healthmarkprId;
  if (host === constants.clientHostNames.healthmarkcompassvale)
    clientId = apolloConfig.healthmarkcompassvaleId;
  if (host === constants.clientHostNames.healthmarkpg)
    clientId = apolloConfig.healthmarkpgId;
  if (host === constants.clientHostNames.healthmarkpioneer)
    clientId = apolloConfig.healthmarkpioneerId;
  if (host === constants.clientHostNames.healthmedbp)
    clientId = apolloConfig.healthmedbpId;
  if (host === constants.clientHostNames.healthpointfc)
    clientId = apolloConfig.healthpointfcId;
  if (host === constants.clientHostNames.healthspringsmedicallaserclinic)
    clientId = apolloConfig.healthspringsmedicallaserclinicId;
  if (host === constants.clientHostNames.healthspringsorchard)
    clientId = apolloConfig.healthspringsorchardId;
  if (host === constants.clientHostNames.healthspringsmedicalclinic)
    clientId = apolloConfig.healthspringsmedicalclinicId;
  if (host === constants.clientHostNames.helix) clientId = apolloConfig.helixId;
  if (host === constants.clientHostNames.holisticcare)
    clientId = apolloConfig.holisticcareId;
  if (host === constants.clientHostNames.ifcfernvale)
    clientId = apolloConfig.ifcfernvaleId;
  if (host === constants.clientHostNames.punggolgp)
    clientId = apolloConfig.punggolgpId;
  if (host === constants.clientHostNames.yangyapclinic)
    clientId = apolloConfig.yangyapclinicId;
  if (host === constants.clientHostNames.woodlandsclinic)
    clientId = apolloConfig.woodlandsclinicId;
  if (host === constants.clientHostNames.unihealthclinictoapayoh)
    clientId = apolloConfig.unihealthclinictoapayohId;
  if (host === constants.clientHostNames.truecareus)
    clientId = apolloConfig.truecareusId;
  if (host === constants.clientHostNames.truecareamk)
    clientId = apolloConfig.truecareamkId;
  if (host === constants.clientHostNames.iclinic)
    clientId = apolloConfig.iclinicId;
  if (host === constants.clientHostNames.tampinesfmc)
    clientId = apolloConfig.tampinesfmcId;
  if (host === constants.clientHostNames.soongclinic)
    clientId = apolloConfig.soongclinicId;
  if (host === constants.clientHostNames.shifaclinicsurgery)
    clientId = apolloConfig.shifaclinicsurgeryId;
  if (host === constants.clientHostNames.seacare)
    clientId = apolloConfig.seacareId;
  if (host === constants.clientHostNames.pioneermedicare)
    clientId = apolloConfig.pioneermedicareId;
  if (host === constants.clientHostNames.peakmedicalsg)
    clientId = apolloConfig.peakmedicalsgId;
  if (host === constants.clientHostNames.ofpcs) clientId = apolloConfig.ofpcsId;
  if (host === constants.clientHostNames.ortholimb)
    clientId = apolloConfig.ortholimbId;
  if (host === constants.clientHostNames.oasis) clientId = apolloConfig.oasisId;
  if (host === constants.clientHostNames.newest)
    clientId = apolloConfig.newestId;
  if (host === constants.clientHostNames.mydoctoradmiralty)
    clientId = apolloConfig.mydoctoradmiraltyId;
  if (host === constants.clientHostNames.mydoctor)
    clientId = apolloConfig.mydoctorId;
  if (host === constants.clientHostNames.missionbb)
    clientId = apolloConfig.missionbbId;
  if (host === constants.clientHostNames.millenniumck)
    clientId = apolloConfig.millenniumckId;
  if (host === constants.clientHostNames.mhcfamilyclinic)
    clientId = apolloConfig.mhcfamilyclinicId;
  if (host === constants.clientHostNames.medivene)
    clientId = apolloConfig.mediveneId;
  if (host === constants.clientHostNames.livewellmedical)
    clientId = apolloConfig.livewellmedicalId;
  if (host === constants.clientHostNames.lilyaw)
    clientId = apolloConfig.lilyawId;
  if (host === constants.clientHostNames.leeandtan)
    clientId = apolloConfig.leeandtanId;
  if (host === constants.clientHostNames.langeye)
    clientId = apolloConfig.langeyeId;
  if (host === constants.clientHostNames.jerehheart)
    clientId = apolloConfig.jerehheartId;
  return clientId;
};

export const chooseDBasedOnHostname = host => {
  let db;
  if (host === constants.clientHostNames.localhost) {
    db = apolloConfig.envUrls.local;
  } else {
    db = apolloConfig.envUrls.production;
  }
  return db;
};
