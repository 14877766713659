// eslint-disable-next-line import/no-cycle
import { apolloClient } from '../apollo/apollo';
import {
  getAllCMSUsers,
  getAdmin,
  getUserConnection,
} from '../graphql/queries/users';
import {
  signInUser,
  adminForgotPassword,
  inviteUser,
  deleteUser,
  adminActivate,
  adminResetPassword,
  signOutUser,
  platoLogin,
} from '../graphql/mutations/users';

export default {
  async onSignInUser(user) {
    return apolloClient.mutate({
      mutation: signInUser,
      variables: {
        email: user.email,
        password: user.password,
      },
    });
  },

  async platoLogin(token) {
    return apolloClient.mutate({
      mutation: platoLogin,
      variables: {
        token,
      },
    });
  },

  async onActivateAdmin(user) {
    return apolloClient.mutate({
      mutation: adminActivate,
      variables: {
        email: user.email,
        token: user.token,
        password: user.password,
      },
    });
  },

  async onAdminResetPassword(user) {
    return apolloClient.mutate({
      mutation: adminResetPassword,
      variables: {
        email: user.email,
        token: user.token,
        password: user.password,
      },
    });
  },

  async onForgotPassword(email) {
    return apolloClient.mutate({
      mutation: adminForgotPassword,
      variables: {
        email,
      },
    });
  },

  async onResetPassword(user) {
    return apolloClient.mutate({
      mutation: adminResetPassword,
      variables: {
        email: user.email,
        token: localStorage.getItem('AUTH_TOKEN'),
        password: user.password,
      },
    });
  },

  async onInviteUser(user) {
    return apolloClient.mutate({
      mutation: inviteUser,
      variables: {
        email: user.email,
        name: user.name,
        clientId: user.clientId,
        role: user.role,
        centerId: user.centerId,
      },
    });
  },

  async onDeleteUser(userId) {
    return apolloClient.mutate({
      mutation: deleteUser,
      variables: {
        id: userId,
      },
    });
  },

  async getAllUsers() {
    return apolloClient.query({
      query: getAllCMSUsers,
      fetchPolicy: 'no-cache',
    });
  },

  async getAdminById(id) {
    return apolloClient.query({
      query: getAdmin,
      fetchPolicy: 'no-cache',
      variables: {
        id,
      },
    });
  },

  async getUserConnection(id) {
    return apolloClient.query({
      query: getUserConnection,
      fetchPolicy: 'no-cache',
      variables: {
        id,
      },
    });
  },

  async logOff(id) {
    return apolloClient.mutate({
      mutation: signOutUser,
      variables: {
        id,
      },
    });
  },
};
