// import {
//   SET_USER,
//   SET_CLIENT_INFORMATION,
//   SET_DOCUMENT_TYPES,
// } from './generalState.actions';

// const initialState = {
//   clientInformation: {},
//   user: {},
//   templates: [],
//   documentTypes: [],
//   docTypesObj: {},
//   clientDocs: [],
// };

// const getTemplates = (client, docTypes) => {
//   if (client.type !== 'medical') return [];
//   const hasDocs = client.documents && client.documents.length > 0;

//   const options = !hasDocs
//     ? Object.keys(docTypes).map(docKey => ({
//         text: docTypes[docKey].name,
//         value: {
//           name: docTypes[docKey].name,
//           type: docTypes[docKey].subType,
//           category: docTypes[docKey].category,
//           subCategory: docTypes[docKey].subCategory,
//           image:
//             'https://riverr-tcm.s3.eu-central-1.amazonaws.com/delta/medicalCourses/pcr-text.jpeg',
//         },
//       }))
//     : client.documents.map(docKey => ({
//         text: docTypes[docKey].name,
//         value: {
//           name: docTypes[docKey].name,
//           type: docTypes[docKey].subType,
//           category: docTypes[docKey].category,
//           subCategory: docTypes[docKey].subCategory,
//           image:
//             'https://riverr-tcm.s3.eu-central-1.amazonaws.com/delta/medicalCourses/pcr-text.jpeg',
//         },
//       }));

//   return options;
// };

// const setClientDocs = (clientDocs, allDocs, docTypes) => {
//   if (!clientDocs || clientDocs.length === 0) return allDocs;
//   return clientDocs.map(doc => ({ ...docTypes[doc] }));
// };

// const generalState = (state = initialState, action) => {
//   switch (action.type) {
//     case SET_USER:
//       return { ...state, user: action.payload };

//     case SET_CLIENT_INFORMATION:
//       return { ...state, clientInformation: action.payload };

//     case SET_DOCUMENT_TYPES: {
//       const obj = {};
//       action.payload.forEach(doc => {
//         obj[doc.subType] = doc.name;
//       });

//       const docTypes = {};
//       action.payload.forEach(doc => {
//         docTypes[doc.subType] = doc;
//       });

//       const templates = getTemplates(state.clientInformation, docTypes);
//       const clientDocs = setClientDocs(
//         state.clientInformation.documents,
//         action.payload,
//         docTypes
//       );
//       return {
//         ...state,
//         documentTypes: action.payload,
//         docTypesObj: obj,
//         templates,
//         clientDocs,
//       };
//     }
//     default:
//       return state;
//   }
// };

// export default generalState;
import {
  SET_USER,
  SET_CLIENT_INFORMATION,
  SET_DOCUMENT_TYPES,
  SET_DEV_MODE,
} from './generalState.actions';

const initialState = {
  clientInformation: {},
  user: {},
  templates: [],
  documentTypes: [],
  docTypesObj: {},
  isDevMode: false,
};

const getTemplates = client => {
  if (client.type !== 'medical') return [];
  const options = [
    {
      text: 'COVID-19 Test Result',
      value: {
        name: 'COVID-19 Test Result',
        type: 'PCR_TEST',
        image:
          'https://riverr-tcm.s3.eu-central-1.amazonaws.com/delta/medicalCourses/pcr-text.jpeg',
      },
    },
    {
      text: 'Medical certificate for service at sea',
      value: {
        name: 'Medical certificate for service at sea',
        type: 'PEME',
        image:
          'https://riverr-tcm.s3.eu-central-1.amazonaws.com/delta/medicalCourses/peme.png',
      },
    },
    {
      text: 'Fitness to Travel',
      value: {
        name: 'Fitness to Travel',
        type: 'FFT',
        image:
          'https://riverr-tcm.s3.eu-central-1.amazonaws.com/delta/medicalCourses/peme.png',
      },
    },
  ];

  if (client.id === 'zHAzw9fw2H4cGE8udj50')
    options.push({
      text: 'COVID-19 Vaccine',
      value: {
        name: 'COVID-19 Vaccine',
        type: 'COVID_VAC',
        image:
          'https://riverr-tcm.s3.eu-central-1.amazonaws.com/delta/medicalCourses/pcr-text.jpeg',
      },
    });

  return options;
};

const generalStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, user: action.payload };

    case SET_DEV_MODE:
      return { ...state, isDevMode: action.payload };

    case SET_CLIENT_INFORMATION:
      const templates = getTemplates(action.payload);
      return { ...state, clientInformation: action.payload, templates };

    case SET_DOCUMENT_TYPES:
      const docTypesObj = {};
      action.payload.forEach(doc => {
        docTypesObj[doc.subType] = doc.name;
      });
      return { ...state, documentTypes: action.payload, docTypesObj };

    default:
      return state;
  }
};
export default generalStateReducer;
