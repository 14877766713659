import { apolloClient } from '../apollo/apollo';
import {
  getInstructorsByClientId,
  getInstructorsByCenterId,
  getInstructor,
  getInstructorCount,
} from '../graphql/queries/instructors';
import {
  createInstructor,
  deleteInstructor,
  updateInstructor,
} from '../graphql/mutations/instructors';

export default {
  async getInstructorsByClientId(clientId) {
    return apolloClient.query({
      query: getInstructorsByClientId,
      fetchPolicy: 'no-cache',
      variables: {
        clientId,
      },
    });
  },

  async getInstructorsByCenterId(centerId) {
    return apolloClient.query({
      query: getInstructorsByCenterId,
      fetchPolicy: 'no-cache',
      variables: {
        centerId,
      },
    });
  },

  async getInstructor(id) {
    return apolloClient.query({
      query: getInstructor,
      fetchPolicy: 'no-cache',
      variables: {
        id,
      },
    });
  },

  async createInstructor(instructor) {
    return apolloClient.mutate({
      mutation: createInstructor,
      variables: {
        ...instructor,
      },
    });
  },

  async updateInstructor(instructor) {
    return apolloClient.mutate({
      mutation: updateInstructor,
      variables: {
        ...instructor,
      },
    });
  },

  async deleteInstructor(instructorId) {
    return apolloClient.mutate({
      mutation: deleteInstructor,
      variables: {
        id: instructorId,
      },
    });
  },

  async getInstructorCount(clientId, centerId) {
    return apolloClient.query({
      query: getInstructorCount,
      variables: {
        clientId,
        centerId,
      },
    });
  },
};
