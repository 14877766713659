import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Text } from '../../../../components';
import { Icon } from 'semantic-ui-react';
import chartColors from '../../../../helpers/chartsColors';

import './legend.scss';

const Legend = ({
  setSelectedSubType,
  selectedSubType,
  setHoveredSubType,
  hoveredSubType,
  setHovering,
}) => {
  const [subTypes, setSubTypes] = useState([]);
  const subTypez = useSelector(state => state.charts.subTypes);

  useEffect(() => {
    const types = [];
    subTypez.forEach(sub => {
      if (sub && sub.text) types.push(sub);
    });
    setSubTypes(types);
  }, [subTypez]);

  return (
    <div className="dashboard-legend">
      <div className="dashboard-legend__titles">Document types</div>

      <div className="dashboard-legend__type-list">
        {subTypes.map((option, index) => (
          <div
            key={option.text + index.toString()}
            className={`dashboard-legend__type${
              option.text === hoveredSubType
                ? ' dashboard-legend__type--hightlighted'
                : ''
            }`}
            // onClick={() => {
            //   // toggling the selection status
            //   !selectedSubType.includes(option.text)
            //     ? setSelectedSubType(selectedSubType.concat(option.text))
            //     : setSelectedSubType(
            //         selectedSubType.filter(y => y !== option.text)
            //       );
            // }}
            onMouseEnter={() => {
              setHoveredSubType(option.text);
              setHovering(true);
            }}
            onMouseLeave={() => setHovering(false)}
          >
            <Icon
              name={
                selectedSubType.includes(option.text)
                  ? 'circle'
                  : 'circle outline'
              }
              style={{ color: chartColors[index] }}
            />
            {option.text}
          </div>
        ))}
        {/* <div class="dashboard-legend__type">
          <Icon name="circle" style={{ color: chartColors[20] }} />
          Total
        </div> */}
      </div>
      {/* <div className="dashboard-legend__selection-details">
        <span>
          {selectedSubType.length} out of {subTypes.length}
        </span>
        <div
          style={{ cursor: 'pointer' }}
          onClick={() => {
            //if currently is selecting all already, change it to empty
            if (selectedSubType.length === subTypes.length) {
              setSelectedSubType([]);
            } else {
              setSelectedSubType(subTypes.map(x => x.text));
            }
          }}
        >
          <Text size="small" color="lightBlue">
            Select All
          </Text>
        </div>
      </div> */}
    </div>
  );
};

export default Legend;
