import React from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import DefaultDashboard from './Dashboard';
import CorporateDashboard from './CorporateDashboard';
import WebshopDashboard from '../webAppointments/appointmentStats/appointmentStats';

const Dashboard = props => {
  const clientInformation = useSelector(
    state => state.general.clientInformation
  );
  const userInfo = useSelector(state => state.general.user);

  if (
    clientInformation.role === 'corporate' ||
    clientInformation.role === 'recruitment'
  )
    return <CorporateDashboard />;

  if (userInfo.role === 'appointmentHandler') return <WebshopDashboard />;

  return <DefaultDashboard />;
};

export default withRouter(Dashboard);
