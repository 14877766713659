import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { DatePicker, Text } from 'components';
import CircleLoader from 'react-spinners/CircleLoader';
import moment from 'moment';

import history from '../../../history';
import Widget from './components/Widget/Widget';
import StatsGraph from './components/StatsGraph/StatsGraph';
import PieChart from './components/graphs/PieGraph';
import BarChart from './components/graphs/BarChart';
import PaymentWidget from './components/PaymentWidget/PaymentWidget';
import AppointmentsWidget from './components/appointmentWidgets/appointmentWidget';

import MedBookingService from '../../../api/newAppointments';

import './appointmentStats.scss';

const AppointmentStats = () => {
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [dashboardStats, setDashboardStats] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState([]);
  const [appointmentStatus, setAppointmentStatus] = useState([]);
  const [currentView, setcurrentView] = useState([]);
  const [currentViewData, setCurrentViewData] = useState([]);
  const [currency, setCurrency] = useState('SGD');

  const clientId = useSelector(state => state.general.clientInformation.id);
  const dateFormat = 'DD-MM-YYYY';
  const startOfMonth = moment()
    .startOf('month')
    .toISOString();

  useEffect(() => {
    if (!isInitialRender) {
      fetchStats({
        clientId,
        fromDate,
        toDate,
      });
    } else {
      setUrlValuesToState();
      setIsInitialRender(false);
    }
  }, [fromDate, toDate]);

  useEffect(() => {
    fetchCurrency();
  }, []);

  const colors = ['#6AD164'];

  const colors1 = ['#8884d8', '#FF5733', '#FFC300'];

  const fetchStats = async ({ clientId, fromDate, toDate }) => {
    try {
      setLoading(true);
      const searchParams = {
        clientId,
        fromDate: startOfMonth,
        isDev: localStorage.getItem('IS_DEV_MODE') === 'true',
      };
      if (toDate) searchParams.toDate = moment(toDate).toISOString();
      if (fromDate) searchParams.fromDate = moment(fromDate).toISOString();
      const {
        data: { getDashboardStats },
      } = await MedBookingService.getDashboardStats(searchParams);
      setDashboardStats(JSON.parse(getDashboardStats));
      mapDataItems(JSON.parse(getDashboardStats));
      updateSearchParams({ clientId, fromDate: searchParams.fromDate, toDate });
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCurrency = async () => {
    try {
      const {
        data: { getClientDetails },
      } = await MedBookingService.getClientDetails({
        clientId,
        isDev: localStorage.getItem('IS_DEV_MODE') === 'true',
      });
      setCurrency(getClientDetails.currency);
    } catch (error) {
      console.log(error);
    }
  };

  const setUrlValuesToState = () => {
    const params = new URLSearchParams(history.location.search);

    const fromDate = params.get('fromDate')
      ? moment(params.get('fromDate'), dateFormat).toDate()
      : '';
    const toDate = params.get('toDate')
      ? moment(params.get('toDate'), dateFormat).toDate()
      : '';

    setFromDate(fromDate);
    setToDate(toDate);
  };

  const updateSearchParams = ({ fromDate, toDate }) => {
    const newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      `?fromDate=${
        fromDate
          ? moment(fromDate).format(dateFormat)
          : moment()
              .startOf('month')
              .format(dateFormat)
      }&toDate=${toDate ? moment(toDate).format(dateFormat) : ''}`;
    window.history.pushState({ path: newurl }, '', newurl);
  };

  const mapDataItems = data => {
    setPaymentStatus([
      {
        name: 'Succeeded',
        value: data.payments.paymentByStatus.succeeded,
      },
    ]);

    setAppointmentStatus([
      {
        name: 'Completed',
        value: data.completedAppointments,
      },
      {
        name: 'Cancelled',
        value: data.cancelledAppointments,
      },
      {
        name: 'Pending',
        value:
          data.appointments -
          (data.cancelledAppointments + data.completedAppointments),
      },
    ]);
    setCurrentViewData(data?.patients?.patientsByTestNames);
    setcurrentView('tests');
  };

  const currencyFormat = value => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: currency,
    }).format(value);
  };

  const toggleTableData = value => {
    setcurrentView(value);
    if (value === 'tests') {
      return setCurrentViewData(dashboardStats?.patients?.patientsByTestNames);
    }
    setCurrentViewData(dashboardStats?.appointmentByTestypes?.byTestName);
  };

  return (
    <div className="appointment-stats">
      <div className="appointment-stats__header-wrapper">
        <Text bold size="big">
          Appointment Statistics
        </Text>
        <div className="appointment-stats__actions">
          {/* <Text
          size="tiny"
          color="black"
          className="appointment-stats__date-filter-label"
        >
          Filter by date range
        </Text> */}
          <div className="appointment-stats__date-wrapper">
            <div style={{ position: 'relative' }}>
              <Text
                size="tinniest"
                color="lightGrey"
                className="appointment-stats__date-tag"
              >
                From
              </Text>
              <DatePicker
                className="appointment-stats__date"
                onChange={date => setFromDate(date)}
                value={fromDate}
              />
            </div>
            <div style={{ position: 'relative' }}>
              <Text
                size="tinniest"
                color="lightGrey"
                className="appointment-stats__date-tag"
              >
                To
              </Text>
              <DatePicker
                className="appointment-stats__date"
                onChange={date => setToDate(date)}
                value={toDate}
              />
            </div>
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className="appointment-stats__loader-wrapper">
          <CircleLoader color="#455af7" />
        </div>
      ) : (
        <div className="appointment-stats__widgets-wrapper">
          <div className="appointment-stats__widgets">
            <Widget
              header="Appointment"
              label="Scheduled"
              data={dashboardStats.appointments}
            />
            <Widget
              header="Patients"
              label="Total"
              data={dashboardStats?.patients?.totalPatients}
            />
            <Widget
              header="Patients"
              label="Total tested"
              data={dashboardStats?.patients?.patientsTested}
            />
            <Widget
              header="Payments"
              label={`In Amount (${currencyFormat(
                dashboardStats?.payments?.totalAmount
              )})`}
              data={dashboardStats?.payments?.totalAmount}
            />
          </div>
          <div className="appointment-stats__widgets stats-overview">
            <div className="appointment-stats__widgets-item">
              <PieChart
                header="Payment Statuses"
                data={paymentStatus}
                colors={colors}
              />
            </div>
            <div className="appointment-stats__widgets-item">
              <PieChart
                header="Appointment Status"
                data={appointmentStatus}
                colors={colors1}
              />
            </div>
            {/* <Widget
              header="Appointment"
              label="Referrals"
              data={dashboardStats?.referrals?.appointments}
            />
            <Widget
              header="Referrals"
              label={`In Amount (${currencyFormat(
                dashboardStats?.referrals?.amount
              )})`}
              data={dashboardStats?.referrals?.amount}
            /> */}
            <div className="appointment-stats__payment__wrapper">
              <PaymentWidget
                data={dashboardStats?.payments?.paymentByMethod}
                header="Payment by method"
                currency={currency}
              />
            </div>
          </div>
          <div className="appointment-stats__graph">
            <div className="appointment-stats__graph--main">
              <StatsGraph
                data={dashboardStats?.monthlyRevenue?.monthlyPayment}
              />
            </div>
            <div className="appointment-stats__tests">
              <div className="appointment-stats__tests__wrapper">
                <div className="appointment-stats-graph__data-wrapper__main-chart__menubar mb-0">
                  {currentView === 'tests'
                    ? 'Patients By Test'
                    : 'Appointments By Test'}
                  <div className="appointment-stats-graph__data-wrapper__main-chart__menubar-buttons">
                    <div
                      className={
                        currentView === 'tests'
                          ? 'appointment-stats-graph__data-wrapper__main-chart__menubar-buttons--highlighted'
                          : ''
                      }
                      onClick={() => toggleTableData('tests')}
                    >
                      Tests
                    </div>
                    <div
                      className={
                        currentView === 'appointments'
                          ? 'appointment-stats-graph__data-wrapper__main-chart__menubar-buttons--highlighted'
                          : ''
                      }
                      onClick={() => toggleTableData('appointments')}
                    >
                      Appointments
                    </div>
                  </div>
                </div>
                <AppointmentsWidget data={currentViewData} type={currentView} />
              </div>
            </div>
          </div>
          {/* <div className="appointment-stats__graph">
            <div className="appointment-stats__tests__wrapper">
              <PaymentWidget
                data={dashboardStats?.payments?.paymentByMethod}
                header="Payment by method"
              />
            </div>
          </div> */}
        </div>
      )}
    </div>
  );
};

export default AppointmentStats;
