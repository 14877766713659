import gql from 'graphql-tag';

export const createAppointment = gql`
  mutation appointmentSet(
    $type: String
    $clientId: ID
    $centerId: ID
    $physicianId: ID
    $patientId: ID
    $startTime: String
    $endTime: String
    $startDate: String
    $street: String
    $zip: String
    $city: String
    $country: String
    $isCompleted: Boolean
    $description: String
    $geoLocation: String
    $sName: String
    $name: String
    $patientFirstName: String
    $patientLastName: String
    $patientEmail: String
    $physicianName: String
    $physicianEmail: String
    $image: String
    $passNo: String
    $templateId: String
    $gender: String
    $birthDate: String
    $countryCode: String
    $nationality: String
    $phoneNumber: String
    $clinicId: String
  ) {
    appointmentSet(
      type: $type
      clientId: $clientId
      centerId: $centerId
      physicianId: $physicianId
      patientId: $patientId
      startTime: $startTime
      endTime: $endTime
      startDate: $startDate
      street: $street
      zip: $zip
      city: $city
      country: $country
      isCompleted: $isCompleted
      description: $description
      geoLocation: $geoLocation
      sName: $sName
      name: $name
      patientFirstName: $patientFirstName
      patientLastName: $patientLastName
      patientEmail: $patientEmail
      physicianName: $physicianName
      physicianEmail: $physicianEmail
      image: $image
      passNo: $passNo
      templateId: $templateId
      gender: $gender
      birthDate: $birthDate
      countryCode: $countryCode
      nationality: $nationality
      phoneNumber: $phoneNumber
      clinicId: $clinicId
    ) {
      success
      message
    }
  }
`;

export const updateAppointment = gql`
  mutation appointmentUpdate(
    $id: ID!
    $type: String
    $clientId: ID
    $centerId: ID
    $physicianId: ID
    $patientId: ID
    $startTime: String
    $startDate: String
    $street: String
    $zip: String
    $city: String
    $country: String
    $isCompleted: Boolean
    $description: String
    $geoLocation: String
  ) {
    appointmentUpdate(
      id: $id
      type: $type
      clientId: $clientId
      centerId: $centerId
      physicianId: $physicianId
      patientId: $patientId
      startTime: $startTime
      startDate: $startDate
      street: $street
      zip: $zip
      city: $city
      country: $country
      isCompleted: $isCompleted
      description: $description
      geoLocation: $geoLocation
    ) {
      success
      message
    }
  }
`;

export const cancelAppointment = gql`
  mutation cancelAppointment($id: ID!) {
    cancelAppointment(id: $id)
  }
`;

export const completeAppointment = gql`
  mutation completeAppointment(
    $appointmentId: ID!
    $templateId: ID!
    $passNo: String
    $ssn: String
    $gender: String
    $birthDate: String
    $nationality: String
    $countryCode: String
    $labPhoneNumber: String
    $labAddress: String
    $labName: String
    $collectionDate: String
    $filePath: String
    $issuer: String
    $issuerPhoneNumber: String
    $issuerAddress: String
    $result: String
    $acsn: String
    $subType: String
  ) {
    completeAppointment(
      appointmentId: $appointmentId
      templateId: $templateId
      passNo: $passNo
      ssn: $ssn
      gender: $gender
      birthDate: $birthDate
      nationality: $nationality
      countryCode: $countryCode
      labPhoneNumber: $labPhoneNumber
      labAddress: $labAddress
      labName: $labName
      collectionDate: $collectionDate
      filePath: $filePath
      issuer: $issuer
      issuerPhoneNumber: $issuerPhoneNumber
      issuerAddress: $issuerAddress
      result: $result
      acsn: $acsn
      subType: $subType
    )
  }
`;

export const completeFftAppointment = gql`
  mutation completeFftAppointment(
    $appointmentId: ID!
    $gender: String
    $birthDate: String
    $labPhoneNumber: String
    $labAddress: String
    $labName: String
    $collectionDate: String
    $filePath: String
    $issuer: String
    $issuerPhoneNumber: String
    $issuerAddress: String
    $pinNo: String
    $physicianLicense: String
    $centerId: ID
    $isFit: Boolean
    $transportName: String
    $clinicId: ID
    $physicianId: ID!
  ) {
    completeFftAppointment(
      appointmentId: $appointmentId
      gender: $gender
      birthDate: $birthDate
      labPhoneNumber: $labPhoneNumber
      labAddress: $labAddress
      labName: $labName
      collectionDate: $collectionDate
      filePath: $filePath
      issuer: $issuer
      issuerPhoneNumber: $issuerPhoneNumber
      issuerAddress: $issuerAddress
      pinNo: $pinNo
      centerId: $centerId
      physicianLicense: $physicianLicense
      isFit: $isFit
      transportName: $transportName
      clinicId: $clinicId
      physicianId: $physicianId
    )
  }
`;

export const completePemeAppointment = gql`
  mutation completePemeAppointment(
    $appointmentId: ID!
    $birthDate: String
    $type: String
    $passNo: String
    $firstName: String
    $middleName: String
    $firstName: String
    $lastName: String
    $nationality: String
    $countryCode: String
    $gender: String
    $religion: String
    $seabookNo: String
    $position: String
    $company: String
    $civilStatus: String
    $declaration: Boolean
    $checkedDocs: Boolean
    $hearingStandards: Boolean
    $visionMeetsStandards: Boolean
    $visualAids: Boolean
    $fitForLookout: Boolean
    $fitnessLimitation: Boolean
    $hearingSatisfactory: Boolean
    $aggravatedHealth: Boolean
    $result: String
    $limitation: String
    $date: String
    $expirationDate: String
    $medicalDirector: String
    $lastColorTest: String
    $issuingAuthority: String
    $medicalDirectorSignature: String
    $signers: String
    $physicianSignature: String
    $physicianName: String
    $physicianLicense: String
    $authority: String
    $city: String
    $country: String
    $address: String
    $additionalImage: String
    $zip: String
    $template: String
    $drugFilePath: String
    $drugScreeningFilePath: String
    $roentgenologicalFilePath: String
    $hivScreeningPath: String
    $labResultsPath: String
    $auditoryResultsPath: String
    $patientCity: String
    $patientCountry: String
    $patientAddress: String
    $patientZip: String
    $birthPlace: String
    $subType: String
  ) {
    completePemeAppointment(
      appointmentId: $appointmentId
      type: $type
      passNo: $passNo
      firstName: $firstName
      middleName: $middleName
      lastName: $lastName
      nationality: $nationality
      countryCode: $countryCode
      gender: $gender
      birthDate: $birthDate
      religion: $religion
      seabookNo: $seabookNo
      position: $position
      company: $company
      civilStatus: $civilStatus
      declaration: $declaration
      checkedDocs: $checkedDocs
      additionalImage: $additionalImage
      hearingStandards: $hearingStandards
      visionMeetsStandards: $visionMeetsStandards
      visualAids: $visualAids
      fitForLookout: $fitForLookout
      fitnessLimitation: $fitnessLimitation
      hearingSatisfactory: $hearingSatisfactory
      aggravatedHealth: $aggravatedHealth
      limitation: $limitation
      signers: $signers
      date: $date
      expirationDate: $expirationDate
      medicalDirector: $medicalDirector
      lastColorTest: $lastColorTest
      issuingAuthority: $issuingAuthority
      medicalDirectorSignature: $medicalDirectorSignature
      physicianSignature: $physicianSignature
      physicianName: $physicianName
      physicianLicense: $physicianLicense
      authority: $authority
      city: $city
      result: $result
      country: $country
      address: $address
      zip: $zip
      template: $template
      drugFilePath: $drugFilePath
      drugScreeningFilePath: $drugScreeningFilePath
      roentgenologicalFilePath: $roentgenologicalFilePath
      hivScreeningPath: $hivScreeningPath
      labResultsPath: $labResultsPath
      auditoryResultsPath: $auditoryResultsPath
      patientCity: $patientCity
      patientCountry: $patientCountry
      patientAddress: $patientAddress
      patientZip: $patientZip
      birthPlace: $birthPlace
      subType: $subType
    )
  }
`;
