import gql from 'graphql-tag';

export const createInstructor = gql`
  mutation instructorSet(
    $clientId: ID
    $name: String
    $type: String
    $email: String
    $phoneNumber: String
    $image: String
    $signature: String
    $courses: [String]
    $centerId: ID
    $licenseNo: String
    $certAuthority: String
    $ogukPin: String
  ) {
    instructorSet(
      clientId: $clientId
      name: $name
      type: $type
      email: $email
      phoneNumber: $phoneNumber
      image: $image
      signature: $signature
      courses: $courses
      centerId: $centerId
      licenseNo: $licenseNo
      certAuthority: $certAuthority
      ogukPin: $ogukPin
    ) {
      success
      message
    }
  }
`;

export const updateInstructor = gql`
  mutation instructorUpdate(
    $id: ID
    $name: String
    $type: String
    $email: String
    $phoneNumber: String
    $image: String
    $signature: String
    $courses: [String]
    $centerId: ID
    $licenseNo: String
    $certAuthority: String
    $ogukPin: String
  ) {
    instructorUpdate(
      id: $id
      name: $name
      email: $email
      type: $type
      phoneNumber: $phoneNumber
      image: $image
      signature: $signature
      courses: $courses
      centerId: $centerId
      licenseNo: $licenseNo
      certAuthority: $certAuthority
      ogukPin: $ogukPin
    ) {
      success
      message
    }
  }
`;

export const deleteInstructor = gql`
  mutation instructorDelete($id: ID) {
    instructorDelete(id: $id)
  }
`;
