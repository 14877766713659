import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import { Table } from 'semantic-ui-react';
import { sortBy } from 'lodash';

import { toast } from 'react-toastify';
import moment from 'moment-timezone';

import { setClients } from '../clientPages/clientManagement/store/clientManagement.actions';

import { Text, ErrorToast, Select, DatePicker } from '../../components';
import Widget from '../dashboard/components/Widget/Widget';

import ClientService from '../../api/clients';
import LogService from '../../api/certificateLog';
import CertificateService from '../../api/certificates';
import LabResultService from '../../api/labResults';

import './stats.scss';

const Stats = props => {
  const dispatch = useDispatch();

  // const [certsCount, setCertsCount] = useState(0);
  const [isShowingAllClient, setShowingAllClient] = useState(false);
  const [blockCount, setBlockCount] = useState(0);
  const [selectedClient, setSelectedClient] = useState(null);
  const [formattedClients, setFormattedClients] = useState([]);
  const [logs, setLogs] = useState([]);
  const [issuedArts, setIssuedArts] = useState([]);
  const [smsCount, setSmsCount] = useState(0);
  const [smsResp, setSmsResp] = useState([]);
  const [documentsIssued, setDocumentsIssued] = useState(0);
  const [startDate, setStartDate] = useState(
    moment()
      .subtract(1, 'months')
      .toDate()
  );
  const [endDate, setEndDate] = useState(moment().toDate());
  const [clientInfo, setClientInfo] = useState('');
  const [clientMap, setClientMap] = useState({});

  const clients = useSelector(state => state.clientManagement.clients);
  const clientInformation = useSelector(
    state => state.general.clientInformation
  );

  useEffect(() => {
    formatClients(clients);
  }, [clients]); //eslint-disable-line

  useEffect(() => {
    getInitialData();
  }, [startDate, endDate, selectedClient]); //eslint-disable-line

  useEffect(() => {
    if (clientInformation?.id === 'zHAzw9fw2H4cGE8udj50') {
      getIssuedArts();
      getIssuedDocsRange();
    }
  }, [startDate, endDate]); //eslint-disable-line

  const getInitialData = async () => {
    if (clientInformation.role === 'lab') {
      if (clientInformation.searchAgg) getIssuedDocsRange();
      else getIssuedLabResultsRange();
    } else if (selectedClient) fetchLogs();
    else if (clientInformation?.id === 'zHAzw9fw2H4cGE8udj50') {
      getIssuedDocsRange();
      await fetchClients();
      getSmsPerMonth(clientInformation.id);
    } else getSingleClient();
  };

  const getIssuedArts = async () => {
    try {
      const {
        data: { getIssuedDocsRange },
      } = await CertificateService.getIssuedDocsRange(
        'zHAzw9fw2H4cGE8udj50',
        `${moment(startDate)
          .tz('Asia/Singapore')
          .startOf('day')
          .toISOString()}`,
        `${moment(endDate)
          .tz('Asia/Singapore')
          .endOf('day')
          .toISOString()}`,
        [
          'SG_ANTI_RAPID_TEST',
          'RMSP_SG_ANTI_RAPID_TEST',
          'SP_SG_ANTI_RAPID_TEST',
          'PA_SG_ANTI_RAPID_TEST',
        ]
      );

      const formattedData = getIssuedDocsRange.map(data => {
        return {
          client: data._id.issuerName,
          count: data.count,
        };
      });

      const sortedData = sortBy(formattedData, 'client');

      setIssuedArts(sortedData);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  const getSingleClient = async () => {
    try {
      const {
        data: { client },
      } = await ClientService.getClientByID(clientInformation.id);

      setSelectedClient(client.id);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  const getIssuedDocsRange = async () => {
    try {
      const {
        data: { getIssuedDocsRange },
      } = await CertificateService.getIssuedDocsRange(
        clientInformation.id,
        `${moment(startDate)
          .tz('Asia/Singapore')
          .startOf('day')
          .toISOString()}`,
        `${moment(endDate)
          .tz('Asia/Singapore')
          .endOf('day')
          .toISOString()}`
      );

      let docsCount = 0;

      const formattedData = getIssuedDocsRange.map(data => {
        docsCount += data.count;
        return {
          client: data._id.issuerName,
          count: data.count,
        };
      });

      const sortedData = sortBy(formattedData, 'client');
      setDocumentsIssued(docsCount);
      setLogs(sortedData);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  const getIssuedLabResultsRange = async () => {
    try {
      const {
        data: { getIssuedLabResultsRange },
      } = await LabResultService.getIssuedLabResultsRange(
        clientInformation.id,
        `${moment(startDate)
          .tz('Asia/Singapore')
          .startOf('day')
          .toISOString()}`,
        `${moment(endDate)
          .tz('Asia/Singapore')
          .endOf('day')
          .toISOString()}`
      );

      let docsCount = 0;

      const formattedData = getIssuedLabResultsRange.map(data => {
        docsCount += data.count;
        return {
          client: data._id.issuerName,
          count: data.count,
        };
      });

      const sortedData = sortBy(formattedData, 'client');

      setDocumentsIssued(docsCount);
      setLogs(sortedData);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  const getSmsPerMonth = async client => {
    if (!client) return;
    try {
      const {
        data: { getSmsCount },
      } = await ClientService.getSmsCount(
        client,
        `${moment(startDate)
          .tz('Asia/Singapore')
          .startOf('day')
          .toISOString()}`,
        `${moment(endDate)
          .tz('Asia/Singapore')
          .endOf('day')
          .toISOString()}`
      );
      const parsedResp = JSON.parse(getSmsCount);
      const sortedData = sortBy(parsedResp, 'client');

      const enrichedSmsData = sortedData.map(data => {
        console.log('data.key', data.key);
        return {
          doc_count: data.doc_count,
          client: clientMap[data.key],
        };
      });
      console.log('enrichedSmsData', enrichedSmsData);
      console.log('clientMap', clientMap);
      setSmsResp(enrichedSmsData);
      if (parsedResp && parsedResp.length > 1) {
        let count = 0;
        parsedResp.forEach(client => (count += client.doc_count));
        setSmsCount(count);
      } else
        setSmsCount(parsedResp && parsedResp[0] ? parsedResp[0].doc_count : 0);
    } catch (error) {
      toast.error(<ErrorToast message="Error fetching sms" />);
    }
  };

  const formatClients = clientData => {
    const clientMapz = {};
    const formattedClients = clientData.map(client => {
      clientMapz[client.id] = client.name;
      return {
        text: client.name,
        value: client.id,
      };
    });

    setFormattedClients(formattedClients);
    setClientMap(clientMapz);
  };

  const fetchClients = async () => {
    if (clients.length > 0) return;
    try {
      const {
        data: { clients },
      } = await ClientService.getAllClients();
      dispatch(setClients(clients));
    } catch {
      toast.error(<ErrorToast message="Error fetching clients" />);
    }
  };

  const fetchLogs = async () => {
    if (!selectedClient) return;

    try {
      const {
        data: { issueLogByClient },
      } = await LogService.issueLogByClient(
        selectedClient,
        `${moment(startDate)
          .tz('Asia/Singapore')
          .startOf('day')
          .toISOString()}`,
        `${moment(endDate)
          .tz('Asia/Singapore')
          .endOf('day')
          .toISOString()}`
      );
      const orderedList = sortBy(issueLogByClient, 'createdAt');
      setLogs(orderedList);
      getBlockCount(orderedList);
    } catch {
      toast.error(<ErrorToast message="Error fetching logs" />);
    }
  };

  const getBlockCount = logz => {
    let count = 0;
    logz.forEach(log => {
      if (log.saveToBlock) count++;
    });
    setBlockCount(count);
  };

  // const clientIssuedCertsCount = async () => {
  //   if (!selectedClient) return;
  //   try {
  //     const clientId = selectedClient;
  //     const {
  //       data: { client },
  //     } = await ClientService.getClientIssuedCerts(clientId);
  //     setCertsCount(client.certificatesIssued);
  //   } catch (error) {
  //     toast.error(<ErrorToast message="Error fetching certs issued count" />);
  //   }
  // };

  // const onView = certId => {
  //   const win = window.open(`/certificate/${certId}`, '_blank');
  //   win.focus();
  // };

  const renderLabTableData = data => {
    return data.map(log => (
      <Table.Row key={log.id} className="table-row patients-overview__row">
        <Table.Cell>
          <Text size="small">{log.client}</Text>
        </Table.Cell>
        <Table.Cell>
          <Text size="small">{log.count}</Text>
        </Table.Cell>
      </Table.Row>
    ));
  };

  const renderSmsTableData = () => {
    return smsResp.map(log => (
      <Table.Row key={log.key} className="table-row patients-overview__row">
        <Table.Cell>
          <Text size="small">{log.client}</Text>
        </Table.Cell>
        <Table.Cell>
          <Text size="small">{log.doc_count}</Text>
        </Table.Cell>
      </Table.Row>
    ));
  };

  const findClientInfo = clientId => {
    clients.forEach(client => {
      if (client.id === clientId) setClientInfo(client);
    });
  };

  const selectClient = value => {
    // setCertsCount(0);
    setBlockCount(0);
    setLogs([]);
    setSelectedClient(value);
    findClientInfo(value);
  };

  return (
    <div className="stats">
      <Text className="mb-3 ml-3" type="header">
        Analytics
      </Text>
      <div className="stats__header">
        {clientInformation?.id === 'zHAzw9fw2H4cGE8udj50' && (
          <>
            <div className="stats__header--client">
              <Text onClick={() => setShowingAllClient(false)}>
                Select a client
              </Text>
            </div>

            <Select
              size="large"
              // label="Select a client"
              border
              search
              icon={false}
              placeholder="Clients"
              className="mb-3 stats__header-select-client"
              options={formattedClients}
              value={selectedClient}
              onChange={(e, { value }) => selectClient(value)}
            />
          </>
        )}

        <div className="stats__dates">
          <Text type="label">Date range</Text>
          <div className="stats__dates__wrapper">
            <DatePicker onChange={setStartDate} value={startDate} />
            <DatePicker onChange={setEndDate} value={endDate} />
          </div>
        </div>
      </div>
      <div className="stats__header stats__header--grey">
        <div>
          <div className="stats__widgets">
            <Widget
              data={selectedClient ? logs.length : documentsIssued}
              header="Date range"
              label={
                clientInformation.role !== 'lab' ||
                (clientInformation.role === 'lab' &&
                  clientInformation.searchAgg)
                  ? 'Documents Issued'
                  : 'Lab Results Sent'
              }
            />
            {smsCount > 0 && (
              <Widget
                data={smsCount}
                header="SMS count"
                // label="Saved to blockchain"
              />
            )}
          </div>
          {clientInformation?.id === 'zHAzw9fw2H4cGE8udj50' && (
            <>
              <Text size="big" bold className="mt-6">
                Client details
              </Text>
              <div className="stats__data-container">
                <div className="stats__data-wrapper">
                  <Text type="label" color="lightGrey">
                    HCI Code
                  </Text>
                  <Text size="big" color="black">
                    {clientInfo.hciCode}
                  </Text>
                </div>
                <div className="stats__data-wrapper">
                  <Text type="label" color="lightGrey">
                    Sub Domain
                  </Text>
                  <Text size="big" color="black">
                    {clientInfo.subdomain}
                  </Text>
                </div>
                <div className="stats__data-wrapper">
                  <Text type="label" color="lightGrey">
                    Plan Type
                  </Text>
                  <Text size="big" color="black">
                    {clientInfo.planType}
                  </Text>
                </div>

                <div className="stats__data-wrapper">
                  <Text type="label" color="lightGrey">
                    Plan Cost (USD)
                  </Text>
                  <Text size="big" color="black">
                    {clientInfo.cost}
                  </Text>
                </div>
              </div>
            </>
          )}
          <div style={{ display: 'flex' }}>
            {clientInformation?.id === 'zHAzw9fw2H4cGE8udj50' && (
              <div className="stats__table">
                <Text size="big" bold>
                  Total issued Docs
                </Text>
                <Table singleLine striped collapsing>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Client</Table.HeaderCell>
                      <Table.HeaderCell>Count</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>{renderLabTableData(logs)}</Table.Body>
                </Table>
              </div>
            )}
            {clientInformation?.id === 'zHAzw9fw2H4cGE8udj50' && (
              <div className="stats__table">
                <Text size="big" bold>
                  Issued ARTs
                </Text>
                <Table collapsing striped>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Client</Table.HeaderCell>
                      <Table.HeaderCell>Count</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>{renderLabTableData(issuedArts)}</Table.Body>
                </Table>
              </div>
            )}
            {clientInformation.role === 'lab' && (
              <div className="stats__table">
                <Text size="big" bold>
                  {clientInformation.searchAgg
                    ? 'Documents issued'
                    : 'Lab Results issued'}
                </Text>
                <Table collapsing striped>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Client</Table.HeaderCell>
                      <Table.HeaderCell>Count</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>{renderLabTableData(logs)}</Table.Body>
                </Table>
              </div>
            )}
            {smsResp.length > 1 && (
              <div className="stats__table ml-6">
                <Text size="big" bold>
                  SMS sending
                </Text>
                <Table singleLine striped collapsing>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Client</Table.HeaderCell>
                      <Table.HeaderCell>Count</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>{renderSmsTableData()}</Table.Body>
                </Table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Stats);
