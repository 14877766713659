export const SET_COMPLETED_COUNT = 'DASHBOARD/SET_COMPLETED_COUNT';
export const SET_PARTICIPANT_COUNT = 'DASHBOARD/SET_PARTICIPANT_COUNT';
export const SET_CORP_PROFILES = 'DASHBOARD/SET_CORP_PROFILES';

/**
 * @function setCompletedCount
 * @description Set information of the logged in client
 */
export const setCompletedCount = count => ({
  type: SET_COMPLETED_COUNT,
  payload: count,
});

/**
 * @function setParticipantCount
 * @description Set information of the logged in client
 */
export const setParticipantCount = count => ({
  type: SET_PARTICIPANT_COUNT,
  payload: count,
});

/**
 * @function setParticipantCount
 * @description Set information of the logged in client
 */
export const setCorpProfiles = payload => ({
  type: SET_CORP_PROFILES,
  payload,
});
