import React from 'react';
import { Table } from 'semantic-ui-react';
import { Text, Icon } from '../../../../../components';

import './paymentWidget.scss';

const PaymentWidget = props => {
  const renderTableData = () => {
    return props.data.map(({ name, value, amount }) => {
      return (
        <Table.Row>
          <Table.Cell style={{ textTransform: 'capitalize' }}>
            {name === 'on_location' ? 'On location' : name}
          </Table.Cell>
          <Table.Cell>{value}</Table.Cell>
          <Table.Cell>{amount}</Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <div className="payment-widget__wrapper">
      <Text size="tiny" dark>
        {props.header}
      </Text>
      <div className="payment-widget__table-wrapper">
        <Table className="payment-widget__table" unstackable selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Type</Table.HeaderCell>
              <Table.HeaderCell>Count</Table.HeaderCell>
              <Table.HeaderCell>Amount ({props.currency})</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{props.data && renderTableData()}</Table.Body>
        </Table>
      </div>
    </div>
  );
};

export default PaymentWidget;
