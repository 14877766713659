import React from 'react';
import { Table } from 'semantic-ui-react';
import { Text, Icon } from '../../../../../components';

const AppointmentsWidget = props => {
  const renderTableData = () => {
    return props?.data?.map(({ name, type, value }) => {
      return (
        <Table.Row>
          <Table.Cell>{name || type}</Table.Cell>
          <Table.Cell>{value}</Table.Cell>
        </Table.Row>
      );
    });
  };

  return (
    <div>
      <Table unstackable selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>
              {props.type === 'tests'
                ? ' Number of Patients'
                : 'Number of Appointments'}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{renderTableData()}</Table.Body>
      </Table>
    </div>
  );
};

export default AppointmentsWidget;
