import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Table, Icon, Label, Loader } from 'semantic-ui-react';
import moment from 'moment';
import CircleLoader from 'react-spinners/CircleLoader';

import {
  ErrorToast,
  Search,
  PageHeader,
  Select,
  Button,
} from '../../../components';
import EmailService from '../../../api/email';

import './emailActivity.scss';

const EmailActivity = props => {
  const clientInfo = useSelector(state => state.general.clientInformation);
  const [emails, setEmails] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [statusFilter, setStatusFilter] = useState('all');
  const [filterLimit, setFilterLimit] = useState(25);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    // const { state } = props.location;

    // if (state && state.status) setStatusFilter(state.status);

    const getEmails = async () => {
      setIsLoadingMore(true);
      try {
        setIsLoading(true);
        const {
          data: { getEmailsByClientId },
        } = await EmailService.getEmailsByClientId({
          clientId: clientInfo.id,
          status: statusFilter,
          limit: filterLimit,
          search: searchValue,
        });
        setEmails(getEmailsByClientId);
        setFilteredData(getEmailsByClientId);
        setIsLoading(false);
      } catch (error) {
        toast.error(<ErrorToast error={error} />);
        setIsLoading(false);
      }
      setIsLoadingMore(false);
    };

    getEmails();
  }, [statusFilter, searchValue]);

  // const searchTable = (e, { value }) => {
  //
  //   const filteredResults = emails.filter(o => {
  //     return Object.keys(o).some(k => {
  //       if (k === 'email' || k === 'subject') {
  //         if (o[k]) return o[k].toLowerCase().includes(value.toLowerCase());
  //       }
  //     });
  //   });
  //   setFilteredData(filteredResults);
  // };

  const searchEmail = () => {
    setSearchValue(inputValue);
  };

  const searchTable = useCallback(
    (e, { value }) => {
      setInputValue(value);
    },
    [inputValue]
  );

  const fetchMoreEvents = useCallback(() => {
    setFilterLimit(prevLimit => prevLimit + 25);
  }, []);

  // const fetchMoreEvents = async () => {
  //   setIsLoadingMore(true);
  //   setFilterLimit(prevLimit => prevLimit + 25);
  //   console.log('limit', filterLimit);
  //   const {
  //     data: { getEmailsByClientId },
  //   } = await EmailService.getEmailsByClientId({
  //     clientId: clientInfo.id,
  //     status: statusFilter,
  //     limit: filterLimit,
  //   });
  //   setEmails(getEmailsByClientId);
  //   setFilteredData(getEmailsByClientId);
  //   setIsLoadingMore(false);
  // };

  const renderStatusColor = status => {
    if (status === 'delivered' || status === 'processed') return 'green';
    if (status === 'bounce' || status === 'dropped') return 'red';
    return 'green';
  };

  const getStatusText = status => {
    if (status === 'bounce' || status === 'dropped') return 'Not Delivered';
    return 'Delivered';
  };

  const filterOptions = [
    { text: 'All', value: 'all' },
    { text: 'Delivered', value: 'delivered' },
    { text: 'Not Delivered', value: 'notdelivered' },
  ];

  const renderTableData = () => {
    return filteredData.map((email, i) => (
      <Table.Row key={i} className="table-row email-activity__row">
        <Table.Cell>{email.email}</Table.Cell>
        <Table.Cell>{email.patientName}</Table.Cell>
        <Table.Cell>{moment(email.timestamp).format('DD-MM-YYYY')}</Table.Cell>
        <Table.Cell>{moment(email.timestamp).format('HH:mm a')}</Table.Cell>
        <Table.Cell>{moment(email.timestamp).fromNow()}</Table.Cell>
        <Table.Cell textAlign="center">
          <Label color={renderStatusColor(email.event)}>
            {getStatusText(email.event)}
          </Label>
        </Table.Cell>
      </Table.Row>
    ));
  };

  return (
    <div className="email-activity">
      <div className="email-activity__header">
        <PageHeader>Email Activity</PageHeader>
      </div>

      <div className="email-activity__search">
        <Select
          icon={false}
          border
          options={filterOptions}
          value={statusFilter}
          onChange={(e, { value }) => setStatusFilter(value)}
          className="email-activity__search__select"
        />
        <div className="email-activity__search-inputs">
          <Search
            id="searchEmail"
            size="large"
            dropdown={false}
            value={inputValue}
            onSearchChange={searchTable}
            onKeyPress={(e, value) => (e.key === 'Enter' ? searchEmail() : '')}
            isFilter={true}
            className="mr-3"
          />
          <Button onClick={() => searchEmail()} color="blue">
            search
          </Button>
        </div>
      </div>

      {isLoading ? (
        <div className="email-activity__loader">
          <CircleLoader color="#455af7" />
        </div>
      ) : (
        <div className="email-activity__table__wrapper">
          <Table className="email-activity__table" unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell>Patient Name</Table.HeaderCell>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>Time sent</Table.HeaderCell>
                <Table.HeaderCell>Last Event Time</Table.HeaderCell>
                <Table.HeaderCell textAlign="center">Status</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body className="email-activity__table__body">
              {renderTableData()}
            </Table.Body>
          </Table>
        </div>
      )}
      {filteredData.length > 24 && (
        <div className="email-activity__load-button">
          <Button
            onClick={() => fetchMoreEvents()}
            disabled={isLoadingMore}
            size="wide"
            color="blue"
          >
            {isLoadingMore ? <Loader active size="tiny" /> : 'Load more'}
          </Button>
        </div>
      )}
    </div>
  );
};

export default withRouter(EmailActivity);
