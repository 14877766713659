import React from 'react';
import styled from 'styled-components';
import CircleLoader from 'react-spinners/CircleLoader';

const LoaderPage = props => {
  return (
    <LoaderContainer>
      <CircleLoader size={75} color="#455af7" loading />
    </LoaderContainer>
  );
};

const LoaderContainer = styled.div`
  top: 0;
  height: 100%;
  left: 0;
  width: 100%;
  z-index: 99999;
  position: fixed;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 150px;
`;

export default LoaderPage;
