import React from 'react';

import chartsColors from '../../../../../helpers/chartsColors';

import { BarChart, Bar } from 'recharts';

const LineGraph = ({
  height,
  hidden,
  data,
  width,
  children,
  hoveredSubType,
  keys,
  hovering,
}) => {
  return (
    <BarChart
      width={width}
      height={height}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      {children}
      {keys.map((val, index) => (
        <Bar
          key={val}
          stackId="a"
          dataKey={val}
          fillOpacity={
            (hovering && hoveredSubType === val) || !hovering ? 1 : 0.7
          }
          fill={chartsColors[keys.indexOf(val) !== -1 ? keys.indexOf(val) : 20]}
          // hide total or else it would stack a bar with the amount as total on top of existing bar
          hide={hidden.indexOf(val) !== -1 || val === 'Total'}
        />
      ))}
    </BarChart>
  );
};
export default LineGraph;
